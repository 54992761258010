
  import { ValidationProvider } from 'vee-validate'

  export default {
    components: {
      ValidationProvider,
    },
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: '',
      },
      rules: {
        type: [ String, Object ],
        default: '',
        validator(value) {
          if (!value || typeof value === 'string') {
            return true
          }

          return (
            value.name &&
            (value.params) ? Object.keys(value.params).length > 0 : true
          )
        },
      },
      setFocus: {
        type: Boolean,
        default: false,
      },
      suggestion: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'text',
      },
      hideValidationCheckmark: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'input',
      'select-suggestion',
    ],
    data() {
      return {
        focussed: false,
      }
    },
    computed: {
      hasValidation() {
        return (
          typeof this.rules === 'string'
            ? this.rules.length
            : Object.keys(this.rules).length
        ) || this.$attrs.hasOwnProperty('required')
      },
    },
    mounted() {
      if (this.setFocus) {
        this.$refs.inputField.focus()
      }
    },
    methods: {
      onBlur() {
        this.focussed = false
      },
      onFocus() {
        this.focussed = true
      },
      updateInput(e) {
        this.$emit('input', e.target.value)
      },
      useSuggestion() {
        this.$emit('select-suggestion', this.suggestion)
      },
    },
  }
