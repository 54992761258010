/*
  We use the following steps to determine in which country the user is located.

  1. Do a call to the default /data/countries/other.json
  2. Netlify will determine from which country the request comes using GeoIP
  3. Netlify will redirect the request if it's one of the primary countries (example: /data/countries/nl.json)
  4. Use the json body to tell the application which country the user is currently in
*/

import axios from 'axios'

// This endpoint gets a Netlify geoIP redirect to `/data/countries/<some_netlify_determined_country_code>.json`
// if the request is from one of the primary countries.
const GEOIP_BASED_ENDPOINT = '/data/countries/other.json'

/**
 * @returns {promise<string>} country
 */
export default async function () {
  const { data } = await axios.get(GEOIP_BASED_ENDPOINT)

  return data.country
}
