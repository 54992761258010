import {
  MOLLIE_CREDIT_CARD_CODE,
  MOLLIE_GIFT_CARD_PAYMENT_METHOD_TYPE,
  MOLLIE_IDEAL_PAYMENT_METHOD_TYPE,
  MOLLIE_KLARNA_PAYMENT_METHOD_PREFIX,
  MOLLIE_PAYPAL_PAYMENT_METHOD_TYPE,
  MAGENTO_CODE_BILLINK,
  ADYEN_IDEAL_PAYMENT_METHOD_TYPE,
  ADYEN_GIFT_CARD_PAYMENT_METHOD_TYPE,
  ADYEN_CREDIT_CARD_CODE,
  ADYEN_GIFT_CARD_CODE,
  ADYEN_KLARNA_PAYMENT_METHOD_TYPE,
  MOLLIE_BANCONTACT_METHOD_TYPE,
  MOLLIE_APPLE_PAY_METHOD_TYPE,
  ADYEN_APPLE_PAY_METHOD_TYPE,
} from '~/lib/constants'

/**
 * @typedef {PaymentMethodImageService}
 * @alias this.$paymentMethodImageService
 */
export class PaymentMethodImageService {
  /** @type {ServerNuxtContext} */
  constructor(context) {
    this.context = context
    this.baseUrl = context.env.adyenImageBaseUrl
    this.paymentMethodImageSources = {
      [MOLLIE_CREDIT_CARD_CODE]: '/logos/credit-cards.svg',
      [ADYEN_CREDIT_CARD_CODE]: '/logos/credit-cards.svg',
      [MOLLIE_CREDIT_CARD_CODE + '_be']: '/logos/credit-cards-be.svg',
      [ADYEN_CREDIT_CARD_CODE + '_be']: '/logos/credit-cards-be.svg',
      [MOLLIE_GIFT_CARD_PAYMENT_METHOD_TYPE]: '/logos/gift-cards-icon.jpg',
      [ADYEN_GIFT_CARD_PAYMENT_METHOD_TYPE]: '/logos/gift-cards-icon.jpg',
      [ADYEN_GIFT_CARD_CODE]: '/logos/gift-cards-icon.jpg',
      [MOLLIE_IDEAL_PAYMENT_METHOD_TYPE]: '/logos/ideal.svg',
      [ADYEN_IDEAL_PAYMENT_METHOD_TYPE]: '/logos/ideal.svg',
      [MOLLIE_PAYPAL_PAYMENT_METHOD_TYPE]: '/logos/paypal.svg',
      [MOLLIE_KLARNA_PAYMENT_METHOD_PREFIX]: '/logos/klarna.svg',
      [ADYEN_KLARNA_PAYMENT_METHOD_TYPE]: '/logos/klarna.svg',
      [MAGENTO_CODE_BILLINK]: '/logos/billink.png',
      [MOLLIE_BANCONTACT_METHOD_TYPE]: '/logos/bancontact_logo.svg',
      [MOLLIE_APPLE_PAY_METHOD_TYPE]: '/logos/apple-pay-icon.svg',
      [ADYEN_APPLE_PAY_METHOD_TYPE]: '/logos/apple-pay-icon.svg',
    }
  }

  init() {
    this.$checkoutApiService = this.context.$checkoutApiService
  }

  /**
   * @param {loavies.models.payment.PaymentMethodModel} paymentMethod
   * @return {string}
   */
  getImageByMethod(paymentMethod) {
    return this.getImage(paymentMethod.type)
  }

  /**
   * @param {loavies.models.payment.PaymentMethodIssuerModel} paymentMethodIssuer
   * @return {string}
   */
  getImageByIssuer(paymentMethodIssuer) {
    return this.getImage(paymentMethodIssuer.type, paymentMethodIssuer.id)
  }

  /**
   * @param {string} type
   * @param {string} [id]
   */
  getImage(type, id) {
    let url = `${this.baseUrl}/${type}`

    if (id) {
      url += `/${id}`
    }

    return `${url}.svg`
  }

  /**
   * @param {string} paymentMethodId
   * @return {null|string}
   */
  getImageSourceByPaymentMethodId(paymentMethodId) {
    if (paymentMethodId.startsWith(MOLLIE_KLARNA_PAYMENT_METHOD_PREFIX)) {
      return this.paymentMethodImageSources[MOLLIE_KLARNA_PAYMENT_METHOD_PREFIX]
    }

    return this.paymentMethodImageSources[paymentMethodId] ?? null
  }

  async getImageSourceByPaymentMethodIssuerId(issuerId) {
    const molliePaymentMethods = await this.$checkoutApiService.getCartMolliePaymentMethodsMetaData()
    const combinedIssuers = molliePaymentMethods.reduce((acc, item) => {
      acc = [...acc, ...item.issuers]

      return acc
    }, [])

    return combinedIssuers.find(issuer => issuer.id === issuerId)?.image_svg ?? null
  }
}
