export default {
  setActiveAppModal(context, payload) {
    context.commit('SET_ACTIVE_APP_MODAL', payload)
  },

  setActiveAppModalTransition(context, payload) {
    context.commit('SET_ACTIVE_APP_MODAL_TRANSITION', payload)
  },

  setActiveAppModalOptions(context, payload) {
    context.commit('SET_ACTIVE_APP_MODAL_OPTIONS', payload)
  },

  setActiveAppTray(context, payload) {
    context.commit('SET_ACTIVE_APP_TRAY', payload)
  },

  setActiveAppTrayTransition(context, payload) {
    context.commit('SET_ACTIVE_APP_TRAY_TRANSITION', payload)
  },

  setActiveAppTrayOptions(context, payload) {
    context.commit('SET_ACTIVE_APP_TRAY_OPTIONS', payload)
  },

  setActiveFlyIn(context, payload) {
    context.commit('SET_ACTIVE_FLY_IN', payload)
  },

  setActiveFlyInTransition(context, payload) {
    context.commit('SET_ACTIVE_FLY_IN_TRANSITION', payload)
  },

  setActiveFlyInOptions(context, payload) {
    context.commit('SET_ACTIVE_FLY_IN_OPTIONS', payload)
  },

  setCurrentOverlay(context, payload) {
    context.commit('SET_CURRENT_OVERLAY', payload)
  },

  closeAppModal(context) {
    context.commit('CLOSE_APP_MODAL')
  },

  closeAppTray(context) {
    context.commit('CLOSE_APP_TRAY')
  },

  closeFlyIn(context) {
    context.commit('CLOSE_FLY_IN')
  },
}
