import formatMagentoErrorMessage from '~/lib/format-magento-error-message'

/**
 * @typedef {NewsletterService}
 * @alias this.$newsletterService
 */
export class NewsletterService {
  #newsletterSubscribeSuccessMessage
  #newsletterUnsubscribeSuccessMessage
  #newsletterErrorMessage

  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store

    this.#newsletterSubscribeSuccessMessage = 'newsletter_success'
    this.#newsletterUnsubscribeSuccessMessage = 'newsletter_unsubscribe'
    this.#newsletterErrorMessage = 'newsletter_fail'
  }

  init() {
    this.$trackingService = this.context.$trackingService
    this.$newsletterApiService = this.context.$newsletterApiService
    this.$emailValidatorService = this.context.$emailValidatorService
  }

  /**
   * @param {string} [newsletterSubscriptionTag]
   * @param {string} [emailAddress]
   * @param {boolean} [silentMessages]
   * @param {boolean} [silentErrors]
   * @returns {Promise}
   */
  subscribe({ newsletterSubscriptionTag, emailAddress, silentMessages, silentErrors } = {}) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']

    if (!isAuthenticated && !emailAddress) {
      return Promise.reject(Error('User needs to be authenticated or e-mail needs to be provided'))
    }

    if (isAuthenticated) {
      this.$trackingService.subscribeToNewsletter({
        email: emailAddress,
        newsletterSubscriptionTag,
      })

      return this.$newsletterApiService
        .subscribe(newsletterSubscriptionTag)
        .then(() => this.store.dispatch('user/setUserNewsletterSubscriptionStatus', { isSubscribedToNewsletter: true }))
        .then(() => {
          if (silentMessages) {
            return Promise.resolve()
          }

          return this.#onNewsletterSubscribeSuccess()
        })
        .catch(error => {
          if (silentErrors || silentMessages) {
            return Promise.reject(error)
          }

          return this.#onNewsletterError(error)
        })
    }

    return this.subscribeWithEmail({ newsletterSubscriptionTag, emailAddress, silentMessages, silentErrors })
  }

  /**
   * @returns {Promise}
   */
  unsubscribe() {
    return this.$newsletterApiService
      .unsubscribe()
      .then(() => this.store.dispatch('user/setUserNewsletterSubscriptionStatus', { isSubscribedToNewsletter: false }))
      .then(() => this.#onNewsletterUnsubscribeSuccess())
      .catch(error => this.#onNewsletterError(error))
  }

  /**
   * @returns {Promise}
   */
  toggleSubscribe() {
    const isAuthenticated = this.store.getters['user/isAuthenticated']

    if (!isAuthenticated) {
      return Promise.reject(Error('Not authenticated'))
    }

    const isSubscribedToNewsletter = this.store.state.user.isSubscribedToNewsletter

    if (isSubscribedToNewsletter) {
      return this.unsubscribe()
    }

    return this.subscribe()
  }

  /**
   * @param {string} emailAddress
   * @param {string} [newsletterSubscriptionTag]
   * @param {boolean} [silentMessages]
   * @param {boolean} [silentErrors]
   * @returns {Promise}
   */
  subscribeWithEmail({ emailAddress, newsletterSubscriptionTag, silentMessages, silentErrors } = {}) {
    if (!emailAddress) {
      return Promise.reject(Error('Missing e-mail address'))
    }

    this.$trackingService.subscribeToNewsletter({
      email: emailAddress,
      newsletterSubscriptionTag,
    })

    return this.$newsletterApiService
      .subscribeWithEmail({
        emailAddress,
        newsletterSubscriptionTag,
      })
      .then(() => {
        if (silentMessages) {
          return Promise.resolve()
        }

        return this.#onNewsletterSubscribeSuccess()
      })
      .catch(error => {
        if (silentErrors || silentMessages) {
          return Promise.reject(error)
        }

        return this.#onNewsletterError(error)
      })
  }

  /**
   * @returns {Promise}
   */
  #onNewsletterSubscribeSuccess() {
    return this.store.dispatch('snackbar/addSnackbarMessage', { message: this.#newsletterSubscribeSuccessMessage })
  }

  /**
   * @returns {Promise}
   */
  #onNewsletterUnsubscribeSuccess() {
    return this.store.dispatch('snackbar/addSnackbarMessage', { message: this.#newsletterUnsubscribeSuccessMessage })
  }

  /**
   * @param {Object} error
   * @return {Promise<Error>}
   */
  async #onNewsletterError(error) {
    const message = formatMagentoErrorMessage(error?.response?.data) || this.#newsletterErrorMessage

    await this.store.dispatch('snackbar/addSnackbarMessage', { message })

    return Promise.reject(error)
  }

  /**
   * @param {string} emailAddress
   * @return {Promise<number>}
   */
  getEmailSubscriptionStatus(emailAddress) {
    if (!this.$emailValidatorService.isValid(emailAddress)) {
      return Promise.reject(Error('Invalid e-mail'))
    }

    return this.$newsletterApiService.getSubscriptionStatus(emailAddress)
  }

  /**
   * @param {number} status
   * @returns {boolean}
   */
  newsletterStatusIsUnsubscribed(status) {
    return status === NEWSLETTER_STATUS.UNSUBSCRIBED
  }
}

export const NEWSLETTER_STATUS = {
  'SUBSCRIBED': 1,
  // NOT_ACTIVE means the user doesn't have an account
  // but has an unconfirmed newsletter subscription status
  'NOT_ACTIVE': 2,
  'UNSUBSCRIBED': 3,
  'UNCONFIRMED': 4,
}
