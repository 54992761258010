import { GUEST_CART_COOKIE_NAME, USER_CART_COOKIE_NAME } from '~/lib/constants'
import cookie from '~/lib/cookie'

export default {
  /**
   * @param {object} context
   * @param {string[]} [preservedProperties]
   */
  resetState(context, preservedProperties) {
    cookie(USER_CART_COOKIE_NAME, false)
    cookie(GUEST_CART_COOKIE_NAME, false)
    context.commit('RESET_STATE', preservedProperties)
  },

  /**
   * @param context
   * @param {string|null} cartId
   */
  setCartId(context, cartId) {
    context.commit('SET_CART_ID', cartId)
  },

  /**
   * @param context
   * @param {number} cartSubTotal
   */
  setCartSubTotal(context, cartSubTotal) {
    context.commit('SET_CART_SUB_TOTAL', cartSubTotal)
  },

  /**
   * @param context
   * @param {number} cartSubTotalExclTax
   */
  setCartSubTotalExclTax(context, cartSubTotalExclTax) {
    context.commit('SET_CART_SUB_TOTAL_EXCL_TAX', cartSubTotalExclTax)
  },

  /**
   * @param context
   * @param {number} cartTotal
   */
  setCartTotal(context, cartTotal) {
    context.commit('SET_CART_TOTAL', cartTotal)
  },

  /**
   * @param context
   * @param {number} cartGrandTotal
   */
  setCartGrandTotal(context, cartGrandTotal) {
    context.commit('SET_CART_GRAND_TOTAL', cartGrandTotal)
  },

  /**
   * @param context
   * @param {number} freeShippingThreshold
   */
  setFreeShippingThreshold(context, freeShippingThreshold) {
    context.commit('SET_FREE_SHIPPING_THRESHOLD', freeShippingThreshold)
  },

  /**
   * @param context
   * @param {Object|null} cartGiftCard
   */
  setCartGiftCard(context, cartGiftCard) {
    context.commit('SET_CART_GIFT_CARD', cartGiftCard)
  },

  /**
   * @param context
   * @param {number|null} cartWalletTotal
   */
  setCartWalletTotal(context, cartWalletTotal) {
    context.commit('SET_CART_WALLET_TOTAL', cartWalletTotal)
  },

  /**
   * @param context
   * @param {boolean} cartIsVirtual
   */
  setCartIsVirtual(context, cartIsVirtual) {
    context.commit('SET_CART_IS_VIRTUAL', cartIsVirtual)
  },

  /**
   * @param context
   * @param {number} cartTotalWithoutDiscount
   */
  setCartTotalWithoutDiscount(context, cartTotalWithoutDiscount) {
    context.commit('SET_CART_TOTAL_WITHOUT_DISCOUNT', cartTotalWithoutDiscount)
  },

  /**
   * @param context
   * @param {number} cartGrandTotalWithoutDiscount
   */
  setCartGrandTotalWithoutDiscount(context, cartGrandTotalWithoutDiscount) {
    context.commit('SET_CART_GRAND_TOTAL_WITHOUT_DISCOUNT', cartGrandTotalWithoutDiscount)
  },

  /**
   * @param context
   * @param {number} totalDiscount
   */
  setTotalDiscount(context, totalDiscount) {
    context.commit('SET_TOTAL_DISCOUNT', totalDiscount)
  },

  /**
   * @param context
   * @param {Object[]} cartItems
   */
  setCartItems(context, cartItems) {
    context.commit('SET_CART_ITEMS', cartItems)
  },

  /**
   * @param context
   * @param {Object|null} cartDiscount
   */
  setCartDiscount(context, cartDiscount) {
    context.commit('SET_CART_DISCOUNT', cartDiscount)
  },

  /**
   * @param context
   * @param {Object} shippingAddress
   * @returns {Promise}
   */
  setShippingAddress(context, shippingAddress) {
    context.commit('SET_SHIPPING_ADDRESS', shippingAddress)
  },

  /**
   * @param context
   * @param {Object[]} shippingMethods
   */
  setShippingMethods(context, shippingMethods) {
    context.commit('SET_SHIPPING_METHODS', shippingMethods)
  },

  /**
   * @param context
   * @param {Object} [selectedShippingMethod]
   * @returns {Promise}
   */
  setSelectedShippingMethod(context, selectedShippingMethod) {
    context.commit('SET_SELECTED_SHIPPING_METHOD', selectedShippingMethod)
  },

  /**
   * @param context
   * @param {Object} [selectedDeliveryMoment]
   * @returns {Promise}
   */
  setSelectedDeliveryMoment(context, selectedDeliveryMoment) {
    context.commit('SET_SELECTED_DELIVERY_MOMENT', selectedDeliveryMoment)
  },

  /**
   * @param context
   * @param {Object} [selectedServicePoint]
   * @returns {Promise}
   */
  setSelectedServicePoint(context, selectedServicePoint) {
    context.commit('SET_SELECTED_SERVICE_POINT', selectedServicePoint)
  },

  /**
   * @param {object} context
   * @param {loavies.models.payment.PaymentMethodModel[]} paymentMethods
   * @return {Promise}
   */
  setPaymentMethods(context, paymentMethods) {
    // Overcome Vue 2 reactive caveats
    context.commit('SET_PAYMENT_METHODS', [])

    context.commit('SET_PAYMENT_METHODS', paymentMethods)
  },

  /**
   * @param {Object} context
   * @param {loavies.models.payment.PaymentMethodModel|null} [selectedPaymentMethod]
   * @returns {Promise}
   */
  setSelectedPaymentMethod(context, selectedPaymentMethod) {
    context.commit('SET_SELECTED_PAYMENT_METHOD', selectedPaymentMethod)
  },

  /**
   * @param {Object} context
   * @param {Object|null} [selectedPaymentMethodDetails]
   * @returns {Promise}
   */
  setSelectedPaymentMethodDetails(context, selectedPaymentMethodDetails) {
    context.commit('SET_SELECTED_PAYMENT_METHOD_DETAILS', selectedPaymentMethodDetails)
  },

  /**
   * @param context
   * @param {boolean} processingOrder
   */
  setProcessingOrder(context, processingOrder) {
    context.commit('SET_PROCESSING_ORDER', processingOrder)
  },

  /**
   * @param context
   * @param {Object|null} donation
   */
  setDonation(context, donation) {
    context.commit('SET_DONATION', donation)
  },

  /**
   * @param context
   * @param {boolean} checkoutIsLoading
   * @returns {Promise}
   */
  setCheckoutIsLoading(context, checkoutIsLoading) {
    context.commit('SET_CHECKOUT_IS_LOADING', checkoutIsLoading)
  },

  /**
   * @param context
   * @param {*} applePaySession
   * @returns {Promise}
   */
  setApplePaySession(context, applePaySession) {
    context.commit('SET_APPLE_PAY_SESSION', applePaySession)
  },
}
