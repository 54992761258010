import cookies from 'js-cookie'

const DEFAULT_PATH = '/'
const DEFAULT_DOMAIN = 'loavies.com'
const DEFAULT_EXPIRES = 365

export default (name, value, options) => {
  // if value is undefined, get the cookie value
  if (value === undefined) {
    return cookies.get(name)
  } else {
    const correctedOptions = getOptions(options || {})

    // if value is a false boolean, we'll treat that as a delete
    if (value === false) {
      cookies.remove(name, correctedOptions)
    } else {
    // if value has a value, we'll treat that as a set
      cookies.set(name, value, correctedOptions)
    }
  }
}

function getOptions({ path = DEFAULT_PATH, domain, expires = DEFAULT_EXPIRES }) {
  // setting cookie for localhost should not have a domain set
  // https://stackoverflow.com/a/1188145/554821

  const correctedDomain = !process.env.isNetlifyProduction
    ? ''
    : domain ? domain : DEFAULT_DOMAIN

  return {
    path,
    domain: correctedDomain,
    expires,
  }
}
