
  import { mapState, mapActions, mapGetters } from 'vuex'

  import {
    APP_MODAL,
    APP_TRAY,
    FLY_IN,
    CONFIGURABLE_APP_MODAL_NAME,
    NEWSLETTER_APP_TRAY_NAME,
    TOAST_COOKIE_NAME,
    SOCIAL_RETAIL_COOKIE_NAME,
  } from '~/lib/constants'
  import cookie from '~/lib/cookie'
  import formatToPascalCase from '~/lib/format-to-pascal-case'

  import matchMedia from '~/mixins/match-media'

  export default {
    name: 'DefaultLayout',
    mixins: [matchMedia],
    data() {
      return {
        lastShownToasts: [],
      }
    },
    head() {
      return {
        htmlAttrs: { lang: this.$i18n.locale },
      }
    },
    computed: {
      ...mapState({
        activatorIsOpen: state => state['newsletter-toast'].activatorIsOpen,
        cookiesAccepted: state => state.cookiesAccepted,
        guestWishlist: state => state.wishlist.guestWishlist,
        isMobile: state => state.isMobile,
        showChatButton: state => state.showChatButton,
        userIsInitialized: state => state.user.isInitialized,
        socialRetailIsActive: state => state.socialRetailIsActive,
        isApp: state => state.isApp,
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
      activeToast() {
        return this.$staticDataService.cmsLayoutsDefault?.newsletterPopup
      },
      sections() {
        return this.$staticDataService.cmsLayoutsDefault?.sections
      },
      showBlackWeekTheme() {
        return this.$staticDataService.cmsLayoutsDefault?.showBlackWeekTheme
      },
      isCategoryPage() {
        const routeName = this.$route.name?.split('___')[0]

        return routeName === 'category' || routeName === 'category-slug'
      },
      renderActivator() {
        return (this.isMobile || this.cookiesAccepted)
          && this.activatorIsOpen
          && !this.socialRetailIsActive
      },
      showSocialRetailActivator() {
        return this.socialRetailIsActive && this.userIsInitialized && !this.isAuthenticated
      },
    },
    watch: {
      '$route'() {
        this.$bloomreachService.handlePageRouteChange()
      },
      '$route.query': {
        immediate: true,
        deep: true,
        async handler() {
          // We need to await the nextTick because the ConfigurableModal
          // is opened at the same time
          await this.$nextTick()

          if (process.server) {
            return
          }

          if (this.$route.query['fly-in']) {
            this.$overlayService.setCurrentOverlay({
              settings: {
                type: FLY_IN,
                component: formatToPascalCase(this.$route.query['fly-in']),
                options: {
                  transitionOrigin: 'right',
                  mode: this.$route.query.mode,
                  type: this.$route.query.type,
                },
              },
            })
          }

          if (this.$route.query['modal']) {
            this.$overlayService.setCurrentOverlay({
              settings: {
                options: {
                  ...this.$route.query,
                },
              },
              mobile: {
                type: APP_TRAY,
                component: formatToPascalCase(this.$route.query['modal']).replace('Modal', 'Tray'),
              },
              desktop: {
                type: APP_MODAL,
                component: formatToPascalCase(this.$route.query['modal']),
              },
            })
          }
        },
      },
      userIsInitialized: {
        immediate: true,
        handler() {
          this.checkForConfigurableModalActivation()
        },
      },
      cookiesAccepted: {
        immediate: true,
        handler() {
          this.checkForConfigurableModalActivation()
        },
      },
    },
    mounted() {
      this.checkIfSocialRetailIsActive()
      this.checkForToastActivation()
      this.initLiveShoppingWidget()
      this.$nuxt.isHydrated = true
    },
    beforeDestroy() {
      this.$bambuserService.removeWidget()
    },
    methods: {
      ...mapActions({
        setActivatorOpen: 'newsletter-toast/setActivatorOpen',
      }),
      initLiveShoppingWidget() {
        if (this.$staticDataService.cmsLayoutsDefault?.app.showLiveShoppingNotification) {
          setTimeout(this.$bambuserService.initWidget, 5000)
        }
      },
      checkForConfigurableModalActivation() {
        if (!this.cookiesAccepted || !this.userIsInitialized || this.$route.query['modal']) {
          return
        }

        this.$overlayService.setCurrentOverlay({
          settings: {
            type: APP_MODAL,
            component: CONFIGURABLE_APP_MODAL_NAME,
          },
        })
      },
      checkForToastActivation() {
        if (!this.activeToast || !this.activeToast.showNewsletterToast) {
          return
        }

        this.lastShownToasts = cookie(TOAST_COOKIE_NAME) ? JSON.parse(cookie(TOAST_COOKIE_NAME)) : []

        if (this.lastShownToasts.includes(this.activeToast.id)) {
          return
        }

        this.setActivatorOpen({ isOpen: true })
      },
      openTray() {
        this.$overlayService.setCurrentOverlay({
          settings: {
            type: APP_TRAY,
            component: NEWSLETTER_APP_TRAY_NAME,
          },
        })
      },
      setCookieAndCloseActivator() {
        this.lastShownToasts.push(this.activeToast.id)
        cookie(TOAST_COOKIE_NAME, this.lastShownToasts)

        this.setActivatorOpen({ isOpen: false })
      },
      checkIfSocialRetailIsActive() {
        if (cookie(SOCIAL_RETAIL_COOKIE_NAME) || this.$route.query.socialRetail) {
          this.$socialRetailService.setSocialRetailIsActive(true)
        }
      },
    },
  }
