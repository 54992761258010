
  import { mapState } from 'vuex'

  import AppImage from '@loavies/component-library/dist/app-image'

  import {
    APP_MODAL,
    APP_TRAY,
    OUT_OF_STOCK_APP_MODAL_NAME,
    OUT_OF_STOCK_APP_TRAY_NAME,
    SIZE_SELECT_APP_TRAY_NAME,
  } from '~/lib/constants'
  import { isValidProduct } from '~/lib/product-validation'
  import { localeProductPath } from '~/lib/product-link'
  import { isInStock } from '~/lib/stock'
  import getPriceGroupFromStorefront from '~/lib/get-price-group-from-storefront'
  import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'
  import checkIfProductIsAvailableSoon from '~/lib/check-if-product-is-available-soon'
  import getFirstImageFromProduct from '~/lib/get-first-image-from-product'

  export default {
    components: {
      AppImage,
    },
    props: {
      product: {
        type: Object,
        default: null,
        validator(product) {
          return !product || isValidProduct
        },
      },
      hasAddToCartButton: {
        type: Boolean,
        default: false,
      },
      // With this prop, we can disable the slider when necessary. In carousels, it's always disabled.
      hasImageSlider: {
        type: Boolean,
        default: false,
      },
      isInCarousel: {
        type: Boolean,
        default: false,
      },
      isInCarouselDynamic: {
        type: Boolean,
        default: false,
      },
      // Used to load bigger images in products-view when user prefers large cards
      isLargeCard: {
        type: Boolean,
        default: false,
      },
      location: {
        type: String,
        default: '',
      },
      showAlternativeProductImages: {
        type: Boolean,
        default: false,
      },
      showAlternativeDiscountStyle: {
        type: Boolean,
        default: false,
      },
      enableVideo: {
        type: Boolean,
        default: false,
      },
      hideProductHighlights: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        sizeSelectIsVisible: false,
        selectedSize: null,
        imageSizes: null,
        imageStartLoading: false,
        image: null,
        fixedRatioWidth: 2,
        fixedRatioHeight: 3,
        touched: false,
        observer: null,
      }
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
        currency: state => state.localization.currency,
        customerGroupId: state => state.user.customerGroupId,
        isHoverSupported: state => state.isHoverSupported,
        isImageLoadingSupported: state => state.isImageLoadingSupported,
      }),
      productHighlights() {
        if (this.hideProductHighlights) {
          return []
        }

        return this.$productHighlightService.getFormattedProductHighlights(this.sku)
      },
      videoUrl() {
        return this.product.media_video
      },
      showVideo() {
        return this.videoUrl && this.enableVideo
      },
      isInStock() {
        return isInStock(this.product)
      },
      canAddToCart() {
        return this.hasAddToCartButton && this.product && this.isInStock
      },
      sku() {
        return this.product && getPrimarySkuFromProduct(this.product)
      },
      sizes() {
        return this.product.configurable_options?.find(option => option.attribute_code === 'size')
      },
      sizeTip() {
        return this.product.option_text_size_tip?.[0] || null
      },
      isSingleSize() {
        return this.sizes?.options?.length === 1
      },
      showImageSlider() {
        return this.hasImageSlider && this.isMobile && !this.isHoverSupported && !this.isInCarousel
      },
      sliderImages() {
        if (!this.showImageSlider) {
          return [this.image]
        }

        const mappedImages = this.product?.media_gallery?.map(image => image.formats)

        if (this.showAlternativeProductImages && this.firstAlternativeImage) {
          return [
            this.firstAlternativeImage,
            ...mappedImages.filter(image => image.original !== this.firstAlternativeImage.original),
          ]
        }

        return mappedImages
      },
      firstImage() {
        return getFirstImageFromProduct(this.product)?.formats
      },
      firstAlternativeImage() {
        return getFirstImageFromProduct(this.product, true)?.formats
      },
      secondImage() {
        return this.product?.media_gallery?.[1]?.formats
      },
      label() {
        return this.$productHelperService.getProductShoutOutLabel(this.product)
      },
      priceGroup() {
        return this.product?.prices_storefront
          ? getPriceGroupFromStorefront(
            this.product.prices_storefront,
            this.customerGroupId,
            this.currency,
          )
          : null
      },
      productHasDiscount() {
        return this.priceGroup?.is_discount && !this.isGiftCard
      },
      discountPercentage() {
        return this.priceGroup ? this.$discountService.getDiscountPercentage(this.priceGroup.original_price, this.priceGroup.price) : null
      },
      productLink() {
        return this.product && localeProductPath(this.product, this.localePath, this.$i18n.locale)
      },
      hasNoStock() {
        return this.product && !this.isInStock
      },
      isAppOnly() {
        return this.product.app_only ?? false
      },
      isAvailableSoon() {
        return checkIfProductIsAvailableSoon(this.product)
      },
      isUnavailableLabelText() {
        return this.$productHelperService.getUnavailableLabelText(this.product) || this.$t('out_of_stock')
      },
      hasMultipleColorVariations() {
        return this.product?.item_layers?.length > 1
      },
      colorVariations() {
        if (!this.hasMultipleColorVariations) {
          return []
        }

        return this.$productSkuConverterService.getColorVariations(this.product?.item_layers, this.sku)
      },
      isGiftCard() {
        return this.$productHelperService.productIsGiftcard(this.product)
      },
      randomlyScrollProductImages() {
        return this.product?.randomlyScrollProductImages ?? false
      },
    },
    watch: {
      selectedSize(selectedSize) {
        if (selectedSize) {
          this.addToCart()
          this.hideSizeSelectInCard()
        }
      },
      isMobile() {
        if (!this.isMobile) {
          this.unobserve()
        }
      },
    },
    async mounted() {
      this.imageSizes = process.client && this.getImageSizes()
      this.imageStartLoading = Boolean(this.imageSizes)
      this.setInitialProductImage()

      await this.$nextTick()

      if (this.randomlyScrollProductImages && this.isMobile && !this.showVideo) {
        await this.setRandomScrollOnProductImages()
      }
    },
    beforeDestroy() {
      this.unobserve()
    },
    methods: {
      async setRandomScrollOnProductImages() {
        if (this.touched || !this.$refs.imageContainer) {
          return
        }

        await this.$nextTick()

        this.setupObserver()

        this.observer.observe(this.$refs.imageContainer.$el)
      },
      setupObserver() {
        this.observer = new IntersectionObserver((entries) => {
          if (this.touched) {
            this.unobserve()

            return
          }

          const imageContainerEl = this.$refs.imageContainer.$el
          const secondImageEl = imageContainerEl.children[1]

          if (entries[0].isIntersecting) {
            setTimeout(() => {
              if (this.touched || !secondImageEl) {
                this.unobserve()

                return
              }

              imageContainerEl.scrollTo({ left: secondImageEl.offsetLeft, behavior: 'smooth' })

              this.unobserve()
            }, 1000)
          }
        }, { threshold: 1, rootMargin: '50px 0px 50px 0px' })
      },
      unobserve() {
        this.observer?.disconnect()
        this.observer = null
      },
      setInitialProductImage() {
        this.image = this.showAlternativeProductImages && this.firstAlternativeImage ? this.firstAlternativeImage : this.firstImage
      },
      initProductClickTracking() {
        this.$trackingService.productClick({ product: this.product, location: this.location })
      },
      onCartButtonClick() {
        // If there are no sizes, we can add the product to card as is, otherwise we have
        // to show the correct size-select-tray.
        if (!this.sizes) {
          this.addToCart()
          return
        }

        // Now we for sure know that this product has sizes so we only
        // have to check the width of the screen.
        this.showCorrectSizeSelect()
      },
      onIsUnavailableButtonClick() {
        // If there are no sizes, we can open the out-of-stock-modal, otherwise we have
        // to show the correct size-select-tray.
        if (!this.sizes || this.isSingleSize) {
          return this.$overlayService.setCurrentOverlay({
            settings: {
              options: {
                selectedSize: this.sizes?.options?.[0],
              },
            },
            mobile: {
              type: APP_TRAY,
              component: OUT_OF_STOCK_APP_TRAY_NAME,
            },
            desktop: {
              type: APP_MODAL,
              component: OUT_OF_STOCK_APP_MODAL_NAME,
            },
          })
        }

        // Now we for sure know that this product has sizes, so we only
        // have to check the width of the screen.
        this.showCorrectSizeSelect()
      },
      showCorrectSizeSelect() {
        if (window.matchMedia('(max-width: 767px)').matches) {
          this.showSizeSelectFromBottom()
        } else {
          this.showSizeSelectInCard()
        }
      },
      addToCart() {
        const selectedSize = this.$productHelperService.getSelectedSizeByOptionValue({
          product: this.product,
          sizeOptionValue: this.selectedSize,
        })

        return this.$cartItemsService.addToCart({
          product: this.product,
          quantity: 1,
          selectedSize,
        })
      },
      selectOutOfStockSize({ selectedSize }) {
        this.$overlayService.setCurrentOverlay({
          settings: {
            type: APP_MODAL,
            component: OUT_OF_STOCK_APP_MODAL_NAME,
            options: { selectedSize },
          },
        })
      },
      getImageFallbackUrl(image, index) {
        if (!this.showImageSlider && index > 0 && !this.isImageLoadingSupported) {
          return
        }

        return image.small
      },
      getImageSizes() {
        const parentWidth = this.$parent.$el.offsetWidth

        if (
          (this.isInCarousel && !this.isInCarouselDynamic) ||
          (this.isInCarouselDynamic && this.isHoverSupported)
        ) {
          return [
            '(min-width: 1920px) 384px',
            '(min-width: 1024px) calc(20vw - (4 / 5 * 12px))',
            '(min-width: 540px) 30vw',
            '40vw',
          ].join(', ')
        }

        if (this.isInCarouselDynamic) {
          return [
            '(min-width: 1024px) 30vw',
            '(min-width: 768px) 40vw',
            '(min-width: 540px) 60vw',
            '75vw',
          ].join(', ')
        }

        if (this.isLargeCard) {
          return [
            `(min-width: 1280px) calc(${parentWidth}px / 3 - (8px * 2))`,
            `(min-width: 1024px) calc(${parentWidth}px / 2 - (8px * 2))`,
            '100vw',
          ].join(', ')
        }

        return [
          `(min-width: 1280px) calc(${parentWidth}px / 4 - (8px * 2))`,
          `(min-width: 1024px) calc(${parentWidth}px / 3 - (8px * 2))`,
          `(min-width: 375px) calc(${parentWidth}px / 2 - 8px)`,
          '100vw',
        ].join(', ')
      },
      getImageSrcset(image, index) {
        const widths = [
          200,
          400,
          600,
          900,
        ]

        if (!this.showImageSlider && index > 0 && !this.isImageLoadingSupported) {
          return
        }

        return this.$responsiveImageService.getSrcsetByWidths({
          url: image.original,
          widths,
        })
      },
      showFirstImage() {
        if (!this.isHoverSupported || this.showVideo) {
          return
        }

        if (this.showAlternativeProductImages && this.firstAlternativeImage) {
          this.image = this.firstAlternativeImage
          return
        }

        if (this.firstImage) {
          this.image = this.firstImage
        }
      },
      showSecondImage() {
        if (!this.isHoverSupported || this.showVideo) {
          return
        }

        if (this.secondImage) {
          this.image = this.secondImage
        }
      },
      showSizeSelectInCard() {
        this.sizeSelectIsVisible = true
      },
      showSizeSelectFromBottom() {
        this.$overlayService.setCurrentOverlay({
          settings: {
            type: APP_TRAY,
            component: SIZE_SELECT_APP_TRAY_NAME,
            options: {
              product: this.product,
              sizes: this.sizes,
            },
          },
        })
      },
      hideSizeSelectInCard() {
        this.selectedSize = null
        this.sizeSelectIsVisible = false
      },
      imageAltText(index) {
        return index === 0 ? this.product.subtitle?.[0] || '' : ''
      },
    },
  }
