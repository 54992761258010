/**
 * @typedef {DiscountService}
 * @alias this.$discountService
 */
export class DiscountService {

  /**
   * @param {number} originalPrice
   * @param {number} discountPrice
   * @param {number} [roundUpToNumber]
   * @return {number}
   */
  getDiscountPercentage(originalPrice, discountPrice, roundUpToNumber = 5) {
    const discountPercentage = Math.round(((originalPrice - discountPrice) / originalPrice * 100) / roundUpToNumber) * roundUpToNumber
    return discountPercentage < roundUpToNumber ? roundUpToNumber : discountPercentage
  }
}
