import axios from 'axios'

import { ApiService } from '~/services/core/api-service'

export class ElasticApiService extends ApiService {
  constructor(i18n) {
    super()

    this.i18n = i18n
    this.axiosConfig = {
      auth: {
        username: process.env.elasticUsername,
        password: process.env.elasticPassword,
      },
    }
    this.axiosReloavedConfig = {
      auth: {
        username: process.env.elasticUsernameReloaved,
        password: process.env.elasticPasswordReloaved,
      },
    }
  }

  /**
   * @param {Object} elasticIndices
   * @return {Object}
   */
  createAxiosInstance(elasticIndices) {
    const instance = axios.create(this.axiosConfig)

    instance.interceptors.request.use(config => {
      const locale = this.i18n.locale
      const index = elasticIndices[locale]

      config.baseURL = `${process.env.elasticBaseUrl}/${index}`

      return config
    })

    return instance
  }

  /**
   * @return {Object}
   */
  createReloavedAxiosInstance() {
    const instance = axios.create(this.axiosReloavedConfig)

    instance.interceptors.request.use(config => {
      const index =  process.env.elasticListingsReloaved

      config.baseURL = `${process.env.elasticBaseUrlReloaved}/${index}`

      return config
    })

    return instance
  }
}
