const MAXIMUM_SYNONYM_COUNT = 2
const MAXIMUM_SYNONYM_COMBINATIONS_COUNT = 1024
const TOKEN_TYPE_SYNONYM = 'SYNONYM'

/**
 * @param {string} searchQuery
 * @param {array} tokens
 * @return {array}
 */
export function mapSynonymsFromApi(searchQuery, tokens) {
  const synonyms = tokens.filter(token => token.type === TOKEN_TYPE_SYNONYM)
  const synonymsCombinedByPositions = getSynonymsCombinedByPosition(searchQuery, synonyms)

  return getCombinedSynonymTokens(searchQuery, synonymsCombinedByPositions)
}

/**
 * This function combines all synonyms based on position in the search query.
 * @param {string} searchQuery
 * @param {array} synonyms
 * @return {array}
 */
function getSynonymsCombinedByPosition(searchQuery, synonyms) {
  // Split all separate words
  // E.g. ['blue', 'dress']
  const searchTerms = searchQuery.split(' ')

  return synonyms.reduce((synonymsCombined, synonym) => {
    const searchTermsIndex = searchTerms.findIndex(term => term === searchQuery.slice(synonym.start_offset, synonym.end_offset))
    const synonymsCombinedIndex = synonymsCombined.findIndex(item => item.position === searchTermsIndex)

    // If no synonyms are combined yet, push new object to combined array
    if (synonymsCombinedIndex === -1) {
      synonymsCombined.push({
        position: searchTermsIndex,
        tokens: [synonym.token],
      })

    // Otherwise push synonym to tokens
    } else {
      synonymsCombined[synonymsCombinedIndex].tokens.push(synonym.token)
    }

    return synonymsCombined
  }, [])
}

/**
 * All synonyms need to be combined for all possible unique combinations,
 * within the current search query
 * @param searchQuery
 * @param tokensCombinedByPositions
 * @returns {array}
 */
function getCombinedSynonymTokens(searchQuery, tokensCombinedByPositions) {
  const searchQueryWords = searchQuery.split(' ')

  const combinedSynonymsCollection = searchQueryWords.map((searchQueryWord, searchQueryWordIndex) => {
    let searchTermArray = [ searchQueryWord ]

    const matchedTokens = tokensCombinedByPositions
      .find((tokensCombinedByPosition, tokensCombinedByPositionIndex) => {
        return tokensCombinedByPosition.position === searchQueryWordIndex && tokensCombinedByPositionIndex < MAXIMUM_SYNONYM_COUNT
      })

    if (matchedTokens) {
      searchTermArray = [...searchTermArray, ...matchedTokens.tokens]
    }

    return searchTermArray
  })

  return getAllPossibleCombinations(combinedSynonymsCollection).filter(item => item !== searchQuery)
}

/**
 * @param {array} combinedSynonymsCollection
 * @return {array}
 */
function getAllPossibleCombinations(combinedSynonymsCollection) {
  if (combinedSynonymsCollection.length === 1) {
    return combinedSynonymsCollection[0].map(synonym => replaceUnderscores(synonym))
  }

  const result = []
  const allCasesOfRest = getAllPossibleCombinations(combinedSynonymsCollection.slice(1))  // Recur with the rest of array

  for (let i = 0; i < allCasesOfRest.length; i++) {
    for (let j = 0; j < combinedSynonymsCollection[0].length; j++) {
      result.push(replaceUnderscores(combinedSynonymsCollection[0][j]) + ' ' + replaceUnderscores(allCasesOfRest[i]))

      if (result.length === MAXIMUM_SYNONYM_COMBINATIONS_COUNT) {
        break
      }
    }
  }

  return result
}

/**
 * @param {string} string
 * @return {string}
 */
function replaceUnderscores(string) {
  return string.replace(/_/g, ' ')
}
