
  import debounce from 'lodash.debounce'
  import { ValidationObserver } from 'vee-validate'

  import smartLink from '~/mixins/smart-link'

  export default {
    components: {
      ValidationObserver,
    },
    mixins: [smartLink],
    props: {
      isInFlyIn: {
        type: Boolean,
        default: false,
      },
      redirectToAccountPage: {
        type: Boolean,
        default: false,
      },
      showHelperText: {
        type: Boolean,
        default: true,
      },
      showLoginButton: {
        type: Boolean,
        default: true,
      },
      checkUserGroup: {
        type: Boolean,
        default: false,
      },
      customHelperText: {
        type: String,
        default: null,
      },
      customRegisterButtonText: {
        type: String,
        default: null,
      },
      customNewsletterDescriptionText: {
        type: String,
        default: null,
      },
      customLoginButtonText: {
        type: String,
        default: null,
      },
      newsletterSubscriptionTag: {
        type: String,
        required: false,
        default: null,
      },
      hasPhoneNumberField: {
        type: Boolean,
        default: false,
      },
      hideExistingAccountMessage: {
        type: Boolean,
        default: false,
      },
      subscribeForNewsletterByDefault: {
        type: Boolean,
        default: false,
      },
      showUsps: {
        type: Boolean,
        default: true,
      },
    },
    emits: [
      'login-click',
      'email-already-exists',
      'register-successful',
    ],
    data() {
      return {
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        phonenumber: null,
        errorMessage: null,
        pending: false,
        wantsToSubscribeForNewsletter: false,
        checkEmailDebounce: debounce(this.checkEmail, 500),
        emailAvailable: true,
        formIsValid: false,
      }
    },
    computed: {
      uspsRegisterForm() {
        return this.$staticDataService.cmsLayoutsDefault?.usps.registerForm
      },
      submitButtonText() {
        return this.customRegisterButtonText ?? this.$t('join_the_loavies_society')
      },
      helperText() {
        return this.customHelperText ?? this.$t('join_the_loavies_squad')
      },
      loginButtonText() {
        return this.customLoginButtonText ?? this.$t('already_have_an_account')
      },
    },
    watch: {
      email() {
        this.checkEmailDebounce(this.email)
      },
      emailAvailable() {
        if (!this.emailAvailable) {
          this.$emit('email-already-exists', this.email)
        }
      },
    },
    mounted() {
      this.wantsToSubscribeForNewsletter = this.subscribeForNewsletterByDefault
    },
    methods: {
      async checkFormValidity() {
        this.formIsValid = await this.$refs.registerForm?.validate({ silent: true })
      },
      async onSubmit() {
        if (!this.formIsValid) {
          return
        }

        this.pending = true

        try {
          await this.$userService.register({
            email: this.email,
            password: this.password,
            firstname: this.firstname,
            lastname: this.lastname,
            telephone: this.phonenumber,
            // Checks if user needs to be put in a specific customer group in Magento
            scopeapp: this.checkUserGroup,
            closeFlyIn: this.isInFlyIn,
            subscribeForNewsletter: this.wantsToSubscribeForNewsletter,
          })

          if (this.wantsToSubscribeForNewsletter) {
            await this.$newsletterService.subscribeWithEmail({
              emailAddress: this.email,
              newsletterSubscriptionTag: this.newsletterSubscriptionTag,
            })
          }

          if (this.link) {
            return this.handleLinkProgrammatically()
          }

          if (this.redirectToAccountPage) {
            return this.$router.push(this.localePath({ name: 'customer-account' })).catch(() => {})
          }

          this.$emit('register-successful')
        } catch (errorMessage) {
          if (errorMessage) {
            this.errorMessage = errorMessage
          } else {
            this.errorMessage = this.$t('something_went_wrong')
          }
        } finally {
          this.pending = false
        }
      },
      loginButton() {
        if (this.isInFlyIn) {
          return this.$emit('login-click')
        }

        this.$router.push(this.localePath({ name: 'login' })).catch(() => {})
      },
      async checkEmail(value) {
        const emailIsValid = this.$emailValidatorService.isValid(value)

        if (emailIsValid) {
          this.emailAvailable = await this.$userApiService.isEmailAvailable(value)
        }
      },
    },
  }
