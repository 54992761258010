import { render, staticRenderFns } from "./telephone-input.vue?vue&type=template&id=a1fc3f6c"
import script from "./telephone-input.vue?vue&type=script&lang=js"
export * from "./telephone-input.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputField: require('/opt/build/repo/src/client/components/input-field/input-field.vue').default})
