
  import CustomButtonItem from '~/components/custom-button-item/custom-button-item'

  export default {
    extends: CustomButtonItem,
    props:{
      showId: {
        type: String,
        required: true,
      },
      link: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      initializeLiveShopping() {
        this.$bambuserService.initializeLiveShopping(this.showId)
      },
    },
  }
