export default {
  ADD_FILTER_TO_APP_HEADER(state) {
    state.filterInAppHeader = true
  },

  REMOVE_FILTER_FROM_APP_HEADER(state) {
    state.filterInAppHeader = false
  },

  SET_CURRENT_ROUTE_IS_PRODUCTS_PAGE(state, { currentRouteIsProductPage }) {
    state.currentRouteIsProductPage = currentRouteIsProductPage
  },

  SET_CURRENT_PRODUCT_PRIMARY_CATEGORY(state, { currentProductPrimaryCategory }) {
    state.currentProductPrimaryCategory = currentProductPrimaryCategory
  },

  SET_IS_DESKTOP(state, { isDesktop }) {
    state.isDesktop = isDesktop
  },

  SET_IS_MOBILE(state, { isMobile }) {
    state.isMobile = isMobile
  },

  SET_IS_HOVER_SUPPORTED(state, { isHoverSupported }) {
    state.isHoverSupported = isHoverSupported
  },

  SET_MEDIA_QUERY_INITIALIZED(state, payload) {
    state.mediaQueryInitialized = payload.mediaQueryInitialized
  },

  SET_IS_IMAGE_LOADING_SUPPORTED(state, { isImageLoadingSupported }) {
    state.isImageLoadingSupported = isImageLoadingSupported
  },

  SET_COOKIES_ACCEPTED(state, { cookiesAccepted }) {
    state.cookiesAccepted = cookiesAccepted
  },

  SET_SHOW_CHAT_BUTTON(state, { showChatButton }) {
    state.showChatButton = showChatButton
  },

  SET_LOQATE_LOADED(state, payload) {
    state.loqateLoaded = payload
  },

  /**
   * @param state
   * @param {boolean} userPrefersLargeProductCards
   */
  SET_USER_PREFERS_LARGE_PRODUCT_CARDS(state, userPrefersLargeProductCards) {
    state.userPrefersLargeProductCards = userPrefersLargeProductCards
  },

  /**
   * @param state
   * @param {boolean} socialRetailIsActive
   */
  SET_SOCIAL_RETAIL_IS_ACTIVE(state, socialRetailIsActive) {
    state.socialRetailIsActive = socialRetailIsActive
  },

  /**
   * @param state
   * @param {boolean} isApp
   */
  SET_IS_APP(state, isApp) {
    state.isApp = isApp
  },
}
