import { stringify } from 'query-string'

import enforceTrailingSlash from './enforce-trailing-slash'

export default function (locale, categoryUrlPath, query) {
  const basePath = `/${locale}/`
  const categoryPath = categoryUrlPath.replace('\\', '')
  const stringifiedQuery = (query && Object.keys(query).length) ? `?${stringify(query, { skipNull: true, skipEmptyString: true })}` : ''

  return enforceTrailingSlash(basePath + categoryPath + stringifiedQuery)
}
