import { ElasticApiService } from '~/services/core/elastic-api-service'

import getCorrespondingListingBySkuQuery from '~/services/reloaved/queries/get-corresponding-listing-by-sku-query'
import { mapListings } from '~/services/reloaved/reloaved-listing-api-mappers'

/**
 * @typedef {ReloavedListingApiService}
 * @alias this.$reloavedListingApiService
 */
export class ReloavedListingApiService extends ElasticApiService {
  constructor(context) {
    super(context.i18n)

    /** @type {ServerNuxtContext} */
    this.context = context
    this.reloavedAxiosInstance = this.createReloavedAxiosInstance()
  }

  /**
   * @param {object} [query]
   * @return {Promise}
   */
  post(query) {
    return this.reloavedAxiosInstance.post('_search', query)
      .then(response => response?.data)
  }

  /**
   * Get corresponding listings in all sizes
   * @param {string} sku
   * @param {number} [priceAmount] in cents
   * @returns {Promise<loavies.models.listing.ListingModel>}
   */
  getCorrespondingListingBySku({ sku, priceAmount }) {
    return this.getCorrespondingListingsBySku({ sku, priceAmount, size: 1 })
      .then(listings => listings?.[0])
  }

  /**
   * Get corresponding listings in all sizes
   * @param {string} sku
   * @param {number} [priceAmount] in cents
   * @returns {Promise<loavies.models.listing.ListingModel[]>}
   */
  getCorrespondingListingsBySku({ sku, priceAmount }) {
    return this.post(getCorrespondingListingBySkuQuery({
      sku,
      priceAmount,
    }))
      .then(data => mapListings(data.hits.hits.map(hit => hit._source)))
  }
}
