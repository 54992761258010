
  import { mapState, mapGetters } from 'vuex'

  import {
    FLY_IN,
    CART_FLY_IN_NAME,
    SEARCH_FLY_IN_NAME,
    PRODUCTS_FILTER_FLY_IN_NAME,
    APP_TRAY,
    SEARCH_APP_TRAY_NAME,
  } from '~/lib/constants'

  export default {
    computed: {
      ...mapState({
        isDesktop: state => state.isDesktop,
        filterInAppHeader: state => state.filterInAppHeader,
        appliedFiltersCount: state => state.category.appliedFiltersCount,
        currentRouteName: state => state.routes.currentRouteName,
      }),
      ...mapGetters({
        amountOfWishlistItems: 'wishlist/amountOfWishlistItems',
        cartItemsQuantity: 'checkout/cartItemsQuantity',
        isAuthenticated: 'user/isAuthenticated',
      }),
      navigation() {
        return this.$staticDataService.cmsLayoutsDefault?.navigation
      },
      wishlistRoute() {
        return this.localePath({ name: 'wishlist' })
      },
      accountIcon() {
        return this.isAuthenticated ? 'account-logged-in' : 'account-logged-out'
      },
      filteredCartItemsQuantity() {
        if (this.currentRouteName?.includes('order-confirmation')) {
          return 0
        }

        return this.cartItemsQuantity
      },
    },
    methods: {
      onSearchButtonClick() {
        this.$overlayService.setCurrentOverlay({
          desktop: {
            type: FLY_IN,
            component: SEARCH_FLY_IN_NAME,
            options: { transitionOrigin: 'left' },
          },
          mobile: {
            type: APP_TRAY,
            component: SEARCH_APP_TRAY_NAME,
            transition: 'fade',
          },
        })
      },
      onFilterButtonClick() {
        this.$overlayService.setCurrentOverlay({
          settings: {
            type: FLY_IN,
            component: PRODUCTS_FILTER_FLY_IN_NAME,
            options: { transitionOrigin: 'right' },
          },
        })
      },
      onCartButtonClick() {
        this.$overlayService.setCurrentOverlay({
          settings: {
            type: FLY_IN,
            component: CART_FLY_IN_NAME,
            options: { transitionOrigin: 'right' },
          },
        })
      },
    },
  }
