
  export default {
    props: {
      link: {
        type: Object,
        required: true,
      },
      text: {
        type: String,
        default: null,
      },
      horizontalOffset: {
        type: Number,
        default: null,
      },
      verticalOffset: {
        type: Number,
        default: null,
      },
      textColor: {
        type: Object,
        default: null,
      },
      backgroundColor: {
        type: Object,
        default: null,
      },
      transparent: {
        type: Boolean,
        default: false,
      },
      underline: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      buttonText() {
        return this.text || this.link.label
      },
      buttonHorizontalOffset() {
        return getOffsetValue(this.horizontalOffset)
      },
      buttonVerticalOffset() {
        return getOffsetValue(this.verticalOffset)
      },
      buttonTextColor() {
        return this.textColor ? this.textColor.hex : '#FFFFFF'
      },
      buttonBackgroundColor() {
        if (this.transparent || this.underline) {
          return 'transparent'
        }

        return this.backgroundColor ? this.backgroundColor.hex : '#000000'
      },
      buttonBorderColor() {
        if (this.transparent && !this.underline) {
          return this.backgroundColor ? this.backgroundColor.hex : '#000000'
        }

        return null
      },
      classBindings() {
        return {
          'custom-button-item--transparent': this.transparent,
          'custom-button-item--underline': this.underline,
        }
      },
      styleBindings(){
        return {
          left: this.buttonHorizontalOffset,
          top: this.buttonVerticalOffset,
          color: this.buttonTextColor,
          backgroundColor: this.buttonBackgroundColor,
          borderColor: this.buttonBorderColor,
          '--button-text-color': this.buttonTextColor,
          '--button-background-color': this.buttonBackgroundColor,
          '--button-border-color': this.buttonBorderColor,
        }
      },
    },
  }

  function getOffsetValue(offsetValue) {
    const offset = offsetValue
      ? String(offsetValue)
      : '0'

    return `${offset}%`
  }
