import { render, staticRenderFns } from "./category-layout.vue?vue&type=template&id=02329a72"
import script from "./category-layout.vue?vue&type=script&lang=js"
export * from "./category-layout.vue?vue&type=script&lang=js"
import style0 from "./category-layout.vue?vue&type=style&index=0&id=02329a72&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModularContent: require('/opt/build/repo/src/client/components/modular-content/modular-content.vue').default,CategoryList: require('/opt/build/repo/src/client/components/category-list/category-list.vue').default,CategoryProductsHeader: require('/opt/build/repo/src/client/components/category-products-header/category-products-header.vue').default,TextAccordion: require('/opt/build/repo/src/client/components/text-accordion/text-accordion.vue').default})
