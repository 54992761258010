
  export default {
    props: {
      link: {
        type: Object,
        required: true,
      },
      isLight: {
        type: Boolean,
        default: false,
      },
      isTransparentLight: {
        type: Boolean,
        default: false,
      },
      isTransparentDark: {
        type: Boolean,
        default: false,
      },
      isUnderlineLight: {
        type: Boolean,
        default: false,
      },
      isUnderlineDark: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: null,
      },
    },
    computed: {
      buttonText() {
        return this.text || this.link.label
      },
    },
  }
