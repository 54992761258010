
  import { mapActions, mapState } from 'vuex'

  export default {
    computed: {
      ...mapState({
        userPrefersLargeProductCards: 'userPrefersLargeProductCards',
      }),
      gridToggleIcon() {
        return this.userPrefersLargeProductCards ? 'grid-view' : 'list-view'
      },
      gridToggleAriaLabel() {
        return this.userPrefersLargeProductCards ? this.$t('switch_to_small_images') : this.$t('switch_to_big_images')
      },
    },
    methods: {
      ...mapActions({
        setProductsPerRowMobile: 'category/setProductsPerRowMobile',
      }),
      async toggleLargeCardsUserPreference() {
        await this.$productCardSettingService.toggleUserPrefersLargeProductCards()

        return this.setProductsPerRowMobile(this.userPrefersLargeProductCards ? 1 : 2)
      },
    },
  }
