import { render, staticRenderFns } from "./category-list.vue?vue&type=template&id=0e740471"
import script from "./category-list.vue?vue&type=script&lang=js"
export * from "./category-list.vue?vue&type=script&lang=js"
import style0 from "./category-list.vue?vue&type=style&index=0&id=0e740471&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoryListItem: require('/opt/build/repo/src/client/components/category-list-item/category-list-item.vue').default})
