export default function () {
  return {
    settings: null,
    mobile: null,
    desktop: null,
    activeAppModal: null,
    activeAppModalTransition: null,
    activeAppModalOptions: null,
    activeAppTray: null,
    activeAppTrayTransition: null,
    activeAppTrayOptions: null,
    activeFlyIn: null,
    activeFlyInTransition: null,
    activeFlyInOptions: null,
  }
}
