
  import { mapState } from 'vuex'

  export default {
    props: {
      carouselData: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
      }),
      hideArrows() {
        return this.isMobile
      },
      cards() {
        return this.carouselData.carouselCards
      },
      verticalOffset() {
        return this.carouselData.verticalOffset || 0
      },
      mobileVerticalOffset() {
        return this.carouselData.mobileVerticalOffset || 0
      },
      offsetStyle() {
        if (this.isMobile) {
          return {
            transform: `translateY(${this.mobileVerticalOffset}%)`,
          }
        }

        return {
          transform: `translateY(${this.verticalOffset}%)`,
        }
      },
    },
  }
