/**
 * @typedef {WalletService}
 * @alias this.$walletService
 */
export class WalletService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$magentoApiService = this.context.$magentoApiService
    this.$cartPaymentService = this.context.$cartPaymentService
  }

  /**
   * @return {Promise<void>}
   */
  async fetchWallet() {
    const isAuthenticated = this.store.getters['user/isAuthenticated']

    if (!isAuthenticated) {
      return
    }

    const wallet = await this.getWallet()

    return this.store.dispatch('wallet/setWallet', wallet)
  }

  /**
   * @return {Promise<Object|null>}
   */
  getWallet() {
    return this.$magentoApiService
      .get('/customers/wallet')
      .then(response => response.data)
      .then(wallet => {
        // Wallet can be disabled in Magento and then wallet.balance
        // wil be an empty array instead of an object.
        if (!wallet?.balance || Array.isArray(wallet.balance)) {
          return null
        }

        return wallet
      })
      .catch(() => null)
  }

  /**
   * @param {number|null} payFromWalletAmountInCents
   * @returns {Promise<void>}
   */
  async updatePayFromWalletAmountInCents(payFromWalletAmountInCents) {
    try {
      await this.applyWalletAmountInCentsForCart(payFromWalletAmountInCents)
      await this.store.dispatch('wallet/setPayFromWalletAmountInCents', payFromWalletAmountInCents)

      // This also calls updateCartTotals when setSelectedPaymentMethod is called
      return this.$cartPaymentService.fetchAllCartPaymentMethods()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * @param {number} amountInCents
   * @returns Promise
   */
  applyWalletAmountInCentsForCart(amountInCents) {
    return this.$magentoApiService
      .post('/carts/mine/wallet/apply', {
        amount: amountInCents,
      })
  }
}
