import { onLoad } from '~/lib/on-load'
import cookie from '~/lib/cookie'
import { CONSENT_COOKIE_NAME } from '~/lib/constants'

/**
 * @typedef GtmService
 * @alias this.$gtmService
 */
export class GtmService {
  #gtmId
  #eventsQueue
  #gtmInitializedTime

  constructor() {
    this.#gtmId = process.env.GTMId
    this.#eventsQueue = []
    this.#gtmInitializedTime = null

    // Overwrite push method to array, so we can control memory leaking
    this.#eventsQueue.push = this.#push

    this.enable()
  }

  enable() {
    if (!this.#isInitialized()) {
      this.#init()

      this.add({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
      })
    }
  }

  /**
   * @param {Object} event
   * @returns {*}
   */
  add(event) {
    if (this.#isInitialized()) {
      return window.dataLayer.push(event)
    }

    const currentTime = new Date().getTime()
    const oneMinute = 60000
    const trackEventsInQueue = currentTime < this.#gtmInitializedTime + oneMinute

    if (trackEventsInQueue) {
      this.#eventsQueue.push(event)
    }
  }

  #init() {
    this.#gtmInitializedTime = new Date().getTime()

    onLoad(() => {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtm.js?id=${this.#gtmId}`
      script.async = true

      window.dataLayer = []

      // Overwrite push method to datalayer array, so we can control memory leaking
      window.dataLayer.push = this.#push
      // Define the gtag function.
      window.gtag = this.#gtag

      // Set default consent to 'granted' as a placeholder
      // Determine actual values based on your own requirements
      gtag('consent', 'default', this.getConsentCookieData())

      document.body.appendChild(script)

      // If the queue contains events add them to the dataLayer
      if (this.#eventsQueue.length > 0) {
        this.#eventsQueue.forEach(queuedEvent => window.dataLayer.push(queuedEvent))
        this.#eventsQueue = []
      }
    }, 5000)
  }

  #gtag() {
    dataLayer.push(arguments)
  }

  getConsentCookieData() {
    try {
      return JSON.parse(cookie(CONSENT_COOKIE_NAME))
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Error parsing consent cookie', error)
      }

      return {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
      }
    }
  }

  updateConsentCookie(cookieData) {
    cookie(CONSENT_COOKIE_NAME, cookieData)

    if (!window.gtag) {
      return
    }

    gtag('consent', 'update', cookieData)
  }

  /**
   * @returns {boolean}
   */
  #isInitialized() {
    return Array
      .from(document.getElementsByTagName('script'))
      .some(script => script.src.includes('googletagmanager'))
  }

  /**
   * @param {Object} event
   * @returns {number}
   */
  #push(event) {
    if (event['gtm.element']) {
      // clone the node and save that to the event instead of keeping reference to the original node, which causes memory leaks
      event['gtm.element'] = event['gtm.element'].cloneNode(true)
    }
    return Array.prototype.push.apply(this, arguments)
  }
}
