import defaultState from './state'

export default {
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {string} email
   */
  SET_USER_EMAIL(state, email) {
    state.email = email
  },

  /**
   * @param state
   * @param {string} token
   */
  SET_USER_TOKEN(state, token) {
    state.token = token
  },


  /**
   * @param state
   * @param {string} firstname
   */
  SET_USER_FIRSTNAME(state, firstname) {
    state.firstname = firstname
  },

  /**
   * @param state
   * @param {string} lastname
   */
  SET_USER_LASTNAME(state, lastname) {
    state.lastname = lastname
  },

  /**
   * @param state
   * @param {string} dateOfBirth
   */
  SET_USER_DATE_OF_BIRTH(state, dateOfBirth) {
    state.dateOfBirth = dateOfBirth
  },

  /**
   * @param state
   * @param {number} customerGroupId
   */
  SET_USER_CUSTOMER_GROUP_ID(state, customerGroupId) {
    state.customerGroupId = customerGroupId
  },

  /**
   * @param state
   * @param {Object[]} addresses
   */
  SET_USER_ADDRESSES(state, addresses) {
    state.addresses = addresses
  },

  /**
   * @param state
   * @param {number} websiteId
   */
  SET_WEBSITE_ID(state, websiteId) {
    state.websiteId = websiteId
  },

  /**
   * @param state
   * @param {number} id
   */
  SET_USER_ID(state, id) {
    state.id = id
  },

  /**
   * @param state
   * @param {boolean} isSubscribedToNewsletter
   */
  SET_USER_NEWSLETTER_SUBSCRIPTION_STATUS(state, isSubscribedToNewsletter) {
    state.isSubscribedToNewsletter = isSubscribedToNewsletter
  },

  /**
   * @param state
   * @param {boolean} isInitialized
   */
  SET_IS_INITIALIZED(state, isInitialized) {
    state.isInitialized = isInitialized
  },

  /**
   * @param state
   * @param {boolean} isBlocked
   */
  SET_IS_BLOCKED(state, isBlocked) {
    state.isBlocked = isBlocked
  },

  /**
   * @param state
   * @param {string} phoneNumber
   */
  SET_USER_PHONE_NUMBER(state, phoneNumber) {
    state.phoneNumber = phoneNumber
  },
}
