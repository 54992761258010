import getOnlineFromDateFilter from './get-online-from-date-filter'

/**
 * @param {array} skus
 * @param {object} [optimizers]
 * @param {object} [_source]
 * @return {Object}
 */
export default (skus, optimizers, _source) => {
  const onlineFromDateFilter = getOnlineFromDateFilter()
  const size = 1000
  const query = {
    bool: {
      must: [
        {
          terms: {
            'sku.untouched': skus,
          },
        },
      ],
      ...(onlineFromDateFilter && { filter: [ onlineFromDateFilter ] }),
    },
  }

  if (optimizers) {
    return {
      size,
      query: {
        function_score: {
          ...optimizers,
          query,
        },
      },
      ...(_source && { _source }),
    }
  }

  return {
    size,
    query,
    ...(_source && { _source }),
  }
}
