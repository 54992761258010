export default {
  /**
   * @param state
   * @returns {boolean}
   */
  isAuthenticated: state => Boolean(state.email && state.token),

  /**
   * @param state
   * @returns {Object|null}
   */
  defaultShippingAddress(state) {
    return state.addresses.find(address => address.default_shipping) || null
  },

  /**
   * @param state
   * @returns {Object|null}
   */
  defaultBillingAddress(state) {
    return state.addresses.find(address => address.default_billing) || null
  },

  /**
   * @param state
   * @returns {function(number): Object}
   */
  getAddressById: (state) => (addressId) => {
    return state.addresses.find(address => address.id === Number(addressId))
  },
}
