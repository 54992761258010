
  export default {
    props: {
      colors: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        maxShowableColors: 6,
      }
    },
    computed: {
      showAdditionalColorsText() {
        return this.colors.length > this.maxShowableColors
      },
      additionalColorsCount() {
        return this.showAdditionalColorsText ? this.colors.length - this.maxShowableColors : null
      },
    },
  }
