import { MAGENTO_OUT_OF_STOCK_ERROR_MESSAGE } from '~/lib/constants'
import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'

/**
 * @typedef {BambuserService}
 * @alias this.$bambuserService
 */
export class BambuserService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.i18n = context.i18n
    this.store = context.store
    this.router = context.app.router
    this.isApp = false

    this.origin = 'live shopping event'
  }

  init() {
    this.$cartItemsService = this.context.$cartItemsService
    this.$productApiService = this.context.$productApiService
    this.$bambuserMapperService = this.context.$bambuserMapperService
  }

  /**
   * Docs: https://bambuser.com/docs/one-to-many/initial-setup/
   * @param {string} showId
   * @return {void}
   */
  initializeLiveShopping(showId) {
    if (!window.initBambuserLiveShopping) {
      window.initBambuserLiveShopping = (item) => {
        window.initBambuserLiveShopping.queue.push(item)
      }

      window.initBambuserLiveShopping.queue = []
      const scriptNode = document.createElement('script')

      scriptNode.src = 'https://lcx-embed-eu.bambuser.com/loavies/embed.js'

      document.body.appendChild(scriptNode)
    }

    this.initOnReady()

    window.initBambuserLiveShopping({
      showId: showId.trim(),
      type: 'overlay',
    })
  }

  /**
   * Docs: https://lcx-eu.bambuser.com/tZkqjIcoj1Xt1pEDeLcJ/one-to-many/floating-widget
   * @return {*}
   */
  initWidget() {
    window.__bfwId = 'T1l46SoOpeQcHFeZH0o1'
    const scriptId = 'bambuser-liveshopping-widget'

    if (document.getElementById(scriptId) && window.__bfwInit) {
      return window.__bfwInit()
    }

    if (document.getElementById(scriptId)) {
      return
    }

    const scriptNode = document.createElement('script')

    scriptNode.id = scriptId
    scriptNode.src = 'https://lcx-widgets-eu.bambuser.com/embed.js'

    const firstScriptElement = document.getElementsByTagName('script')[0]
    const headElement = firstScriptElement.parentNode

    this.initOnReady()

    headElement.insertBefore(scriptNode, firstScriptElement)
  }

  /**
   * Init onready events
   * @returns {void}
   */
  initOnReady() {
    this.isApp = !!this.context.route.query['is-app']

    window.onBambuserLiveShoppingReady = player => {
      player.configure({
        currency: this.store.state.localization.currency,
        locale: this.i18n.locales.find(locale => locale.code === this.i18n.locale).iso,
        buttons: {
          // Disables the Mini-player
          dismiss: player.BUTTON.CLOSE,
          checkout: player.BUTTON.MINIMIZE,
        },
        floatingPlayer:{
          navigationMode: player.FLOATING_PLAYER_NAVIGATION_MODE.MANUAL,
        },
        ...(this.isApp && { checkoutOnCartClick: true }),
      })

      player.on(player.EVENT.PROVIDE_PRODUCT_DATA, event => this.handleProvideProductDataEvent(event, player))
      player.on(player.EVENT.ADD_TO_CART, (addedItem, callback) => this.handleAddToCartEvent(addedItem, callback))
      player.on(player.EVENT.UPDATE_ITEM_IN_CART, (updatedItem, callback) => this.handleUpdateItemInCartEvent(updatedItem, callback))
      player.on(player.EVENT.SYNC_CART_STATE, () => this.handleSyncCartState(player))
      player.on(player.EVENT.NAVIGATE_BEHIND_TO, (route) => this.handleRouteChange(route))
      player.on(player.EVENT.CHECKOUT, () => this.handleCheckoutEvent(player))
    }
  }

  /**
   * Remove all prior floating widgets
   * @returns {void}
   */
  removeWidget() {
    const floatingWidgets = document.querySelectorAll('[data-bambuser-liveshopping-floating-id]')
    floatingWidgets.forEach((floatingWidget) => floatingWidget.remove())
  }

  /**
   * The "event" object contains all products which are entered
   * in the Bambuser Dashboard. The player needs to be enriched
   * with our own data for these products.
   * Docs: https://bambuser.com/docs/one-to-many/cart-integration#providing-more-data-to-player-products
   * @param {Object} event
   * @param {Object} player
   * @returns {Promise<void>}
   */
  async handleProvideProductDataEvent(event, player) {
    const queryableSkus = event.products.map(({ ref: sku }) => sku)

    /**
     * @typedef {Object} ProductData
     * @property {string} bambuserId
     * @property {Object} product
     * @property {Array<Object>|null} variations
     */

    /**
     * A collection of data which we can provide to the Bambuser player
     * to be mapped for each product. This collection is structured as
     * an object with the sku as key and the value contains the bambuserId,
     * the product and all variations of this product.
     * @type {Object.<string, ProductData>}
     */
    const enrichedProductsData = event.products.reduce((acc, { ref: sku, id: bambuserId }) => {
      acc[sku] = {
        bambuserId,
        product: null,
        variations: null,
      }

      return acc
    }, {})

    // Enrich the enrichedProductsData with product and variations data from Elastic.
    await this.$productApiService
      .getMultipleProductsBySku(queryableSkus)
      .then(async products => {
        for (let product of products) {
          const sku = getPrimarySkuFromProduct(product)

          enrichedProductsData[sku].product = product
          enrichedProductsData[sku].variations = product.item_layers?.length
            ? await this.$productApiService.getMultipleProductsBySku(product.item_layers)
            : null
        }
      })

    // Provide the player with all the data for the products.
    Object.values(enrichedProductsData).forEach(item => {
      if (item.product) {
        player.updateProduct(item.bambuserId, productFactory =>
          this.$bambuserMapperService.mapProductForBambuser({
            productFactory,
            productData: item,
          })
        )
      } else {
        // This fallback will hide the product if it is not
        // returned by Elastic.
        player.updateProduct(item.bambuserId, productFactory =>
          productFactory
            .inheritFromPlaceholder()
            .hidden(true)
        )
      }
    })
  }

  /**
   * Docs: https://bambuser.com/docs/one-to-many/cart-integration#handle-add-to-cart-event
   * @param {Object} addedItem
   * @param {Function} callback
   * @returns {Promise<void>}
   */
  async handleAddToCartEvent(addedItem, callback) {
    const sku = addedItem.sku
    const product = await this.$productApiService.getProductBySku(sku)
    const sizes = product.configurable_options?.find(option => option.attribute_code === 'size')
    const selectedSize = sizes
      ? Object.assign(
        {},
        { option_id: sizes.option_id },
          sizes.options.find(size => size.product_sku === sku)
        )
      : null

    this.$cartItemsService.addToCart({
      product,
      quantity: 1,
      selectedSize,
      origin: this.origin,
    })
      .then(() => callback(true))
      .catch(error => this.handleCartError(error, callback))
  }

  /**
   * Docs: https://bambuser.com/docs/one-to-many/cart-integration#handle-update-cart-event
   * @param {Object} updatedItem
   * @param {Function} callback
   * @returns {Promise<void>}
   */
  async handleUpdateItemInCartEvent(updatedItem, callback) {
    const sku = updatedItem.sku
    const cartItem = this.store.getters['checkout/getCartItemBySku'](sku)

    this.$cartItemsService.updateCartItemQuantity({
      cartItem,
      quantity: updatedItem.quantity,
      origin: this.origin,
    })
      .then(() => callback(true))
      .catch(error => this.handleCartError(error, callback))
  }

  /**
   * Docs: https://bambuser.com/docs/one-to-many/cart-integration#sync-the-player-cart
   * @param {Object} player
   * @returns {void}
   */
  handleSyncCartState(player) {
    if (this.store.state.checkout.cartItems.length === 0) {
      player.updateCart({
        items: [],
      })
    }
  }

  /**
   * To prevent player errors we redirect to the checkout and after
   * a timeout of 1 second we close the player.
   * @param {Object} player
   * @returns {void}
   */
  handleCheckoutEvent(player) {
    if (this.isApp) {
      window.location.assign(this.context.env.appCheckoutDeeplink)
      return
    }

    player.showCheckout(`${window.location.origin}/${this.i18n.locale}/checkout/`)
    setTimeout(() => player.close(), 1000)
  }


  /**
   * The player.showCheckout triggers this by an event to handle the routing.
   * This is because we set navigationMode: player.FLOATING_PLAYER_NAVIGATION_MODE.MANUAL.
   * Then you have to implement the player.EVENT.NAVIGATE_BEHIND_TO event which triggers this.
   * @param {Object} route
   * @returns {void}
   */
  handleRouteChange(route) {
    const isApp = this.context.route.query['is-app']

    if (isApp) {
      return
    }

    const base_url_regex = /^https?:\/\/[^\/]+/

    const path = route.url.replace(base_url_regex, '')

    this.router.push(path)
  }

  /**
   * For custom error messages see the docs.
   * Docs: https://bambuser.com/docs/one-to-many/player-api-reference#callback
   * @param {Object} error
   * @param {Function} callback
   */
  handleCartError(error, callback) {
    const isOutOfStockError = error?.response?.data?.message?.includes(MAGENTO_OUT_OF_STOCK_ERROR_MESSAGE)

    if (isOutOfStockError) {
      callback({
        success: false,
        reason: 'out-of-stock',
      })
    } else {
      callback(false)
    }
  }
}
