
  export default {
    props: {
      height: {
        type: [String, Number],
        required: true,
      },
      width: {
        type: [String, Number],
        required: true,
      },
    },
    computed: {
      ratio() {
        return this.height / this.width * 100
      },
    },
  }
