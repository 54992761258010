
  import { mapGetters, mapState } from 'vuex'

  export default {
    data() {
      return {
        currentLocale: this.$i18n.locale,
      }
    },
    computed: {
      ...mapGetters({
        isGlobalECustomer: 'localization/isGlobalECustomer',
      }),
      ...mapState({
        i18nPageParams: state => state.localization.i18nPageParams,
        currency: state => state.localization.currency,
      }),
      paymentMethods() {
        return this.$staticDataService.cmsLayoutsDefault?.paymentMethods ?? []
      },
      footer() {
        return this.$staticDataService.cmsLayoutsDefault?.footer
      },
      locales() {
        return this.$i18n.locales.map(locale => ({
          name: locale.name,
          value: locale.code,
        }))
      },
      localeIcon() {
        const iconByLocale = {
          nl: 'netherlands',
          en: 'united-kingdom',
          fr: 'france',
          es: 'spain',
          de: 'german',
        }

        return iconByLocale[this.currentLocale] || iconByLocale[this.$i18n.defaultLocale]
      },
    },
    methods: {
      handleSwitchLocale(locale) {
        return this.$localizationService.switchLocale({
          locale,
          currentRoute: this.$route,
        })
      },
    },
  }
