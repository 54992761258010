import getOnlineFromDateFilter from './get-online-from-date-filter'

export default (product, optimizers) => {
  const onlineFromDateFilter = getOnlineFromDateFilter()
  const model = product.model[0]
  const guestPriceGroup = product.price.find(priceGroup => priceGroup.customer_group_id === 0)
  const minimumPrice = guestPriceGroup.price

  return {
    size: 30,
    query: {
      function_score: {
        ...(optimizers && optimizers),
        query: {
          bool: {
            must: [
              {
                term: {
                  model: {
                    value: model,
                  },
                },
              },
              {
                term: {
                  'stock.is_in_stock': {
                    value: true,
                  },
                },
              },
              {
                nested: {
                  path: 'price',
                  query: {
                    range: {
                      'price.price': {
                        gte: minimumPrice,
                      },
                    },
                  },
                },
              },
            ],
            ...(onlineFromDateFilter && { filter: [ onlineFromDateFilter ] }),
          },
        },
      },
    },
  }
}
