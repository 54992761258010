const locales = ['en', 'nl', 'fr', 'es']

function mapLocalesToVariables(locales, variable) {
  return locales.reduce((acc, locale) => {
    acc[locale] = variable.replace('{LOCALE}', locale)

    return acc
  }, {})
}

module.exports = {
  products: mapLocalesToVariables(locales, (process.env.elasticProductBase || process.env.ELASTIC_PRODUCT_BASE)),
  categories: mapLocalesToVariables(locales, (process.env.elasticCategoryBase || process.env.ELASTIC_CATEGORY_BASE)),
  thesaurus: mapLocalesToVariables(locales, (process.env.elasticThesaurusBase || process.env.ELASTIC_THESAURUS_BASE)),
}
