
  export default {
    props: {
      labelId: {
        type: [String, Number],
        required: true,
      },
      labelText: {
        type: String,
        default: '',
      },
      invertTogglePosition: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Boolean,
        default: false,
      },
      toggleCentered: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['input'],
  }
