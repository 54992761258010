import cookie from '~/lib/cookie'
import { SOCIAL_RETAIL_COOKIE_NAME } from '~/lib/constants'

/**
 * @typedef {SocialRetailService}
 * @alias this.$socialRetailService
 */
export class SocialRetailService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  /**
   * @param {boolean} socialRetailIsActive
   * @returns {Promise<void>}
   */
  setSocialRetailIsActive(socialRetailIsActive) {
    const SEVEN_DAYS = 7

    cookie(SOCIAL_RETAIL_COOKIE_NAME, socialRetailIsActive, { expires: SEVEN_DAYS })

    return this.store.dispatch('setSocialRetailIsActive', socialRetailIsActive)
  }
}
