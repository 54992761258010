import { render, staticRenderFns } from "./search-bar.vue?vue&type=template&id=46b050c2"
import script from "./search-bar.vue?vue&type=script&lang=js"
export * from "./search-bar.vue?vue&type=script&lang=js"
import style0 from "./search-bar.vue?vue&type=style&index=0&id=46b050c2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIcon: require('/opt/build/repo/src/client/components/svg-icon/svg-icon.vue').default,InputField: require('/opt/build/repo/src/client/components/input-field/input-field.vue').default,AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default})
