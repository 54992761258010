import * as Sentry from '@sentry/vue'

/**
 * This file is initialized by Sentry in nuxt.config.js
 * @see https://sentry.nuxtjs.org/sentry/options#customclientintegrations
 * @returns Array
 */
export default () => {
  return [
    new Sentry.Replay({
      blockAllMedia: false,
      maskAllText: false,
    }),
  ]
}
