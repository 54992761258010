export default {
  /**
   * @param context
   * @param {Object} wishlist
   * @returns {Promise}
   */
  setWishlist(context, wishlist) {
    context.commit('SET_WISHLIST', wishlist)
  },

  /**
   * @param context
   * @returns {Promise}
   */
  clearWishlist(context) {
    context.commit('CLEAR_WISHLIST')
  },

  /**
   * @param context
   * @param {boolean} isInitializingWishlist
   */
  setWishlistInitializing(context, isInitializingWishlist) {
    context.commit('SET_WISHLIST_INITIALIZING', isInitializingWishlist)
  },

  /**
   * @param context
   * @param {Object} product
   * @param {string} sku
   * @returns {Promise}
   */
  addWishlistItem(context, { product, sku }) {
    context.commit('ADD_WISHLIST_ITEM', { product, sku })
  },

  /**
   * @param context
   * @param {string} sku
   * @returns {Promise}
   */
  deleteWishlistItemBySku(context, sku) {
    context.commit('DELETE_WISHLIST_ITEM_BY_SKU', sku)
  },
}
