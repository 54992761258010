import defaultState from './state'

export default {
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {Object} wallet
   */
  SET_WALLET(state, wallet) {
    state.wallet = wallet
  },

  /**
   * @param state
   * @param {number} payFromWalletAmountInCents
   */
  SET_PAY_FROM_WALLET_AMOUNT_IN_CENTS(state, payFromWalletAmountInCents) {
    state.payFromWalletAmountInCents = payFromWalletAmountInCents
  },
}
