import defaultState from './state'

export default {
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {number} currentProductUspIndex
   */
  SET_CURRENT_PRODUCT_USP_INDEX(state, currentProductUspIndex) {
    state.currentProductUspIndex = currentProductUspIndex
  },
}
