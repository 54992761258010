import { render, staticRenderFns } from "./block-item.vue?vue&type=template&id=69bc5b49"
import script from "./block-item.vue?vue&type=script&lang=js"
export * from "./block-item.vue?vue&type=script&lang=js"
import style0 from "./block-item.vue?vue&type=style&index=0&id=69bc5b49&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RegisterForm: require('/opt/build/repo/src/client/components/register-form/register-form.vue').default,CountdownItem: require('/opt/build/repo/src/client/components/countdown-item/countdown-item.vue').default,ButtonItem: require('/opt/build/repo/src/client/components/button-item/button-item.vue').default,MediaItem: require('/opt/build/repo/src/client/components/media-item/media-item.vue').default,CtaLinkBlock: require('/opt/build/repo/src/client/components/cta-link-block/cta-link-block.vue').default,NewsletterSignupForm: require('/opt/build/repo/src/client/components/newsletter-signup-form/newsletter-signup-form.vue').default,LiveShowButtonItem: require('/opt/build/repo/src/client/components/live-show-button-item/live-show-button-item.vue').default,CustomButtonItem: require('/opt/build/repo/src/client/components/custom-button-item/custom-button-item.vue').default,NewsletterSubscribeButton: require('/opt/build/repo/src/client/components/newsletter-subscribe-button/newsletter-subscribe-button.vue').default,CardCarousel: require('/opt/build/repo/src/client/components/card-carousel/card-carousel.vue').default})
