
  import { mapGetters, mapState } from 'vuex'

  import { SHOWN_NOTICE_IDS_COOKIE_NAME } from '~/lib/constants'
  import cookie from '~/lib/cookie'

  export default {
    data() {
      return {
        shownNotices: [],
        noticeToShow: null,
        showNotice: false,
      }
    },
    computed: {
      ...mapState({
        notices: state => state.notice.notices,
        userIsInitialized: state => state.user.isInitialized,
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
      title() {
        return this.noticeToShow?.title[this.$i18n.locale]
      },
      text() {
        return this.noticeToShow?.text[this.$i18n.locale]
      },
      icon() {
        return this.noticeToShow?.icon || 'info-circle'
      },
      iconColor() {
        return this.noticeToShow?.iconColor || '#000000'
      },
    },
    watch: {
      notices: {
        deep: true,
        handler() {
          this.checkNoticeToShow()
        },
      },
      shownNotices: {
        deep: true,
        handler() {
          this.checkNoticeToShow()
        },
      },
      userIsInitialized() {
        this.checkNoticeToShow()
      },
      isAuthenticated() {
        this.checkNoticeToShow()
      },
    },
    mounted() {
      this.shownNotices = cookie(SHOWN_NOTICE_IDS_COOKIE_NAME) ? JSON.parse(cookie(SHOWN_NOTICE_IDS_COOKIE_NAME)) : []
    },
    methods: {
      checkNoticeToShow() {
        if (process.env.isCypressTest) {
          return
        }

        if (!this.userIsInitialized || this.noticeToShow) {
          return
        }

        this.noticeToShow = this.notices
          .find(notice => !this.shownNotices.includes(notice.id) && (this.isAuthenticated
            ? notice.users
            : notice.guests),
          ) ?? null

        if (this.noticeToShow) {
          cookie(SHOWN_NOTICE_IDS_COOKIE_NAME, JSON.stringify([ ...this.shownNotices, this.noticeToShow.id ]))
        }

        this.showNotice = !!this.noticeToShow
      },
      closeNotice() {
        this.showNotice = false

        // Make sure this timeout is longer than the css transform animation
        setTimeout(() => {
          if (!this.noticeToShow?.id) {
            return
          }

          this.shownNotices.push(this.noticeToShow.id)
          this.noticeToShow = null
        }, 500)
      },
    },
  }
