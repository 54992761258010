export default [
  'app_only',
  'category',
  'configurable_options',
  'expected_stock_date',
  'media_gallery',
  'multi_store_urls',
  'name',
  'discount_percentage',
  'option_text_out_of_stock_reason',
  'option_text_shoutout',
  'option_text_size_tip',
  'option_text_brand',
  'option_text_model',
  'out_of_stock_reason',
  'prices_storefront',
  'primary_category_crumbs',
  'sku',
  'stock',
  'subtitle',
  'wishlist_counter',
  'item_layers',
  'image_variation_code',
  'entity_id',
  'document_id',
  'selection',
  'media_video',
]
