import { extend, configure } from 'vee-validate'
import { email, min, regex, required, numeric } from 'vee-validate/dist/rules'
import { COUNTRY_CODE_FR, COUNTRY_CODE_ES } from '~/lib/constants'
import { BuckarooClientSideEncryption } from '~/lib/vendor/buckaroo/client-side-encryption'
import { postalCodeValidatorService } from '~/services/validators/postal-code-validator-service'

const buckarooEncryption = BuckarooClientSideEncryption.V001

export default function ({ app }) {
  // For a complete list of validation rules visit:
  // https://logaretm.github.io/vee-validate/guide/rules.html#rules
  extend('email', email)
  extend('min', min)
  extend('regex', regex)
  extend('required', required)
  extend('numeric', numeric)

  // Custom rules
  extend('password', value => {
    if (value.length < 8) {
      return app.i18n.t('validation_password_length')
    }

    return true
  })

  extend('buckaroo_card_holder', value => {
    return buckarooEncryption.validateCardholderName(value)
  })

  extend('buckaroo_card_number', {
    validate(value, args) {
      if (!args['cardService']) {
        return false
      }

      return buckarooEncryption.validateCardNumber(value.replace(/ /g, ''), args['cardService'])
    },
    params: ['cardService'],
  })

  extend('buckaroo_month', value => {
    return buckarooEncryption.validateMonth(value)
  })

  extend('buckaroo_year', value => {
    return buckarooEncryption.validateYear(value)
  })

  extend('buckaroo_cvc', {
    validate(value, args) {
      if (!args['cardService']) {
        return false
      }

      return buckarooEncryption.validateCvc(value, args['cardService'])
    },
    params: ['cardService'],
  })

  extend('magento_complete_address', {
    validate(value, args) {
      const street = args['populatedAddress']?.street?.[0]
      const houseNumber = args['populatedAddress']?.street?.[1]

      if (!street) {
        return app.i18n.t('validation_missing_street')
      }

      // House number is not required for French addresses
      if (!houseNumber && args['populatedAddress']?.country_id !== COUNTRY_CODE_FR) {
        return app.i18n.t('validation_missing_house_number')
      }

      if (houseNumber && isNaN(Number(houseNumber.replace(/ /g, '')))) {
        return app.i18n.t('validation_numeric_spaces_house_number')
      }

      if (!args['populatedAddress']?.postcode) {
        return app.i18n.t('validation_missing_postal_code')
      }

      // Dirty fix to let people from French overseas departments (i.e. Guadeloupe, Martinique etc.)
      // know that we do not ship to their area.
      if (isFrenchOverseasDepartmentCode(args['populatedAddress']?.country_id, args['populatedAddress']?.postcode)) {
        return app.i18n.t('validation_french_overseas_postal_code')
      }

      // Dirty fix to let people from Spanish overseas departments (i.e. Canary Island etc.)
      // know that we do not ship to their area.
      if (isSpainOverseasDepartmentCode(args['populatedAddress']?.country_id, args['populatedAddress']?.postcode)) {
        return app.i18n.t('validation_spain_overseas_postal_code')
      }

      if (!args['populatedAddress']?.city) {
        return app.i18n.t('validation_missing_city')
      }

      if (!args['populatedAddress']?.country_id) {
        return app.i18n.t('validation_missing_country')
      }

      if (!postalCodeValidatorService.isValid(args['populatedAddress']?.country_id, args['populatedAddress']?.postcode)) {
        return app.i18n.t('validation_postal_code')
      }

      return true
    },
    params: ['populatedAddress'],
  })

  extend('numeric_spaces_house_number', value => {
    return !isNaN(Number(value.replace(/ /g, '')))
  })

  extend('postal_code', {
    validate(value, args) {
      return postalCodeValidatorService.isValid(args['countryId'], value)
    },
    params: ['countryId'],
  })

  // Dirty fix to let people from French overseas departments (i.e. Guadeloupe, Martinique etc.)
  // know that we do not ship to their area.
  extend('french_overseas_postal_code', {
    validate(value, args) {
      return !isFrenchOverseasDepartmentCode(args['countryId'], value)
    },
    params: ['countryId'],
  })

  // Dirty fix to let people from Spanish overseas departments (i.e. Canary Island etc.)
  // know that we do not ship to their area.
  extend('spain_overseas_postal_code', {
    validate(value, args) {
      return !isSpainOverseasDepartmentCode(args['countryId'], value)
    },
    params: ['countryId'],
  })

  configure({
    defaultMessage: (field, values) => {
      // Validation error messages get injected in i18n here
      // Example translation token: validation_required
      return app.i18n.t(`validation_${values._rule_}`, values)
    },
  })

  extend('max_amount_wallet', {
    validate(value, args) {
      const maxAmountInCents = args['maxAmount']
      const valueInCents = app.$currencyService.parseAmountToCents(value)

      if (valueInCents > maxAmountInCents) {
        const currency = args['currency']
        const maxAmountFormatted = app.$currencyService.formatCentsToLocalePrice(maxAmountInCents, currency)

        return app.i18n.t('validation_max_amount_wallet', { amount: maxAmountFormatted })
      }

      return true
    },
    params: ['maxAmount', 'currency'],
  })

  extend('date', value => {
    const date = new Date(value)
    const dateIsValid = app.$dateTimeService.isValidDate(date)
    const yearIsValid = dateIsValid ? String(date.getFullYear()).length === 4 : false

    return yearIsValid
  })
}

function isFrenchOverseasDepartmentCode(countryId, postalCode) {
  if (!countryId || !postalCode) {
    return false
  }

  // All French overseas departments start with either 97 or 98
  // SOURCE: https://en.wikipedia.org/wiki/Postal_codes_in_France
  const departmentCodes = ['97', '98']
  const isFrenchCountry = countryId === COUNTRY_CODE_FR
  let isOverseasDepartmentCode = false

  departmentCodes.forEach(code => {
    if (postalCode.startsWith(code)) {
      isOverseasDepartmentCode = true
    }
  })

  return isFrenchCountry && isOverseasDepartmentCode
}

function isSpainOverseasDepartmentCode(countryId, postalCode) {
  if (!countryId || !postalCode) {
    return false
  }

  // Canary Islands begin with 35 or 38
  // SOURCE: https://en.wikipedia.org/wiki/Postal_codes_in_Spain
  const departmentCodes = ['35', '38', '51', '52']
  const isSpainCountry = countryId === COUNTRY_CODE_ES
  let isOverseasDepartmentCode = false

  departmentCodes.forEach(code => {
    if (postalCode.startsWith(code)) {
      isOverseasDepartmentCode = true
    }
  })

  return isSpainCountry && isOverseasDepartmentCode
}
