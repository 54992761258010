import getOnlineFromDateFilter from './get-online-from-date-filter'
import productCardAttributes from '~/components/product-card/product-card-attributes'
import {
  aggregationsPart,
  postFiltersPart,
  sortPart,
} from '~/services/product/queries/get-products-by-category-options-query'

/**
 * @param {string} searchQuery
 * @param {Array} synonyms
 * @param {number} itemsToSkip
 * @param {number} pageSize
 * @param {Object} optimizers
 * @param {Array} defaultBoosters
 * @param {Array} synonymBoosters
 * @param {Object} [checkedFilters]
 * @param {Object} [sortOption]
 * @param {number} [customerGroupId]
 * @return {Object}
 */
export default ({
  searchQuery,
  synonyms,
  itemsToSkip,
  pageSize,
  optimizers,
  defaultBoosters,
  synonymBoosters,
  checkedFilters,
  sortOption,
  customerGroupId,
}) => {
  const onlineFromDateFilter = getOnlineFromDateFilter()

  return {
    _source: productCardAttributes,
    size: pageSize,
    from: itemsToSkip,
    ...(sortOption && sortOption?.value !== 'position' && sortPart(sortOption, customerGroupId)),
    ...aggregationsPart(checkedFilters),
    ...(checkedFilters && postFiltersPart(checkedFilters)),
    query: {
      function_score: {
        ...optimizers,
        query: {
          bool: {
            boost: 1,
            filter: {
              bool: {
                must: [
                  ...(onlineFromDateFilter ? [onlineFromDateFilter] : []),
                  {
                    terms: {
                      visibility: [3, 4],
                      boost: 1,
                    },
                  },
                  {
                    bool: {
                      must_not: [
                        {
                          nested: {
                            path: 'search_query',
                            score_mode: 'none',
                            query: {
                              bool: {
                                must: [
                                  {
                                    term: {
                                      'search_query.query_id': {
                                        value: 1,
                                        boost: 1,
                                      },
                                    },
                                  },
                                  {
                                    term: {
                                      'search_query.is_blacklisted': {
                                        value: true,
                                        boost: 1,
                                      },
                                    },
                                  },
                                ],
                                must_not: [],
                                should: [],
                                boost: 1,
                              },
                            },
                            boost: 1,
                          },
                        },
                      ],
                      boost: 1,
                    },
                  },
                  {
                    terms: {
                      visibility: [3, 4],
                      boost: 1,
                    },
                  },
                ],
                must_not: [],
                should: [],
                boost: 1,
              },
            },
            must: getQuery(
              searchQuery,
              synonyms,
              defaultBoosters,
              synonymBoosters,
            ),
          },
        },
      },
    },
  }
}

/**
 * @param {string} searchQuery
 * @param {Array} synonyms
 * @param {Array} defaultBoosters
 * @param {Array} synonymBoosters
 * @return {Object}
 */
function getQuery(
  searchQuery,
  synonyms,
  defaultBoosters,
  synonymBoosters,
) {
  return synonyms?.length
    ? getQueryWithSynonyms(
      searchQuery,
      synonyms,
      defaultBoosters,
      synonymBoosters,
    )
    : getQueryWithoutSynonyms(
      searchQuery,
      defaultBoosters,
    )
}

/**
 * @param {string} searchQuery
 * @param {Array} defaultBoosters
 * @return {Object}
 */
function getQueryWithoutSynonyms(searchQuery, defaultBoosters) {
  return {
    bool: {
      filter: {
        multi_match: {
          query: searchQuery,
          fields: [
            'search^1',
            'sku^1',
          ],
          minimum_should_match: '100%',
          tie_breaker: 1,
          boost: 1,
          type: 'best_fields',
          cutoff_frequency: 0.15,
        },
      },
      must: {
        multi_match: {
          query: searchQuery,
          fields: [
            ...defaultBoosters,
          ],
          minimum_should_match: 1,
          tie_breaker: 1,
          boost: 1,
          type: 'best_fields',
          cutoff_frequency: 0.15,
        },
      },
      boost: 1,
    },
  }
}

/**
 * @param {string} searchQuery
 * @param {Array} synonyms
 * @param {Array} defaultBoosters
 * @param {Array} synonymBoosters
 * @return {Object}
 */
function getQueryWithSynonyms(
  searchQuery,
  synonyms,
  defaultBoosters,
  synonymBoosters,
) {
  return {
    bool: {
      boost: 1,
      minimum_should_match: 1,
      must: [],
      must_not: [],
      should: getShouldQuery(
        searchQuery,
        synonyms,
        defaultBoosters,
        synonymBoosters,
      ),
    },
  }
}

/**
 * @param {string} searchQuery
 * @param {Array} synonyms
 * @param {Array} defaultBoosters
 * @param {Array} synonymBoosters
 * @return {Array}
 */
function getShouldQuery(
  searchQuery,
  synonyms,
  defaultBoosters,
  synonymBoosters,
) {
  const should = [
    {
      bool: {
        boost: 1,
        filter: {
          multi_match: {
            boost: 1,
            cutoff_frequency: 0.15,
            fields: [
              'search^1',
              'sku^1',
            ],
            minimum_should_match: '100%',
            query: searchQuery,
            tie_breaker: 1,
            type: 'best_fields',
          },
        },
        must: {
          multi_match: {
            boost: 1,
            cutoff_frequency: 0.15,
            fields: [
              ...defaultBoosters,
            ],
            minimum_should_match: 1,
            query: searchQuery,
            tie_breaker: 1,
            type: 'best_fields',
          },
        },
      },
    },
  ]

  synonyms.forEach(item => {
    should.push({
      bool: {
        boost: 0.1,
        filter: {
          multi_match: {
            boost: 1,
            cutoff_frequency: 0.15,
            fields: [
              'search^1',
              'sku^1',
            ],
            minimum_should_match: '100%',
            query: item,
            tie_breaker: 1,
            type: 'best_fields',
          },
        },
        must: {
          multi_match: {
            boost: 1,
            cutoff_frequency: 0.15,
            fields: [
              ...synonymBoosters,
            ],
            minimum_should_match: 1,
            query: item,
            tie_breaker: 1,
            type: 'best_fields',
          },
        },
      },
    })
  })

  return should
}
