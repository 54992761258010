import cloneDeep from 'lodash.clonedeep'

import defaultState from './state'

export default {
  /**
   * @param state
   * @param {string[]} [preservedProperties]
   * @constructor
   */
  RESET_STATE(state, preservedProperties = []) {
    const preservedKeyValues = preservedProperties.reduce((acc, value) => {
      if (state[value]) {
        acc[value] = cloneDeep(state[value])
      }

      return acc
    }, {})

    Object.assign(state, defaultState())

    Object.keys(preservedKeyValues).forEach(key => {
      state[key] = preservedKeyValues[key]
    })
  },

  /**
   * @param state
   * @param {string|null} cartId
   */
  SET_CART_ID(state, cartId) {
    state.cartId = cartId
  },

  /**
   * @param state
   * @param {Object[]} cartItems
   */
  SET_CART_ITEMS(state, cartItems) {
    state.cartItems = cartItems
  },

  /**
   * @param state
   * @param {Object|null} cartDiscount
   */
  SET_CART_DISCOUNT(state, cartDiscount) {
    state.cartDiscount = cartDiscount
  },

  /**
   * @param state
   * @param {Object|null} cartGiftCard
   */
  SET_CART_GIFT_CARD(state, cartGiftCard) {
    state.cartGiftCard = cartGiftCard
  },

  /**
   * @param state
   * @param {number|null} cartWalletTotal
   */
  SET_CART_WALLET_TOTAL(state, cartWalletTotal) {
    state.cartWalletTotal = cartWalletTotal
  },

  /**
   * @param state
   * @param {boolean} cartIsVirtual
   */
  SET_CART_IS_VIRTUAL(state, cartIsVirtual) {
    state.cartIsVirtual = cartIsVirtual
  },

  /**
   * @param state
   * @param {number} cartTotal
   */
  SET_CART_TOTAL(state, cartTotal) {
    state.cartTotal = cartTotal
  },

  /**
   * @param state
   * @param {number} cartSubTotal
   */
  SET_CART_SUB_TOTAL(state, cartSubTotal) {
    state.cartSubTotal = cartSubTotal
  },

  /**
   * @param state
   * @param {number} cartSubTotalExclTax
   */
  SET_CART_SUB_TOTAL_EXCL_TAX(state, cartSubTotalExclTax) {
    state.cartSubTotalExclTax = cartSubTotalExclTax
  },

  /**
   * @param state
   * @param {number|null} freeShippingThreshold
   */
  SET_FREE_SHIPPING_THRESHOLD(state, freeShippingThreshold) {
    state.freeShippingThreshold = freeShippingThreshold
  },

  /**
   * @param state
   * @param {number} cartGrandTotal
   */
  SET_CART_GRAND_TOTAL(state, cartGrandTotal) {
    state.cartGrandTotal = cartGrandTotal
  },

  /**
   * @param state
   * @param {Object} shippingAddress
   */
  SET_SHIPPING_ADDRESS(state, shippingAddress) {
    state.shippingAddress = shippingAddress
  },

  /**
   * @param state
   * @param {Object[]} shippingMethods
   */
  SET_SHIPPING_METHODS(state, shippingMethods) {
    state.shippingMethods = shippingMethods
  },

  /**
   * @param state
   * @param {Object} selectedShippingMethod
   */
  SET_SELECTED_SHIPPING_METHOD(state, selectedShippingMethod) {
    state.selectedShippingMethod = selectedShippingMethod
  },

  /**
   * @param state
   * @param {Object} selectedDeliveryMoment
   */
  SET_SELECTED_DELIVERY_MOMENT(state, selectedDeliveryMoment) {
    state.selectedDeliveryMoment = selectedDeliveryMoment
  },

  /**
   * @param state
   * @param {Object} selectedServicePoint
   */
  SET_SELECTED_SERVICE_POINT(state, selectedServicePoint) {
    state.selectedServicePoint = selectedServicePoint
  },

  /**
   * @param state
   * @param {loavies.models.payment.PaymentMethodModel[]} paymentMethods
   */
  SET_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods
  },

  /**
   * @param state
   * @param {Object|null} selectedPaymentMethod
   */
  SET_SELECTED_PAYMENT_METHOD(state, selectedPaymentMethod) {
    state.selectedPaymentMethod = selectedPaymentMethod
  },

  /**
   * @param state
   * @param {Object|null} selectedPaymentMethodDetails
   */
  SET_SELECTED_PAYMENT_METHOD_DETAILS(state, selectedPaymentMethodDetails) {
    state.selectedPaymentMethodDetails = selectedPaymentMethodDetails
  },

  /**
   * @param state
   * @param {boolean} processingOrder
   */
  SET_PROCESSING_ORDER(state, processingOrder) {
    state.processingOrder = processingOrder
  },

  /**
   * @param state
   * @param {Object|null} donation
   */
  SET_DONATION(state, donation) {
    state.donation = donation
  },

  /**
   * @param state
   * @param {number} cartTotalWithoutDiscount
   */
  SET_CART_TOTAL_WITHOUT_DISCOUNT(state, cartTotalWithoutDiscount) {
    state.cartTotalWithoutDiscount = cartTotalWithoutDiscount
  },

  /**
   * @param state
   * @param {number} cartGrandTotalWithoutDiscount
   */
  SET_CART_GRAND_TOTAL_WITHOUT_DISCOUNT(state, cartGrandTotalWithoutDiscount) {
    state.cartGrandTotalWithoutDiscount = cartGrandTotalWithoutDiscount
  },

  /**
   * @param state
   * @param {number} totalDiscount
   */
  SET_TOTAL_DISCOUNT(state, totalDiscount) {
    state.totalDiscount = totalDiscount
  },

  /**
   * @param state
   * @param {boolean} checkoutIsLoading
   */
  SET_CHECKOUT_IS_LOADING(state, checkoutIsLoading) {
    state.checkoutIsLoading = checkoutIsLoading
  },

  /**
   * @param state
   * @param {*} applePaySession
   */
  SET_APPLE_PAY_SESSION(state, applePaySession) {
    state.applePaySession = applePaySession
  },
}
