export const DATA_CMS_DIR = 'static/data/cms'

// Category constants
export const DEFAULT_CATEGORY_PAGE_PRODUCT_SIZE = 24
export const DEFAULT_CATEGORY_FIRST_PAGE = 1

// App-modal names
export const APP_MODAL = 'AppModal'
export const BLOCKED_USER_APP_MODAL_NAME = 'BlockedUserModal'
export const GIFTCARD_DETAILS_MODAL_NAME = 'GiftcardDetailsModal'
export const CHECKOUT_DONATION_APP_MODAL_NAME = 'CheckoutDonationModal'
export const CONFIGURABLE_APP_MODAL_NAME = 'ConfigurableModal'
export const DELETE_ADDRESS_CONFIRMATION_APP_MODAL_NAME = 'DeleteAddressConfirmationModal'
export const IMAGE_GALLERY_OVERLAY_APP_MODAL_NAME = 'ImageGalleryOverlayModal'
export const LOGOUT_CONFIRMATION_APP_MODAL_NAME = 'LogoutConfirmationModal'
export const OUT_OF_STOCK_APP_MODAL_NAME = 'OutOfStockModal'
export const BANCONTACT_QRCODE_APP_MODAL_NAME = 'BancontactQrcodeModal'
export const WALLET_PAYMENT_APP_MODAL_NAME = 'WalletPaymentModal'
export const QUIZ_MODAL = 'QuizModal'

// App-tray names
export const APP_TRAY = 'AppTray'
export const NEWSLETTER_APP_TRAY_NAME = 'NewsletterToastTray'
export const OUT_OF_STOCK_APP_TRAY_NAME = 'OutOfStockTray'
export const PRODUCTS_FILTER_APP_TRAY_NAME = 'ProductsFilterTray'
export const SEARCH_RESULTS_FILTER_APP_TRAY_NAME = 'SearchResultsFilterTray'
export const SHOP_THE_LOOK_APP_TRAY_NAME = 'ShopTheLookTray'
export const LISTINGS_APP_TRAY_NAME = 'ListingsTray'
export const SIZE_SELECT_APP_TRAY_NAME = 'SizeSelectTray'
export const QUIZ_TRAY = 'QuizTray'
export const SEARCH_APP_TRAY_NAME = 'SearchTray'
export const REFER_A_FRIEND_TRAY = 'ReferAFriendTray'
export const SOCIAL_RETAIL_TRAY_NAME = 'SocialRetailTray'
export const BANCONTACT_QRCODE_TRAY_NAME = 'BancontactQrcodeTray'
export const GIFTCARD_DETAILS_TRAY_NAME = 'GiftcardDetailsTray'
export const WALLET_PAYMENT_TRAY_NAME = 'WalletPaymentTray'

// Fly-in names
export const FLY_IN = 'FlyIn'
export const AUTH_FLY_IN_NAME = 'AuthFlyIn'
export const CART_FLY_IN_NAME = 'CartFlyIn'
export const NAVIGATION_FLY_IN_NAME = 'NavigationFlyIn'
export const PRODUCTS_FILTER_FLY_IN_NAME = 'ProductsFilterFlyIn'
export const SEARCH_RESULTS_FILTER_FLY_IN_NAME = 'SearchResultsFilterFlyIn'
export const SEARCH_FLY_IN_NAME = 'SearchFlyIn'
export const REFER_A_FRIEND_FLY_IN = 'ReferAFriendFlyIn'
export const SOCIAL_RETAIL_FLY_IN_NAME = 'SocialRetailFlyIn'

// Shipping methods carrier codes
export const SHIPPING_METHOD_CARRIER_CODE_BPOST = 'loavies_bpost'
export const SHIPPING_METHOD_CARRIER_CODE_DHL = 'dhlparcel'

// Shipping methods method codes
export const SHIPPING_METHOD_CODE_DHL_SERVICE_POINT = 'servicepoint'
export const SHIPPING_METHOD_CODE_DHL_DELIVERY = 'adhl'
export const SHIPPING_METHOD_CODE_BPOST_SERVICE_POINT = 'bpost'

// Delivery types
export const DELIVERY_TYPE_SERVICE_POINT = 'servicepoint'
export const DELIVERY_TYPE_HOME_DELIVERY = 'timeframe'
export const DELIVERY_TYPE_DEFAULT = 'direct'

// Magento payment method codes
export const MAGENTO_CODE_FREE = 'free'
export const MAGENTO_CODE_BILLINK = 'billink'
export const MAGENTO_PAYMENT_METHOD_CODES = [MAGENTO_CODE_FREE, MAGENTO_CODE_BILLINK]

// Magento out of stock error message
export const MAGENTO_OUT_OF_STOCK_ERROR_MESSAGE = 'The requested qty is not available'

// Buckaroo payment method codes
export const BUCKAROO_CODE_KLARNA = 'buckaroo_magento2_klarnakp'
export const BUCKAROO_CODE_IDEAL = 'buckaroo_magento2_ideal'
export const BUCKAROO_CODE_CREDIT_CARDS = 'buckaroo_magento2_creditcards'
export const BUCKAROO_CODE_PAYPAL = 'buckaroo_magento2_paypal'
export const BUCKAROO_CODE_AFTER_PAY = 'buckaroo_magento2_afterpay'
export const BUCKAROO_CODE_FASHION_CHEQUE = 'buckaroo_magento2_giftcards'
export const BUCKAROO_CODE_MR_CASH = 'buckaroo_magento2_mrcash'

// Adyen
export const ADYEN_IDEAL_PAYMENT_METHOD_TYPE = 'ideal'
export const ADYEN_APPLE_PAY_METHOD_TYPE = 'applepay'
export const ADYEN_GIFT_CARD_PAYMENT_METHOD_TYPE = 'giftcard'
export const ADYEN_CREDIT_CARD_CODE = 'adyen_cc'
export const ADYEN_GIFT_CARD_CODE = 'adyen_hpp'
export const ADYEN_KLARNA_PAYMENT_METHOD_TYPE = 'klarna'

// Mollie
export const MOLLIE_IDEAL_PAYMENT_METHOD_TYPE = 'mollie_methods_ideal'
export const MOLLIE_PAYPAL_PAYMENT_METHOD_TYPE = 'mollie_methods_paypal'
export const MOLLIE_APPLE_PAY_METHOD_TYPE = 'mollie_methods_applepay'
export const MOLLIE_BANCONTACT_METHOD_TYPE = 'mollie_methods_bancontact'
export const MOLLIE_GIFT_CARD_PAYMENT_METHOD_TYPE = 'mollie_methods_giftcard'
export const MOLLIE_KLARNA_PAYMENT_METHOD_PREFIX = 'mollie_methods_klarna'
export const MOLLIE_KLARNA_SLICEIT_PAYMENT_METHOD_TYPE = 'mollie_methods_klarnasliceit'
export const MOLLIE_KLARNA_PAYNOW_PAYMENT_METHOD_TYPE = 'mollie_methods_klarnapaynow'
export const MOLLIE_KLARNA_PAYLATER_PAYMENT_METHOD_TYPE = 'mollie_methods_klarnapaylater'
export const MOLLIE_CREDIT_CARD_CODE = 'mollie_methods_creditcard'
export const MOLLIE_PAYMENT_METHODS_PREFIX = 'mollie_'

/**
 * Store constants
 */

// Session
export const SESSION_ID_COOKIE_NAME = 'sessionId'

// Auth Fly In
export const CUSTOM_AUTH_FLY_IN_TYPE_COOKIE_NAME = 'customAuthFlyIn'

// User
export const AUTH_TOKEN_COOKIE_NAME = 'authToken'
export const INITIAL_CUSTOMER_GROUP_ID = 0
export const PHONE_NUMBER_ATTRIBUTE_CODE = 'telephone'

// Checkout
export const GUEST_CART_COOKIE_NAME = 'guestCartId'
export const USER_CART_COOKIE_NAME = 'userCartId'
export const PAYMENT_INFORMATION_COOKIE_NAME = 'paymentInformation'
export const ADYEN_SUCCESS_RESULT_CODES = ['Authorised', 'Received', 'PresentToShopper', 'Pending', 'AuthenticationFinished']
export const ADYEN_FAILED_RESULT_CODES = ['Refused', 'Error', 'Cancelled']
export const INITIAL_CART_ID = null
export const FALLBACK_COUNTRY_ID = 'NL'

// Wishlist
export const LOCAL_WISHLIST_NAME = 'local_wishlist'

// Localization
export const DEFAULT_CURRENCY = 'EUR'

// Large products cards preference
export const USER_PREFERS_LARGE_PRODUCTS_CARDS_COOKIE_NAME = 'user_prefers_large_products_cards'

// Newsletter toast
export const TOAST_COOKIE_NAME = 'lastShownToasts'

// Product locations for GA tracking
export const LOCATION_SEARCH_AUTOCOMPLETE = 'Search autocomplete'
export const LOCATION_SEARCH_RESULTS_PAGE = 'Search results page'
export const LOCATION_CATEGORY_PAGE = 'Category page'
export const LOCATION_WISHLIST_PAGE = 'Wishlist page'
export const LOCATION_MODULAR_CONTENT = 'Modular content'
export const LOCATION_COMBINABLE_PRODUCTS = 'Combinable products'
export const LOCATION_RECENTLY_VIEWED_PRODUCTS = 'Recently viewed products'
export const LOCATION_RELATED_PRODUCTS = 'Related products'
export const LOCATION_SHOP_THE_TOOK_TRAY = 'Shop the look tray'

// Split testing
export const SPLIT_TEST_COOKIE_NAME = 'splitTest-'

/**
 * Validation constants
 */

// Phone number
export const PHONE_NUMBER_REGEX = /^\(?([+][0-9]|[0-9]){0,3}-?(\s?|-)([0-9]\s{0,3}){8,12}$/
export const PHONE_NUMBER_REGEX_9 = /^\(?([+][0-9]|[0-9]){0,3}-?(\s?|-)([0-9]\s{0,3}){9,12}$/

/**
 * Country codes
 */

export const COUNTRY_CODE_NL = 'NL'
export const COUNTRY_CODE_FR = 'FR'
export const COUNTRY_CODE_ES = 'ES'

/**
 * Notices
 */

export const SHOWN_NOTICE_IDS_COOKIE_NAME = 'shownNoticeIds'

// Blocked user constants
export const BLOCKED_USER_COOKIE_NAME = 'blockedUser'

// Social retail
export const SOCIAL_RETAIL_COOKIE_NAME = 'socialRetailActive'

export const MANUAL_ADDRESS_FORM_LOCALES = ['fr', 'es']

export const INVERTED_NUMBER_AND_STREET_LOCALES = ['FR', 'ES']

// Image variations
export const DEFAULT_IMAGE_VARIATION_CODE = 'DEFAULT'

// Global-E
export const GLOBAL_E_EXCLUDED_COUNTRIES = ['NL', 'BE', 'FR', 'ES', 'DE']
export const GLOBAL_E_RETURNS_PAGE = 'https://www.bglobale.com/Returns/Portal/mZno'

// App header
export const APP_HEADER_HEIGHT_DESKTOP_BAR = 76
export const APP_HEADER_HEIGHT_DESKTOP_MENU = 38
export const APP_HEADER_HEIGHT_DESKTOP = APP_HEADER_HEIGHT_DESKTOP_BAR + APP_HEADER_HEIGHT_DESKTOP_MENU

// Servicepoints
export const BPOST_SERVICE_POINTS_LOGO_PATHS = {
  BE: '/logos/bpost.png',
  FR: '/logos/mondial-relay.png',
  ES: '/logos/mondial-relay.png',
  FALLBACK: '/logos/dhl.png',
}

export const DHL_SERVICE_POINTS_LOGO_PATHS = {
  FALLBACK: '/logos/dhl.png',
}

export const FALLBACK_LOGO_PATH = '/logos/dhl.png'

// Cookies banner
export const FUNCTIONAL_COOKIE_NAME = 'functional_cookies_accepted_v2'
export const CONSENT_COOKIE_NAME = 'loavies_consent_cookies'

// Wishlist button
export const SHOW_TIMES_ADDED_THRESHOLD = 10

// Donation options
export const DONATION_OPTIONS = [
  {
    title: 'donation_option_first_title',
    titleSelected: 'donation_option_first_title_selected',
    subTitle: 'donation_option_first_sub_title',
    subTitleSelected: 'donation_option_first_sub_title_selected',
    amount: 2.5,
  },
  {
    title: 'donation_option_second_title',
    titleSelected: 'donation_option_second_title_selected',
    subTitle: 'donation_option_second_sub_title',
    subTitleSelected: 'donation_option_second_sub_title_selected',
    amount: 1,
  },
]

// Reloaved
export const RELOAVED_SUPPORTED_LOCALES = ['nl', 'en']
export const RELOAVED_FALLBACK_LOCALE = 'en'

// Returns
export const REVENI_BASE_URL = 'https://returns.reveni.io/loavies'

// Filters
export const APPLY_FILTERS_THROTTLE_DURATION = 1000

// LOAVIES giftcard
export const GIFTCARD_PRODUCT_TYPE = 'giftcard'
export const GIFTCARD_EXTENSION_ATTRIBUTE = {
  DELIVERY_DATE: 'giftcard_delivery_date',
  MESSAGE: 'giftcard_message',
  RECIPIENT_EMAIL: 'giftcard_recipient_email',
  RECIPIENT_NAME: 'giftcard_recipient_name',
  SENDER_NAME: 'giftcard_sender_name',
}

// Social media id's
export const SOCIAL_MEDIA_IDS_COOKIE_NAME = 'loavies_social_ids'
