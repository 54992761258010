
  export default {
    props: {
      block: {
        type: Object,
        required: true,
      },
      ignoreLazyLoad: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // Horizontal root margin to trigger lazy load earlier within sections-slider.
        lazyLoadRootMargin: '600px 200px 600px 200px',
      }
    },
    computed: {
      tagName() {
        return this.block.title ? 'section' : 'div'
      },
      desktopWidth() {
        return this.block.width && `${this.block.width}%`
      },
      mobileWidth() {
        return (this.block.hasMobileLayout && this.block.mobileWidth)
          ? `${this.block.mobileWidth}%`
          : this.desktopWidth
      },
      desktopHeight() {
        return this.block.height && `${this.block.height}%`
      },
      mobileHeight() {
        return (this.block.hasMobileLayout && this.block.mobileHeight)
          ? `${this.block.mobileHeight}%`
          : this.desktopHeight
      },
      desktopHorizontalOffset() {
        return this.block.horizontalOffset || 0
      },
      mobileHorizontalOffset() {
        return this.block.hasMobileLayout
          ? this.block.mobileHorizontalOffset
          : this.desktopHorizontalOffset
      },
      desktopLeft() {
        return getLeftValue(this.block.horizontalAlignment, this.desktopHorizontalOffset)
      },
      mobileLeft() {
        if (this.block.hasMobileLayout) {
          return this.mobileHorizontalAlignment !== 'right'
            ? getLeftValue(this.block.mobileHorizontalAlignment, this.mobileHorizontalOffset)
            : null
        } else {
          return this.desktopLeft
        }
      },
      desktopRight() {
        if (this.block.horizontalAlignment === 'right') {
          return `${this.desktopHorizontalOffset}%`
        }

        return null
      },
      mobileRight() {
        if (this.block.hasMobileLayout) {
          return (this.block.mobileHorizontalAlignment === 'right')
            ? `${this.mobileHorizontalOffset || 0}%`
            : null
        } else {
          return this.desktopRight
        }
      },
      desktopVerticalOffset() {
        return this.block.verticalOffset || 0
      },
      mobileVerticalOffset() {
        return (this.block.hasMobileLayout)
          ? this.block.mobileVerticalOffset || 0
          : this.desktopVerticalOffset
      },
      desktopTop() {
        return getTopValue(this.block.verticalAlignment, this.desktopVerticalOffset)
      },
      mobileTop() {
        if (this.block.hasMobileLayout) {
          return this.block.mobileVerticalAlignment !== 'bottom'
            ? getTopValue(this.block.mobileVerticalAlignment, this.mobileVerticalOffset)
            : null
        } else {
          return this.desktopTop
        }
      },
      desktopBottom() {
        if (this.block.verticalAlignment === 'bottom') {
          return `${this.desktopVerticalOffset}%`
        }

        return null
      },
      mobileBottom() {
        if (this.block.hasMobileLayout) {
          return (this.block.mobileVerticalAlignment === 'bottom')
            ? `${this.mobileVerticalOffset}%`
            : null
        } else {
          return this.desktopBottom
        }
      },
      desktopTransform() {
        return getTransformValue(this.block.horizontalAlignment, this.block.verticalAlignment)
      },
      mobileTransform() {
        return (this.block.hasMobileLayout && this.block.mobileHorizontalAlignment)
          ? getTransformValue(this.block.mobileHorizontalAlignment, this.block.mobileVerticalAlignment)
          : this.desktopTransform
      },
      opacity() {
        if (!this.block.opacity) {
          return 1
        }

        return this.block.opacity / 100
      },
      backgroundImage() {
        if (this.block.backgroundImage) {
          return `url(${this.block.backgroundImage.url})`
        } else if (this.block.backgroundGradient) {
          return this.block.backgroundGradient.replace(';', '')
        }

        return null
      },
      backgroundColor() {
        return this.block.backgroundColor && this.block.backgroundColor.hex
      },
      hasBackground() {
        return Boolean(this.backgroundImage || this.backgroundColor)
      },
      border() {
        if (this.block.borderSize && this.block.borderColor) {
          return `${this.block.borderSize}px solid ${this.block.borderColor.hex}`
        }

        return null
      },
      justifyContent() {
        return getFlexAlignmentValue(this.block.verticalContentAlignment)
      },
      alignItems() {
        return getFlexAlignmentValue(this.block.horizontalContentAlignment)
      },
      textAlign() {
        return this.block.horizontalContentAlignment || 'center'
      },
      products() {
        return (this.block.products && this.block.products.length)
          ? this.block.products
            .split(',')
            .filter(sku => sku.trim().length > 0)
            .map(sku => ({ sku: sku.trim() }))
          : []
      },
      newsletterBackground() {
        if (this.block.newsletterFormBackgroundGradient) {
          return this.block.newsletterFormBackgroundGradient.replace(';', '')
        } else {
          return this.block.newsletterFormBackgroundColor ? this.block.newsletterFormBackgroundColor.hex : 'transparent'
        }
      },
      newsletterFormBorder() {
        if (this.block.newsletterFormBorderSize && this.block.newsletterFormBorderColor) {
          return `${this.block.newsletterFormBorderSize}px solid ${this.block.newsletterFormBorderColor.hex}`
        }

        return null
      },
      titleFontSize() {
        return getFontSize(this.block.titleFontSize)
      },
      bodyFontSize() {
        return getFontSize(this.block.bodyFontSize)
      },
      digitsFontSize() {
        return getFontSize(this.block.digitsFontSize)
      },
      unitFontSize() {
        return getFontSize(this.block.unitFontSize)
      },
    },
  }

  function getFontSize(pixelValue) {
    const fontSizeLookupTable = {
      '8px': '0.5em',
      '11px': '0.6875em',
      '12px': '0.75em',
      '14px': '0.875em',
      '16px': '1em',
      '18px': '1.125em',
      '20px': '1.25em',
      '26px': '1.625em',
      '40px': '2.5em',
      '64px': '4em',
    }

    return fontSizeLookupTable[pixelValue]
  }

  function getFlexAlignmentValue(alignment) {
    switch (alignment) {
      case ('center'): {
        return 'center'
      }
      case ('right'): {
        return 'flex-end'
      }
      case ('bottom'): {
        return 'flex-end'
      }
      default: {
        return 'flex-start'
      }
    }
  }

  function getTransformValue(horizontalAlignment, verticalAlignment) {
    if (verticalAlignment === 'center' && horizontalAlignment !== 'center') {
      return 'translateY(-50%)'
    } else if (verticalAlignment !== 'center' && horizontalAlignment === 'center') {
      return 'translateX(-50%)'
    } else if (verticalAlignment === 'center' && horizontalAlignment === 'center') {
      return 'translate(-50%, -50%)'
    }
  }

  function getLeftValue(alignment, offset) {
    if (alignment === 'left') {
      return `${offset}%`
    } else if (alignment === 'center') {
      return `${50 + offset}%`
    }
  }

  function getTopValue(alignment, offset) {
    if (alignment === 'top') {
      return `${offset}%`
    } else if (alignment === 'center') {
      return `${50 + offset}%`
    }
  }
