/**
 * @typedef {CartDonationService}
 * @alias this.$cartDonationService
 */
export class CartDonationService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$checkoutApiService = this.context.$checkoutApiService
    this.$cartItemsService = this.context.$cartItemsService
    this.$cartService = this.context.$cartService
  }

  /**
   * @param {number} amount
   */
  async addDonation(amount) {
    const cartId = this.store.state.checkout.cartId ?? await this.$cartService.createCart()
    const currentDonation = this.store.state.checkout.donation

    if (currentDonation) {
      return this.removeDonation()
    }

    try {
      await this.$checkoutApiService.addDonationToCart({
        amount,
        cartId,
      })
    } catch (error) {
      const responseData = error?.response?.data

      return this.$cartItemsService.handleAddToCartErrorMessage(responseData)
    }

    await this.$cartService.updateCart()
    await this.store.dispatch('snackbar/addSnackbarMessage', { message: 'added_to_cart', icon: 'cart-check' })
  }

  /**
   * @returns {Promise}
   */
  async removeDonation() {
    const cartId = this.store.state.checkout.cartId
    const currentDonation = this.store.state.checkout.donation

    if (!currentDonation) {
      return
    }

    await this.$checkoutApiService.removeDonationFromCart({
      donation: currentDonation,
      cartId,
    })

    await this.$cartService.updateCart()
  }

  /**
   * @param {Object[]} cartItems
   * @returns {Object|null}
   */
  getDonationFromCartItems(cartItems) {
    return cartItems.find(cartItem => cartItem.product_type === 'donation') || null
  }
}
