import deepFreeze from 'deep-freeze'

import { DEFAULT_IMAGE_VARIATION_CODE } from '~/lib/constants'
import { isValidProduct } from '~/lib/product-validation'
import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'

/**
 * @param {array} hits
 * @return {array}
 */
export function mapProductsFromApi(hits) {
  return hits
    .map(hit => mapProductFromApi(hit))
    .filter(product => isValidProduct(product))
}

/**
 * @param {object} hit
 * @return {object}
 */
export function mapProductFromApi(hit) {
  return deepFreeze(hit?._source)
}

/**
 * @param {array} products
 * @param {array} skus
 * @return {object}
 */
export function mapProductsToSkus(products, skus) {
  return skus
    ?.map(sku => products?.find(product => product?.sku?.includes(sku)))
    .filter(Boolean)
}

/**
 * @param {array} products
 * @param {array} documentIds
 * @return {object}
 */
export function mapProductsToDocumentIds(products, documentIds) {
  return documentIds
    .map(documentId => products?.find(product => product.document_id === documentId))
    .filter(Boolean)
}

/**
 * @param {array} relatedProducts
 * @param {object} product
 * @return {array}
 */
export function mapRelatedProductsForProductFromApi(relatedProducts, product) {
  const sku = getPrimarySkuFromProduct(product)

  return relatedProducts
    .map(relatedProduct => mapProductFromApi(relatedProduct))
    .filter(relatedProduct => getPrimarySkuFromProduct(relatedProduct) !== sku)
}

/**
 * @param {object} data
 * @return {object}
 */
export function mapProductsAndAvailableFiltersFromApi(data) {
  const {
    aggregations,
    hits,
    filterModelBucket = aggregations.filter_model_bucket,
    filterColorBucket = aggregations.filter_color_bucket,
    filterSizeBucket = aggregations.filter_size_bucket,
    originalFilterModelBucket = aggregations.original_filter_model_bucket,
    originalFilterColorBucket = aggregations.original_filter_color_bucket,
    originalFilterSizeBucket = aggregations.original_filter_size_bucket,
  } = data

  const totalProducts = isNumber(hits.total) ? hits.total : hits.total.value

  return {
    availableFilters: {
      models: filterModelBucket.buckets || filterModelBucket.models.buckets,
      colors: filterColorBucket.buckets || filterColorBucket.colors.buckets,
      sizes: mapSizeAggregations(filterSizeBucket),
    },
    originalFilters: {
      models: originalFilterModelBucket.buckets || originalFilterModelBucket.models.buckets,
      colors: originalFilterColorBucket.buckets || originalFilterColorBucket.colors.buckets,
      sizes: mapSizeAggregations(originalFilterSizeBucket),
    },
    products: mapProductsFromApi(hits.hits),
    totalProducts,
  }
}

/**
 * @param {object} filterSizeBucket
 * @return {array}
 */
function mapSizeAggregations(filterSizeBucket) {
  if (filterSizeBucket?.size_options?.in_stock?.sizes?.buckets) {
    return filterSizeBucket.size_options.in_stock.sizes.buckets
  }

  if (filterSizeBucket?.in_stock?.sizes?.buckets) {
    return filterSizeBucket.in_stock.sizes.buckets
  }

  return []
}

/**
 * @param {object} data
 * @return {object}
 */
export function mapProductsForSearchFromApi(data) {
  const totalProducts = isNumber(data.total) ? data.total : data.total.value

  return {
    products: mapProductsFromApi(data.hits),
    totalProducts,
  }
}

/**
 * @param {object} data
 * @param {string} searchQuery
 * @return {array}
 */
export function mapAndFilterSuggestionsForSearchFromApi(data, searchQuery) {
  return data.aggregations.suggestions.buckets
    .map(result => ({
      name: result.key,
      amount: result.doc_count,
    }))
    // As copied from ticket WEB-1053:
    // The list of aggregations returns all suggestions that were found on the returned products,
    // including ones that do not actually match with the search query. So these need to be filtered
    // out on the frontend.
    .filter(result => searchResultIncludesQuery(result, searchQuery))
}

/**
 * @param {*} value
 * @return {boolean}
 */
function isNumber(value) {
  return typeof value === 'number' && isFinite(value)
}

/**
 * @param {object} result
 * @param {string} query
 * @return boolean
 */
function searchResultIncludesQuery(result, query) {
  const queryRegex = new RegExp(query, 'gi')
  return queryRegex.test(result.name)
}

/**
 * @param {Array<Object>} products
 * @param {string} imageVariationCode
 * @returns {Array<Object>}
 */
export function filterOnImageVariationCode(products, imageVariationCode) {
  // Create mapped object of products by entity_id
  const mappedProductsByEntityId = products.reduce((acc, product, index) => {
    if (!acc[product.entity_id]) {
      acc[product.entity_id] = {}
    }

    // Store product and index by image_variation_code. Index is used to determine
    // the place of the product inside the new returned array later on.
    acc[product.entity_id][product.image_variation_code] = {
      product,
      index,
    }

    return acc
  }, {})

  const uniqueProducts = []

  Object.keys(mappedProductsByEntityId).forEach(entityId => {
    // Check for corresponding image variation code.
    // Has 2 fallbacks, one for predetermined 'DEFAULT'
    // and as ultimate fallback just the first one.
    const variation = mappedProductsByEntityId[entityId][imageVariationCode]
      ?? mappedProductsByEntityId[entityId][DEFAULT_IMAGE_VARIATION_CODE]
      ?? mappedProductsByEntityId[entityId][Object.keys(mappedProductsByEntityId[entityId])[0]]
    const index = variation.index

    // Here we use the index from before to set the products in correct order
    uniqueProducts[index] = variation.product
  })

  // Filter out possible undefined values from the array
  return uniqueProducts.filter(Boolean)
}
