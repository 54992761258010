export default {
  SET_CURRENT_ROUTE_PATH(state, currentRoutePath) {
    state.currentRoutePath = currentRoutePath
  },

  SET_CURRENT_ROUTE_NAME(state, currentRouteName) {
    state.currentRouteName = currentRouteName
  },

  SET_PREVIOUS_ROUTE_NAME(state, routeName) {
    state.previousRouteName = routeName
  },

  SET_PREVIOUS_FULL_PATH(state, fullPath) {
    state.previousFullPath = fullPath
  },

  SET_PREVIOUS_LAYOUT(state, previousLayout) {
    state.previousLayout = previousLayout
  },

  SET_CURRENT_LAYOUT(state, currentLayout) {
    state.currentLayout = currentLayout
  },
}
