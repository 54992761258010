import { ElasticApiService } from '~/services/core/elastic-api-service'

// Mappers
import { mapSynonymsFromApi } from '~/services/thesaurus/thesaurus-api-mappers'

import elasticIndices from '../../../../config/elastic'

/**
 * @typedef {ThesaurusApiService}
 * @alias this.$thesaurusApiService
 */
export class ThesaurusApiService extends ElasticApiService {
  constructor(context) {
    super(context.i18n)

    this.thesaurusAxiosInstance = this.createAxiosInstance(elasticIndices.thesaurus)
  }

  /**
   * @param {Object} query
   * @return {Promise}
   */
  post(query) {
    return this.thesaurusAxiosInstance.post('_analyze', query)
      .then(response => response?.data)
  }

  /**
   * @param {string} searchQuery
   * @return {Promise<Array>}
   */
  getSynonyms(searchQuery) {
    if (typeof searchQuery !== 'string') {
      return this.rejectOnInvalidArgumentType('searchQuery', 'getSynonyms')
    }

    return this.post({
      text: searchQuery,
      analyzer: 'synonym',
    })
      .then(response => mapSynonymsFromApi(searchQuery, response.tokens))
  }
}
