import cloneDeep from 'lodash.clonedeep'

import getOnlineFromDateFilter from './get-online-from-date-filter'
import productCardAttributes from '~/components/product-card/product-card-attributes'

const defaultHitsSize = 48
const defaultPage = 1
const filterSize = 100

/**
 * @param {string} categoryId
 * @param {Object} [activeFilters]
 * @param {Object} [sortBy]
 * @param {boolean} [isVirtual]
 * @param {Object} [virtualRules]
 * @param {number} [customerGroupId]
 * @param {Object} [optimizers]
 * @param {number} [hitsSize]
 * @param {number} [page]
 * @return {Object}
 */
export default ({
  categoryId,
  activeFilters,
  sortBy,
  isVirtual,
  virtualRules,
  customerGroupId,
  optimizers,
  hitsSize = defaultHitsSize,
  page = defaultPage,
}) => {
  const from = hitsSize * (page - 1)

  return {
    size: hitsSize,
    from,
    _source: productCardAttributes,
    ...(sortBy && sortBy.value === 'position' && sortPositionPart(categoryId)),
    ...(sortBy && sortBy.value !== 'position' && sortPart(sortBy, customerGroupId)),
    ...(categoryId && (optimizers ? queryPartWithOptimizers(categoryId, optimizers, isVirtual, virtualRules) : queryPartWithoutOptimizers(categoryId, isVirtual, virtualRules))),
    ...(activeFilters && postFiltersPart(activeFilters)),
    ...aggregationsPart(activeFilters),
  }
}

/**
 * @param {string} categoryId
 * @return {Object}
 */
function sortPositionPart(categoryId) {
  return {
    sort: [
      {
        'category.position': {
          order: 'asc',
          missing: '_last',
          unmapped_type: 'keyword',
          nested_path: 'category',
          mode: 'min',
          nested_filter: {
            terms: {
              'category.category_id': [
                categoryId,
              ],
              boost: 1,
            },
          },
        },
      },
      {
        _score: {
          order: 'desc',
        },
      },
      {
        entity_id: {
          order: 'desc',
          missing: '_first',
          unmapped_type: 'keyword',
        },
      },
    ],
  }
}

/**
 * @param {Object} sortBy
 * @param {number} customerGroupId
 * @return {Object}
 */
export function sortPart(sortBy, customerGroupId) {
  const priceSort = sortBy.value === 'price.price'

  return {
    sort: [
      (priceSort && priceSortPart(sortBy, customerGroupId)),
      (!priceSort && genericSortPart(sortBy)),
    ].filter(item => item),
  }
}

/**
 * @param {Object} sortBy
 * @param {number} customerGroupId
 * @return {Object}
 */
function priceSortPart(sortBy, customerGroupId) {
  return {
    [sortBy.value]: {
      order: sortBy.sort_order,
      nested: {
        path: sortBy.nested_path,
        filter: {
          term: {
            'price.customer_group_id': customerGroupId,
          },
        },
      },
    },
  }
}

/**
 * @param {Object} sortBy
 * @return {Object}
 */
function genericSortPart(sortBy) {
  return {
    [sortBy.value]: {
      order: sortBy.sort_order,
    },
  }
}

/**
 * @param {string} categoryId
 * @param {Object} optimizers
 * @param {boolean} [isVirtual]
 * @param {Object} [virtualRules]
 * @return {Object}
 */
function queryPartWithOptimizers(categoryId, optimizers, isVirtual, virtualRules) {
  return {
    query: {
      function_score: {
        ...optimizers.function_score,
        ...useVirtualRulesOrCategoryId(categoryId, isVirtual, virtualRules),
      },
    },
  }
}

/**
 * @param {string} categoryId
 * @param {boolean} [isVirtual]
 * @param {Object} [virtualRules]
 * @return {Object}
 */
function queryPartWithoutOptimizers(categoryId, isVirtual, virtualRules) {
  return {
    ...useVirtualRulesOrCategoryId(categoryId, isVirtual, virtualRules),
  }
}

/**
 * Check if a category is virtual, if so, use the virtual rules. Otherwise just use
 * the original query part.
 * @param {string}  categoryId
 * @param {boolean} [isVirtual]
 * @param {Object}  [virtualRules]
 * @returns object
 */
function useVirtualRulesOrCategoryId(categoryId, isVirtual, virtualRules) {
  const onlineFromDateFilter = getOnlineFromDateFilter()

  if (!isVirtual) {
    return {
      query: {
        constant_score: {
          filter: {
            bool: {
              must: [
                {
                  nested: {
                    path: 'category',
                    query: {
                      bool: {
                        must: [
                          {
                            terms: {
                              'category.category_id': [
                                categoryId,
                              ],
                            },
                          },
                        ],
                      },
                    },
                  },
                },
                ...(onlineFromDateFilter ? [onlineFromDateFilter] : []),
              ],
            },
          },
        },
      },
    }
  }

  // isVirtual
  if (!onlineFromDateFilter) {
    return {
      query: {
        constant_score: {
          filter: {
            ...virtualRules,
          },
        },
      },
    }
  }

  // isVirtual with onlineFromDateFilter
  const formattedVirtualRules = cloneDeep(virtualRules)

  if (!virtualRules.hasOwnProperty('bool')) {
    formattedVirtualRules.bool = {}
  }

  if (!virtualRules.bool.hasOwnProperty('must')) {
    formattedVirtualRules.bool.must = []
  }

  formattedVirtualRules.bool.must.push(onlineFromDateFilter)

  return {
    query: {
      constant_score: {
        filter: {
          ...formattedVirtualRules,
        },
      },
    },
  }
}

/**
 * @param {Object} checkedFilters
 * @return {Object}
 */
export function postFiltersPart(checkedFilters) {
  return {
    post_filter: {
      bool: {
        filter: [
          checkedFilters.models && filterTerm('model', checkedFilters.models),
          checkedFilters.colors && filterTerm('color', checkedFilters.colors),
          checkedFilters.sizes && filterTermSize('size', checkedFilters.sizes),
        ].filter(item => item),
      },
    },
  }
}

/**
 * @param {string} type
 * @param {Array} array
 * @return {Object}
 */
function filterTerm(type, array) {
  /*
  * As of WEB-623 color uses a different key because otherwise the color
  * filters are all over the place with colors like 'sand' or 'very dark red'.
  */
  const termKey = type === 'color'
    ? `option_text_filter_${type}.untouched`
    : `option_text_${type}.untouched`

  return {
    terms: {
      [termKey]: array,
    },
  }
}

/**
 * Specific method for sizes because sizes which are on stock should be returned only
 * @param {string} type
 * @param {Array} array
 * @return {Object}
 */
function filterTermSize(type, array) {
  return {
    nested: {
      path: 'configurable_options.options',
      query: {
        bool: {
          must: [
            {
              terms: {
                'configurable_options.options.default_label': array,
              },
            },
            {
              range: {
                'configurable_options.options.product_qty': {
                  gt: 0,
                },
              },
            },
          ],
        },
      },
    },
  }
}

/**
 * @param {Object} [checkedFilters]
 * @return {Object}
 */
export function aggregationsPart(checkedFilters) {
  return {
    aggs: {
      filter_model_bucket: (checkedFilters && (checkedFilters.colors || checkedFilters.sizes)
        ? modelAggregationWithFilters(checkedFilters)
        : aggregationWithoutFilters('model')),
      filter_color_bucket: (checkedFilters && (checkedFilters.models || checkedFilters.sizes)
        ? colorAggregationWithFilters(checkedFilters)
        : aggregationWithoutFilters('color')),
      filter_size_bucket: (checkedFilters && (checkedFilters.colors || checkedFilters.models)
        ? sizeAggregationWithFilters(checkedFilters)
        : sizeAggregationWithoutFilters()),
      original_filter_model_bucket: aggregationWithoutFilters('model'),
      original_filter_color_bucket: aggregationWithoutFilters('color'),
      original_filter_size_bucket: sizeAggregationWithoutFilters(),
    },
  }
}

/**
 * @param {Object} checkedFilters
 * @return {Object}
 */
function modelAggregationWithFilters(checkedFilters) {
  return {
    filter: {
      bool: {
        filter: [
          checkedFilters.colors && filterTerm('color', checkedFilters.colors),
          checkedFilters.sizes && filterTermSize('size', checkedFilters.sizes),
        ].filter(item => item),
      },
    },
    aggs: {
      models: {
        terms: {
          size: filterSize,
          field: 'option_text_model.untouched',
        },
      },
    },
  }
}

/**
 * @param {Object} checkedFilters
 * @return {Object}
 */
function colorAggregationWithFilters(checkedFilters) {
  return {
    filter: {
      bool: {
        filter: [
          checkedFilters.models && filterTerm('model', checkedFilters.models),
          checkedFilters.sizes && filterTermSize('size', checkedFilters.sizes),
        ].filter(item => item),
      },
    },
    aggs: {
      colors: {
        terms: {
          size: filterSize,
          field: 'option_text_color.untouched',
        },
      },
    },
  }
}

/**
 * @param {Object} checkedFilters
 * @return {Object}
 */
function sizeAggregationWithFilters(checkedFilters) {
  return {
    filter: {
      bool: {
        filter: [
          checkedFilters.colors && filterTerm('color', checkedFilters.colors),
          checkedFilters.models && filterTerm('model', checkedFilters.models),
        ].filter(item => item),
      },
    },
    aggs: {
      size_options: {
        nested: {
          path: 'configurable_options.options',
        },
        aggs: {
          in_stock: {
            filter: {
              range: {
                'configurable_options.options.product_qty': {
                  'gt': 0,
                },
              },
            },
            aggs: {
              sizes: {
                terms: {
                  size: filterSize,
                  field: 'configurable_options.options.default_label',
                },
              },
            },
          },
        },
      },
    },
  }
}

/**
 * @param {string} type
 * @return {Object}
 */
function aggregationWithoutFilters(type) {
  /*
  * As of WEB-623 color uses a different field value because otherwise the color
  * filters are all over the place with colors like 'sand' or 'very dark red'.
  */
  const fieldValue = type === 'color'
    ? `option_text_filter_${type}.untouched`
    : `option_text_${type}.untouched`

  return {
    terms: {
      size: filterSize,
      field: fieldValue,
    },
  }
}

/**
 * @return {Object}
 */
function sizeAggregationWithoutFilters() {
  return {
    nested: {
      path: 'configurable_options.options',
    },
    aggs: {
      in_stock: {
        filter: {
          range: {
            'configurable_options.options.product_qty': {
              gt: 0,
            },
          },
        },
        aggs: {
          sizes: {
            terms: {
              size: filterSize,
              field: 'configurable_options.options.default_label',
            },
          },
        },
      },
    },
  }
}
