/**
 * @typedef {ProductHighlightService}
 * @alias this.$productHighlightService
 */
export class ProductHighlightService {
    constructor(context) {
        /** @type {ServerNuxtContext} */
        this.context = context
        this.store = context.store
        this.i18n = context.i18n
    }

    init() {
        this.$productHighlightApiService = this.context.$productHighlightApiService
    }

    /**
     * @param {string} sku
     * @return {Promise<void>}
     */
    async fetchHighlightsBySku(sku) {
        try {
            const skusToQuery = this.getSkusToQuery([sku])

            if (!skusToQuery.length) {
                return
            }

            return this.setProductHighlightsToStore(skusToQuery, await this.$productHighlightApiService.getHighlightsBySkus(skusToQuery))
        } catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error(error)
            }
        }
    }

    /**
     * @param {string[]} skus
     * @return {Promise<void>}
     */
    async fetchHighlightsBySkus(skus) {
        try {
            const skusToQuery = this.getSkusToQuery(skus)

            if (!skusToQuery.length) {
                return
            }

            return this.setProductHighlightsToStore(skusToQuery, await this.$productHighlightApiService.getHighlightsBySkus(skusToQuery))
        } catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error(error)
            }
        }
    }

    /**
     * @param {string[]} skus
     * @return  {string[]}
     */
    getSkusToQuery(skus) {
        const currentDateTime = new Date().getTime()

        return skus.filter(sku => {
            const skuIsCached = Object.hasOwn(this.store.state['product-highlights'].productHighlights, sku)

            if (!skuIsCached) {
                return true
            }

            return this.store.state['product-highlights'].productHighlights[sku].ttl < currentDateTime
        })
    }

    /**
     * @param {string[]} queriedSkus
     * @param {ProductHighlight[]} productHighlights
     * @return {Promise<void>}
     */
    setProductHighlightsToStore(queriedSkus, productHighlights) {
        const currentStoredProductHighlights = this.store.state['product-highlights'].productHighlights
        const mappedProductHighlights = queriedSkus.reduce((acc, sku) => {
            acc[sku] = {
                ttl: new Date().getTime() + 30 * 60000, // 30 mins
                highlights: productHighlights.find(item => item.sku === sku) ?? null,
            }

            return acc
        }, {})

        return this.store.dispatch('product-highlights/setProductHighlights', Object.assign({}, currentStoredProductHighlights, mappedProductHighlights))
    }

    /**
     * @param {string} sku
     * @return {string[]}
     */
    getFormattedProductHighlights(sku) {
        const storedHighlights = this.store.state['product-highlights'].productHighlights
        const productHighlight = storedHighlights[sku]?.highlights

        if (!productHighlight) {
            return null
        }

        return Object.entries(productHighlight).reduce((acc, [key, value]) => {
            if (['sku', 'active_status'].includes(key)) {
                return acc
            }

            if (typeof value === 'number' && value > 0) {
                acc.push(this.i18n.t(`product_highlight_${key}`, { count: value }))
            }

            if (typeof value === 'boolean' && value) {
                acc.push(this.i18n.t(`product_highlight_${key}`))
            }

            return acc
        }, [])
    }
}
