import { getRandomGroupWithProbability } from '~/plugins/init-split-testing.client'

export default {
  data() {
    return {
      scrollableProductIndices: {},
    }
  },
  methods: {
    shouldRandomlyScrollProductImage(index) {
      // Check if entry exists and return it
      if (typeof this.scrollableProductIndices[index] === 'boolean') {
        return this.scrollableProductIndices[index] = getRandomGroupWithProbability([{ weight: 5 }, { weight: 1 }]) === 1
      }

      // Check if previous entry is already true, then return false to prevent 2 scrolls next to each other.
      if (this.scrollableProductIndices[index - 1]) {
        this.scrollableProductIndices[index] = false

        return this.scrollableProductIndices[index]
      }

      // New random entry
      this.scrollableProductIndices[index] = getRandomGroupWithProbability([{ weight: 5 }, { weight: 1 }]) === 1

      return this.scrollableProductIndices[index]
    },
  },
}
