
  export default {
    name: 'AppLayout',
    computed: {
      showBlackWeekTheme() {
        return this.$staticDataService.cmsLayoutsDefault?.showBlackWeekTheme
      },
    },
    mounted() {
      this.$nuxt.isHydrated = true

      if (this.showBlackWeekTheme) {
        document.querySelector('html').classList.add('black-week')
      }
    },
  }
