
  export default {
    props: {
      sizes: {
        type: Array,
        required: true,
      },
      sizeTip: {
        type: String,
        required: false,
        default: () => null,
      },
      value: {
        type: Number,
        default: null,
      },
    },
    emits: [
      'input',
      'out-of-stock-select',
    ],
    computed: {
      hasInStockSizes() {
        return Boolean(this.sizes.find(size => size.product_qty))
      },
      tagName() {
        return this.hasInStockSizes ? 'fieldset' : 'div'
      },
      isFieldset() {
        return (this.tagName === 'fieldset')
      },
    },
    methods: {
      onInStockSelected(size) {
        this.$emit('input', size.option_value)
      },
      onOutOfStockSelected(size) {
        this.$emit('out-of-stock-select', { selectedSize: size })
      },
    },
  }
