
  export default {
    data() {
      return {
        isOpen: false,
        buttonVisible: false,
      }
    },
    computed: {
      buttonLabel() {
        return this.isOpen ? this.$t('show_less') : this.$t('read_more')
      },
    },
    mounted() {
      this.buttonVisible = this.getButtonVisible()
    },
    methods: {
      toggle() {
        this.isOpen = !this.isOpen
      },
      getButtonVisible() {
        return this.$refs.textAccordionContent.scrollHeight > this.$refs.textAccordionContent.clientHeight
      },
    },
  }
