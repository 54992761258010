
  export default {
    props: {
      category: {
        type: Object,
        required: true,
      },
      activeCategoryId: {
        type: String,
        required: true,
      },
      usesAccordionForSubcategories: {
        type: Boolean,
        default: true,
      },
      activeAccordionId: {
        type: String,
        default: null,
      },
      userChangedActiveAccordion: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['set-active-accordion'],
    computed: {
      showSubcategories() {
        return (
          this.category.subNavigation
          && !this.usesAccordionForSubcategories
          || (
            this.category.categoryId === this.activeAccordionId
            || this.hasActiveSubcategory && !this.userChangedActiveAccordion
          )
        )
      },
      subCategoriesComponent() {
        return this.usesAccordionForSubcategories
          ? 'accordion-transition'
          : 'div'
      },
      displayedSubcategories() {
        return this.category?.subNavigation?.categories?.filter(
          subcategory => subcategory.includeInMenu,
        ) || []
      },
      mainCategoryShowsAsActive() {
        return this.categoryIsActive(this.category)
      },
      hasActiveSubcategory() {
        return (
          this.categoryHasActiveSubcategory(this.category)
          || this.categoryHasNestedSubcategory(this.category)
        )
      },
    },
    methods: {
      categoryIsActive(category) {
        return (
          category.categoryId === this.activeCategoryId
          || this.categoryHasActiveSubcategory(category)
          || this.categoryHasNestedSubcategory(category)
        )
      },
      categoryHasNestedSubcategory(category) {
        return category.subNavigation?.categories?.some(this.categoryHasActiveSubcategory)
      },
      categoryHasActiveSubcategory(category) {
        return category.subNavigation?.categories?.find(
          category => category.categoryId === this.activeCategoryId,
        )
      },
    },
  }
