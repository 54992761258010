
  import cloneDeep from 'lodash.clonedeep'

  export default {
    props: {
      activeCategory: {
        type: Object,
        required: true,
      },
    },
    computed: {
      categoryMenuItems() {
        return this.$staticDataService.cmsLayoutsDefault?.categoryMenu?.items ?? []
      },
      activeCategoryId() {
        return this.activeCategory?.entity_id ?? ''
      },
      allCategories() {
        return mergeAllSubCategories(this.categoryMenuItems)
      },
      parentCategory() {
        return this.allCategories.find(category => isDirectParentOfCurrentCategory(category, this.activeCategoryId))
      },
      parentCategoryToShow() {
        return this.subCategories ? this.parentCategory : this.allCategories.find(category => isDirectParentOfCurrentCategory(category, this.parentCategory.categoryId))
      },
      parentSubcategories() {
        return this.parentCategory?.subNavigation?.categories?.filter(category => category.includeInMenu)
      },
      subCategories() {
        return this.activeCategoryData?.subNavigation?.categories?.filter(category => category.includeInMenu)
      },
      subCategoriesToShow() {
        let subcategories = this.subCategories || this.parentSubcategories

        const activeCategoryData = cloneDeep(this.subCategories ? this.activeCategoryData : this.parentCategory)

        activeCategoryData.label = `${this.$t('all')} ${activeCategoryData.label.toLowerCase()}`

        return [activeCategoryData, ...subcategories]
      },
      activeCategoryData() {
        return this.allCategories.find(category => +category.categoryId === +this.activeCategoryId)
      },
      activeCategoryIndex() {
        return this.subCategoriesToShow?.findIndex(category => +category.categoryId === +this.activeCategoryId)
      },
    },
    watch: {
      activeCategoryId() {
        this.$refs.horizontalCarousel?.setScrollLeft(0)
      },
    },
    methods: {
      isActiveCategory(category) {
        return +category.categoryId === +this.activeCategoryId
      },
    },
  }

  function mergeAllSubCategories(categories) {
    return categories.map(mergeWithSubCategoriesRecursive).flat(2)
  }

  function mergeWithSubCategoriesRecursive(category) {
    if (!category.subNavigation) {
      return category
    }

    return [
      category,
      ...category.subNavigation.categories.map(mergeWithSubCategoriesRecursive),
    ]
  }

  function isDirectParentOfCurrentCategory(category, activeCategoryId) {
    return category.subNavigation?.categories?.find(subCategory => +subCategory.categoryId === +activeCategoryId)
  }
