
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      link: {
        type: Object,
        default: null,
      },
      description: {
        type: String,
        default: null,
      },
      cta: {
        type: String,
        default: null,
      },
      backgroundImage: {
        type: Object,
        default: null,
      },
      backgroundColor: {
        type: String,
        default: null,
      },
      blackText: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      background() {
        if (this.backgroundImage) return `background-image: url(${this.backgroundImage.url})`
        if (this.backgroundColor) return `background-color: ${this.backgroundColor}`
        return undefined
      },
    },
  }
