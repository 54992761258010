
  import { mapGetters } from 'vuex'

  import {
    FLY_IN,
    AUTH_FLY_IN_NAME,
    CUSTOM_AUTH_FLY_IN_TYPE_COOKIE_NAME,
  } from '~/lib/constants'
  import cookie from '~/lib/cookie'

  export default {
    props: {
      flyInOrigin: {
        type: String,
        default: 'left',
        validator: dir => ['left', 'right'].indexOf(dir) !== -1,
      },
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
      flyInCookie() {
        return cookie(CUSTOM_AUTH_FLY_IN_TYPE_COOKIE_NAME) ? JSON.parse(cookie(CUSTOM_AUTH_FLY_IN_TYPE_COOKIE_NAME)) : null
      },
    },
    methods: {
      onClick() {
        this.isAuthenticated
          ? this.$router.push(this.localePath({ name: 'customer-account' })).catch(() => {})
          : this.openAuthFlyIn()
      },
      openAuthFlyIn() {
        let mode = null
        let type = null

        if (this.flyInCookie) {
          type = this.flyInCookie.type
          mode = this.flyInCookie.mode
        }

        this.$overlayService.setCurrentOverlay({
          settings: {
            type: FLY_IN,
            component: AUTH_FLY_IN_NAME,
            options: {
              transitionOrigin: this.flyInOrigin,
              type,
              mode,
            },
          },
        })
      },
    },
  }
