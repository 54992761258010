import { render, staticRenderFns } from "./product-size-select.vue?vue&type=template&id=112e9e06"
import script from "./product-size-select.vue?vue&type=script&lang=js"
export * from "./product-size-select.vue?vue&type=script&lang=js"
import style0 from "./product-size-select.vue?vue&type=style&index=0&id=112e9e06&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductSizeSelectOption: require('/opt/build/repo/src/client/components/product-size-select-option/product-size-select-option.vue').default})
