
  const aspectRatioRegex = /\d{1,} ?\/ ?\d{1,}/
  const fallbackRatio = { width: 16, height: 9 }

  export default {
    props: {
      section: {
        type: Object,
        required: true,
        validator: isValidSection,
      },
      ignoreLazyLoad: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      fixedHeight() {
        return this.section.hasFixedHeight && this.section.fixedHeight ? `${this.section.fixedHeight}px` : null
      },
      blocks() {
        // Reverse blocks to make block on top in CMS be latest in DOM and therefore visually on top
        return [...this.section.blocks].reverse()
      },
      desktopRatio() {
        if (this.section.desktopAspectRatio && this.section.desktopAspectRatio.match(aspectRatioRegex)) {
          return getAspectRatio(this.section.desktopAspectRatio)
        } else {
          return fallbackRatio
        }
      },
      mobileRatio() {
        if (this.section.mobileAspectRatio && this.section.mobileAspectRatio.match(aspectRatioRegex)) {
          return getAspectRatio(this.section.mobileAspectRatio)
        } else {
          return this.desktopRatio
        }
      },
      desktopPaddingBottom () {
        return calcPaddingBottom(this.desktopRatio)
      },
      backgroundImage() {
        if (this.section.backgroundImage) {
          return `url(${this.section.backgroundImage.url})`
        } else if (this.section.backgroundGradient) {
          return this.section.backgroundGradient.replace(';', '')
        }

        return undefined
      },
      backgroundColor() {
        return this.section.backgroundColor && this.section.backgroundColor.hex
      },
    },
  }

  function isValidSection(section) {
    return (
      (!section.isFullWidth || typeof(section.isFullWidth) === 'boolean')
      && typeof(section.hideOnMobile) === 'boolean'
      && typeof(section.hideOnDesktop) === 'boolean'
      && hasFixedHeightOrValidRatio(section)
      && (!section.backgroundColor || (
        typeof(section.backgroundColor) === 'object'
        && typeof(section.backgroundColor.hex) === 'string'
      ))
      && (!section.backgroundImage || (
        typeof(section.backgroundImage) === 'object'
        && typeof(section.backgroundImage.url) === 'string'
      ))
      && (!section.backgroundGradient || typeof(section.backgroundGradient) === 'string')
      && (!section.blocks || Array.isArray(section.blocks))
    )
  }

  function hasFixedHeightOrValidRatio(section) {
    return isValidFixedHeight(section)
      || (isValidMobileRatio(section.mobileAspectRatio) && isValidDesktopRatio(section.desktopAspectRatio))
  }

  function isValidFixedHeight(section) {
    return section.hasFixedHeight && section.fixedHeight > 0
  }

  function isValidDesktopRatio(ratio) {
    return !ratio || (typeof ratio === 'string' && ratio.match(aspectRatioRegex))
  }

  function isValidMobileRatio(ratio) {
    return !ratio || (typeof ratio === 'string' && ratio !== ''
      ? ratio.match(aspectRatioRegex)
      : true
    )
  }

  function getAspectRatio(ratio) {
    const [width, height] = ratio
      .split('/')
      .map(item => item.trim())

    return { width, height }
  }

  function calcPaddingBottom(ratio) {
    return `${ratio.height / ratio.width * 100}%`
  }
