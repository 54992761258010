import { onLoad } from '~/lib/on-load'

export default (context, inject) => {
  // Load all script which are not loaded on demand
  const externalScripts = context.$staticDataService.cmsLayoutsDefault?.app.externalScripts ?? []
  onLoad(() => initializeExternalScripts(externalScripts))

  /**
   * @alias this.$externalScripts
   */
  inject('externalScripts', {
    /**
     * Enable an external script on demand through a trigger
     * @param {string} trigger
     */
    async enable(trigger) {
      if (!trigger) {
        return console.warn('No trigger given to enable script')
      }

      const externalScript = externalScripts.find(script => script.trigger === trigger)

      if (!externalScript) {
        return console.warn(`No external script with trigger '${trigger}' found`)
      }

      if (scriptIsInitialized(externalScript.source)) {
        return console.log('Script already initialized')
      }

      return initializeExternalScript(externalScript)
        .catch(error => handleError(error))
    },
  })
}

function initializeExternalScripts(externalScripts) {
  externalScripts.forEach(externalScript => {
    if (!externalScript.loadOnTrigger){
      return initializeExternalScript(externalScript)
        .catch(error => handleError(error))
    }
  })
}

/**
 * @param {object} externalScript
 * @return {Promise}
 */
function initializeExternalScript({ source, tagAttributes }) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    if (Array.isArray(tagAttributes) && tagAttributes?.length) {
      tagAttributes.forEach(({ name, value }) => (script[name] = value))
    }
    script.src = source
    script.async = true
    script.onload = () => resolve()
    script.onerror = event => reject(event)
    document.body.appendChild(script)
  })
}

/**
 * @param {string} source
 * @returns {boolean}
 */
function scriptIsInitialized(source) {
  return Array
    .from(document.getElementsByTagName('script'))
    .some(script => script.src.includes(source))
}

/**
 * @param {string} error
 * @returns {void}
 */
function handleError(error) {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error)
  }
}
