import { render, staticRenderFns } from "./app-footer-desktop.vue?vue&type=template&id=0595b951"
import script from "./app-footer-desktop.vue?vue&type=script&lang=js"
export * from "./app-footer-desktop.vue?vue&type=script&lang=js"
import style0 from "./app-footer-desktop.vue?vue&type=style&index=0&id=0595b951&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsletterSignup: require('/opt/build/repo/src/client/components/newsletter-signup/newsletter-signup.vue').default,AppFooterList: require('/opt/build/repo/src/client/components/app-footer-list/app-footer-list.vue').default,SocialLinks: require('/opt/build/repo/src/client/components/social-links/social-links.vue').default,SvgIcon: require('/opt/build/repo/src/client/components/svg-icon/svg-icon.vue').default,SelectInput: require('/opt/build/repo/src/client/components/select-input/select-input.vue').default,AppLogo: require('/opt/build/repo/src/client/components/app-logo/app-logo.vue').default,SmartLink: require('/opt/build/repo/src/client/components/smart-link/smart-link.vue').default,LazyLoad: require('/opt/build/repo/src/client/components/lazy-load/lazy-load.vue').default})
