
  import { localeProductPath } from '~/lib/product-link'
  import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'

  export default {
    props: {
      product: {
        type: Object,
        required: true,
      },
      location: {
        type: String,
        default: '',
      },
      hideWishList: {
        type: Boolean,
        default: false,
      },
      wrapProductInfo: {
        type: Boolean,
        default: false,
      },
      showVariationColors: {
        type: Boolean,
        default: false,
      },
      showAlternativeDiscountStyle: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      subtitle() {
        return this.product.subtitle?.[0] || ''
      },
      productLink() {
        return this.product && localeProductPath(this.product, this.localePath, this.$i18n.locale)
      },
      timesAddedToWishlist() {
        return this.product.wishlist_counter && this.product.wishlist_counter[0]
      },
      sku() {
        return this.product && getPrimarySkuFromProduct(this.product)
      },
      hasMultipleColorVariations() {
        return this.product?.item_layers?.length > 1
      },
      colorVariations() {
        if (!this.hasMultipleColorVariations) {
          return []
        }

        return this.$productSkuConverterService.getColorVariations(this.product?.item_layers, this.sku)
      },
    },
    methods: {
      initProductClickTracking() {
        this.$trackingService.productClick({ product: this.product, location: this.location })
      },
    },
  }
