import { GUEST_CART_COOKIE_NAME, INITIAL_CART_ID, USER_CART_COOKIE_NAME } from '~/lib/constants'

import addressContainsMinimalValidData from '~/lib/checkout/address-contains-minimal-valid-data'
import cookie from '~/lib/cookie'

/**
 * @typedef {CartService}
 * @alias this.$cartService
 */
export class CartService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$checkoutApiService = this.context.$checkoutApiService
    this.$productApiService = this.context.$productApiService
    this.$cartShippingAddressService = this.context.$cartShippingAddressService
    this.$cartTotalsService = this.context.$cartTotalsService
    this.$cartDonationService = this.context.$cartDonationService
    this.$cartShippingMethodService = this.context.$cartShippingMethodService
    this.$orderService = this.context.$orderService
  }

  /**
   * Called in init-user-state.client.js
   * @param {string} guestCartId
   * @returns {Promise}
   */
  async reinstateGuestCart(guestCartId) {
    const cart = await this.getCart(guestCartId).catch(() => null)

    if (!cart) {
      cookie(GUEST_CART_COOKIE_NAME, false)
      return this.store.dispatch('checkout/setCartId', INITIAL_CART_ID)
    }

    return this.reinstateCart({ cart, cartId: guestCartId })
  }

  /**
   * Called in init-user-state.client.js
   * @returns {Promise}
   */
  async reinstateUserCart() {
    const isAuthenticated = this.store.getters['user/isAuthenticated']

    if (!isAuthenticated) {
      return Promise.reject(Error('Not authorized'))
    }

    const cart = await this.getCart().catch(() => null)

    if (!cart) {
      return this.store.dispatch('checkout/setCartId', INITIAL_CART_ID)
    }

    return this.reinstateCart({ cart, cartId: cart.id })
  }

  /**
   * Caution: guest cart ids are masked ids. That's why the id from the cart can't be used by default.
   * @param {Object} cart
   * @param {string} cartId
   * @returns {Promise}
   */
  async reinstateCart({ cart, cartId }) {
    try {
      if (!cart.is_active) {
        // Check if order is paid
        // Carts get removed/re-initialized here accordingly
        return this.checkIfInactiveCartIsPaid(cartId)
      }

      await this.store.dispatch('checkout/setCartId', cartId)

      // Saved cart is still active, update the cart
      // Set shipping address from cart
      const cartAddress = cart.extension_attributes?.shipping_assignments?.[0]?.shipping?.address

      if (addressContainsMinimalValidData(cartAddress)) {
        await this.store.dispatch('checkout/setShippingAddress', cartAddress)
      }

      // Update cart is called in fetchCartShippingMethods -> updateSelectedShippingMethod
      await this.$cartShippingMethodService.fetchCartShippingMethods().catch(() => {})
    } catch (error) {
      await this.store.dispatch('checkout/setCartId', INITIAL_CART_ID)

      return Promise.reject(error)
    }
  }

  /**
   * @returns {Promise<string>} Created cart id
   */
  async createCart() {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const country = this.store.state.localization.country
    const cartId = await this.$checkoutApiService.createNewCart(country)

    if (!isAuthenticated) {
      cookie(GUEST_CART_COOKIE_NAME, cartId)
      cookie(USER_CART_COOKIE_NAME, false)
    }

    await this.store.dispatch('checkout/setCartId', cartId)

    return cartId
  }

  /**
   * @param {string} [cartId]
   * @returns {Promise<Object>}
   */
  getCart(cartId) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const currentCartId = cartId ?? this.store.state.checkout.cartId

    if (!isAuthenticated && !currentCartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    return this.$checkoutApiService.getCart(currentCartId)
  }

  /**
   * Not used anymore, but endpoint is still live
   * @param {string} cartId
   * @returns {Promise<Object>}
   */
  getUserCartById(cartId) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']

    if (!isAuthenticated) {
      return Promise.reject(Error('Not authorized'))
    }

    return this.$checkoutApiService.getUserCartById(cartId)
  }

  /**
   * @param {string} cartId
   * @returns {Promise}
   */
  async checkIfInactiveCartIsPaid(cartId) {
    const orderIsPaid = await this.$orderService.getOrderIsPaid(cartId)

    if (orderIsPaid) {
      // Clear checkout if order paid
      return this.store.dispatch('checkout/resetState', ['shippingAddress'])
    }

    // Order is not paid, re-activate cart
    return this.restoreCart(cartId)
  }

  /**
   * @returns {Promise}
   */
  async restoreCart(cartId) {
    const newCartId = await this.$checkoutApiService.restoreCart(cartId)

    cookie(GUEST_CART_COOKIE_NAME, newCartId)
    cookie(USER_CART_COOKIE_NAME, false)

    await this.store.dispatch('checkout/setCartId', newCartId)

    const cart = await this.updateCart()

    // Set shipping address from cart
    const cartAddress = cart?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address

    if (addressContainsMinimalValidData(cartAddress)) {
        await this.store.dispatch('checkout/setShippingAddress', cartAddress)
    }

    return cart
  }

  /**
   * @param {string} guestCartId
   * @returns {Promise}
   */
  async mergeGuestCartWithUserCart(guestCartId) {
    const guestCartHasItems = this.store.state.checkout.cartItems.length > 0

    if (!guestCartHasItems) {
      // Guest cart doesn't need to be merged if the cart doesn't have items.
      // Just update the current cart.
      return this.updateCart()
    }

    try {
      const mergedCart = await this.$checkoutApiService.getMergedGuestCartWithUserCart(guestCartId)

      // Set new cart id
      await this.store.dispatch('checkout/setCartId', mergedCart.id)
      await this.store.dispatch('snackbar/addSnackbarMessage', { message: 'merged_carts', icon: 'cart-check' })

      // Update cart is called in fetchCartShippingMethods -> updateSelectedShippingMethod
      await this.$cartShippingMethodService.fetchCartShippingMethods()
      await this.$cartShippingMethodService.updateCartShippingInformation()
    } finally {
      // Always remove the guest cart cookie
      cookie(GUEST_CART_COOKIE_NAME, false)
    }
  }

  /**
   * @returns {Promise}
   */
  async updateCart() {
    try {
      const userIsBlocked = this.store.state.user.isBlocked
      const isAuthenticated = this.store.getters['user/isAuthenticated']

      if (userIsBlocked) {
        return Promise.reject('User is blocked')
      }

      const cartId = this.store.state.checkout.cartId ?? await this.createCart()

      if (!cartId && !isAuthenticated) {
        return Promise.reject('No cart initialized yet')
      }

      const cart = await this.getCart()
        .catch(error => {
          const statusCode = error?.response?.status

          if (!isAuthenticated && statusCode >= 400 && statusCode < 500) {
            this.store.dispatch('checkout/setCartId', INITIAL_CART_ID)
            cookie(GUEST_CART_COOKIE_NAME, false)
            cookie(USER_CART_COOKIE_NAME, false)
          }

          return Promise.reject(error)
        })

      // Always update cart id for users
      if (isAuthenticated) {
        await this.store.dispatch('checkout/setCartId', cart.id)
        cookie(GUEST_CART_COOKIE_NAME, false)
        cookie(USER_CART_COOKIE_NAME, cart.id)
      }

      // Return if cart is not active anymore
      if (!cart.is_active) {
        // Check if order is paid. Carts get removed/re-initialized here accordingly
        return this.checkIfInactiveCartIsPaid(cart.id)
      }

      const freeShippingThreshold = cart.extension_attributes?.free_shipping_threshold
      const formattedFreeShippingThreshold = freeShippingThreshold?.length ? Number(freeShippingThreshold) : null
      const cartItems = cart.items
      const donation = this.$cartDonationService.getDonationFromCartItems(cartItems)

      await Promise.all([
        this.store.dispatch('checkout/setFreeShippingThreshold', formattedFreeShippingThreshold),
        this.store.dispatch('checkout/setDonation', donation),
        this.store.dispatch('checkout/setCartIsVirtual', cart.is_virtual),
        this.#fetchEnrichedCartItems(cartItems),
        this.$cartTotalsService.updateCartTotals(),
      ])

      return cart
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error)
      }

      return Promise.reject(error)
    }
  }

  /**
   * Enriches cart items and sets it in the store.
   * @param {Object[]} cartItems
   * @returns {Promise}
   */
  async #fetchEnrichedCartItems(cartItems) {
    const enrichedCartItems = await this.#getEnrichedCartItems(cartItems)

    return this.store.dispatch('checkout/setCartItems', enrichedCartItems)
  }

  /**
   * Enriches cart items with Elastic product data.
   * @param {Object[]} cartItems
   * @returns {Promise<Object[]>}
   */
  async #getEnrichedCartItems(cartItems) {
    const nonDonationCartItems = cartItems.filter(cartItem => cartItem.product_type !== 'donation')
    const skus = nonDonationCartItems.map(item => item.extension_attributes?.main_sku)
    const products = await this.$productApiService.getMultipleProductsBySku(skus, { shouldMapProductsToSkus: false })

    return nonDonationCartItems.map(cartItem => {
      // Find product variation from Elastic response
      const variationProduct = products.find(product =>
        product.sku.includes(cartItem.sku)
        && product.document_id === cartItem.extension_attributes.document_id
      )

      if (variationProduct) {
        return {
          ...cartItem,
          product: variationProduct,
        }
      }

      // If variation is not available, use the first variation there is available
      const fallbackProduct = products.find(product => product.sku.includes(cartItem.sku))

      if (fallbackProduct) {
        return {
          ...cartItem,
          product: fallbackProduct,
        }
      }

      return cartItem
    })
  }
}
