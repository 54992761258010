export default {
  /**
   * @param state
   * @param {string} country
   */
  SET_COUNTRY(state, country) {
    state.country = country
  },

  /**
   * @param state
   * @param {Object} i18nPageParams
   */
  SET_I18N_PAGE_PARAMS(state, i18nPageParams) {
    state.i18nPageParams = i18nPageParams
  },
}
