import axios from 'axios'

import cookie from '~/lib/cookie'
import { AUTH_TOKEN_COOKIE_NAME } from '~/lib/constants'

/**
 * @typedef MagentoApiService
 * @alias this.$magentoApiService
 */
export class MagentoApiService {
  constructor(
    context
  ) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.store = context.store
    this.locale = context.i18n.locale
    this.magentoBaseUrl = context.env.magentoBaseUrl

    this.instance = axios.create({
      headers: {
        'User-Channel': 'Web',
      },
    })

    this.setInstanceInterceptors()
  }

  setInstanceInterceptors() {
    this.instance.interceptors.request.use(config => {
      const token = cookie(AUTH_TOKEN_COOKIE_NAME) ?? null
      const userIsInitialized = this.store.state.user.isInitialized
      const userIsAuthenticated = this.store.getters['user/isAuthenticated']

      if (
        process.client
        && userIsAuthenticated
        && userIsInitialized
        && this.store.state.user.token !== token
      ) {
        window.location.reload()
      }

      config.baseURL = `${this.magentoBaseUrl}/rest/${this.locale}/V1`
      config.withCredentials = true

      // Authorization key can be hard-coded like checkout-api-service/getProductStocks
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    })
  }

  /**
   * @param {string} url
   * @param {*} [config]
   * @returns {Promise}
   */
  get(url, config) {
    return this.instance.get(url, config)
  }

  /**
   * @param {string} url
   * @param {Object} [payload]
   * @param {*} [config]
   * @returns {Promise}
   */
  post(url, payload = {}, config) {
    return this.instance.post(url, payload, config)
  }

  /**
   * @param {string} url
   * @param {object} [payload]
   * @param {*} [config]
   * @returns {Promise}
   */
  patch(url, payload = {}, config) {
    return this.instance.patch(url, payload, config)
  }

  /**
   * @param {string} url
   * @param {object} [payload]
   * @param {*} [config]
   * @returns {Promise}
   */
  put(url, payload = {}, config) {
    return this.instance.put(url, payload, config)
  }

  /**
   * @param {string} url
   * @param {*} [config]
   * @returns {Promise}
   */
  delete(url, config) {
    return this.instance.delete(url, config)
  }
}
