import deepFreeze from 'deep-freeze'
import deepmerge from 'deepmerge'

import {
  DEFAULT_CATEGORY_PAGE_PRODUCT_SIZE,
  DEFAULT_CATEGORY_FIRST_PAGE,
} from '~/lib/constants'

import { ElasticApiService } from '~/services/core/elastic-api-service'

// Mappers
import {
  mapProductsFromApi,
  mapProductsToSkus,
  mapRelatedProductsForProductFromApi,
  mapProductsAndAvailableFiltersFromApi,
  mapProductFromApi,
  mapAndFilterSuggestionsForSearchFromApi,
  filterOnImageVariationCode,
  mapProductsToDocumentIds,
} from '~/services/product/product-api-mappers'

// Queries
import getMultipleProductsBySkuQuery from './queries/get-multiple-products-by-sku-query'
import getProductBySlugQuery from './queries/get-product-by-slug-query'
import getRelatedProductsForProductQuery from './queries/get-related-products-for-product-query'
import getProductsByCategoryOptionsQuery from './queries/get-products-by-category-options-query'
import getProductsForSearchQuery from './queries/get-products-for-search-query'
import getSuggestionsForSearchQuery from './queries/get-suggestions-for-search-query'
import getProductBySkuQuery from './queries/get-product-by-sku-query'
import getMultipleProductsByDocumentIdsQuery from './queries/get-multiple-products-by-document-ids-query'

import elasticIndices from '../../../../config/elastic'

/**
 * @typedef {ProductApiService}
 * @alias this.$productApiService
 */
export class ProductApiService extends ElasticApiService {
  constructor(context) {
    super(context.i18n)

    /** @type {ServerNuxtContext} */
    this.context = context
    this.store = context.store
    this.productsAxiosInstance = this.createAxiosInstance(elasticIndices.products)
  }

  init() {
    this.$categoryApiService = this.context.$categoryApiService
    this.$thesaurusApiService = this.context.$thesaurusApiService
  }

  /**
   * @param {Object} [query]
   * @return {Promise}
   */
  post(query) {
    return this.productsAxiosInstance.post('_search', query)
      .then(response => response?.data)
  }

  /**
   * @param {string} slug
   * @return {Promise}
   */
  getProductBySlug(slug) {
    if (!slug) {
      return this.rejectOnMissingArgument('slug', 'getProductBySlug')
    }

    const currentLocale = this.i18n.locale

    return this.post(getProductBySlugQuery(slug, currentLocale))
      .then(response => {
        if (response?.hits?.hits?.length) {
          return mapProductFromApi(response.hits?.hits[0])
        } else {
          return Promise.reject(Error(`Product with slug '${slug}' not found with locale '${currentLocale}'`))
        }
      })
  }

  /**
   * @param {string} sku
   * @return {Promise}
   */
  getProductBySku(sku) {
    if (!sku) {
      return this.rejectOnMissingArgument('sku', 'getProductBySku')
    }

    return this.post(getProductBySkuQuery(sku))
      .then(response => {
        if (response?.hits?.hits?.length) {
          return mapProductFromApi(response.hits?.hits[0])
        } else {
          return Promise.reject(Error(`Product with sku '${sku}' not found`))
        }
      })
  }

  /**
   * @param {Array} skus
   * @param {Object} [options]
   * @return {Promise<Array>}
   */
  getMultipleProductsBySku(skus, options) {
    if (!skus) {
      return this.rejectOnMissingArgument('skus', 'getMultipleProductsBySku')
    }

    if (!Array.isArray(skus)) {
      return this.rejectOnInvalidArgumentType('skus', 'getMultipleProductsBySku')
    }

    const defaultOptions = {
      useOptimizers: false,
      useMostRelevantImageVariation: false,
      shouldMapProductsToSkus: true,
    }

    const mergedOptions = options ? deepmerge(defaultOptions, options) : defaultOptions

    // Optimizers are used for sorting all the products by the optimizers rules
    // or to sort the image variations
    const optimizers = mergedOptions.useOptimizers || mergedOptions.useMostRelevantImageVariation
      ? this.store.state.search.optimizers
      : null
    const _source = mergedOptions._source
    const imageVariationCode = mergedOptions.imageVariationCode

    const shouldMapProductsToSkus =
      mergedOptions.shouldMapProductsToSkus
      || (optimizers && !mergedOptions.useMostRelevantImageVariation)

    return this.post(getMultipleProductsBySkuQuery(skus, optimizers, _source))
      .then(response => mapProductsFromApi(response.hits.hits))
      .then(products => imageVariationCode ? filterOnImageVariationCode(products, imageVariationCode) : products)
      // When using optimizers the order of the returned products are relevant
      // That's why we don't map the product to the skus.
      // Except when optimizers are used to get the most relevant image variation.
      .then(products => shouldMapProductsToSkus ? mapProductsToSkus(products, skus) : products)
  }

  /**
   * @param {Array} documentIds
   * @param {Object} [options]
   * @return {Promise<Array>}
   */
  getMultipleProductsByDocumentId(documentIds, options) {
    if (!documentIds) {
      return this.rejectOnMissingArgument('documentIds', 'getMultipleProductsByDocumentId')
    }

    if (!Array.isArray(documentIds)) {
      return this.rejectOnInvalidArgumentType('documentIds', 'getMultipleProductsByDocumentId')
    }

    const defaultOptions = {
      useOptimizers: false,
    }

    const mergedOptions = options ? deepmerge(defaultOptions, options) : defaultOptions
    const optimizers = mergedOptions.useOptimizers ? this.store.state.search.optimizers : null
    const _source = mergedOptions._source

    return this.post(getMultipleProductsByDocumentIdsQuery(documentIds, optimizers, _source))
      .then(response => mapProductsFromApi(response.hits.hits))
      .then(products => optimizers ? products : mapProductsToDocumentIds(products, documentIds))
  }

  /**
   * @param {Object} product
   * @param {Object} [options]
   * @return {Promise<Array>}
   */
  getRelatedProductsForProduct(product, options) {
    if (!product) {
      return this.rejectOnMissingArgument('product', 'getRelatedProductsForProduct')
    }

    const defaultOptions = {
      useOptimizers: true,
    }

    const mergedOptions = options ? deepmerge(defaultOptions, options) : defaultOptions
    const optimizers = mergedOptions.useOptimizers ? this.store.state.search.optimizers : null
    const imageVariationCode = mergedOptions.imageVariationCode

    return this.post(getRelatedProductsForProductQuery(product, optimizers))
      .then(response => mapRelatedProductsForProductFromApi(response.hits.hits, product))
      .then(products => imageVariationCode ? filterOnImageVariationCode(products, imageVariationCode) : products)
  }

  /**
   * @param {string} categoryId
   * @param {Object} [options]
   * @return {Promise}
   */
  async getProductsAndAvailableFiltersByCategoryId(categoryId, options) {
    if (!categoryId) {
      return this.rejectOnMissingArgument('categoryId', 'getProductsAndAvailableFiltersByCategoryId')
    }

    try {
      const category = await this.$categoryApiService.getCategoryById(
        categoryId,
        ['is_virtual', 'virtual_rules', 'optimizers'],
      )
      const defaultOptions = {
        categoryId,
        activeFilters: null,
        sortBy: {
          label: 'Most popular',
          sort_order: 'desc',
          value: 'position',
        },
        isVirtual: category.is_virtual,
        virtualRules: category.virtual_rules,
        customerGroupId: 0,
        optimizers: category.optimizers,
        hitsSize: DEFAULT_CATEGORY_PAGE_PRODUCT_SIZE,
        page: DEFAULT_CATEGORY_FIRST_PAGE,
      }

      const categoryOptions = options ? Object.assign(defaultOptions, options) : defaultOptions

      return this.getProductsByCategoryOptions(categoryOptions)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  getProductsByCategoryOptions(categoryOptions) {
    if (!categoryOptions) {
      return this.rejectOnMissingArgument('categoryOptions', 'getProductsByCategoryOptions')
    }

    return this.post(getProductsByCategoryOptionsQuery(categoryOptions))
      .then(response => mapProductsAndAvailableFiltersFromApi(response))
      .then(deepFreeze)
  }

  /**
   *
   * @param categoryOptions
   * @return {Promise<Object>}
   */
  getProductsAndAvailableFiltersByCategoryOptions(categoryOptions) {
    if (!categoryOptions) {
      return this.rejectOnMissingArgument('categoryOptions', 'getProductsAndAvailableFiltersByCategoryOptions')
    }

    return this.post(getProductsByCategoryOptionsQuery(categoryOptions))
      .then(response => mapProductsAndAvailableFiltersFromApi(response))
      .then(deepFreeze)
  }

  /**
   * @param categoryId
   * @param {boolean} [isVirtual]
   * @param {Object} [virtualRules]
   * @param {Object} [activeFilters]
   * @return {Promise<Object>}
   */
  getAvailableFiltersForCategory(categoryId, isVirtual, virtualRules, activeFilters) {
    if (!categoryId) {
      return this.rejectOnMissingArgument('categoryId', 'getAvailableFiltersForCategory')
    }

    return this.getProductsAndAvailableFiltersByCategoryOptions({
      categoryId,
      isVirtual,
      virtualRules,
      activeFilters,
      hitsSize: 0,
    })
  }

  /**
   * @param categoryId
   * @param {boolean} [isVirtual]
   * @param {Object} [virtualRules]
   * @return {Promise<Object>}
   */
  getAllFiltersForCategory(categoryId, isVirtual, virtualRules) {
    if (!categoryId) {
      return this.rejectOnMissingArgument('categoryId', 'getAllFiltersForCategory')
    }

    return this.getProductsAndAvailableFiltersByCategoryOptions({
      categoryId,
      isVirtual,
      virtualRules,
      hitsSize: 0,
    })
  }

  /**
   * @param {string} searchQuery
   * @param {number} [itemsToSkip]
   * @param {number} [pageSize]
   * @param {Object} [checkedFilters]
   * @param {Object} [sortOption]
   * @return {Promise<Object>}
   */
  async getProductsForSearch({
    searchQuery,
    itemsToSkip = 0,
    pageSize = DEFAULT_CATEGORY_PAGE_PRODUCT_SIZE,
    checkedFilters,
    sortOption,
  }) {
    if (typeof searchQuery !== 'string') {
      return this.rejectOnInvalidArgumentType('searchQuery', 'getProductsForSearch')
    }

    const synonyms = await this.$thesaurusApiService.getSynonyms(searchQuery.trim())

    const options = {
      searchQuery,
      synonyms,
      itemsToSkip,
      pageSize,
      optimizers: this.store.state.search.optimizers,
      defaultBoosters: this.store.state.search.defaultBoosters,
      synonymBoosters: this.store.state.search.synonymBoosters,
      checkedFilters,
      sortOption,
      customerGroupId: this.store.state.user.customerGroupId,
    }

    return this.post(getProductsForSearchQuery(options))
      .then(response => mapProductsAndAvailableFiltersFromApi(response))
      .then(deepFreeze)
  }

  /**
   * @param {string} searchQuery
   * @returns {Promise<Array>}
   */
  async getSuggestionsForSearch(searchQuery) {
    if (typeof searchQuery !== 'string') {
      return this.rejectOnInvalidArgumentType('searchQuery', 'getSuggestionsForSearch')
    }

    return this.post(getSuggestionsForSearchQuery(searchQuery))
      .then(mapAndFilterSuggestionsForSearchFromApi)
  }
}
