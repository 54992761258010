/**
 * @typedef {BambuserTrackingService}
 * @alias this.$bambuserTrackingService
 */
export class BambuserTrackingService {
  /**
   * @param {string} orderId
   * @param {number} orderValue
   * @param {string[]|string} orderProductIds comma-separated string, or array of all product ids in the order
   * @param {string} currency
   */
  purchase({
    orderId,
    orderValue,
    orderProductIds,
    currency,
  }) {
    if (!this.#isInitialized()) {
      return this.#init(() => this.#sendPurchaseData({
        orderId,
        orderValue,
        orderProductIds,
        currency,
      }))
    }

    return this.#sendPurchaseData({
      orderId,
      orderValue,
      orderProductIds,
      currency,
    })
  }

  /**
   * @param {string} orderId
   * @param {number} orderValue
   * @param {string[]|string} orderProductIds
   * @param {string} currency
   */
  #sendPurchaseData({
    orderId,
    orderValue,
    orderProductIds,
    currency,
  }) {
    window._bambuser.collect({
      event: 'purchase',
      orderId,
      orderValue,
      orderProductIds,
      currency,
    })
  }

  /**
   * Docs: https://bambuser.com/docs/one-to-many/conversion-tracking
   * @param {Function} callback
   */
  #init(callback) {
    const script = document.createElement('script')
    script.src = 'https://cdn.liveshopping.bambuser.com/metrics/bambuser-eu.min.js'
    script.onload = callback

    document.body.appendChild(script)
  }

  /**
   * @returns {boolean}
   */
  #isInitialized() {
    return !!window._bambuser
  }
}
