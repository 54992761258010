export class PaymentMethodIssuer {
  /**
   * @param {string} id
   * @param {string} title
   * @param {string} [imageSrc]
   */
  constructor({
    id,
    title,
    imageSrc,
  }) {
    /** @type {string} */
    this.id = id

    /** @type {string} */
    this.title = title

    /** @type {null|string} */
    this.imageSrc = imageSrc ?? null
  }
}
