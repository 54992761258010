import { render, staticRenderFns } from "./responsive-image.vue?vue&type=template&id=ff370120"
import script from "./responsive-image.vue?vue&type=script&lang=js"
export * from "./responsive-image.vue?vue&type=script&lang=js"
import style0 from "./responsive-image.vue?vue&type=style&index=0&id=ff370120&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NoScript: require('/opt/build/repo/src/client/components/no-script/no-script.vue').default,FixedRatio: require('/opt/build/repo/src/client/components/fixed-ratio/fixed-ratio.vue').default})
