import { render, staticRenderFns } from "./checkout.vue?vue&type=template&id=7f640cad"
import script from "./checkout.vue?vue&type=script&lang=js"
export * from "./checkout.vue?vue&type=script&lang=js"
import style0 from "./checkout.vue?vue&type=style&index=0&id=7f640cad&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CheckoutHeader: require('/opt/build/repo/src/client/components/checkout-header/checkout-header.vue').default,ChatButton: require('/opt/build/repo/src/client/components/chat-button/chat-button.vue').default,AppSnackbar: require('/opt/build/repo/src/client/components/app-snackbar/app-snackbar.vue').default,AppOverlay: require('/opt/build/repo/src/client/components/app-overlay/app-overlay.vue').default})
