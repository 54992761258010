
  import { mapState, mapGetters, mapActions } from 'vuex'
  import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'

  export default {
    props: {
      product: {
        type: Object,
        required: true,
      },
      timesAdded: {
        type: Number,
        default: 0,
      },
      showTimesAdded: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isMounted: false,
      }
    },
    computed: {
      ...mapState({
        wishlist: state => state.wishlist.wishlist,
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
      isAvailable() {
        return this.isAuthenticated && this.isMounted
      },
      sku() {
        return getPrimarySkuFromProduct(this.product)
      },
      wishlistCta() {
        return this.isInWishlist
          ? this.$t('remove_from_wishlist')
          : this.$t('add_to_wishlist')
      },
      isInWishlist() {
        return Boolean(this.wishlist[this.sku])
      },
      // Fake showed times added to be increased on add to wishlist
      showedTimesAdded() {
        return (this.isInWishlist && this.showTimesAdded)
          ? this.timesAdded + 1
          : this.timesAdded
      },
    },
    mounted() {
      this.isMounted = true
    },
    methods: {
      ...mapActions({
        addSnackbarMessage: 'snackbar/addSnackbarMessage',
      }),
      onWishlistClick() {
        if (this.isInWishlist) {
          this.$wishlistService.removeProduct({ product: this.product })
            .catch(this.catchWishlistAction)
        } else {
          this.$wishlistService.addProduct({ product: this.product })
            .catch(this.catchWishlistAction)
        }
      },
      catchWishlistAction() {
        this.isInWishlist
          ? this.addSnackbarMessage({ message: 'remove_from_wishlist_fail' })
          : this.addSnackbarMessage({ message: 'add_to_wishlist_fail' })
      },
    },
  }
