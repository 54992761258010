import axios from 'axios'

export default async (context) => {
  // Prevent calls during static generation
  if (process.client) {
    const url = `${process.env.magentoBaseUrl}/media/notice.json?timestamp=${new Date().getTime()}`

    return axios.get(url)
      .then(response => {
        if (response?.data && Array.isArray(response.data)) {
          return context.store.dispatch('notice/setNotices', { notices: response.data })
        }
      })
      .catch(() => [])
  }
}
