export default function (context) {
  // Current route is being tracked because this.$router.currentRoute is empty in Vuex during hydration.
  // Middleware is called on client side and during static generation for every page.
  // This way the Vuex state is immediately available on hydration because of the generated state.js
  // is filled with the currentRoutePath
  context.store.dispatch('routes/setCurrentRoutePath', context.route.path)
  context.store.dispatch('routes/setCurrentRouteName', context.route.name)

  // Prevent previous route being set with the same route as current route on initial visit
  if (context.from && context.from.path !== context.route.path) {
    context.store.dispatch('routes/setPreviousRouteName', context.from.name)
    context.store.dispatch('routes/setPreviousFullPath', context.from.fullPath)
  }
}
