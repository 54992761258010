
  export default {
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        required: true,
      },
      hasForgotPassword: {
        type: Boolean,
        default: false,
      },
      rules: {
        type: [ String, Object ],
        default: '',
        validator(value) {
          if (!value || typeof value === 'string') {
            return true
          }

          return (
            value.name &&
            (value.params) ? Object.keys(value.params).length > 0 : true
          )
        },
      },
      showValidationHint: {
        type: Boolean,
        default: false,
      },
      hideValidationCheckmark: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'forgot-password',
      'input',
    ],
    data() {
      return {
        password: '',
        passwordInputType: 'password',
        passwordToggleCta: this.$t('show_password'),
        toggleIconName: 'visibility',
      }
    },
    methods: {
      togglePassword() {
        if (this.passwordInputType === 'password') {
          this.passwordInputType = 'text'
          this.passwordToggleCta = this.$t('hide_password')
          this.toggleIconName = 'visibility-off'
        } else {
          this.passwordInputType = 'password'
          this.passwordToggleCta = this.$t('show_password')
          this.toggleIconName = 'visibility'
        }
      },
    },
  }
