import deepFreeze from 'deep-freeze'

/**
 * @param {array} hits
 * @return {array}
 */
export function mapCategoriesForSearchFromApi(hits) {
  return hits.map(category => deepFreeze({
    name: category._source?.name?.[0],
    urlPath: category._source?.url_path?.[0],
  }))
}

/**
 * @param {array} hits
 * @return {object}
 */
export function mapCategoryFromApi(hits) {
  return deepFreeze(hits[0]._source)
}
