import { render, staticRenderFns } from "./app-footer-mobile.vue?vue&type=template&id=7d2af2ae"
import script from "./app-footer-mobile.vue?vue&type=script&lang=js"
export * from "./app-footer-mobile.vue?vue&type=script&lang=js"
import style0 from "./app-footer-mobile.vue?vue&type=style&index=0&id=7d2af2ae&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsletterSignup: require('/opt/build/repo/src/client/components/newsletter-signup/newsletter-signup.vue').default,AppFooterList: require('/opt/build/repo/src/client/components/app-footer-list/app-footer-list.vue').default,SocialLinks: require('/opt/build/repo/src/client/components/social-links/social-links.vue').default})
