import { MAGENTO_CODE_FREE, MAGENTO_PAYMENT_METHOD_CODES, MOLLIE_PAYMENT_METHODS_PREFIX } from '~/lib/constants'
import { PaymentMethod } from '~/lib/checkout/payment-method'

/**
 * @typedef {CartPaymentService}
 * @alias this.$cartPaymentService
 */
export class CartPaymentService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
    this.activeMagentoPaymentMethods = MAGENTO_PAYMENT_METHOD_CODES
  }

  init() {
    this.$trackingService = this.context.$trackingService
    this.$checkoutApiService = this.context.$checkoutApiService
    this.$checkoutApiMapperService = this.context.$checkoutApiMapperService
    this.$cartService = this.context.$cartService
    this.$cartTotalsService = this.context.$cartTotalsService
  }

  /**
   * Get Magento and Adyen payment methods
   * @param {Object[]} paymentMethodsFromMagento
   * @returns {Promise}
   */
  async fetchAllCartPaymentMethods({ paymentMethodsFromMagento } = {}) {
    const magentoPaymentMethods = paymentMethodsFromMagento ?? await this.getCartMagentoPaymentMethods()

    if (magentoPaymentMethods.find(magentoPaymentMethod => magentoPaymentMethod.code === MAGENTO_CODE_FREE)) {
      return this.#updateCartPaymentMethods({
        paymentMethods: [
          new PaymentMethod({
            id: MAGENTO_CODE_FREE,
            title: MAGENTO_CODE_FREE,
          }),
        ],
      })
    }

    const molliePaymentMethodsMetaData = await this.$checkoutApiService.getCartMolliePaymentMethodsMetaData()
    const activeMagentoPaymentMethods = magentoPaymentMethods
      .filter(magentoPaymentMethod =>
        magentoPaymentMethod.code.startsWith(MOLLIE_PAYMENT_METHODS_PREFIX)
        || this.activeMagentoPaymentMethods.includes(magentoPaymentMethod.code)
      )
    const mappedPaymentMethods = this.$checkoutApiMapperService.mapMagentoPaymentMethods(activeMagentoPaymentMethods, molliePaymentMethodsMetaData)

    return this.#updateCartPaymentMethods({
      paymentMethods: mappedPaymentMethods,
    })
  }

  /**
   * @param {PaymentMethod[]} paymentMethods
   * @param {PaymentMethod} [historicallySelectedPaymentMethod]
   * @return {Promise}
   */
  async #updateCartPaymentMethods({ paymentMethods, historicallySelectedPaymentMethod }) {
    if (!paymentMethods) {
      await this.store.dispatch('checkout/setPaymentMethods', [])

      return this.updateSelectedPaymentMethod(null)
    }

    const freePaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.code === MAGENTO_CODE_FREE)
    const cartEmailIsValid = this.store.getters['checkout/emailIsValid']
    const previouslySelectedPaymentMethod = this.store.state.checkout.selectedPaymentMethod
    const previouslySelectedPaymentMethodIsAvailable = this.#paymentMethodIsAvailable({ paymentMethods, paymentMethod: previouslySelectedPaymentMethod })
    const historicallySelectedPaymentMethodIsAvailable = this.#paymentMethodIsAvailable({ paymentMethods, paymentMethod: historicallySelectedPaymentMethod })

    await this.store.dispatch('checkout/setPaymentMethods', paymentMethods)

    // Preselect free payment method
    // We can only select payment methods with a valid email address
    if (freePaymentMethod && cartEmailIsValid) {
      return this.updateSelectedPaymentMethod(freePaymentMethod)
    }

    // If previously selected payment method is still available, don't do anything
    if (previouslySelectedPaymentMethodIsAvailable) {
      return this.$cartTotalsService.updateCartTotals()
    }

    // If there is a payment method know from a previous order,
    // it's available and there is no payment method already selected.
    // Then this payment method is selected
    if (historicallySelectedPaymentMethodIsAvailable) {
      return this.updateSelectedPaymentMethod(historicallySelectedPaymentMethod)
    }

    // If all above conditions fail, we remove the selected payment method
    if (previouslySelectedPaymentMethod) {
      return this.updateSelectedPaymentMethod(null)
    }

    return this.$cartTotalsService.updateCartTotals()
  }

  /**
   * Payment method objects are Magento payment method response returned by updateCartShippingInformation
   * @param {PaymentMethod|null} [paymentMethod]
   * @returns {Promise}
   */
  async updateSelectedPaymentMethod(paymentMethod) {
    const previouslySelectedPaymentMethod = this.store.state.checkout.selectedPaymentMethod

    if (!previouslySelectedPaymentMethod && !paymentMethod) {
      return
    }

    if (previouslySelectedPaymentMethod?.id === paymentMethod?.id) {
      return
    }

    if (!paymentMethod) {
      this.store.dispatch('checkout/setSelectedPaymentMethod', null)

      return this.$cartTotalsService.updateCartTotals()
    }

    // Check if selected payment method is a possible payment method
    const paymentMethods = this.store.state.checkout.paymentMethods
    const paymentMethodIsAvailable = this.#paymentMethodIsAvailable({ paymentMethods, paymentMethod })

    if (paymentMethodIsAvailable) {
      this.$trackingService.selectPaymentMethod(paymentMethod)

      await this.store.dispatch('checkout/setSelectedPaymentMethod', paymentMethod)
      await this.store.dispatch('checkout/setSelectedPaymentMethodDetails', null)

      await this.updateCartPaymentInformation(paymentMethod)
      await this.$cartService.updateCart()
    }
  }

  /**
   * @param {PaymentMethod[]} paymentMethods
   * @param {PaymentMethod} [paymentMethod]
   * @returns {boolean}
   */
  #paymentMethodIsAvailable({ paymentMethods, paymentMethod }) {
    return paymentMethods.some(currentPaymentMethod => currentPaymentMethod.id === paymentMethod?.id)
  }

  /**
   * Magento's payment methods are only used to determine if
   * there is a 'free' payment method.
   * @returns {Promise<Object[]>}
   */
  getCartMagentoPaymentMethods() {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    return this.$checkoutApiService.getCartMagentoPaymentMethods(cartId)
  }

  /**
   * @param {PaymentMethod} paymentMethod
   * @returns Promise<boolean>
   */
  updateCartPaymentInformation(paymentMethod) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    return this.$checkoutApiService.updateCartPaymentInformation({
      paymentMethod,
      cartId,
    })
  }
}
