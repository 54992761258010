
  import AppImage from '@loavies/component-library/dist/app-image'

  export default {
    components: {
      AppImage,
    },
    props: {
      stories: {
        type: Array,
        required: true,
        validator: areValidStories,
      },
    },
  }

  function areValidStories(stories) {
    // story returns an object with a single prop called story :(
    return stories.every(({ story }) => (
      typeof(story) === 'object'
      && typeof(story.title) === 'string'
      && typeof(story.subtitle) === 'string'
      && typeof(story.link) === 'object'
      && typeof(story.image) === 'object'
      && typeof(story.image.url) === 'string'
    ))
  }
