import { render, staticRenderFns } from "./chat-button.vue?vue&type=template&id=3ac36a0d"
import script from "./chat-button.vue?vue&type=script&lang=js"
export * from "./chat-button.vue?vue&type=script&lang=js"
import style0 from "./chat-button.vue?vue&type=style&index=0&id=3ac36a0d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIcon: require('/opt/build/repo/src/client/components/svg-icon/svg-icon.vue').default,AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default})
