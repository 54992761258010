export class ApiService {
  /**
   * @param {string} argumentName
   * @param {string} methodName
   * @return {Promise}
   */
  rejectOnMissingArgument(argumentName, methodName) {
    return Promise.reject(Error(`Missing argument "${argumentName}" in method: ${methodName}`))
  }

  /**
   * @param {string} methodName
   * @return {Promise}
   */
  rejectOnUnauthorized(methodName) {
    return Promise.reject(Error(`User is unauthorized to use method: ${methodName}`))
  }

  /**
   * @param {string} argumentName
   * @param {string} methodName
   * @return {Promise}
   */
  rejectOnInvalidArgumentType(argumentName, methodName) {
    return Promise.reject(Error(`Argument "${argumentName}" is of the wrong type in method: ${methodName}`))
  }
}
