import getCountryId from '~/lib/get-country-id'

export default function (context) {
  /**
   * Shipping address
   */
  const shippingAddress = context.store.state.checkout.shippingAddress

  /**
   * Here we set default settings for the checkout page
   * There is a dependency on initializing the country and login
   */
  if (!shippingAddress) {
    // Set a default shipping address with the local country id.
    // This is used to determine the initial shipping methods
    return context.store.dispatch('checkout/setShippingAddress', {
      country_id: getCountryId(context.store.state.localization.country),
    })
  }
}
