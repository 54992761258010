import { AUTH_TOKEN_COOKIE_NAME } from '~/lib/constants'
import cookie from '~/lib/cookie'

export default function (context) {
  if (!process.client) {
    return
  }

  // Don't trigger if no tokens are present.
  if (!context.store.state.user.token && !cookie(AUTH_TOKEN_COOKIE_NAME)) {
    return
  }

  // If a user logs out and logs in with another user account
  // on another tab this wil result in a mismatch of tokens
  // because the cookie is updated, but the user is not.
  // We match the store token with the cookie token.
  const tokenMismatch = context.store.state.user.token !== cookie(AUTH_TOKEN_COOKIE_NAME)

  if (tokenMismatch) {
    // A page reload will force a re-initialization of the store
    // so user and cart will be updated.
    window.location = `${window.location.origin}${context.route.path}`
  }
}
