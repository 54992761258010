
  export default {
    props: {
      highlighted: {
        type: Boolean,
        default: false,
      },
      usps: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      getTextStyle(usp) {
        return {
          ...(usp.textColor?.hex && { color: usp.textColor.hex }),
        }
      },
    },
  }
