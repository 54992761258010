import { render, staticRenderFns } from "./navigation-desktop.vue?vue&type=template&id=188835c5"
import script from "./navigation-desktop.vue?vue&type=script&lang=js"
export * from "./navigation-desktop.vue?vue&type=script&lang=js"
import style0 from "./navigation-desktop.vue?vue&type=style&index=0&id=188835c5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationDesktopList: require('/opt/build/repo/src/client/components/navigation-desktop-list/navigation-desktop-list.vue').default,StoriesOverview: require('/opt/build/repo/src/client/components/stories-overview/stories-overview.vue').default,SmartLink: require('/opt/build/repo/src/client/components/smart-link/smart-link.vue').default,NavigationHighlightedItem: require('/opt/build/repo/src/client/components/navigation-highlighted-item/navigation-highlighted-item.vue').default})
