import cookie from '~/lib/cookie'

const SKIP_MAINTENANCE_COOKIE_NAME = 'skipMaintenance'

/**
 * Expects query parameters object from route
 * @param {object} routeQuery
 * @returns {boolean}
 */
export default (routeQuery) => {
  if (!process.client) {
    return true
  }

  const hasMaintenanceCookie = Boolean(cookie(SKIP_MAINTENANCE_COOKIE_NAME))

  if (routeQuery.hasOwnProperty(SKIP_MAINTENANCE_COOKIE_NAME) && !hasMaintenanceCookie) {
    cookie(SKIP_MAINTENANCE_COOKIE_NAME, true)
    return true
  }

  return !!hasMaintenanceCookie
}
