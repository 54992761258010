import defaultState from './state'

export default {
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  SET_NOTICES(state, { notices }) {
    state.notices = notices
  },
}
