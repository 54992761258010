import deepFreeze from 'deep-freeze'

export const productsViewRecordType = 'ProductsViewRecord'

export function fetchProductsForProductsViewConfigs(productApiService, productsViewConfigs) {
  return Promise.all(productsViewConfigs.map(productsViewConfig => {
    return fetchProductsForProductsViewConfig(productApiService, productsViewConfig)
  }))
}

function fetchProductsForProductsViewConfig(productApiService, productsViewConfig) {
  return productApiService.getProductsAndAvailableFiltersByCategoryId(productsViewConfig.categoryId)
    .then(response => response.products)
    .then(products => {
      productsViewConfig.products = products

      return productsViewConfig
    })
    .then(deepFreeze)
    .catch(error => {
      if (!process.client) {
        console.error('Failed to fetch products for category', productsViewConfig.categoryId, error)
      }

      productsViewConfig.products = []

      deepFreeze(productsViewConfig)
    })
}
