
  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
    },
    computed: {
      src() {
        return `/svg/${this.name}.svg`
      },
    },
  }
