export default {
  /**
   * @param state
   * @param {Object} payload
   */
  ADD_OPTIMIZERS(state, payload) {
    state.optimizers = payload
  },

  /**
   * @param state
   * @param {string[]} payload
   */
  ADD_DEFAULT_BOOSTERS(state, payload) {
    state.defaultBoosters = payload
  },

  /**
   * @param state
   * @param {string[]} payload
   */
  ADD_SYNONYM_BOOSTERS(state, payload) {
    state.synonymBoosters = payload
  },

  /**
   * @param state
   * @param {Object} payload
   */
  SET_VALUE(state, { value }) {
    state.value = value
  },

  /**
   * @param state
   * @param {Object} payload
   */
  SET_DATA(state, { pages, categories, suggestions, products, totalProductsCount }) {
    state.pages = pages
    state.categories = categories
    state.suggestions = suggestions
    state.products = products
    state.totalProductsCount = totalProductsCount
  },

  /**
   * @param state
   * @param {Object} payload
   */
  SET_PRODUCTS_LOADING(state, { productsLoading }) {
    state.productsLoading = productsLoading
  },

  /**
   * @param state
   * @param {Object} payload
   */
  SET_ACTIVE_SORT_OPTION(state, { activeSortOption }) {
    state.activeSortOption = activeSortOption
  },

  /**
   * @param state
   * @param {Object} payload
   */
  SET_ALL_FILTERS(state, { allFilters }) {
    state.allFilters = allFilters
  },

  /**
   * @param state
   * @param {Object} payload
   */
  SET_AVAILABLE_FILTERS(state, { availableFilters }) {
    state.availableFilters = availableFilters
  },

  /**
   * @param state
   * @param {Object} payload
   */
  SET_CHECKED_FILTERS(state, { checkedFilters }) {
    state.checkedFilters = checkedFilters
  },
}
