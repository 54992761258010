
  import { PHONE_NUMBER_REGEX } from '~/lib/constants'

  export default {
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      rules: {
        type: Object,
        default: () => {},
      },
      value: {
        type: String,
        default: '',
      },
    },
    emits: ['input'],
    computed: {
      validationRules() {
        const defaultRules = {
          min: 1,
          regex: PHONE_NUMBER_REGEX,
        }
        return { ...defaultRules, ...this.rules }
      },
    },
    methods:{
      handleInput(value) {
        this.$emit('input', value)
      },
    },
  }

