export default function ({ app, from, redirect, route, store }) {
  const addressIdToEdit = Number(route?.query?.addressId || from?.query?.addressId)
  const userAddresses = store.state.user.addresses

  if (
    store.state.user.isInitialized &&
    !userAddresses.some(address => address.id === addressIdToEdit)
  ) {
    return redirect(app.localePath('404'))
  }
}
