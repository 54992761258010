import {
  ImageModel,
  ImageVariantModel,
  ImageVariantsModel,
  ListingDiscountModel,
  ListingModel,
  ListingPublicDataModel,
  PriceModel,
  UserModel,
  UserPublicDataModel,
} from '@loavies/c2c-models'

/**
 * @param {Array} listings
 * @return {loavies.models.listing.ListingModel[]}
 */
export function mapListings(listings) {
  return listings.map(listing => mapListing(listing))
}

/**
 * @param {Object} listing
 * @return {loavies.models.listing.ListingModel}
 */
export function mapListing(listing) {
  return new ListingModel({
    ...listing,
    createdAt: new Date(listing.createdAt),
    price: new PriceModel(listing.price),
    images: listing.images.map(image => mapImage(image)),
    publicData: mapListingPublicData(listing.publicData),
    author: new UserModel({
      ...listing.author,
      createdAt: new Date(listing.author.createdAt),
      image: listing.author.image ? mapImage(listing.author.image) : null,
      publicData: new UserPublicDataModel(listing.author.publicData),
    }),
  })
}

/**
 * @param {Object} image
 * @return {loavies.models.image.ImageModel}
 */
export function mapImage(image) {
  return new ImageModel({
    ...image,
    variants: new ImageVariantsModel(Object
      .entries(image.variants)
      .reduce((acc, variant) => {
        const key = variant[0]
        const value = variant[1]

        acc[key] = new ImageVariantModel(value)

        return acc
      }, {})),
  })
}

/**
 * @param {Object} publicData
 * @return {loavies.models.listing.ListingPublicDataModel}
 */
export function mapListingPublicData(publicData) {
  return new ListingPublicDataModel({
    ...publicData,
    discount: publicData.discount && mapListingDiscount(publicData.discount),
  })
}

/**
 * @param {Object} discount
 * @return {loavies.models.listing.ListingDiscountModel}
 */
export function mapListingDiscount(discount) {
  return new ListingDiscountModel({
    ...discount,
    originalPrice: new PriceModel(discount.originalPrice),
  })
}
