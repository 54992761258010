import cloneDeep from 'lodash.clonedeep'

const INSTAGRAM_ACCOUNT_NAME_LOAVIES = 'loavies'
const LANGUAGES = {
  'en': 'english',
  'nl': 'dutch',
  'fr': 'french',
  'es': 'spanish',
  'de': 'german',
}

/**
 * @param {Array}   locales
 * @param {Array}   items
 * @param {string}  [locale]
 * @param {number}  [maxPosts]
 * @return {Object}
 */
export function mapContentForProductFromApi(locales, items, locale, maxPosts) {
  const filteredItems = getFilteredItems(items)
  const avatars = mapAvatars(filteredItems, locale, maxPosts)
  const posts = mapPosts(filteredItems, maxPosts)

  return {
    avatars,
    posts,
  }
}

/**
 * @param {Array} items
 * @return {Array}
 */
function getFilteredItems(items) {
  if (!items) {
    return []
  }

  const uniqueItems = new Set()

  return items
    // Remove duplicate items with the same avatar_url
    // (or pixlee_cdn_photos.small_url as a fallback)
    .filter(item => {
      const imageUrl = item.avatar_url ? item.avatar_url : item.pixlee_cdn_photos.small_url
      const duplicate = uniqueItems.has(imageUrl)

      uniqueItems.add(imageUrl)

      return !duplicate
    })
    // Filter out items with user_name 'loavies'
    .filter(item => item.user_name !== INSTAGRAM_ACCOUNT_NAME_LOAVIES && item.user_name !== null)
}

/**
 * @param {Array} filteredItems
 * @param {string} [locale]
 * @param {number} [maxPosts]
 * @return {Array}
 */
function mapAvatars(filteredItems, locale, maxPosts = 30) {
  // Sort from most Instagram followers to least
  const sortedItems = cloneDeep(filteredItems).sort((a, b) => b.instagram_followers - a.instagram_followers)

  // Sort on language but keep followers sort
  if (locale && LANGUAGES[locale]) {
    return getItemsSortedByLocale(sortedItems, LANGUAGES[locale])
      .slice(0, maxPosts)
      .map(item => mapAvatar(item))
  }

  return sortedItems
    .slice(0, maxPosts)
    .map(item => mapAvatar(item))
}

/**
 * The avatar_url can be present in the response but also return
 * an error when being downloaded. Then the onload event will
 * try to use the fallback image.
 * @param {Object} filteredItem
 * @return {Object}
 */
function mapAvatar(filteredItem) {
  return {
    userName: filteredItem.user_name,
    avatarImageUrl: filteredItem.avatar_url ? filteredItem.avatar_url : filteredItem.pixlee_cdn_photos.small_url,
    fallBackImageUrl: filteredItem.pixlee_cdn_photos.small_url,
    language: filteredItem.language,
    instagramFollowers: filteredItem.instagram_followers,
  }
}

/**
 * @param {Array} filteredItems
 * @param {number} [maxPosts]
 * @return {Array}
 */
function mapPosts(filteredItems, maxPosts = 30) {
  let clonedFilteredItems = cloneDeep(filteredItems)

  return clonedFilteredItems
    .slice(0, maxPosts)
    .map(item => mapPost(item))
}

/**
 * @param {Object} item
 * @return {Object}
 */
function mapPost(item) {
  return {
    id: item.id,
    userName: item.user_name,
    avatarUrl: item.avatar_url,
    altText: item.alt_text,
    products: item.products.map(product => ({ sku: product.sku })),
    imageUrl: item.pixlee_cdn_photos.medium_url,
    contentType: item.content_type,
    sourceUrl: item.source_url,
    language: item.language,
  }
}

/**
 * This functions keeps any other sorting in place
 * @param {Array} items
 * @param {string} language
 * @return {Array}
 */
function getItemsSortedByLocale(items, language) {
  const itemsInCurrentLocale = []
  const otherItems = []

  items.forEach(item => {
    if (item.language === language) {
      itemsInCurrentLocale.push(item)
    } else {
      otherItems.push(item)
    }
  })

  return [ ...itemsInCurrentLocale, ...otherItems ]
}
