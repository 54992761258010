
  import { mapState } from 'vuex'

  export default {
    props: {
      size: {
        type: Object,
        required: true,
      },
      value: {
        type: Number,
        default: null,
      },
    },
    emits: [
      'in-stock-selected',
      'out-of-stock-selected',
    ],
    computed: {
      ...mapState({
        cartItems: state => state.checkout.cartItems,
      }),
      isInStock() {
        return this.size.product_qty > 0
      },
      // Fake the amount of products that are available
      // by using the quantity of items in cart
      showedCount() {
        const foundCartItem = this.cartItems.find(cartItem => {
          return cartItem.sku === this.size.product_sku
        })

        return foundCartItem
          ? this.size.product_qty - foundCartItem.qty
          : this.size.product_qty
      },
    },
    methods: {
      onClick() {
        this.isInStock
          ? this.$emit('in-stock-selected', this.size.option_value)
          : this.$emit('out-of-stock-selected')
      },
    },
  }
