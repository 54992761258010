import getPrimarySkuFromProduct from './get-primary-sku-from-product'

export function isValidProduct(product) {
  if (typeof(product) !== 'object') {
    return false
  }

  const optionalProps = [
    'meta_title',
    'meta_description',
    'length',
    'fabric_content',
    'option_text_wash',
  ]
  const sku = getPrimarySkuFromProduct(product)

  return (
    optionalProps.every(prop => product[prop]
      ? typeof(product[prop][0]) === 'string'
      : true,
    )
    && typeof(sku) === 'string'
    && typeof(product.name[0]) === 'string'
    && (Array.isArray(product.prices_storefront) && product.prices_storefront.every(isValidPriceGroup))
  )
}

export function isValidMediaGallery(gallery) {
  return (
    gallery
    && typeof(gallery.formats) === 'object'
    && typeof(gallery.formats.tiny) === 'string'
    && typeof(gallery.formats.small) === 'string'
    && typeof(gallery.formats.medium) === 'string'
    && typeof(gallery.formats.large) === 'string'
    && typeof(gallery.formats.mega) === 'string'
  )
}

export function isValidPriceGroup(priceGroup) {
  return (
    typeof(priceGroup.customer_group_id) === 'number'
    && typeof(priceGroup.currency) === 'object'
    && Object.keys(priceGroup.currency).every(currency => {
      const price = priceGroup.currency[currency]
      return (
        typeof(price) === 'object'
        && typeof(price.is_discount) === 'boolean'
        && !isNaN(Number(price.price))
        && !isNaN(Number(price.original_price))
      )
    })
  )
}
