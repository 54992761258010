/**
 * @typedef {CheckoutApiService}
 * @alias this.$checkoutApiService
 */
export class CheckoutApiService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.store = context.store
    this.localePath = context.localePath
    this.molliePaymentMethodsMetaData = null
  }

  init() {
    this.$magentoApiService = this.context.$magentoApiService
    this.$userApiMapperService = this.context.$userApiMapperService
    this.$checkoutApiMapperService = this.context.$checkoutApiMapperService
  }

  /**
   * @param {string[]} skus
   * @returns {Promise<Object>}
   */
  getProductStocks(skus) {
    return this.$magentoApiService
      .post(
        '/inventory/mass-get-product-salable-quantity/',
        {
          skuList: skus,
          stockId: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.magentoStockKey}`,
          },
        }
      )
      .then(response => this.$checkoutApiMapperService.mapProductQuantitiesFromApi(response.data))
  }

  /**
   * @param {string} country e.g. 'NL'
   * @returns {Promise<string>} Created cart id
   */
  createNewCart(country) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine' : '/guest-carts'

    return this.$magentoApiService
      .post(url, {
        bold_shipping_country: country,
      })
      .then(response => String(response.data))
  }

  /**
   * @param {string} [cartId]
   * @returns {Promise<Object>}
   */
  getCart(cartId) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine' : `/guest-carts/${cartId}`

    return this.$magentoApiService
      .get(url)
      .then(response => this.#handleCartResponse(response))
  }
  /**
   * @param {string} cartId
   * @return {Promise<Object>}
   */
  getUserCartById(cartId) {
    return this.$magentoApiService
      .get(`/carts/mine/${cartId}`)
      .then(response => this.#handleCartResponse(response))
  }

  /**
   * @param {Object} response
   * @returns {Object}
   */
  #handleCartResponse(response) {
    const cart = response.data
    const shippingAddress = cart?.extension_attributes?.shipping_assignments?.[0]?.shipping.address

    if (shippingAddress) {
      cart.extension_attributes.shipping_assignments[0].shipping.address = this.$userApiMapperService.mapAddressFromApi(shippingAddress, false)
    }

    return cart
  }

  /**
   * @param {string} [cartId]
   * @param {Object} product
   * @param {number} quantity
   * @param {Object} [selectedSize]
   * @param {Object} [eGiftCardDetails]
   * @returns {Promise}
   */
  addToCart({ cartId, product, quantity, selectedSize, eGiftCardDetails }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/items' : `/guest-carts/${cartId}/items`

    return this.$magentoApiService
      .post(url, {
        cartItem: this.$checkoutApiMapperService.mapCartItem({
          cartId,
          product,
          quantity,
          selectedSize,
          eGiftCardDetails,
        }),
      })
  }

  /**
   * @param {string} [cartId]
   * @param {number} quantity
   * @param {Object} cartItem
   * @returns {Promise}
   */
  updateCartItem({ cartId, quantity, cartItem }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? `/carts/mine/items/${cartItem.item_id}` : `/guest-carts/${cartId}/items/${cartItem.item_id}`

    return this.$magentoApiService
      .put(url, {
        cartItem: {
          item_id: cartItem.item_id,
          quote_id: cartId,
          qty: quantity,
        },
      })
  }

  /**
   * @param {string} [cartId]
   * @param {Object} cartItem
   * @returns {Promise}
   */
  removeCartItem({ cartId, cartItem } = {}) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? `/carts/mine/items/${cartItem.item_id}` : `/guest-carts/${cartId}/items/${cartItem.item_id}`

    return this.$magentoApiService.delete(url)
  }

  /**
   * @param {string} [cartId]
   * @returns {Promise<Object>}
   */
  getCartTotals(cartId) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const timeStamp = new Date().getTime()
    const url = isAuthenticated ? `/carts/mine/totals?_=${timeStamp}` : `/guest-carts/${cartId}/totals?_=${timeStamp}`

    return this.$magentoApiService
      .get(url)
      .then(response => response.data)
  }

  /**
   * @param {Object} address
   * @param {string} [cartId]
   * @returns {Promise<Object>}
   */
  getCartShippingMethodsByAddress({ address, cartId }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/estimate-shipping-methods' : `/guest-carts/${cartId}/estimate-shipping-methods`

    return this.$magentoApiService
      .post(url, {
        address: this.$userApiMapperService.mapAddressToApi(address),
      })
      .then(response => this.$checkoutApiMapperService.mapShippingMethodsFromApi(response.data))
  }

  /**
   * @param {number} addressId
   * @returns {Promise<Object>}
   */
  getUserCartShippingMethodsByAddressId(addressId) {
    return this.$magentoApiService
      .post('/carts/mine/estimate-shipping-methods-by-address-id', { addressId })
      .then(response => this.$checkoutApiMapperService.mapShippingMethodsFromApi(response.data))
  }

  /**
   * Shipping method details are fetched in checkoutApiMapperService
   * @param {Object} shippingMethod
   * @param {Object} shippingAddress
   * @param {string} [cartId]
   * @returns {Promise<Object>}
   */
  updateCartShippingInformation({ shippingMethod, shippingAddress, cartId }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/shipping-information' : `/guest-carts/${cartId}/shipping-information`

    return this.$magentoApiService
      .post(url, {
        addressInformation: this.$checkoutApiMapperService.mapShippingInformationToApi({
          shippingMethod,
          shippingAddress,
        }),
      })
      .then(response => response.data)
  }

  /**
   * @param {string} [cartId]
   * @returns {Promise<Object[]>}
   */
  getCartMagentoPaymentMethods(cartId) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/payment-methods' : `/guest-carts/${cartId}/payment-methods`

    return this.$magentoApiService
      .get(url)
      .then(response => response.data)
  }

  getCartMolliePaymentMethodsMetaData() {
    if (this.molliePaymentMethodsMetaData) {
      return Promise.resolve(this.molliePaymentMethodsMetaData)
    }

    return this.$magentoApiService
      .get('/mollie/payment-method/meta')
      .then((response) => {
        this.molliePaymentMethodsMetaData = response.data

        return this.molliePaymentMethodsMetaData
      })
  }

  /**
   * @param {PaymentMethod} paymentMethod
   * @param {string} [cartId]
   * @returns {Promise<boolean>}
   */
  updateCartPaymentInformation({ paymentMethod, cartId }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/set-payment-information' : `/guest-carts/${cartId}/set-payment-information`

    return this.$magentoApiService
      .post(url, this.$checkoutApiMapperService.mapPaymentInformationToApi(paymentMethod))
  }

  /**
   * @param {string} [cartId]
   * @returns {Promise<string>}
   */
  createMolliePaymentToken(cartId) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/mollie/payment-token' : `/guest-carts/${cartId}/mollie/payment-token`

    return this.$magentoApiService
      .get(url)
      .then(response => response.data)
  }

  /**
   * @param {string} token
   * @returns {Promise<string>} Redirect URL
   */
  startMollieTransaction(token) {
    return this.$magentoApiService
      .post('/loavies/mollie/transaction/start', {
        token,
        returnUrl: `${window.location.origin}${this.localePath({ name: 'order-confirmation' })}`,
      })
      .then(response => response.data)
  }

  /**
   * @param {string} orderId
   * @returns {Promise<string>}
   */
  getMollieOrderDetails(orderId) {
    return this.$magentoApiService
      .get(`/mollie/get-order/${orderId}`)
      .then(response => response.data?.[0])
  }

  /**
   * @param {PaymentMethod} paymentMethod
   * @param {Object} paymentMethodDetails
   * @param {string} [cartId]
   * @returns {Promise<Object>}
   */
  placeCartOrder({ paymentMethod, paymentMethodDetails, cartId }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/payment-information' : `/guest-carts/${cartId}/payment-information`

    return this.$magentoApiService
      .post(url, this.$checkoutApiMapperService.mapPaymentInformationToApi(paymentMethod, paymentMethodDetails))
      .then(response => JSON.parse(response.data))
  }

  /**
   * @param {string} redirectResult
   * @param {string} incrementalOrderId
   * @returns {Promise<Object>} Returns payment details
   */
  getPaymentDetails({ redirectResult, incrementalOrderId }) {
    const url = '/loavies/adyen/paymentDetails'

    return this.$magentoApiService
      .post(url, {
        payload: JSON.stringify({
          orderId: incrementalOrderId,
          details: {
            redirectResult,
          },
        }),
      })
      .then(response => JSON.parse(response.data))
  }

  /**
   * @param {string} orderId
   * @param {string} key
   * @returns {Promise<Object>}
   */
  getOrderInformation({ orderId, key }) {
    return this.$magentoApiService
      .get(`/checkout/order?increment_id=${orderId}&key=${key}`)
      .then(response => response.data)
  }

  /**
   * @param {string} cartId
   * @returns {Promise<string>} New cart id
   */
  restoreCart(cartId) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/restore-cart' : `/guest-carts/${cartId}/restore-cart`
    const payload = isAuthenticated ? { cartId } : undefined

    return this.$magentoApiService
      .post(url, payload)
      .then(response => response.data)
  }

  /**
   * @param {string} cartId
   * @returns {Promise<string>} New cart id
   */
  getOrderIsPaid(cartId) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? `/carts/mine/order-paid?cartId=${cartId}` : `/guest-carts/${cartId}/order-paid`

    return this.$magentoApiService
      .get(url)
      .then(response => response.data)
  }

  /**
   * @param {string} guestCartId
   * @returns {Promise<Object>}
   */
  getMergedGuestCartWithUserCart(guestCartId) {
    return this.$magentoApiService
      .post('/carts/mine/merge-guest', { cartId: guestCartId })
      .then(response => response.data)
  }

  /**
   * @param {number} amount
   * @param {string} cartId
   * @returns {Promise}
   */
  addDonationToCart({ amount, cartId }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/items' : `/guest-carts/${cartId}/items`

    return this.$magentoApiService
      .post(url, {
        cartItem: this.$checkoutApiMapperService.mapCartDonationItem({
          cartId,
          amount,
        }),
      })
  }

  /**
   * @param {Object} donation
   * @param {string} [cartId]
   * @returns {Promise}
   */
  removeDonationFromCart({ donation, cartId }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? `/carts/mine/items/${donation.item_id}` : `/guest-carts/${cartId}/items/${donation.item_id}`

    return this.$magentoApiService
      .delete(url)
  }

  /**
   * @param {string} discountCode
   * @param {string} [cartId]
   * @returns {Promise}
   */
  addDiscountCodeToCart({ discountCode, cartId }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? `/carts/mine/coupons/${discountCode}` : `/guest-carts/${cartId}/coupons/${discountCode}`

    return this.$magentoApiService
      .put(url)
      .then(response => response.data)
  }

  /**
   * @param {string} [cartId]
   * @returns {Promise}
   */
  removeDiscountCodeFromCart(cartId) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? '/carts/mine/coupons' : `/guest-carts/${cartId}/coupons`

    return this.$magentoApiService
      .delete(url)
      .then(response => response.data)
  }

  /**
   * @param {string} giftCardCode
   * @param {string} [cartId]
   * @returns {Promise}
   */
  addGiftCardToCart({ giftCardCode, cartId }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? `/carts/mine/gift-card/${giftCardCode}` : `/guest-carts/${cartId}/gift-card/${giftCardCode}`

    return this.$magentoApiService
      .put(url)
      .then(response => response.data)
  }

  /**
   * @param {string} giftCardCode
   * @param {string} [cartId]
   * @returns {Promise}
   */
  removeGiftCardFromCart({ giftCardCode, cartId }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const url = isAuthenticated ? `/carts/mine/gift-card/${giftCardCode}` : `/guest-carts/${cartId}/gift-card/${giftCardCode}`

    return this.$magentoApiService
      .delete(url)
      .then(response => response.data)
  }

  /**
   * @param {string} validationUrl
   * @returns {Promise<Object>}
   */
  getApplePayPaymentSession(validationUrl) {
    return this.$magentoApiService
      .post('/loavies/mollie/apple-pay/validation', { validationUrl } )
      .then(response => response.data[0].validationData)
  }
}
