
  import { mapState } from 'vuex'

  import { isValidProduct } from '~/lib/product-validation'
  import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'

  import AnchorElements from '~/mixins/anchor-elements'
  import RandomlyScrollProductImages from '~/mixins/randomly-scroll-product-images'

  export default {
    mixins: [
      AnchorElements,
      RandomlyScrollProductImages,
    ],
    props: {
      products: {
        type: Array,
        default: null,
      },
      productsHaveAddToCartButton: {
        type: Boolean,
        default: false,
      },
      numberOfSkeletonCards: {
        type: Number,
        default: null,
      },
      location: {
        type: String,
        default: '',
      },
      showAlternativeProductImages: {
        type: Boolean,
        default: false,
      },
      showAlternativeDiscountStyle: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      enableVideoOnProductCards: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState({
        userPrefersLargeProductCards: state => state.userPrefersLargeProductCards,
      }),
      hideHeader() {
        return !this.$slots['title']
      },
      validProducts() {
        return (this.products || [])
          .filter(isValidProduct)
      },
      iterableItems() {
        return this.validProducts
          .map((product, index) => ({
            ...product,
            renderProduct: this.shouldRenderProduct(index),
            randomlyScrollProductImages: this.shouldRandomlyScrollProductImage(index),
          }))
      },
    },
    watch: {
      iterableItems: {
        async handler() {
          // Iterable items are watched so updated anchor positions can be checked
          // after user loads more products. We need to wait for the $nextTick
          // because product/anchor elements need to be rendered first before checking.
          await this.$nextTick()
          this.checkAnchorPositionsThrottled()
        },
      },
      userPrefersLargeProductCards: {
        async handler() {
          await this.$nextTick()
          this.checkAnchorPositionsThrottled()
        },
      },
    },
    created() {
      if (process.env.NODE_ENV !== 'production' && this.numberOfSkeletonCards === null && !this.products) {
        throw new Error('Either the products, or the numberOfSkeletonCards should be defined as prop')
      }
    },
    methods: {
      getPrimarySkuFromProduct,
    },
  }
