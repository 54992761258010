export default {
  /**
   * @param context
   * @param {string} country
   */
  setCountry(context, country) {
    context.commit('SET_COUNTRY', country)
  },

  /**
   * @param context
   * @param {Object} i18nPageParams
   */
  setI18nPageParams(context, i18nPageParams) {
    context.commit('SET_I18N_PAGE_PARAMS', i18nPageParams)
  },
}
