import { render, staticRenderFns } from "./account-button.vue?vue&type=template&id=59b8d12c"
import script from "./account-button.vue?vue&type=script&lang=js"
export * from "./account-button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default})
