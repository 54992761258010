
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState({
        customerGroupId: state => state.user.customerGroupId,
        activeCategory: state => state.category.activeCategory,
        categoryPageModularContent: state => state.category.categoryPageModularContent,
        singleCategoryPageModularContent: state => state.category.singleCategoryPageModularContent,
        pageDescription: state => state.category.activeCategory?.description?.[0] || null,
      }),
      allCategories() {
        return this.$staticDataService.cmsLayoutsDefault?.categoryMenu?.items ?? []
      },
      replaceCategoryPageContent() {
        return this.singleCategoryPageModularContent?.replaceCategoryPageContent || false
      },
      customerGroupSpecificCategoryModularContent() {
        return this.categoryPageModularContent?.customerGroupsSpecificContent?.[this.customerGroupId]
      },
      customerGroupSpecificSingleCategoryModularContent() {
        return this.singleCategoryPageModularContent?.customerGroupsSpecificContent?.[this.customerGroupId]
      },
      hideProducts() {
        return this.singleCategoryPageModularContent?.hideProducts || false
      },
      activeCategoryId() {
        return this.activeCategory?.entity_id || ''
      },
    },
  }
