
  import { mapState, mapActions } from 'vuex'

  import { FUNCTIONAL_COOKIE_NAME } from '~/lib/constants'
  import cookie from '~/lib/cookie'

  const marketingCookieKeys = ['ad_storage', 'ad_user_data', 'ad_personalization']
  const allConsentKeys = [...marketingCookieKeys, 'analytics_storage']

  export default {
    data() {
      return {
        refreshKey: 0,
        isAccepted: false,
        isRequested: false,
        togglesVisible: false,
      }
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
      }),
      cookieBanner() {
        return this.$staticDataService.cmsLayoutsDefault?.cookieBanner
      },
      showPrompt() {
        return this.isRequested && !this.isAccepted
      },
      googlePrivacyPolicyLink() {
        return this.cookieBanner.googlePrivacyPolicy
      },
      loaviesPrivacyPolicyLink() {
        return this.cookieBanner.loaviesPrivacyPolicy
      },
      consentCookies: {
        get() {
          // force a computed property to update
          this.refreshKey

          return Object
            .entries(this.$gtmService.getConsentCookieData())
            .reduce((acc, [key, value]) => {
              acc[key] = value === 'granted'

              return acc
            }, {})
        },
        set(payload) {
          const mappedCookie = Object
            .entries(payload)
            .reduce((acc, [key, value]) => {
              acc[key] = value ? 'granted' : 'denied'

              return acc
            }, {})

          this.$gtmService.updateConsentCookie(mappedCookie)
          this.refreshKey++
        },
      },
      marketingAccepted: {
        get() {
          return !Object.entries(this.consentCookies)
            .some(([key, value]) => marketingCookieKeys.includes(key) && !value)
        },
        set(payload) {
          const marketingCookieKeysAccepted = marketingCookieKeys.reduce((acc, key) => {
            acc[key] = payload

            return acc
          }, {})

          this.consentCookies = Object.assign({}, this.consentCookies, marketingCookieKeysAccepted)
        },
      },
    },
    mounted() {
      this.isAccepted = cookie(FUNCTIONAL_COOKIE_NAME) ?? false
      this.isRequested = true
    },
    methods: {
      ...mapActions({
        setCookiesAccepted: 'setCookiesAccepted',
      }),
      accept() {
        this.isAccepted = true

        cookie(FUNCTIONAL_COOKIE_NAME, true)

        this.setCookiesAccepted({ cookiesAccepted: true })
      },
      handleUpdateSingleValue(key, value) {
        this.consentCookies = Object.assign({}, this.consentCookies, { [key]: value })
      },
      acceptAll() {
        this.setAllCookieKeysToValue(true)

        this.accept()
      },
      declineAll() {
        this.setAllCookieKeysToValue(false)

        this.accept()
      },
      setAllCookieKeysToValue(value) {
        const allCookieKeysSet = allConsentKeys.reduce((acc, key) => {
          acc[key] = value

          return acc
        }, {})

        this.consentCookies = Object.assign({}, this.consentCookies, allCookieKeysSet)
      },
      showToggles() {
        this.consentCookies = Object.assign({}, this.consentCookies)

        this.togglesVisible = true
      },
    },
  }
