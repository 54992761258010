import cookie from '~/lib/cookie'
import { SPLIT_TEST_COOKIE_NAME } from '~/lib/constants'

/**
 * Split testing
 *
 * A/B Tests Overview:
 * https://docs.google.com/spreadsheets/d/1uwA0r--Fk2VTQmz3IjpXZDxD8gBkRgzqwo2g6G8thx8
 */
export default async context => {
  const splitTests = context.$staticDataService.cmsLayoutsDefault.app.splitTests

  splitTests?.forEach(splitTest => initializeSplitTest(context, splitTest))
}

/**
 * @typedef ActiveSplitTest
 * @type {Object}
 * @property {string} id
 * @property {string} name
 * @property {string} variantId
 * @property {string} variantName
 */

/**
 * @param {Object} context
 * @param {Object} splitTest
 * @returns {void}
 */
function initializeSplitTest(context, splitTest) {
  const splitTestCookie = getSplitTestCookie(splitTest.id)

  if (!splitTestCookie) {
    const variantToShow = splitTest.variants[getRandomGroupWithProbability(splitTest.variants.map(variant => ({ weight: variant.probabilityWeight })))]

    /** @type ActiveSplitTest */
    const formattedSplitTest = {
      name: splitTest.name,
      id: splitTest.id,
      variantId: variantToShow.id,
      variantName: variantToShow.name,
    }

    // Set cookie
    setSplitTestCookie(formattedSplitTest)

    // Set store
    context.store.dispatch('split-testing/addActiveSplitTest', formattedSplitTest)

    // Send to tracker
    context.$bloomreachService.identifyTestVariants()

    return
  }

  // Set store
  context.store.dispatch('split-testing/addActiveSplitTest', splitTestCookie)

  // Send to tracker
  context.$bloomreachService.identifyTestVariants()
}

/**
 * @param {string} id
 * @returns {string}
 */
function getSplitTestCookieName(id) {
  return `${SPLIT_TEST_COOKIE_NAME}${id}`
}

/**
 * @param {string} id
 * @returns {ActiveSplitTest|null}
 */
function getSplitTestCookie(id) {
  const cookieName = getSplitTestCookieName(id)

  try {
    return cookie(cookieName)
      ? JSON.parse(cookie(cookieName))
      : null
  } catch {
    return null
  }
}

/**
 * @param {ActiveSplitTest} activeSplitTest
 * @returns {void}
 */
function setSplitTestCookie(activeSplitTest) {
  const cookieName = getSplitTestCookieName(activeSplitTest.id)

  cookie(cookieName, activeSplitTest)
}

/**
 * This helper function gets a random group with probabilities.
 * E.g. 2 variant:
 * - variant 1 with a weight of 9
 * - variant 2 with a weight of 1
 * Variant 1 has 90% probability and variant 2 10%.
 * @param {Object[]} totalVariants
 * @returns {number}
 */
export function getRandomGroupWithProbability(totalVariants = [{ weight: 3 }, { weight: 1 }]) {
  const totalValues = totalVariants.reduce((acc, variant, index) => {
    let currentValues = []

    for (let i = 0; i < variant.weight; i++) {
      currentValues.push(index)
    }

    return [ ...acc, ...currentValues ]
  }, [])
  const id = Math.floor(Math.random() * totalValues.length)

  return totalValues[id]
}
