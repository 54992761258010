import cloneDeep from 'lodash.clonedeep'

/**
 * @typedef {FilterHelperService}
 * @alias this.$filterHelperService
 */
export class FilterHelperService {
  /**
   * @param {Object} allFilters
   * @param {Object} [availableFilters]
   * @param {Object} [checkedFilters]
   * @returns {Object}
   */
  formatFilters(allFilters, availableFilters, checkedFilters) {
    const filtersKeys = allFilters ? Object.keys(allFilters) : []

    return filtersKeys.map(filterKey => {
      const filter = allFilters[filterKey]
      const availableFilter = availableFilters && availableFilters[filterKey]
      const checkedFilter = checkedFilters && checkedFilters[filterKey]

      return {
        key: filterKey,
        value: new Filter(filter, availableFilter, checkedFilter),
      }
    })
      .reduce(( acc, cur ) => {
        acc[cur.key] = cur.value.data
        return acc
      }, {})
  }
}

class Filter {
  constructor(filter, availableFilter, checkedFilter) {
    this.data = cloneDeep(filter)

    this.formatFilterItems()

    if (availableFilter) {
      this.enableFilterItems(availableFilter)
    }

    if (checkedFilter) {
      this.checkFilterItems(checkedFilter)
    }
  }

  formatFilterItems() {
    this.data.forEach(item => {

      return Object.assign(item, {
        checked: false,
        enabled: false,
        doc_count: 0,
      })
    })
  }

  enableFilterItems(enabledFilter) {
    enabledFilter.forEach(enabledItem => {

      return this.data.some(item => {
        if (enabledItem.key === item.key) {
          item.enabled = true
          item.doc_count = enabledItem.doc_count
          return true
        }
      })
    })
  }

  checkFilterItems(checkedFilter) {
    checkedFilter.forEach(selectedValue => {

      return this.data.some(item => {
        if (selectedValue === item.key) {
          item.checked = true
          item.enabled = true
          return true
        }
      })
    })
  }
}
