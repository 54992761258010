import Router from 'vue-router'
import mainCategories from '~/static/data/categories/index.json'
import subCategories from '~/static/data/sub-categories/index.json'
import subSubCategories from '~/static/data/sub-sub-categories/index.json'
import translatedDatoSlugs from '~/static/data/cms/translated-slugs.json'
import { locales } from '@cmsData/app.json'

export const CATEGORY_PAGE_NAME = 'category'
const CategoryPage = () => import('~/pages/category/_slug').then(module => module.default)
const AppReturnsPage = () => import('~/pages/app/returns/index').then(module => module.default)

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const routes = [
    /*
     * All category pages
     * First in routes array, to match before product route
    */
    ...locales.map(locale => ({
      path: `${getBaseCategoryRoutePath(locale)}`,
      component: CategoryPage,
      name: `${CATEGORY_PAGE_NAME}-slug___${locale.code}`,
    })),

    // Filter out routes with custom implementation
    ...routerOptions.routes.filter(
      route =>
        !route.name?.startsWith(`${CATEGORY_PAGE_NAME}___`) &&
        !route.name?.startsWith(`${CATEGORY_PAGE_NAME}-slug___`),
    ),

    ...locales.map(locale => ({
      path: `/${locale.code}/app/${translatedDatoSlugs['returns'][locale.code]}`,
      component: AppReturnsPage,
      name: `app-returns-slug___${locale.code}`,
    })),
  ]

  const router = new Router({
    ...routerOptions,
    routes,
  })

  router.beforeEach((to, from, next) => {
    // Used for Bloomreach page_visit tracking. By default, Nuxt doesn't
    // update document.referrer after navigation.
    if (process.client && from.name) {
      Object.defineProperty(
        document,
        'referrer',
        {
          configurable: true,
          get: () => {
            return window.location.origin + from.path
          },
        }
      )
    }

    next()
  })

  return router
}

function getBaseCategoryRoutePath(locale) {
  const localizedSubSubCategoryUrls = subSubCategories[locale.code]?.map(category => category.url)
  const localizedSubCategoryUrls = subCategories[locale.code]?.map(category => category.url)
  const localizedMainCategoryUrls = mainCategories[locale.code]?.map(category => category.url)

  const combinedCategoryUrls = [
    ...localizedSubSubCategoryUrls,
    ...localizedSubCategoryUrls,
    ...localizedMainCategoryUrls,
  ]
  const encodedCombinedUrls = combinedCategoryUrls.map(entry => encodeURIComponent(entry))
  const combinedUrls = [
    ...combinedCategoryUrls,
    ...encodedCombinedUrls,
  ]

  return `/${locale.code}/:slug(${combinedUrls?.join('|')})` ?? ''
}
