
  import { mapState } from 'vuex'

  import matchMedia from '~/mixins/match-media'

  export default {
    name: 'CheckoutLayout',
    mixins: [matchMedia],
    computed: {
      ...mapState({
        showChatButton: state => state.showChatButton,
      }),
    },
    mounted() {
      this.$nuxt.isHydrated = true
    },
  }
