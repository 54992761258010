/**
 * @typedef {NewsletterApiService}
 * @alias this.$newsletterApiService
 */
export class NewsletterApiService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
  }

  init() {
    this.magentoApiService = this.context.$magentoApiService
  }

  /**
   * @param {string} [newsletterSubscriptionTag]
   * @returns {Promise}
   */
  subscribe(newsletterSubscriptionTag) {
    return this.magentoApiService
      .post('/newsletter/mine/subscribe', {
        ...(newsletterSubscriptionTag && { context: newsletterSubscriptionTag }),
      })
  }

  /**
   * @param {string} emailAddress
   * @param {string} [newsletterSubscriptionTag]
   * @returns {Promise}
   */
  subscribeWithEmail({ emailAddress, newsletterSubscriptionTag }) {
    return this.magentoApiService
      .post('/newsletter/subscribe', {
        email: emailAddress,
        ...(newsletterSubscriptionTag && { context: newsletterSubscriptionTag }),
      })
  }

  /**
   * @returns {Promise}
   */
  unsubscribe() {
    return this.magentoApiService
      .post('/newsletter/mine/unsubscribe')
  }

  /**
   * @param {string} emailAddress
   * @param {string} sku
   * @returns {Promise}
   */
  subscribeToStockNotification({ emailAddress, sku }) {
    return this.magentoApiService
      .post('/in-stock-notify/subscribe-by-sku', {
        email: emailAddress,
        sku,
      })
  }

  /**
   * @param {string} emailAddress
   * @returns {Promise<number>}
   */
  getSubscriptionStatus(emailAddress) {
    return this.magentoApiService
      .post('/newsletter/status', { email: emailAddress })
      .then(response => response.data?.subscription_status)
  }
}
