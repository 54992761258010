
  export default {
    props: {
      isRight: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        required: true,
      },
    },
    emits: ['arrow-click'],
    computed: {
      arrow() {
        return this.isRight
          ? 'arrow-right'
          : 'arrow-left'
      },
    },
  }
