/**
 * @return {null|object}
 */
export default () => {
  if (!process.env.filterOnOnlineFromDate) {
    return null
  }

  return {
    range: {
      online_from_date: {
        lte: 'now',
      },
    },
  }
}
