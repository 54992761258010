export default function (video) {
  if (!video) {
    return false
  }

  return (
    typeof(video.width) === 'number'
    && typeof(video.height) === 'number'
    && video.url
  )
}
