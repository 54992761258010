import removeEmptyObjectValues from '~/lib/remove-empty-object-values'
import getCountryId from '~/lib/get-country-id'

/**
 * @typedef UserApiMapperService
 * @alias this.$userApiMapperService
 */
export class UserApiMapperService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.store = context.store
  }

  /**
   * @param {Object} address
   * @param {number} [saveInAddressBook]
   * @returns {Object}
   */
  mapAddressToApi(address, saveInAddressBook) {
    // Magento API doesn't accept key values with undefined or null values
    const clearedAddress = removeEmptyObjectValues(address)
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const email = isAuthenticated ? this.store.state.user.email : clearedAddress?.email
    const country_id = clearedAddress?.country_id || getCountryId(this.store.state.localization.country)
    const customer_address_id = this.#getCustomerAddressId(clearedAddress)

    return {
      firstname: clearedAddress?.firstname,
      lastname: clearedAddress?.lastname,
      city: clearedAddress?.city,
      region: clearedAddress?.region,
      postcode: clearedAddress?.postcode,
      street: clearedAddress?.street,
      saveInAddressBook: saveInAddressBook,
      country_id,
      email,
      customer_address_id,
    }
  }

  /**
   * @param {Object} address
   * @returns {number|undefined}
   */
  #getCustomerAddressId(address) {
    if (
      !this.store.getters['user/isAuthenticated']
      || !this.store.getters['user/getAddressById'](address.id)
    ) {
      return
    }

    return address.id
  }

  /**
   * @param {Object} address
   * @param {boolean} [addressFromUserAccount]
   * @returns {Object}
   */
  mapAddressFromApi(address, addressFromUserAccount = true) {
    return removeEmptyObjectValues({
      firstname: address?.firstname,
      lastname: address?.lastname,
      city: address?.city,
      region: this.#getRegion(address, addressFromUserAccount),
      country_id: address?.country_id,
      postcode: address?.postcode,
      street: [
        ...(address?.street || []),
      ],
      email: address?.email,
      id: this.#getAddressId(address, addressFromUserAccount),
      telephone: address?.telephone,
      default_billing: address?.default_billing,
      default_shipping: address?.default_shipping,
    })
  }

  /**
   * If an address originates from the users account, region is saved in an object.
   * If the address originates from the cart information the region is saved in
   * separate key values without the parent 'region' object
   * @param {Object} address
   * @param {boolean} addressFromUserAccount
   * @returns {string|undefined}
   */
  #getRegion(address, addressFromUserAccount) {
    return addressFromUserAccount ? address?.region?.region_code : address?.region_code
  }

  /**
   * If an address originates from the users account, the id is the saved address id.
   * If the address originates from the cart information the address id is a temporary id
   * which can't be used for the estimate-shipping-methods-by-address-id endpoint.
   * Therefore, there must be a check to determine of the id must be mapped or not.
   * @param {Object} address
   * @param {boolean} addressFromUserAccount
   * @returns {number|null}
   */
  #getAddressId(address, addressFromUserAccount) {
    if (address.customer_address_id) {
      return address.customer_address_id
    }

    return addressFromUserAccount ? address.id : null
  }
}
