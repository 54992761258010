
  import { mapState, mapGetters } from 'vuex'

  import chatMixin from '~/mixins/chat'

  export default {
    mixins: [chatMixin],
    props: {
      inline: {
        type: Boolean,
        default: false,
      },
      simple: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState({
        activatorIsOpen: state => state['newsletter-toast'].activatorIsOpen,
        cookiesAccepted: state => state.cookiesAccepted,
        isMobile: state => state.isMobile,
        userIsBlocked: state => state.user.isBlocked,
        socialRetailIsActive: state => state.socialRetailIsActive,
      }),
      ...mapGetters({
        currentRoutePathIsCheckoutPage: 'routes/currentRoutePathIsCheckoutPage',
      }),
      hasSpaceConflict() {
        return (this.isMobile && (this.activatorIsOpen || this.socialRetailIsActive))
          || (!this.isMobile && !this.cookiesAccepted)
      },
      iconName() {
        return this.chatIsOnline ? 'chat-active' : 'chat'
      },
    },
  }
