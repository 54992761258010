import axios from 'axios'

/**
 * @typedef LoaviesClusterApiService
 * @alias this.$loaviesClusterApiService
 */
export class LoaviesClusterApiService {
  constructor(
    context
  ) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.loaviesClusterBaseUrl = context.env.loaviesClusterBaseUrl

    this.instance = axios.create({
      baseURL: this.loaviesClusterBaseUrl,
      headers: {
        'Content-Language': this.context.i18n.locale,
      },
    })
  }

  /**
   * @param {string} url
   * @param {*} [config]
   * @returns {Promise}
   */
  get(url, config) {
    return this.instance.get(url, config)
  }

  /**
   * @param {string} url
   * @param {Object} [payload]
   * @param {*} [config]
   * @returns {Promise}
   */
  post(url, payload = {}, config) {
    return this.instance.post(url, payload, config)
  }

  /**
   * @param {string} url
   * @param {object} [payload]
   * @param {*} [config]
   * @returns {Promise}
   */
  patch(url, payload = {}, config) {
    return this.instance.patch(url, payload, config)
  }

  /**
   * @param {string} url
   * @param {object} [payload]
   * @param {*} [config]
   * @returns {Promise}
   */
  put(url, payload = {}, config) {
    return this.instance.put(url, payload, config)
  }

  /**
   * @param {string} url
   * @param {*} [config]
   * @returns {Promise}
   */
  delete(url, config) {
    return this.instance.delete(url, config)
  }
}
