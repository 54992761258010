/**
 * @typedef GiftcardService
 * @alias this.$giftcardService
 */
export class GiftcardService {
  constructor(
    context
  ) {
    /** @type {ServerNuxtContext} */
    this.context = context
  }

  init() {
    this.$loaviesClusterApiService = this.context.$loaviesClusterApiService
  }

  /**
   * @param {string} cardNumber
   * @param {string} pinCode
   * @param {string} recaptchaToken
   * @return {Promise<{
   *   balance: {number},
   *   cardId: {string},
   *   currency: {string},
   *   expiryDate: {string},
   * }>}
   */
  getBalance({ cardNumber, pinCode, recaptchaToken }) {
    return this.$loaviesClusterApiService
      .post(
        '/giftcard/api/v1/card/balance',
        {
          cardNumber,
          pinCode,
          recaptchaToken,
          action: 'giftcardBalance',
        }
      )
      .then(data => data.data.data)
  }
}
