
  import { mapState } from 'vuex'

  import {
    FLY_IN,
    NAVIGATION_FLY_IN_NAME,
  } from '~/lib/constants'

  export default {
    computed: {
      ...mapState({
        previousRouteName: state => state.routes.previousRouteName,
        currentRouteIsProductPage: state => state.currentRouteIsProductPage,
        currentProductPrimaryCategory: state => state.currentProductPrimaryCategory,
      }),
      isPdpPageWithBackRoute() {
        return this.currentRouteIsProductPage && this.previousRouteName
      },
      isPdpWithPrimaryCategory() {
        return this.currentRouteIsProductPage && this.currentProductPrimaryCategory
      },
    },
    methods: {
      onMenuButtonClick() {
        const { locale } = this.$i18n

        // If user comes from category, go back to the category
        if (this.isPdpPageWithBackRoute) {
          return this.$router.go(-1)
        }

        // If user comes from external source and product has primary_category_crumbs,
        // go back to primary category
        if (this.isPdpWithPrimaryCategory) {
          return this.$router.push(this.localePath(this.currentProductPrimaryCategory)).catch(() => {})
        }

        // If user comes from external source and product doesn't have a primary
        // category, go back to /clothing or translated equivalent
        if (this.currentRouteIsProductPage) {
          return this.$router.push(`/${this.$i18n.locale}/${this.$staticDataService.getFirstMainCategoryByLocale(locale)?.url}/`).catch(() => {})
        }

        this.$overlayService.setCurrentOverlay({
          settings: {
            type: FLY_IN,
            component: NAVIGATION_FLY_IN_NAME,
          },
        })
      },
    },
  }
