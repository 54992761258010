
  import cookie from '~/lib/cookie'

  const FIRST_INSTANCE_INDEX = 0

  export default {
    props: {
      popupKey: {
        type: String,
        required: true,
      },
      isLeft: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: false,
        showObserver: null,
      }
    },
    computed: {
      onboardingPopups() {
        return this.$staticDataService.cmsLayoutsDefault?.onboardingPopups
      },
      text() {
        return this.onboardingPopups?.[this.popupKey]
      },
      popupCookieName() {
        return `${this.popupKey}_popup_is_shown`
      },
    },
    mounted() {
      this.addShowObserver()
    },
    beforeDestroy() {
      this.removeShowObserver()
    },
    methods: {
      addShowObserver() {
        if (this.showObserver || !this.$parent.$el) {
          return
        }

        const showObserver = new IntersectionObserver(this.setShow)

        /*
          We need to observe the parent since we don't event want to render the popup
          if the parent element is not the first instance on the page or if a cookie
          for this certain popup is already present.
        */
        showObserver.observe(this.$parent.$el)

        this.showObserver = showObserver
      },
      removeShowObserver() {
        this.showObserver?.disconnect
        this.showObserver = null
      },
      setShow(entries) {
        const { isIntersecting } = entries[0]
        const isShownCookie = cookie(this.popupCookieName)

        if (!this.getIsFirstInstance || isShownCookie) {
          this.removeShowObserver()
          return
        }

        if (isIntersecting) {
          this.showAndSetCookie()
          this.removeShowObserver()
        }
      },
      getIsFirstInstance() {
        const firstParentClass = this.$parent.$el.classList[0]
        let parentElements = document.getElementsByClassName(firstParentClass)

        /*
          There are some cases in which the element is appended to the DOM but not visible
          on the page. Since it wouldn't make sense to show a pop-up next to an invisible element
          we have to filter out the invisible parent elements before checking the first index.
        */
        let visibleInstances = Array.from(parentElements).filter(isVisibleElement)
        let isFirstInstanceIndex = visibleInstances.indexOf(this.$parent.$el) === FIRST_INSTANCE_INDEX

        // Prevent memory leaks
        parentElements = null
        visibleInstances = null

        return isFirstInstanceIndex
      },
      showAndSetCookie() {
        this.show = true
        cookie(this.popupCookieName, true)
      },
    },
  }

  function isVisibleElement(element) {
    return element.offsetHeight !== 0 && element.offsetWidth !== 0
  }
