import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'

import { EmailValidatorService } from '~/services/validators/email-validator-service'
import { ADYEN_GIFT_CARD_PAYMENT_METHOD_TYPE } from '~/lib/constants'

const emailValidatorService = new EmailValidatorService()

export default {
  /**
   * @param state
   * @returns {number}
   */
  cartItemsQuantity(state) {
    return state.cartItems.reduce((total, item) => total + item.qty, 0)
  },

  /**
   * @param state
   * @returns {boolean}
   */
  shippingAddressIsValid(state) {
    return Boolean(state.shippingAddress?.city
      && state.shippingAddress?.country_id
      && state.shippingAddress?.postcode
      && state.shippingAddress?.street?.[0] // Street
      // TEMP fix until addresses are fixed in Magento
      // && state.shippingAddress?.street?.[1] // House number
      && state.shippingAddress?.firstname
      && state.shippingAddress?.lastname)
  },

  /**
   * @param state
   * @returns {boolean}
   */
  selectedShippingMethodIsValid(state) {
    if (state.cartIsVirtual) {
      return true
    }

    return state.selectedShippingMethod !== null
  },

  /**
   * @param state
   * @returns {boolean}
   */
  selectedShippingMethodDetailIsValid(state) {
    if (state.cartIsVirtual) {
      return true
    }

    if (!state.selectedShippingMethod) {
      return false
    }

    if (state.selectedShippingMethod?.extension_attributes) {
      return state.selectedServicePoint !== null || state.selectedDeliveryMoment !== null
    }

    // Shipping method is selected but doesn't have extension_attributes
    return true
  },

  /**
   * @param state
   * @returns {boolean}
   */
  selectedPaymentMethodIsValid(state) {
    return state.selectedPaymentMethod !== null
  },

  /**
   * Guest email address is stored in shipping address
   * @param state
   * @param getters
   * @param rootState
   * @returns {boolean}
   */
  emailIsValid(state, getters, rootState) {
    return emailValidatorService.isValid(state.shippingAddress?.email) || emailValidatorService.isValid(rootState.user.email)
  },

  /**
   * @param state
   * @param getters
   * @param rootState
   * @returns {string|null}
   */
  shippingAddressEmail(state, getters, rootState) {
    return state.shippingAddress?.email ?? rootState.user.email ?? null
  },

  /**
   * @param state
   * @returns {function(string): Object}
   */
  getCartItemBySku: (state) => (sku) => {
    return state.cartItems.find(cartItem => cartItem.sku === sku)
  },

  /**
   * @param state
   * @returns {string[]}
   */
  getEntityIdsFromCartItems(state) {
    return state.cartItems.map(cartItem => cartItem.product.entity_id)
  },

  /**
   * @param state
   * @returns {Object[]}
   */
  getProductListFromCartItems(state) {
    return state.cartItems.map(cartItem => {
      return {
        product_id: cartItem.product.entity_id,
        product_sku: getPrimarySkuFromProduct(cartItem.product),
        sku: cartItem.sku,
        quantity: cartItem.qty,
      }
    })
  },

  /**
   * @param state
   * @param getters
   * @returns {string[]}
   */
  getPrimarySkusFromCartItems(state, getters) {
    return getters.getProductListFromCartItems.map(item => item.product_sku)
  },

  /**
   * @param state
   * @param getters
   * @returns {string[]}
   */
  getSizeSkusFromCartItems(state, getters) {
    return getters.getProductListFromCartItems.map(item => item.sku)
  },

  /**
   * @param state
   * @returns {loavies.models.payment.PaymentMethodModel[]}
   */
  getGiftCardPaymentMethods(state) {
    return state.paymentMethods.filter(paymentMethod => paymentMethod.type === ADYEN_GIFT_CARD_PAYMENT_METHOD_TYPE)
  },
}
