/**
 * @typedef {ProductHighlightApiService}
 * @alias this.$productHighlightApiService
 */
export class ProductHighlightApiService {
    constructor(context) {
        /** @type {ServerNuxtContext} */
        this.context = context
        this.store = context.store
    }

    init() {
        this.$magentoApiService = this.context.$magentoApiService
    }

    /**
     * @typedef ProductHighlight
     * @type {Object}
     * @property {string} sku
     * @property {boolean} active_status
     * @property {number} quantity
     * @property {number} purchased_count_today
     * @property {number} purchased_count_last_period
     * @property {number} views_count_last_hour
     * @property {number} views_count_today
     * @property {number} views_count_period
     * @property {number} wishlist_count_last_hour
     * @property {number} wishlist_count_today
     * @property {number} wishlist_count_period
     * @property {number} cart_count_last_hour
     * @property {number} cart_count_today
     * @property {number} cart_count_period
     * @property {number} happy_count
     * @property {boolean} good_fit
     * @property {boolean} hot_drop
     * @property {boolean} sold_out_soon
     * @property {boolean} new_in_stock
     * @property {number} best_seller_all_time
     * @property {number} best_seller_long
     * @property {number} best_seller_medium
     * @property {number} best_seller_short
     */

    /**
     * @param {string[]} skus
     * @return {Promise<ProductHighlight[]>}
     */
    getHighlightsBySkus(skus) {
        return this.$magentoApiService
            .post('/products/highlights', {
                sku: skus,
            })
            .then(response => response.data)
    }
}
