import { CATEGORY_PAGE_NAME } from '~/router'
import cookie from '~/lib/cookie'
import getCategoryPageUrl from '~/lib/get-category-page-url'

/**
 * @typedef {LocalizationService}
 * @alias this.$localizationService
 */
export class LocalizationService {
  // https://www.netlify.com/docs/redirects/#geoip-and-language-based-redirects
  #netlifyLocalizationCookieName = 'nf_country'

  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
    this.localePath = context.localePath
  }

  /**
   * @param {Object} currentRoute Nuxt route object
   * @param {string} locale
   */
  switchLocale({ currentRoute, locale }) {
    cookie(this.#netlifyLocalizationCookieName, locale)

    window.location = this.#getLocation({ currentRoute, locale })
  }

  /**
   * @param {Object} currentRoute
   * @param {string} locale
   */
  #getLocation({ currentRoute, locale } = {}) {
    const currentRouteName = currentRoute.name.split('___')[0]

    if ([CATEGORY_PAGE_NAME, `${CATEGORY_PAGE_NAME}-slug`].includes(currentRouteName)) {
      const categoryUrlPath = this.store.state.localization.i18nPageParams[locale].url_path

      return getCategoryPageUrl(locale, categoryUrlPath, currentRoute.query)
    }

    return this.localePath({
      name: currentRouteName,
      params: {
        ...currentRoute.params,
        ...this.store.state.localization.i18nPageParams[locale],
      },
      query: {
        ...currentRoute.query,
      },
    }, locale)
  }
}
