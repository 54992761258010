import {
  DEFAULT_CATEGORY_PAGE_PRODUCT_SIZE,
  DEFAULT_CATEGORY_FIRST_PAGE,
} from '~/lib/constants'
import cloneDeep from 'lodash.clonedeep'
import getPrimarySkuFromProduct from '@/lib/get-primary-sku-from-product'

/**
 * @typedef {ProductBundleService}
 * @alias this.$productBundleService
 */
export class ProductBundleService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$productApiService = this.context.$productApiService
    this.$productHighlightService = this.context.$productHighlightService
  }

  /**
   * @param {Object[]} rawProductBundles
   * @returns {Promise}
   */
  async convertRawProductAndStore(rawProductBundles) {
    const hitsSize = this.store.state.category.categoryOptions?.hitsSize ?? DEFAULT_CATEGORY_PAGE_PRODUCT_SIZE
    const currentPage = this.store.state.category.categoryOptions?.page ?? DEFAULT_CATEGORY_FIRST_PAGE

    let remainingTotalProductsToShow = hitsSize * currentPage
    const enrichedProductBundles = []

    for (const productBundle of rawProductBundles) {
      const productBundleCopy = cloneDeep(productBundle)

      // Get products and counts
      const data = await this.$productApiService
        .getProductsAndAvailableFiltersByCategoryId(
          productBundle.category.id,
          {
            hitsSize: remainingTotalProductsToShow,
          },
        )
        .catch(error => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(`Failed to fetch products`, error)
          }
        })

      const products = data?.products

      if (!products) {
        continue
      }

      this.$productHighlightService.fetchHighlightsBySkus(products.map(product => getPrimarySkuFromProduct(product)))

      productBundleCopy.products = products
      enrichedProductBundles.push(productBundleCopy)

      // Check response products count
      let currentProductsCount

      if (products.length % 2 !== 0) {
        currentProductsCount = products.length - 1
      } else {
        currentProductsCount = products.length
      }

      // Check if more products must be shown
      remainingTotalProductsToShow -= currentProductsCount

      if (remainingTotalProductsToShow === 0) {
        break
      }
    }

    return this.store.dispatch('category/setProductBundles', enrichedProductBundles)
  }
}
