export default {
  SET_CATEGORY_PRODUCTS_LOADING(state, { isLoading }) {
    state.categoryProductsLoading = isLoading
  },

  SET_CATEGORY_PRODUCTS(state, { categoryProducts }) {
    state.categoryProducts = categoryProducts
  },

  SET_CATEGORY_OPTIONS(state, { categoryOptions }) {
    state.categoryOptions = categoryOptions
  },

  UPDATE_CATEGORY_OPTIONS(state, { updates }) {
    state.categoryOptions = Object.assign({}, state.categoryOptions, updates)
  },

  SET_ACTIVE_CATEGORY(state, { activeCategory }) {
    state.activeCategory = activeCategory
  },

  SET_ALL_FILTERS(state, { allFilters }) {
    state.allFilters = allFilters
  },

  SET_AVAILABLE_FILTERS(state, { availableFilters }) {
    state.availableFilters = availableFilters
  },

  SET_CATEGORY_PRODUCTS_COUNT(state, { categoryProductsCount }) {
    state.categoryProductsCount = categoryProductsCount
  },

  SET_PREVIOUS_CATEGORY_URL(state, { previousCategoryUrl }) {
    state.previousCategoryUrl = previousCategoryUrl
  },

  SET_APPLIED_FILTERS_COUNT(state, { appliedFiltersCount }) {
    state.appliedFiltersCount = appliedFiltersCount
  },

  SET_CATEGORY_PAGE_MODULAR_CONTENT(state, payload) {
    state.categoryPageModularContent = payload
  },

  SET_SINGLE_CATEGORY_PAGE_MODULAR_CONTENT(state, payload) {
    state.singleCategoryPageModularContent = payload
  },

  SET_ANCHOR_INDEX(state, { anchorIndex }) {
    state.anchorIndex = anchorIndex
  },

  SET_CHECKED_FILTERS(state, { checkedFilters }) {
    state.checkedFilters = checkedFilters
  },

  SET_SORT_OPTIONS(state, { sortOptions }) {
    state.sortOptions = sortOptions
  },

  SET_PRODUCT_BUNDLES(state, productBundles) {
    state.productBundles = productBundles
  },

  SET_COMBINED_PRODUCT_BUNDLES(state, { combinedProductBundles }) {
    state.combinedProductBundles = combinedProductBundles
  },

  SET_PRODUCTS_PER_ROW_MOBILE(state, payload) {
    state.productsPerRowMobile = payload
  },
}
