import { render, staticRenderFns } from "./media-item.vue?vue&type=template&id=704bc3ba"
import script from "./media-item.vue?vue&type=script&lang=js"
export * from "./media-item.vue?vue&type=script&lang=js"
import style0 from "./media-item.vue?vue&type=style&index=0&id=704bc3ba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveImage: require('/opt/build/repo/src/client/components/responsive-image/responsive-image.vue').default,VideoPlayer: require('/opt/build/repo/src/client/components/video-player/video-player.vue').default,FixedRatio: require('/opt/build/repo/src/client/components/fixed-ratio/fixed-ratio.vue').default,ShopTheLookButton: require('/opt/build/repo/src/client/components/shop-the-look-button/shop-the-look-button.vue').default,SmartLink: require('/opt/build/repo/src/client/components/smart-link/smart-link.vue').default})
