export default {
  SET_ACTIVE_APP_MODAL(state, { activeAppModal }) {
    state.activeAppModal = activeAppModal
  },

  SET_ACTIVE_APP_MODAL_TRANSITION(state, { activeAppModalTransition }) {
    state.activeAppModalTransition = activeAppModalTransition
  },

  SET_ACTIVE_APP_MODAL_OPTIONS(state, { activeAppModalOptions }) {
    state.activeAppModalOptions = activeAppModalOptions
  },

  SET_ACTIVE_APP_TRAY(state, { activeAppTray }) {
    state.activeAppTray = activeAppTray
  },

  SET_ACTIVE_APP_TRAY_TRANSITION(state, { activeAppTrayTransition }) {
    state.activeAppTrayTransition = activeAppTrayTransition
  },

  SET_ACTIVE_APP_TRAY_OPTIONS(state, { activeAppTrayOptions }) {
    state.activeAppTrayOptions = activeAppTrayOptions
  },

  SET_ACTIVE_FLY_IN(state, { activeFlyIn }) {
    state.activeFlyIn = activeFlyIn
  },

  SET_ACTIVE_FLY_IN_TRANSITION(state, { activeFlyInTransition }) {
    state.activeFlyInTransition = activeFlyInTransition
  },

  SET_ACTIVE_FLY_IN_OPTIONS(state, { activeFlyInOptions }) {
    state.activeFlyInOptions = activeFlyInOptions
  },

  SET_CURRENT_OVERLAY(state, { desktop, mobile, settings }) {
    state.desktop = desktop
    state.mobile = mobile
    state.settings = settings
  },

  CLOSE_APP_MODAL(state) {
    state.activeAppModal = null
    state.activeAppModalTransition = null
    state.activeAppModalOptions = null
    state.desktop = null
    state.mobile = null
    state.settings = null
  },

  CLOSE_APP_TRAY(state) {
    state.activeAppTray = null
    state.activeAppTrayTransition = null
    state.activeAppTrayOptions = null
    state.desktop = null
    state.mobile = null
    state.settings = null
  },

  CLOSE_FLY_IN(state) {
    state.activeFlyIn = null
    state.activeFlyTransition = null
    state.activeFlyInOptions = null
    state.desktop = null
    state.mobile = null
    state.settings = null
  },
}
