
  export default {
    props: {
      link: {
        type: Object,
        required: true,
      },
      image: {
        type: Object,
        required: true,
      },
      products: {
        type: Array,
        default: null,
      },
    },
  }
