import { parse, stringify } from 'query-string'
import cloneDeep from 'lodash.clonedeep'

import getDatoToNuxtRoute from '~/lib/get-dato-to-nuxt-route'
import getCategoryPageUrl from '~/lib/get-category-page-url'
import {
  APP_MODAL,
  APP_TRAY,
  QUIZ_MODAL,
  QUIZ_TRAY,
} from '~/lib/constants'

export default {
  props: {
    link: {
      type: Object,
      required: false,
      validator: isValidLink,
    },
    query: {
      type: Object,
      default: null,
    },
  },
  computed: {
    linkLabel() {
      return this.link?.label
    },
    nuxtRoute() {
      if (this.link?.datoModel?.__typename === 'quiz') {
        return null
      } else if (this.link?.datoModel) {
        const nuxtRoute = getDatoToNuxtRoute(this.link.datoModel)

        return this.localePath({
          ...cloneDeep(nuxtRoute),
          query: {
            ...cloneDeep(this.query),
          },
        })
      } else if (this.link?.productSlug?.length) {
        return this.localePath({
          name: 'product',
          params: {
            product: this.link.productSlug,
          },
          query: {
            ...cloneDeep(this.query),
          },
        })
      } else if (this.link?.categoryUrlPath?.length) {
        return getCategoryPageUrl(this.$i18n.locale, this.link.categoryUrlPath, this.query)
      }

      return null
    },
    externalUrl() {
      return this.link?.externalUrl
    },
    externalUrlWithQuery() {
      const searchIndex = this.externalUrl.indexOf('?')
      const url = searchIndex !== -1 ? this.externalUrl.substring(0, searchIndex) : this.externalUrl
      const queryString = searchIndex !== -1 ? this.externalUrl.substring(searchIndex) : ''
      const parsed = {
        url: url,
        query: parse(queryString),
      }

      parsed.query = {
        ...cloneDeep(parsed.query),
        ...cloneDeep(this.query),
      }

      const stringifiedQuery = stringify(parsed.query, { skipNull: true, skipEmptyString: true })

      return `${parsed.url}?${stringifiedQuery}`
    },
    internalUrl() {
      if (this.link?.datoModel?.__typename === 'quiz') {
        return true
      }

      return this.link?.internalUrl
    },
  },
  methods: {
    closeOverlays() {
      this.$overlayService.closeAppModal()
      this.$overlayService.closeAppTray()
      this.$overlayService.closeFlyIn()
    },
    async navigateInternal() {
      this.closeOverlays()

      if (this.link?.datoModel?.__typename === 'quiz') {
        return this.$overlayService.setCurrentOverlay({
          settings: {
            options: {
              quizId: this.link.datoModel.id,
            },
          },
          mobile: {
            type: APP_TRAY,
            component: QUIZ_TRAY,
          },
          desktop: {
            type: APP_MODAL,
            component: QUIZ_MODAL,
          },
        })
      }

      // For internal linking
      if (this.internalUrl?.startsWith('/')) {
        return this.$router.push(this.localePath(this.internalUrl)).catch(() => {})
      }

      // For internal linking to query parameters but query parameters
      // are already active. They need to be removed.
      if (this.internalUrl?.startsWith('?') && this.$route.query) {
        await this.$router.replace({ query: {} }).catch(() => {})
      }

      // For internal linking to query parameters
      if (this.internalUrl?.startsWith('?')) {
        return this.$router.replace(this.internalUrl).catch(() => {})
      }
    },
    // This method can be used in other components than smart-link.vue to navigate programmatically.
    // See register-form.vue for an example
    handleLinkProgrammatically() {
      if (this.nuxtRoute) {
        return this.$router.push(this.nuxtRoute).catch(() => {})
      }

      if (this.externalUrl) {
        const url = this.query ? this.externalUrlWithQuery : this.externalUrl

        return window.open(url, '_blank')
      }

      if (this.internalUrl) {
        return this.navigateInternal()
      }
    },
  },
}

function isValidLink(link) {
  const isValid = (
    typeof(link) === 'object'
    && typeof(link.label) === 'string'
    && (link.datoModel || link.externalUrl || link.internalUrl || link.productSlug || link.categoryUrlPath)
    && (!link.datoModel || (link.datoModel && typeof(link.datoModel.__typename) === 'string'))
    && (!link.externalUrl || (typeof(link.externalUrl) === 'string' && link.externalUrl.length))
    && (!link.internalUrl || (typeof(link.internalUrl) === 'string' && link.internalUrl.length))
    && (!link.productSlug || (typeof(link.productSlug) === 'string' && link.productSlug.length))
    && (!link.categoryUrlPath || (typeof(link.categoryUrlPath) === 'string' && link.categoryUrlPath.length))
  )

  if (!isValid && process.env.NODE_ENV !== 'production') {
    console.error('SmartLink is not valid:', link)
  }

  return isValid
}
