import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'
import getPriceGroupFromStorefront from '~/lib/get-price-group-from-storefront'
import getPriceExcludingTax from '~/lib/get-price-excluding-tax'

export default {
  /**
   * @param state
   * @returns {number}
   */
  amountOfWishlistItems(state) {
    return Object.keys(state.wishlist).length
  },

  /**
   * @param state
   * @returns {Object[]}
   */
  wishlistItems(state) {
    const wishlistItems = Object.values(state.wishlist)
    const isMagentoWishlist = wishlistItems.every(item => item.itemId !== 'guest')

    if (isMagentoWishlist) {
      return wishlistItems.sort(descendingByWishlistItemId)
    }

    return wishlistItems.reverse()
  },

  /**
   * @returns {Object[]}
   */
  productListFromWishlistItems(state) {
    return Object.values(state.wishlist).map(product => {
      return {
        product_id: product.entity_id,
        product_sku: getPrimarySkuFromProduct(product),
        quantity: 1,
      }
    })
  },

  /**
   * @returns {string[]}
   */
  productEntityIdsFromWishlistItems(state) {
    return Object.values(state.wishlist).map(product => product.entity_id)
  },

  /**
   * @returns {number}
   */
  totalPriceOfWishlistInclTax(state, getters, rootState) {
    const customerGroupId = rootState.user.customerGroupId
    const currency = rootState.localization.currency

    return Object.values(state.wishlist).reduce((acc, product) => {
      const productPricesStorefront = product.prices_storefront
      const productPriceGroup = productPricesStorefront
        ? getPriceGroupFromStorefront(productPricesStorefront, customerGroupId, currency)
        : null
      const price = productPriceGroup ? productPriceGroup.price : 0

      acc += +price

      return Number(acc.toFixed(2))
    }, 0)
  },

  /**
   * @returns {number}
   */
  totalPriceOfWishlistExclTax(state, getters) {
    return getPriceExcludingTax(getters.totalPriceOfWishlistInclTax)
  },

  /**
   * @returns {string[]}
   */
  primarySkusFromWishlistItems(state) {
    return Object.values(state.wishlist).map(product => getPrimarySkuFromProduct(product))
  },
}

/**
 * @param {Object} current
 * @param {Object} next
 * @returns {number}
 */
function descendingByWishlistItemId(current, next) {
  if (next.itemId > current.itemId) {
    return 1
  }

  return -1
}
