
  export default {
    props: {
      link: {
        type: Object,
        required: true,
      },
      fullBlockClickable: {
        type: Boolean,
        default: false,
      },
    },
  }
