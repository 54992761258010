
  import { mapActions, mapState } from 'vuex'

  const SNACKBAR_TIMEOUT_DURATION = 2500

  export default {
    data() {
      return {
        showSnackbar: false,
        activeMessage: null,
      }
    },
    computed: {
      ...mapState({
        messages: state => state.snackbar.messages,
      }),
    },
    watch: {
      messages() {
        if (!this.activeMessage) {
          this.checkSnackbarMessages()
        }
      },
    },
    mounted() {
      this.checkSnackbarMessages()
    },
    methods: {
      ...mapActions({
        removeCurrentSnackbarMessage: 'snackbar/removeCurrentSnackbarMessage',
      }),
      checkSnackbarMessages() {
        if (this.messages.length > 0) {
          const timeout = this.messages[0]?.timeout || SNACKBAR_TIMEOUT_DURATION

          this.activeMessage = this.messages[0]
          this.removeCurrentSnackbarMessage()

          setTimeout(() => {
            this.activeMessage = null
          }, timeout)
        }
      },
    },
  }
