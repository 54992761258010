
  import {
    APP_TRAY,
    NEWSLETTER_APP_TRAY_NAME,
  } from '~/lib/constants'

  import CustomButtonItem from '~/components/custom-button-item/custom-button-item'

  export default {
    extends: CustomButtonItem,
    props:{
      newsletterItem:{
        type: Object,
        required: true,
      },
      newsletterSubscriptionTag:{
        type: String,
        default: null,
      },
      link: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      openOverlay() {
        this.$overlayService.setCurrentOverlay({
          settings: {
            type: APP_TRAY,
            component: NEWSLETTER_APP_TRAY_NAME,
            options: {
              newsletterSignupTitle: this.newsletterItem?.newsletterSignupTitle,
              newsletterSignupSubtitle: this.newsletterItem?.newsletterSignupSubtitle,
              newsletterSubscriptionTag: this.newsletterSubscriptionTag,
              showPhoneNumberField: this.newsletterItem?.showPhoneNumberField,
            },
          },
        })
      },
    },
  }
