
  import { mapState } from 'vuex'

  export default {
    props: {
      channelId: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapState({
        locale: state => state.i18n.locale,
      }),
    },
    mounted() {
      this.$bambuserService.initWidget()
    },
    beforeDestroy() {
      this.$bambuserService.removeWidget()
    },
  }
