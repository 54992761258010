
  import {
    APP_TRAY,
    NEWSLETTER_APP_TRAY_NAME,
  } from '~/lib/constants'

  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      subtitle: {
        type: String,
        default: '',
      },
    },
    computed: {
      defaultTitle() {
        return this.$t('keep_in_touch')
      },
      defaultSubtitle() {
        return this.$t('join_loavies_society_newsletter')
      },
      titleToUse() {
        return this.title || this.defaultTitle
      },
      subtitleToUse() {
        return this.subtitle || this.defaultSubtitle
      },
    },
    methods: {
      onButtonClick() {
        this.$overlayService.setCurrentOverlay({
          settings: {
            type: APP_TRAY,
            component: NEWSLETTER_APP_TRAY_NAME,
          },
        })
      },
    },
  }
