
  export default {
    props: {
      rootMargin: {
        type: String,
        default: '0px 0px 0px 0px',
      },
      threshold: {
        type: [Number, Array],
        default: 0,
      },
    },
    data() {
      return {
        isIntersected: false,
        observer: null,
      }
    },
    mounted() {
      this.observe()
    },
    beforeDestroy() {
      this.unobserve()
    },
    methods: {
      observe() {
        const { rootMargin, threshold } = this
        const config = { root: undefined, rootMargin, threshold }

        this.observer = new IntersectionObserver(this.onIntersection, config)
        this.observer.observe(this.$el)
      },
      onIntersection(entries) {
        this.isIntersected = entries.some(entry => entry.isIntersecting)

        if (this.isIntersected) {
          this.unobserve()
        }
      },
      unobserve() {
        this.observer?.disconnect()
        this.observer = null
      },
    },
  }
