import { render, staticRenderFns } from "./app-header.vue?vue&type=template&id=d23bd86c"
import script from "./app-header.vue?vue&type=script&lang=js"
export * from "./app-header.vue?vue&type=script&lang=js"
import style0 from "./app-header.vue?vue&type=style&index=0&id=d23bd86c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuButton: require('/opt/build/repo/src/client/components/menu-button/menu-button.vue').default,SvgIcon: require('/opt/build/repo/src/client/components/svg-icon/svg-icon.vue').default,AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default,AppLogo: require('/opt/build/repo/src/client/components/app-logo/app-logo.vue').default,FilterButton: require('/opt/build/repo/src/client/components/filter-button/filter-button.vue').default,AccountButton: require('/opt/build/repo/src/client/components/account-button/account-button.vue').default,IconWithCounter: require('/opt/build/repo/src/client/components/icon-with-counter/icon-with-counter.vue').default,NavigationDesktop: require('/opt/build/repo/src/client/components/navigation-desktop/navigation-desktop.vue').default})
