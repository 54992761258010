const ALTERNATIVE_IMAGE_IDENTIFIER = 'special_1'

/**
 * Returns first positioned image object from the media gallery
 * @param {Object} product
 * @param {boolean} [alternative]
 * @returns {Object|undefined} image
 */
export default (product, alternative = false) => {
  if (!product?.media_gallery || product.media_gallery?.length < 1) {
    return
  }

  if (alternative) {
    return product.media_gallery.find(image => image.magento_roles.includes(ALTERNATIVE_IMAGE_IDENTIFIER))
  }

  return product.media_gallery.find(image => image.position === '1') ?? product.media_gallery[0]
}
