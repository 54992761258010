
  export default {
    props: {
      textBlock: {
        type: Object,
        required: true,
      },
    },
    computed: {
      background() {
        if (this.textBlock.backgroundImage) {
          return `background-image: url(${this.textBlock.backgroundImage.url})`
        }
        if (this.textBlock.backgroundColor) {
          return `background-color: ${this.textBlock.backgroundColor.hex}`
        }

        return undefined
      },
    },
  }
