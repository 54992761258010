
  export default {
    props: {
      color: {
        type: Object,
        required: true,
      },
    },
    computed: {
      style() {
        return {
          background: this.color.rgbCode,
        }
      },
    },
  }
