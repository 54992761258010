export default {
  /**
   * @param context
   * @returns {Promise}
   */
  resetState(context) {
    context.commit('RESET_STATE')
  },

  /**
   * @param context
   * @param {Object} wallet
   * @returns {Promise}
   */
  setWallet(context, wallet) {
    context.commit('SET_WALLET', wallet)
  },

  /**
   * @param context
   * @param {number} payFromWalletAmountInCents
   * @returns {Promise}
   */
  setPayFromWalletAmountInCents(context, payFromWalletAmountInCents) {
    context.commit('SET_PAY_FROM_WALLET_AMOUNT_IN_CENTS', payFromWalletAmountInCents)
  },
}
