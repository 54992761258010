/**
 * @typedef UserAddressService
 * @alias this.$userAddressService
 */
export class UserAddressService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$userApiService = this.context.$userApiService
    this.$cartShippingAddressService = this.context.$cartShippingAddressService
  }

  /**
   * @param {Object} address
   * @returns {Promise}
   */
  addAddress(address) {
    return this.$userApiService
      .addAddress(address)
      .then(() => this.fetchAddresses())
  }

  /**
   * @param {Object} address
   * @returns {Promise}
   */
  updateAddress(address) {
    return this.$userApiService
      .updateAddress(address)
      .then(() => this.fetchAddresses())
  }

  /**
   * @param {number} addressId
   * @returns {Promise}
   */
  deleteAddress(addressId) {
    return this.$userApiService
      .deleteAddress(addressId)
      .then(() => this.fetchAddresses())
      .then(() => {
        if (this.store.state.checkout.shippingAddress?.id === addressId) {
          return this.$cartShippingAddressService.resetShippingAddress()
        }
      })
  }

  /**
   * @returns {Promise}
   */
  async fetchAddresses() {
    const { addresses } = await this.$userApiService.getUser()

    return this.store.dispatch('user/setUserAddresses', addresses)
  }
}
