export default {
  setCurrentRoutePath(context, currentRoutePath) {
    context.commit('SET_CURRENT_ROUTE_PATH', currentRoutePath)
  },

  setCurrentRouteName(context, currentRouteName) {
    context.commit('SET_CURRENT_ROUTE_NAME', currentRouteName)
  },

  setPreviousRouteName(context, routeName) {
    context.commit('SET_PREVIOUS_ROUTE_NAME', routeName)
  },

  setPreviousFullPath(context, fullPath) {
    context.commit('SET_PREVIOUS_FULL_PATH', fullPath)
  },

  setPreviousLayout(context, previousLayout) {
    context.commit('SET_PREVIOUS_LAYOUT', previousLayout)
  },

  setCurrentLayout(context, currentLayout) {
    context.commit('SET_CURRENT_LAYOUT', currentLayout)
  },
}
