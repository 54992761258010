
  export default {
    props: {
      countdownTarget: {
        type: String,
        required: true,
      },
      hideDays: {
        type: Boolean,
        default: false,
      },
      hideUnits: {
        type: Boolean,
        default: false,
      },
      countdownColor: {
        type: Object,
        required: true,
      },
      digitsFontSize: {
        type: String,
        default: null,
      },
      unitFontSize: {
        type: String,
        default: null,
      },
      hasUnitSeparators: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['countdown-finished'],
    data() {
      return {
        timeRemaining: null,
        timer: null,
      }
    },
    computed: {
      days() {
        return getFormattedTime( Math.floor(( this.timeRemaining / ( 1000 * 60 * 60 * 24 ))) )
      },
      hours() {
        const hours = getFormattedTime( Math.floor(( this.timeRemaining / ( 1000 * 60 * 60 )) % 24) )

        if (this.hideDays) {
          return Number(hours) + (Number(this.days) * 24)
        }

        return hours
      },
      minutes() {
        return getFormattedTime( Math.floor(( this.timeRemaining / 1000 / 60 ) % 60 ) )
      },
      seconds() {
        return getFormattedTime( Math.floor(( this.timeRemaining / 1000 ) % 60 ) )
      },
    },
    mounted() {
      this.timer = setInterval(() => {
        this.setTimeRemaining()
      }, 1000)

      this.setTimeRemaining()
    },
    beforeDestroy() {
      this.clearTimer()
    },
    methods: {
      setTimeRemaining() {
        const now = Date.parse(new Date())
        const parsedCountdownTarget = Date.parse(this.countdownTarget)

        this.timeRemaining = parsedCountdownTarget - now

        if ( this.timeRemaining <= 0 ) {
          this.$emit('countdown-finished')
          this.clearTimer()
        }
      },
      clearTimer() {
        clearInterval(this.timer)
      },
    },
  }

  function getFormattedTime(time) {
    if ( time < 0) {
      return '00'
    }

    return time < 10 ? `0${time}` : String(time)
  }
