import getPriceGroupFromStorefront from '~/lib/get-price-group-from-storefront'

/**
 * @typedef {CartTotalsService}
 * @alias this.$cartTotalsService
 */
export class CartTotalsService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$checkoutApiService = this.context.$checkoutApiService
    this.$currencyService = this.context.$currencyService
    this.$productHelperService = this.context.$productHelperService
  }

  /**
   * @returns {Promise<Object>}
   */
  getCartTotals() {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    return this.$checkoutApiService.getCartTotals(cartId)
  }

  /**
   * @returns {Promise}
   */
  async updateCartTotals() {
    const cartTotals = await this.getCartTotals()
    const currentCartItems = this.store.state.checkout.cartItems
    const cartTotalsTotalSegment = cartTotals.total_segments
    const cartSubtotal = cartTotals.subtotal_incl_tax
    const cartSubtotalExclTax = cartTotals.subtotal_with_discount
    const cartTotal = cartSubtotal + cartTotals.discount_amount
    const cartGrandTotal = this.#getCartGrandTotalFromTotals(cartTotalsTotalSegment)
    const cartDiscount = this.#getDiscountFromTotals(cartTotalsTotalSegment)
    const cartGiftCard = this.#getGiftCardFromTotals(cartTotalsTotalSegment)
    const cartWalletTotal = this.#getWalletTotalFromTotals(cartTotalsTotalSegment)
    const cartSaleDiscount = this.#getDiscountValueFromSaleCartItems(currentCartItems)
    const otherDiscount = cartDiscount?.amount_number || 0
    const cartTotalWithoutDiscount = cartTotal + cartSaleDiscount + otherDiscount
    const cartGrandTotalWithoutDiscount = cartGrandTotal + cartSaleDiscount + otherDiscount + (cartWalletTotal ? Math.abs(cartWalletTotal) : 0)
    const totalDiscount = cartSaleDiscount + otherDiscount
    const cartWalletTotalInCents = cartWalletTotal ? this.$currencyService.parseAmountToCents(Math.abs(cartWalletTotal)) : null

    return Promise.all([
      this.store.dispatch('checkout/setCartSubTotal', cartSubtotal),
      this.store.dispatch('checkout/setCartSubTotalExclTax', cartSubtotalExclTax),
      this.store.dispatch('checkout/setCartTotal', cartTotal),
      this.store.dispatch('checkout/setCartGrandTotal', cartGrandTotal),
      this.store.dispatch('checkout/setCartGiftCard', cartGiftCard ? cartGiftCard : null),
      this.store.dispatch('checkout/setCartWalletTotal', cartWalletTotal ? cartWalletTotal : null),
      this.store.dispatch('checkout/setCartTotalWithoutDiscount', cartTotalWithoutDiscount),
      this.store.dispatch('checkout/setCartGrandTotalWithoutDiscount', cartGrandTotalWithoutDiscount),
      this.store.dispatch('checkout/setTotalDiscount', totalDiscount),
      this.store.dispatch('checkout/setCartDiscount', cartDiscount),
      this.store.dispatch('wallet/setPayFromWalletAmountInCents', cartWalletTotalInCents),
    ])
  }

  /**
   * @param {Object} totalSegments
   * @param {string} segmentCode
   * @returns {Object|null}
   */
  #getSegmentFromTotals(totalSegments, segmentCode) {
    return totalSegments.find(segment => segment.code === segmentCode) ?? null
  }

  /**
   * @param {Object} totalSegments
   * @returns {number}
   */
  #getCartGrandTotalFromTotals(totalSegments) {
    const grandTotal = this.#getSegmentFromTotals(totalSegments, 'grand_total')
    const buckarooAlreadyPaid = this.#getSegmentFromTotals(totalSegments, 'buckaroo_already_paid')

    if (!grandTotal) {
      return 0
    }

    let grandTotalValue = grandTotal.value

    if (buckarooAlreadyPaid) {
      grandTotalValue += buckarooAlreadyPaid.value
    }

    return grandTotalValue
  }

  /**
   * @param {Object} totalSegments
   * @returns {Object|null}
   */
  #getDiscountFromTotals(totalSegments) {
    const currency = this.store.state.localization.currency
    const locale = this.store.state.i18n.locale
    const discount = this.#getSegmentFromTotals(totalSegments, 'discount')

    if (!discount) {
      return null
    }

    const discountCode = discount.title.substring(
      discount.title.lastIndexOf('(') + 1,
      discount.title.lastIndexOf(')'),
    )

    return {
      code: discountCode,
      amount: this.$currencyService.formatToLocalePrice(discount.value, currency, locale),
      amount_number: Math.abs(discount.value),
    }
  }

  /**
   * @param {Object} totalSegments
   * @returns {Object|null}
   */
  #getGiftCardFromTotals(totalSegments) {
    const currency = this.store.state.localization.currency
    const locale = this.store.state.i18n.locale
    const giftCard = this.#getSegmentFromTotals(totalSegments, 'amasty_giftcard')

    if (!giftCard) {
      return null
    }

    const giftCardCodes = giftCard.title.split(', ')

    return {
      codes: giftCardCodes,
      amount: this.$currencyService.formatToLocalePrice(giftCard.value, currency, locale),
    }
  }

  /**
   * @param {Object} totalSegments
   * @returns {number|null}
   */
  #getWalletTotalFromTotals(totalSegments) {
    const walletSegment = this.#getSegmentFromTotals(totalSegments, 'wallet')

    return walletSegment?.value ?? null
  }

  /**
   * @param {Object[]} cartItems
   * @returns {number}
   */
  #getDiscountValueFromSaleCartItems(cartItems) {
    const saleDiscount = cartItems
      .map(cartItem => {

        if (this.$productHelperService.productIsGiftcard(cartItem.product)) {
          return 0
        }

        const originalPriceGroup = this.#getOriginalPriceGroup(cartItem)
        const originalPrice = originalPriceGroup?.original_price ?? cartItem.extension_attributes.price_incl_tax
        const price = cartItem.extension_attributes.price_incl_tax

        if (originalPrice > price) {
          return (originalPrice - price) * cartItem.qty
        }

        return 0
      })
      .reduce((currentPrice, price) => currentPrice + price, 0)

    return saleDiscount || 0
  }

  /**
   * @param {Object} cartItem
   * @returns {Object|null}
   */
  #getOriginalPriceGroup(cartItem) {
    const customerGroupId = this.store.state.user.customerGroupId
    const currency = this.store.state.localization.currency
    const storeFrontPrices = cartItem.product?.prices_storefront

    if (!storeFrontPrices) {
      return null
    }

    return getPriceGroupFromStorefront(storeFrontPrices, customerGroupId, currency)
  }
}
