/**
 * @typedef EmailValidatorService
 * @alias this.$emailValidatorService
 */
export class EmailValidatorService {
  constructor() {
    // Source: http://emailregex.com/
    this.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }

  /**
   * @param {string} email
   * @returns {boolean}
   */
  isValid(email) {
    return this.emailRegex.test(email)
  }
}
