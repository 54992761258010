import checkServerMaintenance from '~/lib/check-server-maintenance'

const MAGENTO_MAINTENANCE_CODE = 503

/**
 * These interceptors check for a 503 response from Magento on every call.
 * If Magento returns a 503 response it means Magento is in maintenance mode
 * and users will be redirected to the maintenance page.
 * @param {ServerNuxtContext} context
 */
export default (context) => {
  context.$magentoApiService.instance.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === MAGENTO_MAINTENANCE_CODE) {
        checkServerMaintenance(context)
      }

      return Promise.reject(error)
    },
  )
}
