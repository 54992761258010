const datoToNuxtRoutes = {
  'maintenance_page': 'maintenance',
  'order_confirmation_page': 'order-confirmation',
  'customer_service_page': 'contact-slug',
  'returns_page': 'returns',
  'delivery_page': 'deliveries',
  'campaign_overview_page': 'campaigns',
  'campaign_page': 'campaigns-slug',
  'content_page': 'content-slug',
  'home_page': 'index',
  'giftcard_page': 'giftcard',
  'saldo_checker_page': 'saldo-checker',
}

export default value => {
  return { name: datoToNuxtRoutes[value.__typename], params: { slug: value.slug } }
}
