/**
 * @param {string} searchQuery
 * @return {object}
 */
export default searchQuery => {
  return {
    size: 10,
    _source: [
      'include_in_category_search',
      'name',
      'level',
      'url_path',
    ],
    sort: [
      {
        _score: {
          order: 'desc',
        },
      },
      {
        entity_id: {
          order: 'desc',
          missing: '_first',
          unmapped_type: 'keyword',
        },
      },
    ],
    from: 0,
    query: {
      bool: {
        must: {
          bool: {
            must: [
              {
                bool: {
                  filter: {
                    term: {
                      include_in_category_search: true,
                    },
                  },
                },
              },
              {
                bool: {
                  filter: {
                    range: {
                      level: {
                        gte: 2,
                      },
                    },
                  },
                },
              },
            ],
            must_not: [],
            should: [
              {
                bool: {
                  filter: {
                    multi_match: {
                      query: searchQuery,
                      fields: [
                        'search^1',
                        'name^1',
                      ],
                      minimum_should_match: '100%',
                      tie_breaker: 1,
                      boost: 1,
                      type: 'phrase_prefix',
                      cutoff_frequency: 0.15,
                    },
                  },
                  must: {
                    multi_match: {
                      query: searchQuery,
                      fields: [
                        'search^1',
                        'search.whitespace^10',
                      ],
                      minimum_should_match: 1,
                      tie_breaker: 1,
                      boost: 1,
                      type: 'phrase_prefix',
                      cutoff_frequency: 0.15,
                    },
                  },
                  boost: 1,
                },
              },
            ],
            minimum_should_match: 1,
            boost: 1,
          },
        },
        boost: 1,
      },
    },
  }
}
