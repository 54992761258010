/**
 * @param {Object} product
 * @param {Function} localePath
 * @param {string} locale
 * @param {Object} [query]
 * @returns {*}
 */
export function localeProductPath(product, localePath, locale, query) {
  const slug = getProductSlug(product, locale)

  return localePath({
    name: 'product',
    params: { product: slug },
    ...(query  && { query }),
  })
}

export function getProductSlug(product, locale) {
  return product.multi_store_urls[locale]
}
