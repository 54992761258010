import getPriceGroupFromStorefront from '~/lib/get-price-group-from-storefront'
import { GIFTCARD_PRODUCT_TYPE } from '~/lib/constants'

/**
 * @typedef {ProductHelperService}
 * @alias this.$productHelperService
 */
export class ProductHelperService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }
  init() {
    this.$currencyService = this.context.$currencyService
  }

  /**
   * @param {Object} cartItem
   * @returns {Object|null}
   */
  getSelectedSizeByCartItem(cartItem) {
    const product = cartItem.product
    const sku = cartItem.sku
    const sizes = this.#getSizesFromProduct(product)

    if (!sizes) {
      return null
    }

    const selectedSize = sizes.options.find(option => option.product_sku === sku)

    return this.#getFormattedSelectedSize({ sizes, selectedSize })
  }

  /**
   * @param {Object} product
   * @param {number} sizeOptionValue
   * @returns {Object|null}
   */
  getSelectedSizeByOptionValue({ product, sizeOptionValue }) {
    const sizes = this.#getSizesFromProduct(product)

    if (!sizes) {
      return null
    }

    const selectedSize = sizes.options.find(option => option.option_value === sizeOptionValue)

    return this.#getFormattedSelectedSize({ sizes, selectedSize })
  }

  /**
   * @param {Object} product
   * @returns {string|null}
   */
  getUnavailableLabelText(product) {
    const hasOutOfStockReason = product
      && product.option_text_out_of_stock_reason
      && Array.isArray(product.option_text_out_of_stock_reason)

    if (!hasOutOfStockReason) {
      return null
    }

    const expectedStockDate = product.expected_stock_date?.[0] && new Date(product.expected_stock_date)
    const today = new Date()
    const isExpectedAfterToday = expectedStockDate > today

    if (isExpectedAfterToday) {
      const date = new Date(product.expected_stock_date[0].replace(/ /g, 'T'))
      const dateTimeFormat = new Intl.DateTimeFormat('en', { day: '2-digit', month: 'numeric', year: 'numeric' })
      const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date)

      return `${product.option_text_out_of_stock_reason[0]} | ${day}.${month}.${year}`
    }

    return product.option_text_out_of_stock_reason[0]
  }

  /**
   * @param {Object} product
   * @returns {string|null}
   */
  getProductShoutOutLabel(product) {
    const productShoutOut = product?.option_text_shoutout?.[0]

    const isPassedDate = new Date('2024-11-28').setHours(0, 0, 0, 0)
    const isBeforeDate = new Date('2024-11-29').setHours(0, 0, 0, 0)

    const now = new Date().getTime()
    const isAppDiscountTime = now > isPassedDate && now < isBeforeDate

    if (isAppDiscountTime && (productShoutOut?.includes('%') || productShoutOut?.toLowerCase().trim().includes('early access'))) {
      return `In App ${productShoutOut}`
    }

    return productShoutOut || null
  }

  /**
   * @param {Object} product
   * @returns {boolean}
   */
  productIsGiftcard(product) {
    const sku = product.sku?.[0]
    const beddingProducts = ['25708010600', '25708012300', '25708012900']

    if (beddingProducts.includes(sku)) {
      return true
    }

    return product.configurable_options?.[0]?.options?.some(option => option.product_type.includes(GIFTCARD_PRODUCT_TYPE)) ?? false
  }

  /**
   * @param {Object} product
   * @returns {boolean}
   */
  productIsEGiftcard(product) {
    return this.productIsGiftcard(product) && product.configurable_options?.[0]?.options?.some(option => option.is_virtual)
  }

  /**
   * @param {Object} product
   * @returns {string|null}
   */
  formatGiftCardPrice(product) {
    if (!product.prices_storefront) {
      return null
    }

    const currency = this.store.state.localization.currency
    const customerGroupId = this.store.state.user.customerGroupId
    const price = getPriceGroupFromStorefront(product.prices_storefront, customerGroupId, currency)

    if (!price) {
      return null
    }

    return `${this.$currencyService.formatToLocalePrice(price.price, currency)} - ${this.$currencyService.formatToLocalePrice(price.original_price, currency)}`
  }

  /**
   * @param {Object} product
   * @returns {*|null}
   */
  #getSizesFromProduct(product) {
    return product?.configurable_options?.find(option => option.attribute_code === 'size') ?? null
  }

  /**
   * @param {Object} sizes
   * @param {Object} selectedSize
   * @returns {Object}
   */
  #getFormattedSelectedSize({ sizes, selectedSize }) {
    return { option_id: sizes.option_id, ...selectedSize }
  }
}
