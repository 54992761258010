export default function () {
  return {
    optimizers: {},
    defaultBoosters: [],
    synonymBoosters: [],
    value: '',
    categories: [],
    suggestions: [],
    products: [],
    pages: [],
    totalProductsCount: 0,

    productsLoading: false,
    sortOptions,
    activeSortOption: sortOptions[0],
    allFilters: null,
    availableFilters: null,
    checkedFilters: null,
  }
}

const sortOptions = [
  {
    sort_order: 'desc',
    value: 'position',
    translationKey: 'filter_sort_type_position',
  },
  {
    sort_order: 'desc',
    value: 'wishlist_counter',
    translationKey: 'filter_sort_type_wishlist_counter',
  },
  {
    sort_order: 'desc',
    value: 'created_at',
    translationKey: 'filter_sort_type_created_at',
  },
  {
    sort_order: 'asc',
    value: 'price.price',
    nested_path: 'price',
    translationKey: 'filter_sort_type_price_asc',
  },
  {
    sort_order: 'desc',
    value: 'price.price',
    nested_path: 'price',
    translationKey: 'filter_sort_type_price_desc',
  },
]
