
  export default {
    props: {
      clean: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      form: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: Boolean,
        default: false,
      },
      link: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      native: {
        type: Boolean,
        default: false,
      },
      secondary: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      tertiary: {
        type: Boolean,
        default: false,
      },
      to: {
        type: [String, Object],
        validator(value) {
          if (!value || typeof value === 'string') {
            return true
          }

          return (
            value.name &&
            (value.params) ? Object.keys(value.params).length > 0 : true
          )
        },
        default: '',
      },
      type: {
        type: String,
        default: 'button',
      },
    },
    computed: {
      buttonType() {
        return (!this.to && !this.native) ? this.type : null
      },
      element() {
        return this.to ? 'nuxt-link' : this.native ? 'a' : 'button'
      },
      linkTo() {
        return this.to ? this.to : null
      },
    },
  }
