
  import cloneDeep from 'lodash.clonedeep'

  export default {
    props: {
      productPosts: {
        type: Array,
        default: null,
      },
      albumId: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
      showShopTheLookInPosts: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        posts: null,
        hasPixleeError: false,
      }
    },
    computed: {
      hasPosts() {
        return this.posts?.length > 0
      },
      renderComponent() {
        if (this.hasPixleeError || !this.hasPosts) {
          return !process.env.isNetlifyProduction
        }

        return true
      },
    },
    async mounted() {
      try {
        this.posts = [...await this.getPosts()]
      } catch {
        this.hasPixleeError = true
      }
    },
    methods: {
      async getPosts() {
        // Product posts from PDP
        if (this.productPosts?.length > 0) {
          return cloneDeep(this.productPosts)
        }

        // Post fetched from Pixlee album
        if (this.albumId) {
          return this.$pixleeApiService.getContentForAlbum(this.albumId)
            .then(response => response.posts)
            .catch(() => [])
        }

        return null
      },
      onImageError(errorPost) {
        const errorPostIndex = this.posts.findIndex(post => post === errorPost)
        const isErrorFromExistingImage = errorPostIndex !== -1

        if (isErrorFromExistingImage) {
          this.removePost(errorPostIndex)
        }
      },
      removePost(index) {
        this.posts.splice(index, 1)
      },
    },
  }
