export default (product) => {
  const AVAILABLE_SOON_REASON_ID = Number(process.env.availableSoonReasonId)
  const safeDate = product.expected_stock_date?.[0]?.replace(/-/g, '/')

  if (!safeDate) {
    return false
  }

  const expectedStockDate = new Date(safeDate)
  const today = new Date()

  if (!expectedStockDate) {
    return false
  }

  const isExpectedAfterToday = expectedStockDate > today
  const hasAvailableSoonOutOfStockReason = product?.out_of_stock_reason?.[0] === AVAILABLE_SOON_REASON_ID

  return hasAvailableSoonOutOfStockReason && isExpectedAfterToday
}
