import { render, staticRenderFns } from "./modular-content.vue?vue&type=template&id=3898677d"
import script from "./modular-content.vue?vue&type=script&lang=js"
export * from "./modular-content.vue?vue&type=script&lang=js"
import style0 from "./modular-content.vue?vue&type=style&index=0&id=3898677d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UpsellBar: require('/opt/build/repo/src/client/components/upsell-bar/upsell-bar.vue').default,StoriesOverview: require('/opt/build/repo/src/client/components/stories-overview/stories-overview.vue').default,ProductCarousel: require('/opt/build/repo/src/client/components/product-carousel/product-carousel.vue').default,CtaLinkBlock: require('/opt/build/repo/src/client/components/cta-link-block/cta-link-block.vue').default,AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default,ProductsView: require('/opt/build/repo/src/client/components/products-view/products-view.vue').default,SectionItem: require('/opt/build/repo/src/client/components/section-item/section-item.vue').default,SectionsSlider: require('/opt/build/repo/src/client/components/sections-slider/sections-slider.vue').default,TextBlockItem: require('/opt/build/repo/src/client/components/text-block-item/text-block-item.vue').default,PixleeFeed: require('/opt/build/repo/src/client/components/pixlee-feed/pixlee-feed.vue').default,LiveShoppingChannel: require('/opt/build/repo/src/client/components/live-shopping-channel/live-shopping-channel.vue').default})
