
  let Hls

  export default {
    props: {
      videoSource: {
        type: String,
        required: true,
      },
    },
    emits: [
      'error',
      'ready',
    ],
    data() {
      return {
        player: null,
        ready: false,
      }
    },
    async mounted() {
      Hls = await import('hls.js/dist/hls.min.js').then(module => module.default)

      this.initHlsPlayer()
    },
    beforeDestroy() {
      // We need to clear the innerHTML otherwise a memory leak persists
      this.$el.innerHTML = ''

      if (this.player) {
        this.player.detachMedia()
        this.player.destroy()
      }
    },
    methods: {
      initHlsPlayer() {
        let videoPlayer = this.$refs.videoPlayer

        // If HLS is supported natively, set video source
        // https://github.com/video-dev/hls.js/#embedding-hlsjs
        if (videoPlayer?.canPlayType('application/vnd.apple.mpegurl')) {
          videoPlayer.src = this.videoSource
          this.playVideo()
          videoPlayer = null
          return
        }

        videoPlayer = null

        if (!Hls.isSupported()) {
          return
        }

        // Initiate hls.js
        this.player = new Hls()

        // Attach media and play video
        this.player.attachMedia(this.$refs.videoPlayer)
        this.player.on(Hls.Events.MEDIA_ATTACHED, () => {
          this.player.loadSource(this.videoSource)
          this.playVideo()
        })

        // Error events
        this.player.on(Hls.Events.ERROR, (event, data) => this.handleErrors(data))
      },
      playVideo() {
        let { videoPlayer } = this.$refs
        const playPromise = videoPlayer.play()

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Automatic playback started!
              this.ready = true
              this.$emit('ready')
            })
            .catch(error => {
              this.$emit('error')

              if (process.env.NODE_ENV !== 'production') {
                console.error(error)
              }
            })
            .finally(() => {
              videoPlayer = null
            })
        }
      },
      handleErrors(data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR: {
              if (process.env.NODE_ENV !== 'production') {
                // Try to recover network error
                console.warn('Fatal network error encountered, try to recover')
              }

              this.player.startLoad()
              break
            }
            case Hls.ErrorTypes.MEDIA_ERROR: {
              if (process.env.NODE_ENV !== 'production') {
                console.warn('Fatal media error encountered, try to recover')
              }

              this.player.recoverMediaError()
              break
            }
            default: {
              // Cannot recover
              this.player.detachMedia()
              this.player.destroy()
              this.$emit('error')
              break
            }
          }
        }
      },
    },
  }
