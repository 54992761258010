export default function () {
  return {
    activeCategory: null,
    allFilters: null,
    appliedFiltersCount: 0,
    availableFilters: null,
    categoryOptions: null,
    categoryPageModularContent: null,
    categoryProductsLoading: false,
    categoryProducts: null,
    categoryProductsCount: null,
    checkedFilters: null,
    anchorIndex: 0,
    previousCategoryUrl: null,
    singleCategoryPageModularContent: null,
    sortOptions: null,
    productBundles: [],
    combinedProductBundles: null,
    productsPerRowMobile: 1,
  }
}
