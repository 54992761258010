/**
 * @typedef {CountryService}
 * @alias this.$countryService
 */
export class CountryService {
  constructor(
    context
  ) {
    this.context = context
    this.store = context.store

    this.shippingCountries = []
  }

  init() {
    this.$staticDataService = this.context.$staticDataService
    this.shippingCountries = this.$staticDataService.shippingCountriesIndex
  }

  /**
   * @param {string} countryId
   * @returns {Object}
   */
  getShippingCountryByCountryId(countryId) {
    return this.$staticDataService.shippingCountriesIndex?.find(shippingCountry => shippingCountry.id === countryId)
  }
}
