
  const hoverTimerAmount = 100

  export default {
    props: {
      navigationItems: {
        type: Array,
        required: true,
      },
    },
    emits: ['set-navigation-item-active-hover'],
    data() {
      return {
        navigationItemActiveHover: null,
        hoverTimer: null,
      }
    },
    watch: {
      $route: {
        deep: true,
        handler() {
          this.setNavigationItemActiveHover(null)
        },
      },
    },
    methods: {
      onNavigationLeave() {
        window.clearTimeout(this.hoverTimer)
        this.setNavigationItemActiveHover(null)
      },
      onNavigationItemEnter(item) {
        if (!item.subNavigation) {
          window.clearTimeout(this.hoverTimer)
          this.setNavigationItemActiveHover(null)
          return
        }

        if (!this.navigationItemActiveHover) {
          window.clearTimeout(this.hoverTimer)

          this.hoverTimer = window.setTimeout(() => {
            this.setNavigationItemActiveHover(item.label)
          }, hoverTimerAmount)

        } else {
          this.setNavigationItemActiveHover(item.label)
        }
      },
      setNavigationItemActiveHover(payload) {
        this.$emit('set-navigation-item-active-hover', payload)
        this.navigationItemActiveHover = payload
      },
    },
  }
