
  import { mapState, mapGetters } from 'vuex'

  import getNavigationLinks from '~/lib/get-navigation-links'

  export default {
    props: {
      categories: {
        type: Array,
        required: true,
      },
      activeCategoryId: {
        type: String,
        default: null,
      },
      usesAccordionForSubcategories: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        activeAccordionId: null,
        userChangedActiveAccordion: false,
      }
    },
    computed: {
      ...mapState({
        customerGroupId: state => state.user.customerGroupId,
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
      filteredCategories() {
        return getNavigationLinks(this.categories, this.isAuthenticated, this.customerGroupId)
      },
    },
    watch: {
      activeCategoryId() {
        this.initActiveAccordionId()
      },
    },
    methods: {
      initActiveAccordionId() {
        this.activeAccordionId = this.activeCategoryId
      },
      setActiveAccordion({ id }) {
        this.userChangedActiveAccordion = true
        this.activeAccordionId = this.activeAccordionId === id ? null : id
      },
    },
  }
