
  export default {
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      toast: {
        type: Object,
        // Return an object as default so we don't get 'cannot read property ... of null' errors.
        // The computed prop messageToUse has a fallback to the translated message.
        default: () => {},
      },
    },
    emits: [
      'close',
      'input',
    ],
    computed: {
      messageToUse() {
        // Use the toast popuptext, fallback to the default newsletter_toast_message
        return this.toast.popupText || this.$t('newsletter_toast_message')
      },
    },
  }
