import Swiper, { Autoplay, Pagination } from 'swiper'

export default async (context, inject) => {
  Swiper.use([ Autoplay, Pagination ])

  /**
   * @type {Swiper}
   * @alias this.$swiperInstance
   */
  inject('swiperInstance', Swiper)
}
