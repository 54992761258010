import enforceTrailingSlash from '~/lib/enforce-trailing-slash'

/**
 * @typedef {CanonicalService}
 * @alias this.$canonicalService
 */
export class CanonicalService {
  constructor(
    context
  ) {
    this.context = context
    this.locale = context.i18n.locale
    this.store = context.store
  }

  init() {
    this.$staticDataService = this.context.$staticDataService
  }

  /**
   * @param {Object} [page]
   * @return {undefined|string}
   */
  getCustomCanonicalPathForPage(page) {
    const currentPageSlug = page?.slug

    if (!currentPageSlug) return

    const canonicals = this.$staticDataService.cmsLayoutsDefault?.canonicals ?? []
    const canonical = canonicals.find(canonical => canonical.canonicalSourcePage.slug === currentPageSlug)

    if (!canonical) return

    if (canonical.canonicalTargetPage) {
      const slugPrefix = this.getPageSlugPrefix(canonical.canonicalTargetPage.slug)

      return `/${this.locale}/${slugPrefix}${canonical.canonicalTargetPage.slug}`
    }

    if (canonical.canonicalTargetCategory) {
      return this.$staticDataService.getCategoryUrlPathById(canonical.canonicalTargetCategory, this.locale)
    }
  }

  /**
   * @param {string} slug
   * @return {string}
   */
  getPageSlugPrefix(slug) {
    const campaignSlugs = this.$staticDataService.cmsTranslatedSlugs?.campaigns
    if (this.slugIsFromCampaignPage(slug) && campaignSlugs) {
      return enforceTrailingSlash(`${campaignSlugs[this.locale]}`)
    }

    return ''
  }

  /**
   * @param {string} slug
   * @return {boolean}
   */
  slugIsFromCampaignPage(slug) {
    return this.$staticDataService.cmsPagesCampaigns?.some(campaignSlug => campaignSlug[this.locale] === slug)
  }
}
