import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'

import getCountryId from '~/lib/get-country-id'
import removeEmptyObjectValues from '~/lib/remove-empty-object-values'

/**
 * @typedef {CartShippingAddressService}
 * @alias this.$cartShippingAddressService
 */
export class CartShippingAddressService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$cartShippingMethodService = this.context.$cartShippingMethodService
  }

  /**
   * The minimum data Magento needs for an address is a 2-digit ISO country code.
   * @returns {Object}
   */
  #getDefaultMagentoAddress() {
    return { country_id: getCountryId(this.store.state.localization.country) }
  }

  /**
   * If no address is given, we set a default shipping address with the local country
   * Magento needs this information to return initial shipping methods
   * @param {Object} [address]
   * @returns {Promise}
   */
  async updateShippingAddress(address = this.#getDefaultMagentoAddress()) {
    const oldShippingAddress = removeEmptyObjectValues(cloneDeep(this.store.state.checkout.shippingAddress))
    const newShippingAddress = removeEmptyObjectValues(cloneDeep(address))

    // To prevent extra calls to Magento we check if the addresses are different
    if (!isEqual(oldShippingAddress, newShippingAddress)) {
      await this.store.dispatch('checkout/setShippingAddress', newShippingAddress)

      // IMPORTANT! We need to get shipping methods before updating shipping information.
      // This is because the selected shipping method is updated accordingly
      await this.$cartShippingMethodService.fetchCartShippingMethods()

      // After we set a shipping address we need to update the shipping information in Magento.
      // Magento will return new payment methods accordingly.
      await this.$cartShippingMethodService.updateCartShippingInformation()
    }
  }

  /**
   * @returns {Promise}
   */
  removeAddressFromShippingAddress() {
    if (!this.store.state.checkout.shippingAddress) {
      return Promise.resolve()
    }

    // this.store.state.checkout.shippingAddress is cloned because it can contain other key values
    // like 'firstname', 'lastname' and 'email'.
    // This method specifically removes the address key values
    // country_id is preserved so the delivery options stay correct
    const currentShippingAddress = {
      firstname: this.store.state.checkout.shippingAddress.firstname,
      lastname: this.store.state.checkout.shippingAddress.lastname,
      country_id: this.store.state.checkout.shippingAddress.country_id || getCountryId(this.store.state.localization.country),
      email: this.store.state.checkout.shippingAddress.email,
      city: null,
      postcode: null,
      street: [],
    }

    return this.updateShippingAddress(currentShippingAddress)
  }

  /**
   * @returns {Promise}
   */
  resetShippingAddress() {
    return this.store.dispatch('checkout/setShippingAddress', this.#getDefaultMagentoAddress())
  }
}
