/**
 * @typedef {OverlayService}
 * @alias this.$overlayService
 */
export class OverlayService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$scrollLockService = this.context.$scrollLockService
  }

  /**
   * @param {Object} payload
   * @param {Object} [payload.settings]
   * @param {string} [payload.settings.type]
   * @param {string} [payload.settings.component]
   * @param {Object} [payload.settings.options]
   * @param {Object} [payload.desktop]
   * @param {string} [payload.desktop.type]
   * @param {string} [payload.desktop.component]
   * @param {Object} [payload.desktop.options]
   * @param {Object} [payload.mobile]
   * @param {string} [payload.mobile.type]
   * @param {string} [payload.mobile.component]
   * @param {Object} [payload.mobile.options]
   */
  setCurrentOverlay(payload) {
    return this.store.dispatch('overlay/setCurrentOverlay', payload)
  }

  /**
   * @returns {Promise<void>}
   */
  async closeAppModal() {
    await this.store.dispatch('overlay/closeAppModal')

    this.#enableScroll()
  }

  /**
   * @returns {Promise<void>}
   */
  async closeAppTray() {
    await this.store.dispatch('overlay/closeAppTray')

    this.#enableScroll()
  }

  /**
   * @returns {Promise<void>}
   */
  async closeFlyIn() {
    await this.store.dispatch('overlay/closeFlyIn')

    this.#enableScroll()
  }

  /**
   * Only enable scroll if there is not another
   * AppModal/AppTray/FlyIn active.
   * @returns {void}
   */
  #enableScroll() {
    if (!this.store.getters['overlay/overlayIsActive']) {
      this.$scrollLockService.enableScroll()
    }
  }
}
