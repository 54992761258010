import colorIndex from './indexes/color-index'

/**
 * @typedef {ProductSkuConverterService}
 * @alias this.$productSkuConverterService
 */
export class ProductSkuConverterService {
  constructor() {

    // Grabs the last 6 digits of a sku and groups them into 3 groups
    // Pattern, color and size. (in specific order)
    // 10197014400 -> 01, 44 and 00
    // 44 is the color which is mapped in the index to 'GOLD'
    this.regex = new RegExp(/(\d{2})(\d{2})(\d{2})$/)
  }
  /**
   * @param {string} sku
   * @returns {Object|undefined}
   */
  extractColorData(sku) {
    const results = this.regex.exec(sku)
    // Keep for future reference
    // const patternCode = results[1]
    const colorCode = results[2]
    // const sizeCode = results[3]

    const colorData = colorIndex[colorCode]

    if (!colorData && process.env.NODE_ENV !== 'production') {
      console.error(`Missing color for color code ${colorCode}`)
    }

    return colorData
  }

  /**
   * Get all unique color variations.
   * If a single sku is given as second parameter.
   * This variation will placed first in the array.
   * @param {Array} skus
   * @param {string} [sku]
   * @returns {Array}
   */
  getColorVariations(skus, sku) {
    const filteredSkus = sku ? skus.filter(currentSku => currentSku !== sku) : skus
    const colorVariations = filteredSkus
      .map(currentSku => this.extractColorData(currentSku))
      .filter(Boolean)

    if (!sku) {
      return this.getUniqueColorVariations(colorVariations)
    }

    const skuColorVariation = this.extractColorData(sku)
    const combinedColorVariations = [skuColorVariation, ...colorVariations].filter(Boolean)

    return this.getUniqueColorVariations(combinedColorVariations)
  }

  /**
   * Return an array with only unique colors
   * @param {Array} colorVariations
   * @return {Array}
   */
  getUniqueColorVariations(colorVariations) {
    return colorVariations.reduce((uniqueVariations, variation) => {
      // Find current variation in array
      const index = uniqueVariations.findIndex(item => item.description === variation.description)

      // If variation is not present, push it to the new array
      if (index === -1) {
        uniqueVariations.push(variation)
      }

      return uniqueVariations
    }, [])
  }
}
