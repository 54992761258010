export default {
  /**
   * @param {Object} state
   * @returns {boolean}
   */
  currentRoutePathIsCheckoutPage(state) {
    return state.currentLayout.includes('checkout')
  },
}

