import { v4 as uuidv4 } from 'uuid'

import cookie from '~/lib/cookie'
import { SESSION_ID_COOKIE_NAME } from '~/lib/constants'

export default function (context) {
  const sessionId = cookie(SESSION_ID_COOKIE_NAME) ?? uuidv4()
  cookie(SESSION_ID_COOKIE_NAME, sessionId)

  return context.store.dispatch('session/setId', sessionId)
}
