import maintenanceIsSkippable from '~/lib/maintenance-is-skippable'
import checkIfMagentoIsInMaintenance from '~/lib/check-if-magento-is-in-maintenance'

export default async (context, isNuxtPlugin = false) => {
  if (maintenanceIsSkippable(context.route.query)) {
    return
  }

  const translatedDatoSlugs = context.$staticDataService.cmsTranslatedSlugs
  const locale = context.app.i18n.locale
  const maintenancePath = translatedDatoSlugs.maintenance[locale]

  // To prevent endless redirect loop
  if (context.route.path.endsWith(`/${maintenancePath}`)) {
    return
  }

  const serverIsMaintenance = await checkIfMagentoIsInMaintenance()

  if (!serverIsMaintenance) {
    return
  }

  if (isNuxtPlugin) {
    // Only way to redirect in a client side plugin is to push the route after Nuxt is ready.
    // Otherwise there will be hydration issues.
    // https://nuxtjs.org/docs/2.x/internals-glossary/context#redirect
    return window.onNuxtReady(() => window.$nuxt.$router.push(context.app.localePath({ name: 'maintenance' })).catch(() => {}))
  } else {
    return context.redirect(context.app.localePath({ name: 'maintenance' }))
  }
}
