import Vue from 'vue'

export default {
  /**
   * @param {Object} state
   * @param {ActiveSplitTest} activeSplitTest
   */
  ADD_ACTIVE_SPLIT_TEST(state, activeSplitTest) {
    Vue.set(state.activeSplitTests, activeSplitTest.id, activeSplitTest)
  },
}
