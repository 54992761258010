import { ElasticApiService } from '~/services/core/elastic-api-service'

// Mappers
import {
  mapCategoriesForSearchFromApi,
  mapCategoryFromApi,
} from './category-api-mappers'

// Queries
import getCategoryByIdQuery from './queries/get-category-by-id-query'
import getCategoryByUrlPathQuery from './queries/get-category-by-url-path-query'
import getCategoriesForSearchQuery from './queries/get-categories-for-search-query'

import getCategoryUrlPathFromRoute from '~/lib/get-category-url-path-from-route'
import elasticIndices from '../../../../config/elastic'

/**
 * @typedef {CategoryApiService}
 * @alias this.$categoryApiService
 */
export class CategoryApiService extends ElasticApiService {
  constructor(context) {
    super(context.i18n)

    this.categoriesAxiosInstance = this.createAxiosInstance(elasticIndices.categories)
  }

  /**
   * @param {object} [query]
   * @return {Promise}
   */
  post(query) {
    return this.categoriesAxiosInstance.post('_search', query)
      .then(response => response?.data)
  }

  /**
   * @param {string} id
   * @param {object} [_source]
   * @return {Promise<object>}
   */
  getCategoryById(id, _source) {
    if (!id) {
      return this.rejectOnMissingArgument('id', 'getCategoryById')
    }

    return this.post(getCategoryByIdQuery(id, _source))
      .then(response => this.handleCategoryResponse(response, `Category not found with id '${id}'`))
  }

  /**
   * @param {string} routePath
   * @param {object} [_source]
   * @return {Promise<object>}
   */
  getCategoryByUrlPath(routePath, _source) {
    if (!routePath) {
      return this.rejectOnMissingArgument('routePath', 'getCategoryUrlPath')
    }

    const currentLocale = this.i18n.locale
    const categoryUrlPath = getCategoryUrlPathFromRoute(routePath, currentLocale)

    return this.post(getCategoryByUrlPathQuery(categoryUrlPath, currentLocale, _source))
      .then(response => this.handleCategoryResponse(response, `Category not found with categoryUrlPath '${categoryUrlPath}' and locale '${currentLocale}'`))
  }

  /**
   * @param {string} searchQuery
   * @return {Promise<array>}
   */
  getCategoriesForSearch(searchQuery) {
    if (typeof searchQuery !== 'string') {
      return this.rejectOnInvalidArgumentType('searchQuery', 'getCategoriesForSearch')
    }

    return this.post(getCategoriesForSearchQuery(searchQuery))
      .then(response => mapCategoriesForSearchFromApi(response.hits.hits))
      .catch(() => [])
  }

  /**
   * @param {object} response
   * @param {string} errorMessage
   * @return {object|Error}
   */
  handleCategoryResponse(response, errorMessage) {
    if (response?.hits?.hits?.length) {
      return mapCategoryFromApi(response.hits.hits)
    } else {
      return Promise.reject(Error(errorMessage))
    }
  }
}
