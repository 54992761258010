import throttle from 'lodash.throttle'
import { mapState } from 'vuex'

import { DEFAULT_CATEGORY_PAGE_PRODUCT_SIZE } from '~/lib/constants'

const scrollThrottleDuration = 100

export default {
  emits: [
    'update-anchor-index',
  ],
  props: {
    pageSize: {
      type: Number,
      default: DEFAULT_CATEGORY_PAGE_PRODUCT_SIZE,
    },
    initialAnchorPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      checkAnchorPositionsThrottled: throttle(this.checkAnchorPositions, scrollThrottleDuration),
      localAnchorIndex: 0,
    }
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile,
    }),
  },
  watch: {
    async isMobile() {
      await this.$nextTick()
      this.checkAnchorPositionsThrottled()
    },
  },
  mounted() {
    this.localAnchorIndex = this.initialAnchorPosition ?? 0

    this.checkAnchorPositionsThrottled()
    window.addEventListener('scroll', this.checkAnchorPositionsThrottled)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkAnchorPositionsThrottled)
  },
  methods: {
    checkAnchorPositions() {
      const elements = this.$refs.anchorElement

      if (!elements?.length) {
        return
      }

      elements.forEach(element => {
        if (element?.style) {
          element.style.top = `${element.nextElementSibling.offsetTop}px`
          element.style.position = 'absolute'
        }
      })

      const elementTopDistances = elements.map(element => element.getBoundingClientRect().top)
      const activeElement = elements[this.getElementIndexClosestToTop(elementTopDistances)]

      this.localAnchorIndex = Number(activeElement.dataset.index)

      // Prevent dispatch spam
      if (this.localAnchorIndex !== this.initialAnchorPosition) {
        this.$emit('update-anchor-index', { anchorIndex: this.localAnchorIndex })
      }
    },
    getElementIndexClosestToTop(numbers) {
      if (!numbers.length){
        return 0
      }

      let closest = 0
      let index = 0

      for (let i = 0; i < numbers.length ; i++) {
        if (closest === 0) {
          closest = numbers[i]
          index = i
        } else if (numbers[i] > 0 && numbers[i] <= Math.abs(closest)) {
          closest = numbers[i]
          index = i
        } else if (numbers[i] < 0 && - numbers[i] < Math.abs(closest)) {
          closest = numbers[i]
          index = i
        }
      }

      return index
    },
    isAnchorPosition(index) {
      if (index === undefined) {
        return false
      }

      const size = this.isMobile ? (this.pageSize / 2) : this.pageSize

      return index % size === 0
    },
    shouldRenderProduct(index) {
      const productsToShow = this.isMobile ? this.pageSize : this.pageSize * 2

      // Numbers depend on mobile/desktop
      // Always show 24/48 items
      if (this.localAnchorIndex === 0) {
        // Should return true for first 24/48 product cards
        return index < this.localAnchorIndex + productsToShow
      } else {
        // Should return true for previous 12/24 and 12/24 following product cards
        return index >= this.localAnchorIndex - (productsToShow / 2)
          && index < this.localAnchorIndex + (productsToShow / 2)
      }
    },
  },
}
