const ONE_MINUTE = 60000
const HALF_A_SECOND = 500

/**
 * Statement callback should return a boolean.
 * This statement determines if the interval
 * should be cleared.
 * @callback statement
 * @returns {boolean}
 */

/**
 * @param {statement} statement
 * @param {Function} callback
 * @param {Function} [reject]
 * @param {number} [intervalTime] in ms
 * @param {number} [maxRetryTime] in ms
 */
export default ({
  statement,
  callback,
  reject,
  intervalTime,
  maxRetryTime,
}) => {
  let timeLeft = maxRetryTime ?? ONE_MINUTE
  const interval = intervalTime ?? HALF_A_SECOND
  const timer = setInterval(() => {
    if (statement()) {
      clearInterval(timer)

      callback()
    }

    if (timeLeft <= 0) {
      clearInterval(timer)
      reject?.()
    }

    timeLeft -= interval
  }, interval)
}
