import { render, staticRenderFns } from "./empty.vue?vue&type=template&id=65643fc1"
import script from "./empty.vue?vue&type=script&lang=js"
export * from "./empty.vue?vue&type=script&lang=js"
import style0 from "./empty.vue?vue&type=style&index=0&id=65643fc1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLogo: require('/opt/build/repo/src/client/components/app-logo/app-logo.vue').default})
