import cookie from '~/lib/cookie'

/**
 * @typedef {RecentlyViewedService}
 * @alias this.$recentlyViewedService
 */
export class RecentlyViewedService {
  #maxViewableProducts = 12
  #recentlyViewedProductsStorageKey = 'recently_viewed_products'

  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$productApiService = this.context.$productApiService

    const itemsInCookie = cookie(this.#recentlyViewedProductsStorageKey) ? JSON.parse(cookie(this.#recentlyViewedProductsStorageKey)) : null

    if (itemsInCookie?.length) {
      this.$productApiService.getMultipleProductsByDocumentId(itemsInCookie)
        .then(products => this.setRecentlyViewedProducts(products))
    }
  }

  /**
   * @param {Object} product
   * @returns {Promise}
   */
  addRecentlyViewedProduct(product) {
    const stateProducts = this.store.state['recently-viewed-products'].products
    const products = stateProducts.filter(item => item.document_id !== product.document_id)

    // Max count of products that will be shown are 12
    // the reason why we check on MAX_VIEWABLE_PRODUCTS + 1
    // is because we still want to show MAX_VIEWABLE_PRODUCTS products
    // on a product page which is listed in the recently viewed products.
    // So we add a total of 13 products to this list.
    if (products.length === this.#maxViewableProducts + 1) {
      products.pop()
    }

    products.unshift(product)

    return this.setRecentlyViewedProducts(products)
  }

  /**
   * @param {Object[]} products
   * @returns {Promise}
   */
  setRecentlyViewedProducts(products) {
    this.setLocalRecentlyViewedProducts(products)

    return this.store.dispatch('recently-viewed-products/setRecentlyViewedProducts', products)
  }

  /**
   * @param {Object[]} products
   * @returns {void}
   */
  setLocalRecentlyViewedProducts(products) {
    const productDocumentIds = []

    products.forEach(product => productDocumentIds.push(product.document_id))

    cookie(this.#recentlyViewedProductsStorageKey, productDocumentIds)
  }
}
