
  import { mapState } from 'vuex'

  export default {
    props: {
      texts: {
        type: Array,
        default: () => [],
        validator: (texts) => texts.length > 1,
      },
      light: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        textToShow: this.texts[0],
        hidden: false,
        index: 0,
        interval: null,
        width: 0,
      }
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
      }),
      hasTextsToShow() {
        return this.texts.length > 0
      },
    },
    beforeMount() {
      this.textToShow = this.texts?.[0]
    },
    async mounted() {
      if (!this.textToShow || this.texts.length < 2) {
        return
      }

      // Set initial width
      await this.$nextTick()
      this.width = this.$refs.hiddenText?.offsetWidth ?? 0

      // Set interval to update width according to new text
      this.interval = setInterval(() => {
        this.index++

        // Start over if end is reached
        if (this.index === this.texts.length) {
          this.index = 0
        }

        // Trigger hide text animation
        this.hidden = true

        // Wait for text hide animation to complete then replace text.
        // Update width to new text
        setTimeout(async () => {
          let showText = false

          while (!showText) {
            this.textToShow = this.texts[this.index]

            await this.$nextTick()

            const maxWidth = this.$refs.hiddenText?.closest('.product-card')?.offsetWidth - 20 ?? null
            this.width = this.$refs.hiddenText?.offsetWidth ?? 0

            if (maxWidth === null) {
              showText = true
            } else if (this.width > maxWidth) {
              this.index++

              // Start over if end is reached
              if (this.index === this.texts.length) {
                this.index = 0
              }
            } else {
              showText = true
            }
          }

          // Wait for width animation to complete then trigger text reveal animation
          setTimeout(() => {
            this.hidden = false
          }, 400)
        }, 400)
      }, this.getRandomInterval())
    },
    beforeDestroy() {
      clearInterval(this.interval)
    },
    methods: {
      getRandomInterval() {
        return Math.floor(Math.random() * 8000) + 8000
      },
    },
  }
