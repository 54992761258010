import {
  AUTH_TOKEN_COOKIE_NAME,
  GUEST_CART_COOKIE_NAME,
  USER_PREFERS_LARGE_PRODUCTS_CARDS_COOKIE_NAME,
  BLOCKED_USER_COOKIE_NAME,
} from '~/lib/constants'
import cookie from '~/lib/cookie'

export default function (context) {
  // async iife, so the vue app is mounted before the plugin resolves
  (async () => {
    context.$userIpService.storeUserIpv6InLocalStorage()

    const userIsBlocked = cookie(BLOCKED_USER_COOKIE_NAME) === 'true'

    if (userIsBlocked) {
      await context.$userService.blockUser()
    }

    /**
     * Preferred product cards
     */
    const userPrefersLargeProductCards = cookie(USER_PREFERS_LARGE_PRODUCTS_CARDS_COOKIE_NAME) === 'true'

    await context.$productCardSettingService.setUserPrefersLargeProductCards(userPrefersLargeProductCards)

    /**
     * Guest cart
     */
    const guestCartId = cookie(GUEST_CART_COOKIE_NAME)

    if (guestCartId) {
      try {
        await context.$cartService.reinstateGuestCart(guestCartId)
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Failed to reinstate guest cart', error)
        }

        context.app.$sentry.captureException(error, {
          extra: {
            guestCartId,
          },
        })
      }
    }

    /**
     * Authentication and user cart
     */
    const authToken = cookie(AUTH_TOKEN_COOKIE_NAME)

    if (authToken) {
      try {
        const { order_hash: mollieOrderHash } = context.route.query

        if (mollieOrderHash) {
          // Mollie endpoint must be called before the initial fetching of
          // the user cart. Because Magento will re-instate the cart after
          // this Mollie order details check. If not called, the cart endpoint
          // will always return a 404. This is not desirable for cancelled/failed
          // payments.
          await context.$checkoutApiService.getMollieOrderDetails(mollieOrderHash)
        }

        await context.$userService.fetchUser()
        await context.$cartService.reinstateUserCart()
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Failed to login or reinstate cart', error)
        }

        context.app.$sentry.captureException(error)
      } finally {
        context.store.commit('user/SET_IS_INITIALIZED', true)
      }
    } else {
      context.store.commit('user/SET_IS_INITIALIZED', true)
    }

    /**
     * Wishlist
     */
    try {
      await context.$wishlistService.fetchWishlist()
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Failed to init wishlist', error)
      }

      context.app.$sentry.captureException(error)
    }
  })()
}
