/**
 * @param {string} categoryUrlPath
 * @param {string} locale
 * @param {object} [_source]
 * @return {Object}
 */
export default (categoryUrlPath, locale, _source) => {
  return {
    query: {
      term: {
        [`multi_store_urls.${locale}`]: {
          value: categoryUrlPath,
        },
      },
    },
    size: 1, // Only return best match
    ...(_source && { _source }),
  }
}
