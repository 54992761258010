export default {
  appliedFiltersCount(state) {
    const filterCount = state.checkedFilters
      ? Object.keys(state.checkedFilters).reduce((acc, key) => {
        acc += state.checkedFilters[key].length

        return acc
      }, 0)
      : 0

    return state.activeSortOption?.value === state.sortOptions[0].value
      ? filterCount
      : filterCount + 1
  },
}
