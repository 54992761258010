import { INITIAL_CUSTOMER_GROUP_ID } from '~/lib/constants'

/**
 * In Dato navigation items can include a specific customer group id.
 * These navigation items should only been shown for this specific group.
 * @param {Array}   navigationLinks
 * @param {Boolean} isAuthenticated
 * @param {Number}  customerGroupId
 */
export default function (navigationLinks, isAuthenticated, customerGroupId = 0) {
  if (isAuthenticated) {
    return navigationLinks.filter(navigationLink => {
      return navigationLink.customerGroupId === INITIAL_CUSTOMER_GROUP_ID
        || !navigationLink.customerGroupId
        || navigationLink.customerGroupId === customerGroupId
    })
  }

  return navigationLinks.filter(category => {
    return category.customerGroupId === INITIAL_CUSTOMER_GROUP_ID || !category.customerGroupId
  })
}
