
  import { mapGetters, mapActions } from 'vuex'
  import cloneDeep from 'lodash.clonedeep'
  import AppImage from '@loavies/component-library/dist/app-image'

  import {
    APP_TRAY,
    OUT_OF_STOCK_APP_TRAY_NAME,
    SIZE_SELECT_APP_TRAY_NAME,
  } from '~/lib/constants'
  import { isValidProduct } from '~/lib/product-validation'
  import { isInStock } from '~/lib/stock'
  import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'

  export default {
    components: {
      AppImage,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      products: {
        type: Array,
        default: null,
      },
      link: {
        type: Object,
        default: null,
      },
      hasDynamicDetails: {
        type: Boolean,
        default: false,
      },
      numberOfSkeletonCards: {
        type: Number,
        default: null,
      },
      uid: {
        type: String,
        default: null,
        required: false,
      },
      location: {
        type: String,
        default: '',
      },
      hasCustomBlock: {
        type: Boolean,
        default: false,
      },
      customBlockImage: {
        type: Object,
        default: null,
      },
      hasCustomBlockButton: {
        type: Boolean,
        default: false,
      },
      customBlockButtonText: {
        type: String,
        default: null,
      },
      customBlockButtonHorizontalOffset: {
        type: Number,
        default: null,
      },
      customBlockButtonVerticalOffset: {
        type: Number,
        default: null,
      },
      customBlockButtonBackgroundColor: {
        type: Object,
        default: null,
      },
      customBlockButtonTextColor: {
        type: Object,
        default: null,
      },
    },
    emits: ['size-select-tray-toggled'],
    data() {
      return {
        activeProduct: null,
        selectedSize: null,
        initialScrollLeft: null,
        observers: null,
      }
    },
    computed: {
      ...mapGetters({
        getProductCarouselSetting: 'product-carousels/getProductCarouselSetting',
      }),
      activeProductIsInStock() {
        return isInStock(this.activeProduct)
      },
      showSkeleton() {
        return !this.products && this.numberOfSkeletonCards > 0
      },
      validProducts() {
        return this.products ? this.products.filter(isValidProduct) : []
      },
      activeProductSku() {
        return getPrimarySkuFromProduct(this.activeProduct)
      },
      activeProductSizes() {
        const configurableOptions = this.activeProduct.configurable_options

        return configurableOptions && configurableOptions.find(option => option.attribute_code === 'size')
      },
      isSingleSize() {
        return this.activeProductSizes?.options?.length === 1
      },
      hasProducts() {
        return this.validProducts.length > 0
      },
    },
    watch: {
      validProducts: {
        immediate: true,
        async handler() {
          if (this.observers) {
            this.removeObservers()
          }

          if (!this.validProducts.length) {
            return
          }


          await this.$nextTick()

          this.activeProduct = this.validProducts[0]

          if (this.hasDynamicDetails) {
            this.addObservers()
          }
        },
      },
      activeProduct: {
        handler() {
          this.selectedSize = null
        },
      },
      selectedSize(size) {
        if (size && !this.isSingleSize) {
          this.addToCart()
        }
      },
    },
    mounted() {
      if (this.hasDynamicDetails && this.validProducts.length) {
        this.addObservers()
      }

      const productCarouselSetting = cloneDeep(this.getProductCarouselSetting(this.uid))
      this.initialScrollLeft = productCarouselSetting?.scrollLeft
    },
    beforeDestroy() {
      if (this.observers) {
        this.removeObservers()
      }
    },
    methods: {
      ...mapActions({
        setProductCarouselsSetting: 'product-carousels/setProductCarouselsSetting',
      }),
      storeScrollLeftPosition(scrollLeft) {
        if (!this.uid) {
          return
        }

        this.setProductCarouselsSetting({
          uid: this.uid,
          scrollLeft,
        })
      },
      onAddToCartButtonClick() {
        if (this.activeProductSizes && !this.isSingleSize) {
          this.$overlayService.setCurrentOverlay({
            settings: {
              type: APP_TRAY,
              component: SIZE_SELECT_APP_TRAY_NAME,
              options: {
                product: this.activeProduct,
                sizes: this.activeProductSizes,
              },
            },
          })
        } else {
          this.addToCart()
        }
      },
      addToCart() {
        if (this.activeProductSizes && this.isSingleSize) {
          this.selectedSize = this.activeProductSizes.options[0].option_value
        }

        const selectedSize = this.$productHelperService.getSelectedSizeByOptionValue({
          product: this.activeProduct,
          sizeOptionValue: this.selectedSize,
        })

        this.$cartItemsService.addToCart({
          product: this.activeProduct,
          quantity: 1,
          selectedSize,
        })
      },
      selectOutOfStockSize({ selectedSize }) {
        this.$overlayService.setCurrentOverlay({
          settings: {
            type: APP_TRAY,
            component: OUT_OF_STOCK_APP_TRAY_NAME,
            options: { selectedSize },
          },
        })
      },
      addObservers() {
        this.observers = this.$refs.productCards?.map(productCard => {
          const observer = new IntersectionObserver(this.onIntersection, {
            root: this.$refs.carousel.$el,
            rootMargin: '0px',
            threshold: 0,
          })

          observer.observe(productCard.$el)

          return observer
        }) ?? null
      },
      onIntersection(entries) {
        let activeEntry = entries.find(entry => {
          return entry.boundingClientRect.x < 0
        })

        if (activeEntry) {
          let entryProduct = activeEntry.target

          const entryProductIndex = this.$refs.productCards.findIndex(productCard => {
            return productCard.$el === entryProduct
          })

          const activeProductIndex = (activeEntry.boundingClientRect.x + activeEntry.boundingClientRect.width > 0)
            ? entryProductIndex
            : entryProductIndex + 1

          let activeProduct = this.$refs.productCards[activeProductIndex]

          this.activeProduct = activeProduct?.product ? cloneDeep(activeProduct.product) : null

          // Reset references
          entryProduct = null
          activeEntry = null
          activeProduct = null
        }
      },
      removeObservers() {
        this.observers?.forEach((observer, index) => {
          observer?.disconnect
          this.observers[index] = null
        })

        this.observers = null
      },
    },
  }
