/**
 * @param {object} address
 * @return {boolean}
 */
export default address => {
  return !!(
    address?.city
    || address?.postcode
    || address?.street?.[0]?.length
  )
}
