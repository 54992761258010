export default function () {
  window._loaviesLoadOverlays = (event) => handleEvent(event)

  document.addEventListener('scroll', window._loaviesLoadOverlays)
  document.addEventListener('mousemove', window._loaviesLoadOverlays)
  document.addEventListener('touchstart', window._loaviesLoadOverlays)
}

function handleEvent(event) {
  loadOverlays()
  event.currentTarget.removeEventListener(event.type, window._loaviesLoadOverlays) // remove the event listener that got triggered
}

function loadOverlays() {
  if (window._loaviesOverlaysLoaded) {
    return
  }

  window._loaviesOverlaysLoaded = true

  // App modals
  import('~/components/app-modals/blocked-user-modal/blocked-user-modal.vue')
  import('~/components/app-modals/giftcard-details-modal/giftcard-details-modal.vue')
  import('~/components/app-modals/checkout-donation-modal/checkout-donation-modal.vue')
  import('~/components/app-modals/configurable-modal/configurable-modal.vue')
  import('~/components/app-modals/delete-address-confirmation-modal/delete-address-confirmation-modal.vue')
  import('~/components/app-modals/image-gallery-overlay-modal/image-gallery-overlay-modal.vue')
  import('~/components/app-modals/logout-confirmation-modal/logout-confirmation-modal.vue')
  import('~/components/app-modals/out-of-stock-modal/out-of-stock-modal.vue')
  import('~/components/app-modals/bancontact-qrcode-modal/bancontact-qrcode-modal.vue')
  import('~/components/app-modals/wallet-payment-modal/wallet-payment-modal.vue')

  // App trays
  import('~/components/app-trays/newsletter-toast-tray/newsletter-toast-tray.vue')
  import('~/components/app-trays/out-of-stock-tray/out-of-stock-tray.vue')
  import('~/components/app-trays/products-filter-tray/products-filter-tray.vue')
  import('~/components/app-trays/search-results-filter-tray/search-results-filter-tray.vue')
  import('~/components/app-trays/shop-the-look-tray/shop-the-look-tray.vue')
  import('~/components/app-trays/listings-tray/listings-tray.vue')
  import('~/components/app-trays/size-select-tray/size-select-tray.vue')
  import('~/components/app-trays/search-tray/search-tray.vue')
  import('~/components/app-trays/refer-a-friend-tray/refer-a-friend-tray.vue')
  import('~/components/app-trays/social-retail-tray/social-retail-tray.vue')
  import('~/components/app-trays/bancontact-qrcode-tray/bancontact-qrcode-tray.vue')
  import('~/components/app-trays/giftcard-details-tray/giftcard-details-tray.vue')
  import('~/components/app-trays/wallet-payment-tray/wallet-payment-tray.vue')

  // Fly in's
  import('~/components/fly-ins/navigation-fly-in/navigation-fly-in.vue')
  import('~/components/fly-ins/auth-fly-in/auth-fly-in.vue')
  import('~/components/fly-ins/cart-fly-in/cart-fly-in.vue')
  import('~/components/fly-ins/search-fly-in/search-fly-in.vue')
  import('~/components/fly-ins/products-filter-fly-in/products-filter-fly-in.vue')
  import('~/components/fly-ins/refer-a-friend-fly-in/refer-a-friend-fly-in.vue')
  import('~/components/fly-ins/search-results-filter-fly-in/search-results-filter-fly-in.vue')
}
