
  import AppImage from '@loavies/component-library/dist/app-image'

  export default {
    components: {
      AppImage,
    },
    props: {
      cardData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        countdownIsFinished: false,
      }
    },
    computed: {
      liveShoppingShowId() {
        return this.cardData.liveShoppingItem?.showId
      },
      countDownIsToday() {
        if (!this.cardData.countdownDatetime) return false

        const today = new Date()
        const countDownTime = new Date(this.cardData.countdownDatetime)

        return today.getDate() === countDownTime.getDate()
          && today.getMonth() === countDownTime.getMonth()
          && today.getFullYear() === countDownTime.getFullYear()
      },
      buttonText() {
        return this.countdownIsFinished ? this.cardData.buttonText : this.cardData.inactiveButtonText
      },
    },
    methods: {
      countdownFinished() {
        this.countdownIsFinished = true
      },
    },
  }
