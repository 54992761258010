export default {
  /**
   * @param context
   * @returns {Promise}
   */
  resetState(context) {
    context.commit('RESET_STATE')
  },

  /**
   * @param context
   * @param {boolean} isInitialized
   * @returns {Promise}
   */
  setIsInitialized(context, isInitialized) {
    context.commit('SET_IS_INITIALIZED', isInitialized)
  },

  /**
   * @param context
   * @param {Object[]} addresses
   */
  setUserAddresses(context, addresses) {
    context.commit('SET_USER_ADDRESSES', addresses)
  },

  /**
   * @param context
   * @param {string} email
   * @param {string} firstname
   * @param {string} lastname
   * @param {string} dateOfBirth
   * @param {number} customerGroupId
   * @param {boolean} isSubscribedToNewsletter
   * @param {string} token
   * @param {Object[]} addresses
   * @param {number} websiteId
   * @param {number} id
   * @param {string} telephone
   * @returns {Promise}
   */
  setUserData(context, {
    email,
    firstname,
    lastname,
    dateOfBirth,
    customerGroupId,
    isSubscribedToNewsletter,
    token,
    addresses,
    websiteId,
    id,
    telephone,
  }) {
    context.commit('SET_USER_EMAIL', email)
    context.commit('SET_USER_FIRSTNAME', firstname)
    context.commit('SET_USER_LASTNAME', lastname)
    context.commit('SET_USER_DATE_OF_BIRTH', dateOfBirth)
    context.commit('SET_USER_CUSTOMER_GROUP_ID', customerGroupId)
    context.commit('SET_USER_NEWSLETTER_SUBSCRIPTION_STATUS', isSubscribedToNewsletter)
    context.commit('SET_USER_TOKEN', token)
    context.commit('SET_USER_ADDRESSES', addresses)
    context.commit('SET_WEBSITE_ID', websiteId)
    context.commit('SET_USER_ID', id)
    context.commit('SET_USER_PHONE_NUMBER', telephone)
  },

  /**
   * @param context
   * @param {boolean} isSubscribedToNewsletter
   * @returns {Promise}
   */
  setUserNewsletterSubscriptionStatus(context, { isSubscribedToNewsletter }) {
    context.commit('SET_USER_NEWSLETTER_SUBSCRIPTION_STATUS', isSubscribedToNewsletter)
  },

  /**
   * @param context
   * @param {boolean} isBlocked
   */
  setIsBlocked(context, isBlocked) {
    context.commit('SET_IS_BLOCKED', isBlocked)
  },
}
