import axios from 'axios'

export default () => {
  // Timestamp is added because calls to files in browsers are cached
  const url = `${process.env.magentoBaseUrl}/media/maintenance.flag?timestamp=${new Date().getTime()}`
  return axios.get(url)
    .then(response => {
      const status = Number(response?.data)

      // 0 means Magento is not in maintenance mode
      if (isNaN(status) || status === 0) {
        return false
      }

      // 1 means Magento is in maintenance mode.
      // Added extra fallback if Magento would respond with something other than 1
      return status === 1
    })
    .catch(() => false)
}
