/**
 * @param {string} id
 * @param {object} [_source]
 * @return {Object}
 */
export default (id, _source) => {
  return {
    query: {
      term: {
        entity_id: {
          value: id,
        },
      },
    },
    ...(_source && { _source }),
  }
}
