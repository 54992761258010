import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      chatLoaded: state => state.chat.loaded,
      chatIsOnline: state => state.chat.chatIsOnline,
    }),
  },
  methods: {
    openChat() {
      if (!this.chatLoaded) {
        return
      }

      if (window.adaLoaded) {
        return this.$adaChatBot.openChat()
      }

      //Fallback
      this.$zendesk.openChat()
    },
    closeChat() {
      if (!this.chatLoaded) {
        return
      }

      if (window.adaLoaded) {
        return this.$adaChatBot.closeChat()
      }

      //Fallback
      this.$zendesk.closeChat()
    },
  },
}
