
  export default {
    props: {
      align: {
        type: String,
        default: 'right',
        validator: dir => ['left', 'right'].indexOf(dir) !== -1,
      },
      amount: {
        type: Number,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
    },
    computed: {
      cartCounter() {
        return this.icon === 'cart'
      },
      cappedAmount() {
        return this.amount <= 99 ? this.amount : '99+'
      },
    },
  }
