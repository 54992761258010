import getOnlineFromDateFilter from './get-online-from-date-filter'

/**
 * @param {string} sku
 * @return {Object}
 */
export default (sku) => {
  const onlineFromDateFilter = getOnlineFromDateFilter()

  return {
    query: {
      bool: {
        must: [
          {
            term: {
              'sku.untouched': sku,
            },
          },
        ],
        ...(onlineFromDateFilter && { filter: [ onlineFromDateFilter ] }),
      },
    },
  }
}
