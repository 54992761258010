import {
  SHIPPING_METHOD_CODE_BPOST_SERVICE_POINT,
  SHIPPING_METHOD_CODE_DHL_SERVICE_POINT,
  MAGENTO_CODE_BILLINK,
} from '~/lib/constants'
import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'
import { PaymentMethod } from '~/lib/checkout/payment-method'
import { PaymentMethodIssuer } from '@/lib/checkout/payment-method-issuer'

/**
 * @typedef {CheckoutApiMapperService}
 * @alias this.$checkoutApiMapperService
 */
export class CheckoutApiMapperService {
  constructor(
    context
  ) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.store = context.store
    this.localePath = context.localePath
  }

  init() {
    this.$userApiMapperService = this.context.$userApiMapperService
  }

  /**
   * @param {Object[]} productQuantities
   * @return {Object}
   */
  mapProductQuantitiesFromApi(productQuantities) {
    return productQuantities.reduce((mappedQuantities, productQuantity) => {
      mappedQuantities[productQuantity.sku] = productQuantity.qty

      return mappedQuantities
    }, {})
  }

  /**
   * @param {Object} shippingMethods
   * @return {Object[]}
   */
  mapShippingMethodsFromApi(shippingMethods) {
    return shippingMethods.map(shippingMethod => {
      const mappedShippingMethodDetails = this.#mapShippingMethodDetailsFromApi(shippingMethod)

      return {
        amount: shippingMethod.amount,
        available: shippingMethod.available,
        base_amount: shippingMethod.base_amount,
        carrier_code: shippingMethod.carrier_code,
        carrier_title: shippingMethod.carrier_title,
        error_message: shippingMethod.error_message,
        method_code: shippingMethod.method_code,
        method_title: shippingMethod.method_title,
        price_excl_tax: shippingMethod.price_excl_tax,
        price_incl_tax: shippingMethod.price_incl_tax,
        ...mappedShippingMethodDetails && { details: mappedShippingMethodDetails },
      }
    })
  }

  /**
   * @param {Object} shippingMethod
   * @returns {Object|null}
   */
  #mapShippingMethodDetailsFromApi(shippingMethod) {
    if (!shippingMethod.extension_attributes) {
      return null
    }

    // DHL schedules
    if (shippingMethod.extension_attributes.dhl_timeframes?.length) {
      return {
        timeframes: shippingMethod.extension_attributes.dhl_timeframes.map(timeframe => {
          return {
            formattedDateTime: `${timeframe.date_label} ${timeframe.start_time} - ${timeframe.end_time}`,
            formattedDate: timeframe.date_label,
            date: timeframe.date,
            startTime: timeframe.start_time,
            endTime: timeframe.end_time,
          }
        }),
      }
    }

    // DHL service points
    if (shippingMethod.extension_attributes.dhl_servicepoints?.length) {
      return {
        servicePoints: shippingMethod.extension_attributes.dhl_servicepoints.map(servicePoint => {
          return {
            id: servicePoint.id,
            name: servicePoint.name,
            distance: servicePoint.distance,
            street: servicePoint.address?.street,
            number: servicePoint.address?.number,
            addition: servicePoint.address?.addition,
            city: servicePoint.address?.city,
            countryCode: servicePoint.address?.country_code,
          }
        }),
      }
    }

    // bpost service points
    if (shippingMethod.extension_attributes.bpost_servicepoints?.length) {
      return {
        servicePoints: shippingMethod.extension_attributes.bpost_servicepoints.map(servicePoint => {
          return {
            id: servicePoint.id,
            name: servicePoint.name,
            distance: servicePoint.distance,
            street: servicePoint.address?.street,
            number: servicePoint.address?.number,
            addition: servicePoint.address?.addition,
            city: servicePoint.address?.city,
            countryCode: servicePoint.address?.country_code,
          }
        }),
      }
    }

    return null
  }

  /**
   * @param {Object} shippingMethod
   * @param {Object} shippingAddress
   * @returns {Object}
   */
  mapShippingInformationToApi({ shippingMethod, shippingAddress }) {
    const shippingMethodDetails = this.#getShippingMethodDetails()
    return {
      billing_address: this.$userApiMapperService.mapAddressToApi(shippingAddress),
      shipping_address: this.$userApiMapperService.mapAddressToApi(shippingAddress),
      shipping_carrier_code: shippingMethod?.carrier_code,
      shipping_method_code: shippingMethod?.method_code,
      ...shippingMethodDetails && {
        extension_attributes: shippingMethodDetails,
      },
    }
  }

  /**
   * @returns {Object|null}
   */
  #getShippingMethodDetails() {
    // DHL delivery
    if (this.store.state.checkout.selectedDeliveryMoment) {
      return {
        dhlparcel_shipping_deliverytimes: {
          date: this.store.state.checkout.selectedDeliveryMoment.date,
          startTime: this.#formatTimeForApi(this.store.state.checkout.selectedDeliveryMoment.startTime),
          endTime: this.#formatTimeForApi(this.store.state.checkout.selectedDeliveryMoment.endTime),
        },
      }
    }

    if (!this.store.state.checkout.selectedServicePoint) {
      return null
    }

    // DHL service point
    if (this.store.state.checkout.selectedShippingMethod?.method_code === SHIPPING_METHOD_CODE_DHL_SERVICE_POINT) {
      return {
        dhlparcel_shipping_servicepoint_id: this.store.state.checkout.selectedServicePoint.id,
      }
    }

    // bpost service point
    if (this.store.state.checkout.selectedShippingMethod?.method_code === SHIPPING_METHOD_CODE_BPOST_SERVICE_POINT) {
      return {
        bpost_servicepoint_id: this.store.state.checkout.selectedServicePoint.id,
      }
    }

    return null
  }

  /**
   * Api expects time format: 1400
   * @param {string} time
   * @returns {string}
   */
  #formatTimeForApi(time) {
    return time?.replace(/:/g, '')
  }

  mapMagentoPaymentMethods(magentoPaymentMethods, molliePaymentMethodsMetaData) {
    return magentoPaymentMethods.map((magentoPaymentMethod) => {
      const metaData = molliePaymentMethodsMetaData.find((metaDataItem) => metaDataItem.code === magentoPaymentMethod.code)

      return new PaymentMethod({
        id: magentoPaymentMethod.code,
        title: magentoPaymentMethod.title,
        // TODO: Image src
        imageSrc: null,
        ...metaData?.issuers.length && {
          issuers: metaData.issuers.map(issuer => new PaymentMethodIssuer({
            id: issuer.id,
            title: issuer.name,
            imageSrc: issuer.image_svg,
          })),
        },
      })
    })
  }

  /**
   * @see https://docs.adyen.com/plugins/magento-2/magento-headless-integration?tab=rest3_1#create-a-data-object
   * @param {PaymentMethod} paymentMethod
   * @param {Object} [paymentMethodDetails]
   * @returns {Object}
   */
  mapPaymentInformationToApi(paymentMethod, paymentMethodDetails) {
    const shippingAddress = this.store.state.checkout.shippingAddress
    const newAddress = !Boolean(shippingAddress.id)
    const saveInAddressBook = newAddress ? 1 : undefined

    if (paymentMethod.id === MAGENTO_CODE_BILLINK) {
      return {
        billingAddress: this.$userApiMapperService.mapAddressToApi(shippingAddress, saveInAddressBook),
        // Guest email address is stored in shipping address
        email: this.store.getters['user/isAuthenticated'] ? this.store.state.user.email : shippingAddress.email,
        paymentMethod: {
          method: paymentMethod.id,
          additional_data: {
            billink_customer_type: 'P',
            billink_customer_phone: paymentMethodDetails?.phone,
            billink_customer_birthdate: paymentMethodDetails?.birthDate,
          },
        },
      }
    }

    return {
      billingAddress: this.$userApiMapperService.mapAddressToApi(shippingAddress, saveInAddressBook),
      // Guest email address is stored in shipping address
      email: this.store.getters['user/isAuthenticated'] ? this.store.state.user.email : shippingAddress.email,
      paymentMethod: {
        method: paymentMethod.id,
        ...paymentMethodDetails && {
          additional_data: {
            ...paymentMethodDetails.selected_issuer && { selected_issuer: paymentMethodDetails.selected_issuer },
            ...paymentMethodDetails.card_token && { card_token: paymentMethodDetails.card_token },
            ...paymentMethodDetails.applepay_payment_token && { applepay_payment_token: paymentMethodDetails.applepay_payment_token },
          },
        },
      },
    }
  }

  /**
   * @param {string} cartId
   * @param {Object} product
   * @param {number} quantity
   * @param {Object} [selectedSize]
   * @param {Object} [eGiftcardDetails]
   * @returns {Object}
   */
  mapCartItem({ cartId, product, quantity, selectedSize, eGiftCardDetails }) {
    const productSku = getPrimarySkuFromProduct(product)

    const cartItem = {
      sku: productSku,
      qty: quantity,
      quote_id: cartId,
    }

    if (selectedSize) {
      cartItem.product_option = {
        extension_attributes: {
          custom_options: [
            {
              option_id: 'image_variation_code',
              option_value: product.image_variation_code,
            },
              ...(eGiftCardDetails && [ ...eGiftCardDetails ]),
          ],
          configurable_item_options: [{
            option_id: selectedSize.option_id,
            option_value: selectedSize.option_value,
          }],
        },
      }
    }

    return cartItem
  }

  /**
   * @param {string} cartId
   * @param {number} amount
   */
  mapCartDonationItem({ cartId, amount }) {
    return {
      sku: 'DONATION',
      qty: 1,
      quote_id: cartId,
      product_option: {
        extension_attributes: {
          donation_options: {
            amount,
          },
        },
      },
    }
  }
}
