export default {
  /**
   * @param context
   * @returns {Promise}
   */
  resetState(context) {
    context.commit('RESET_STATE')
  },

  /**
   * @param context
   * @param {number} currentProductUspIndex
   * @return {Promise}
   */
  async setCurrentProductUspIndex(context, currentProductUspIndex) {
    context.commit('SET_CURRENT_PRODUCT_USP_INDEX', currentProductUspIndex)
  },
}
