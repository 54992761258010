
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState({
        previousRouteName: state => state.routes.previousRouteName,
        activeFlyIn: state => state.overlay.activeFlyIn,
        showChatButton: state => state.showChatButton,
      }),
      flyInIsActive() {
        return this.activeFlyIn !== null
      },
      buttonIcon() {
        return this.flyInIsActive ? 'close' : 'arrow-left'
      },
    },
    methods: {
      handleClick() {
        if (this.flyInIsActive) {
          return this.$overlayService.closeFlyIn()
        }

        if (!this.previousRouteName || this.previousRouteName?.includes('order-confirmation')) {
          return this.$router.replace(this.localePath({ name: 'index' })).catch(() => {})
        }

        return this.$router.go(-1)
      },
    },
  }
