import cookie from '~/lib/cookie'
import { USER_PREFERS_LARGE_PRODUCTS_CARDS_COOKIE_NAME } from '~/lib/constants'

/**
 * @typedef {ProductCardSettingService}
 * @alias this.$productCardSettingService
 */
export class ProductCardSettingService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  /**
   * @param {boolean} userPrefersLargeProductCards
   * @returns {Promise<void>}
   */
  setUserPrefersLargeProductCards(userPrefersLargeProductCards) {
    cookie(USER_PREFERS_LARGE_PRODUCTS_CARDS_COOKIE_NAME, userPrefersLargeProductCards)

    return this.store.dispatch('setUserPrefersLargeProductCards', userPrefersLargeProductCards)
  }

  /**
   * @returns {Promise<void>}
   */
  toggleUserPrefersLargeProductCards() {
    return this.setUserPrefersLargeProductCards(!this.store.state.userPrefersLargeProductCards)
  }
}
