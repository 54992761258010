
  import { mapState, mapGetters } from 'vuex'

  import getNavigationLinks from '~/lib/get-navigation-links'

  export default {
    props: {
      navigation: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        navigationItemActiveHover: null,
      }
    },
    computed: {
      ...mapState({
        customerGroupId: state => state.user.customerGroupId,
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
      loggedInNavigationItems() {
        const filteredNavigationLinks = getNavigationLinks(this.navigation.items, this.isAuthenticated, this.customerGroupId)
        return this.mapNavigationItems(filteredNavigationLinks)
      },
      navigationItems() {
        const filteredNavigationLinks = getNavigationLinks(this.navigation.items, false)
        return this.mapNavigationItems(filteredNavigationLinks)
      },
      subNavigationList() {
        return this.navigation.items
          .filter(item => item.subNavigation)
          .reduce(( acc, cur ) => {
            acc[cur.label] = cur.subNavigation
            return acc
          }, {})
      },
      subNavigation() {
        return this.subNavigationList[this.navigationItemActiveHover]
      },
      subNavigationCategories() {
        return this.subNavigation?.categories.filter(category => category.includeInMenu) || []
      },
    },
    methods: {
      onNavigationLeave() {
        this.$refs?.navigationDesktopList?.onNavigationLeave?.()
      },
      handleSetNavigationItemActiveHover(payload) {
        this.navigationItemActiveHover = payload
      },
      mapNavigationItems(navigationItems) {
        // This if-statement checks whether the router path (without params)
        // match the current item.categoryUrlPath. We use this to add a CSS class
        // for the navigation item's active state. Nuxt's built-in classes are not
        // useful in this use-case because of the URL parameters. (exact won't match)
        return navigationItems.map(item => {
          // return this.navigation.items.map(item => {
          if (this.$nuxt.$route.path.endsWith(item.categoryUrlPath + '/')) {
            return { ...item, isActive: true }
          } else {
            return { ...item, isActive: false }
          }
        })
      },
    },
  }
