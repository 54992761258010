import { INITIAL_CART_ID } from '~/lib/constants'

export default function () {
  return {
    cartDiscount: null,
    cartId: INITIAL_CART_ID,
    cartItems: [],
    cartGiftCard: null,
    cartGrandTotal: 0,
    cartSubTotal: 0,
    cartSubTotalExclTax: 0,
    cartTotal: 0,
    cartWalletTotal: null,
    // Cart will be virtual if only contain gift cards
    cartIsVirtual: false,
    donation: null,
    freeShippingThreshold: null,
    paymentMethods: [],
    processingOrder: false,
    selectedDeliveryMoment: null,
    selectedPaymentMethod: null,
    selectedPaymentMethodDetails: null,
    selectedShippingMethod: null,
    selectedServicePoint: null,
    shippingAddress: null,
    shippingMethods: null,
    cartTotalWithoutDiscount: null,
    cartGrandTotalWithoutDiscount: null,
    totalDiscount: null,
    checkoutIsLoading: false,
    applePaySession: null,
  }
}
