/**
 * Removes all empty key/value pairs, empty string and empty array's from an object
 * @param {Object} object
 * @returns {Object}
 */
export default function(object) {
  if (!object) {
    return object
  }

  return Object.keys(object).reduce((acc, currentValue) => {
    if (
      object[currentValue] === null
      || object[currentValue] === undefined
      || object[currentValue]?.length === 0
      || object[currentValue]?.filter?.(item => item?.length > 0).length === 0
    ) {
      return acc
    } else {
      return {
        ...acc,
        [currentValue]: object[currentValue],
      }
    }
  }, {})
}
