import { render, staticRenderFns } from "./shop-the-look-button.vue?vue&type=template&id=5e0a3c94"
import script from "./shop-the-look-button.vue?vue&type=script&lang=js"
export * from "./shop-the-look-button.vue?vue&type=script&lang=js"
import style0 from "./shop-the-look-button.vue?vue&type=style&index=0&id=5e0a3c94&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OnboardingPopup: require('/opt/build/repo/src/client/components/onboarding-popup/onboarding-popup.vue').default,SvgIcon: require('/opt/build/repo/src/client/components/svg-icon/svg-icon.vue').default,AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default})
