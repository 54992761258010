export default {
  setIsDesktop(context, payload) {
    context.commit('SET_IS_DESKTOP', payload)
  },

  setIsMobile(context, payload) {
    context.commit('SET_IS_MOBILE', payload)
  },

  setIsHoverSupported(context, payload) {
    context.commit('SET_IS_HOVER_SUPPORTED', payload)
  },

  setMediaQueryInitialized(context, payload) {
    context.commit('SET_MEDIA_QUERY_INITIALIZED', payload)
  },

  setIsImageLoadingSupported(context, payload) {
    context.commit('SET_IS_IMAGE_LOADING_SUPPORTED', payload)
  },

  setCookiesAccepted(context, payload) {
    context.commit('SET_COOKIES_ACCEPTED', payload)
  },

  addFilterToAppHeader(context) {
    context.commit('ADD_FILTER_TO_APP_HEADER')
  },

  removeFilterFromAppHeader(context) {
    context.commit('REMOVE_FILTER_FROM_APP_HEADER')
  },

  setCurrentRouteIsProductsPage(context, payload) {
    context.commit('SET_CURRENT_ROUTE_IS_PRODUCTS_PAGE', payload)
  },

  setCurrentProductPrimaryCategory(context, payload) {
    context.commit('SET_CURRENT_PRODUCT_PRIMARY_CATEGORY', payload)
  },

  setShowChatButton(context, payload) {
    context.commit('SET_SHOW_CHAT_BUTTON', payload)
  },

  setLoqateLoaded(context, payload) {
    context.commit('SET_LOQATE_LOADED', payload)
  },

  /**
   * @param context
   * @param {boolean} userPrefersLargeProductCards
   * @returns {Promise<void>}
   */
  setUserPrefersLargeProductCards(context, userPrefersLargeProductCards) {
    context.commit('SET_USER_PREFERS_LARGE_PRODUCT_CARDS', userPrefersLargeProductCards)
  },

  /**
   * @param context
   * @param {boolean} socialRetailIsActive
   * @returns {Promise<void>}
   */
  setSocialRetailIsActive(context, socialRetailIsActive) {
    context.commit('SET_SOCIAL_RETAIL_IS_ACTIVE', socialRetailIsActive)
  },

  /**
   * @param context
   * @param {boolean} isApp
   * @returns {Promise<void>}
   */
  setIsApp(context, isApp) {
    context.commit('SET_IS_APP', isApp)
  },
}
