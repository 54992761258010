import { GLOBAL_E_EXCLUDED_COUNTRIES } from '~/lib/constants'

export default {
  /**
   * @param {Object} state
   * @returns {boolean}
   */
  isGlobalECustomer(state) {
    if (!state.country) return false

    return !GLOBAL_E_EXCLUDED_COUNTRIES.includes(state.country)
  },
}
