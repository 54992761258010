/**
 * @param {string} sku
 * @param {Object} [priceAmount] in cents
 * @param {number} [size]
 * @param {Object} [_source]
 * @return {Object}
 */
export default ({ sku, priceAmount, size = 24, _source }) => {
  return {
    query: {
      bool: {
        must: {
          wildcard: {
            'publicData.magentoSku': {
              value: `${sku.slice(0, -2)}*`,
            },
          },
        },
        must_not: {
          term: {
            'author.publicData.storeIsClosed': true,
          },
        },
        ...(priceAmount && {
          filter: {
            range: {
              'price.amount': {
                lt: priceAmount,
              },
            },
          },
        }),
      },
    },
    sort: [
      {
        'price.amount': {
          order: 'asc',
        },
      },
    ],
    size,
    ...(_source && { _source }),
  }
}
