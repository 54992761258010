import { INITIAL_CUSTOMER_GROUP_ID } from '~/lib/constants'

export default function () {
  return {
    addresses: [],
    customerGroupId: INITIAL_CUSTOMER_GROUP_ID,
    email: null,
    firstname: null,
    dateOfBirth: null,
    id: null,
    isBlocked: false,
    isInitialized: false,
    isSubscribedToNewsletter: false,
    lastname: null,
    token: null,
    websiteId: null,
    phoneNumber: null,
  }
}
