
  import { ValidationProvider } from 'vee-validate'

  export default {
    components: {
      ValidationProvider,
    },
    inheritAttrs: false,
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: '',
      },
      rules: {
        type: [ String, Object ],
        default: '',
        validator(value) {
          if (!value || typeof value === 'string') {
            return true
          }

          return (
            value.name &&
            (value.params) ? Object.keys(value.params).length > 0 : true
          )
        },
      },
      options: {
        type: Array,
        required: true,
      },
      emptyFirstOption: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['change'],
    data() {
      return {
        focussed: false,
      }
    },
    computed: {
      hasValidation() {
        return (
          typeof this.rules === 'string'
            ? this.rules.length
            : Object.keys(this.rules).length
        ) || this.$attrs.hasOwnProperty('required')
      },
    },
    methods: {
      onBlur() {
        this.focussed = false
      },
      onFocus() {
        this.focussed = true
      },
      updateSelect(event) {
        this.$emit('change', event.target.value)
      },
    },
  }
