export class PaymentMethod {
  /**
   * @param {string} id,
   * @param {string} title
   * @param {string} [imageSrc]
   * @param {PaymentMethodIssuer[]} [issuers]
   */
  constructor({
    id,
    title,
    imageSrc,
    issuers,

  }) {
    /** @type {string} */
    this.id = id

    /** @type {string} */
    this.title = title

    /** @type {null|string} */
    this.imageSrc = imageSrc ?? null

    /** @type {null|PaymentMethodIssuer[]} */
    this.issuers = issuers ?? null
  }
}
