/**
 * Convert a string to PascalCase
 *
 * @param {String} string
 *
 * Examples:
 * formatToPascalCase('hello_world') returns `HelloWorld`.
 * formatToPascalCase('fOO BAR') returns `FooBar`.
 *
 * @returns {String}
 */
export default (string) => {
  const words = string.match(/[a-z]+/gi)

  if (!words) return ''

  if (words.length === 1) {
    return  words[0].charAt(0).toUpperCase() + words[0].slice(1)
  }

  return words.map((word) =>
    word.charAt(0).toUpperCase() + word.substr(1).toLowerCase(),
  ).join('')
}
