import { render, staticRenderFns } from "./product-color-variation-dots.vue?vue&type=template&id=4756d088"
import script from "./product-color-variation-dots.vue?vue&type=script&lang=js"
export * from "./product-color-variation-dots.vue?vue&type=script&lang=js"
import style0 from "./product-color-variation-dots.vue?vue&type=style&index=0&id=4756d088&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductColorVariationDot: require('/opt/build/repo/src/client/components/product-color-variation-dot/product-color-variation-dot.vue').default})
