
  import getDatoToNuxtRoute from '~/lib/get-dato-to-nuxt-route'
  import isValidVideo from '~/lib/is-valid-video'
  import isValidImage from '~/lib/is-valid-image'

  export default {
    props: {
      isImage: {
        type: Boolean,
        default: false,
      },
      image: {
        type: Object,
        default: null,
        validator: isValidImage,
      },
      placeholderImage: {
        type: Object,
        default: null,
        validator: isValidImage,
      },
      isVideo: {
        type: Boolean,
        default: false,
      },
      ratio: {
        type: Object,
        default: null,
        validator: ratio => (typeof(ratio) === 'object' && ratio.width && ratio.height),
      },
      video: {
        type: Object,
        default: null,
        validator: isValidVideo,
      },
      products: {
        type: Array,
        default: null,
        validator(value) {
          return value.every(item => item.sku)
        },
      },
      link: {
        type: Object,
        default: null,
      },
      shopTheLookButtonIsLeft: {
        type: Boolean,
        default: false,
      },
      lazyLoadRootMargin: {
        type: String,
        default: '600px 0px 600px 0px',
      },
      ignoreLazyLoad: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        videoError: false,
        videoReady: false,
      }
    },
    computed: {
      component() {
        return this.ignoreLazyLoad ? 'div' : 'lazyLoad'
      },
      formatJpgParams() {
        // https://docs.imgix.com/apis/url/format
        // pngs won't be handled
        // also, because auto=format takes precedence, jpgs will be delivered as webp when possible
        return this.placeholderImage?.url.toLowerCase().endsWith('jpg') ? '&fm=pjpg' : ''
      },
      placeholderImageSrc() {
        return this.placeholderImage ? `${this.placeholderImage.url}?auto=format${this.formatJpgParams}&fit=crop&q=70&w=${this.video?.width || '375'}` : ''
      },
      fixedRatio() {
        if (this.ratio) {
          return this.ratio
        } else if (this.isImage && this.image && this.image.width && this.image.height) {
          return { width: this.image.width, height: this.image.height }
        } else if (this.isVideo && this.video && this.video.width && this.video.height) {
          return { width: this.video.width, height: this.video.height }
        } else {
          return { width: 16, height: 9 }
        }
      },
    },
    methods: {
      getDatoToNuxtRoute,
      isValidVideo,
      handleVideoError() {
        this.videoError = true
      },
      handleVideoReady() {
        this.videoReady = true
      },
    },
  }
