import { render, staticRenderFns } from "./products-view.vue?vue&type=template&id=6b03a950"
import script from "./products-view.vue?vue&type=script&lang=js"
export * from "./products-view.vue?vue&type=script&lang=js"
import style0 from "./products-view.vue?vue&type=style&index=0&id=6b03a950&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCardSkeleton: require('/opt/build/repo/src/client/components/product-card-skeleton/product-card-skeleton.vue').default,ProductCard: require('/opt/build/repo/src/client/components/product-card/product-card.vue').default})
