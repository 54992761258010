import { onLoad } from '~/lib/on-load'
import checkStatementPeriodically from '~/lib/check-statement-periodically'

/**
 * @typedef {GrinService}
 * @alias this.$grinService
 */
export class GrinService {
  #eventsQueue
  #grinInitializedTime

  constructor() {
    this.#eventsQueue = []
    this.#grinInitializedTime = null

    this.#init()
  }

  #init() {
    this.#grinInitializedTime = new Date().getTime()

    onLoad(() => {
      Grin = window.Grin || (window.Grin = [])
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js'
      script.onload = () => checkStatementPeriodically({
        statement: () => !!window.Grin,
        callback: () => this.#startGrinTracking(),
      })

      document.body.appendChild(script)
    }, 5000)
  }

  #startGrinTracking() {
    if (this.#eventsQueue.length > 0) {
      this.#eventsQueue.forEach(queuedEvent => window.Grin.push(queuedEvent))
      this.#eventsQueue = []
    }
  }

  /**
   * @param {string} currency
   * @param {string} orderId
   * @param {number} subTotalExclTax
   * @returns {number}
   */
  reportPurchase({ currency, orderId, subTotalExclTax }) {
    const event = ['conversion', subTotalExclTax, { order_number: orderId, currency }]

    if (window.Grin) {
      return window.Grin.push(event)
    }

    const currentTime = new Date().getTime()
    const oneMinute = 60000
    const trackEventsInQueue = currentTime < this.#grinInitializedTime + oneMinute

    if (trackEventsInQueue) {
      this.#eventsQueue.push(event)
    }
  }
}
