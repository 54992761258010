
  import {
    APP_TRAY,
    FLY_IN,
    SOCIAL_RETAIL_TRAY_NAME,
    SOCIAL_RETAIL_FLY_IN_NAME,
  } from '~/lib/constants'

  export default {
    computed: {
      socialRetail() {
        return this.$staticDataService.cmsLayoutsDefault?.socialRetail
      },
      buttonText() {
        return this.socialRetail?.buttonText ?? ''
      },
    },
    methods: {
      openTray() {
        this.$overlayService.setCurrentOverlay({
          mobile: {
            type: APP_TRAY,
            component: SOCIAL_RETAIL_TRAY_NAME,
          },
          desktop: {
            type: FLY_IN,
            component: SOCIAL_RETAIL_FLY_IN_NAME,
            options: { transitionOrigin: 'right' },
          },
        })
      },
    },
  }
