
  import deepmerge from 'deepmerge'
  import { mapActions, mapState } from 'vuex'

  import { APP_MODAL, FLY_IN, APP_TRAY } from '~/lib/constants'

  export default {
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
        settings: state => state.overlay.settings,
        mobile: state => state.overlay.mobile,
        desktop: state => state.overlay.desktop,
        activeAppModal: state => state.overlay.activeAppModal,
        activeAppModalTransition: state => state.overlay.activeAppModalTransition,
        activeAppModalOptions: state => state.overlay.activeAppModalOptions,
        activeFlyIn: state => state.overlay.activeFlyIn,
        activeFlyInTransition: state => state.overlay.activeFlyInTransition,
        activeFlyInOptions: state => state.overlay.activeFlyInOptions,
        activeAppTray: state => state.overlay.activeAppTray,
        activeAppTrayTransition: state => state.overlay.activeAppTrayTransition,
        activeAppTrayOptions: state => state.overlay.activeAppTrayOptions,
      }),

      desktopSettings() {
        return this.desktop ? deepmerge(this.settings, this.desktop) : this.settings
      },
      mobileSettings() {
        return this.mobile ? deepmerge(this.settings, this.mobile) : this.settings
      },
      overlaySettings() {
        return this.isMobile ? this.mobileSettings : this.desktopSettings
      },
    },
    watch: {
      isMobile: {
        handler() {
          this.$overlayService.closeAppModal()
          this.$overlayService.closeAppTray()
          this.$overlayService.closeFlyIn()
        },
      },
      overlaySettings: {
        immediate: true,
        handler() {
          this.setCorrectOverlay()
        },
      },
    },
    methods: {
      ...mapActions({
        setActiveAppModal: 'overlay/setActiveAppModal',
        setActiveAppModalTransition: 'overlay/setActiveAppModalTransition',
        setActiveAppModalOptions: 'overlay/setActiveAppModalOptions',
        setActiveAppTray: 'overlay/setActiveAppTray',
        setActiveAppTrayTransition: 'overlay/setActiveAppTrayTransition',
        setActiveAppTrayOptions: 'overlay/setActiveAppTrayOptions',
        setActiveFlyIn: 'overlay/setActiveFlyIn',
        setActiveFlyInTransition: 'overlay/setActiveFlyInTransition',
        setActiveFlyInOptions: 'overlay/setActiveFlyInOptions',
      }),
      setCorrectOverlay() {
        if (!this.overlaySettings) {
          return
        }

        if (this.overlaySettings.type === APP_MODAL) {
          this.setActiveAppModalTransition({ activeAppModalTransition: this.overlaySettings?.transition ?? 'fade' })
          this.setActiveAppModalOptions({ activeAppModalOptions: this.overlaySettings.options })
          this.setActiveAppModal({ activeAppModal: this.overlaySettings.component })
        }

        if (this.overlaySettings.type === APP_TRAY) {
          this.setActiveAppTrayTransition({ activeAppTrayTransition: this.overlaySettings?.transition ?? 'app-tray-transition' })
          this.setActiveAppTrayOptions({ activeAppTrayOptions: this.overlaySettings.options })
          this.setActiveAppTray({ activeAppTray: this.overlaySettings.component })
        }

        if (this.overlaySettings.type === FLY_IN) {
          this.setActiveFlyInTransition({ activeFlyInTransition: this.overlaySettings?.transition ?? 'fly-in-transition' })
          this.setActiveFlyInOptions({ activeFlyInOptions: this.overlaySettings.options })
          this.setActiveFlyIn({ activeFlyIn: this.overlaySettings.component })
        }
      },
    },
  }
