import checkStatementPeriodically from '~/lib/check-statement-periodically'

export default (context) => {
  const locale = context.i18n.locale

  if (locale !== 'en') return

  checkStatementPeriodically({
    statement: () => window?.$nuxt?.isHydrated,
    callback: () => init(),
  })
}

function init() {
  const script = document.createElement('script')
  script.src = process.env.globalEScriptSrc
  script.type = 'text/javascript'

  const style = document.createElement('link')
  style.rel = 'stylesheet'
  style.type = 'text/css'
  style.href = process.env.globalEStyleSrc

  document.body.appendChild(script)
  document.body.appendChild(style)
}
