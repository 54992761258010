
  import Mailcheck from 'mailcheck'
  import debounce from 'lodash.debounce'

  export default {
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
      rules: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      hideValidationCheckmark: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['input'],
    data() {
      return {
        suggestedEmail: null,
        mailCheckDebounce: debounce(this.mailCheck, 500),
      }
    },
    computed: {
      validationRules() {
        return this.rules ? `${this.rules}|email` : 'email'
      },
    },
    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.verifyEmail(newVal)
        }
      },
    },
    methods: {
      setEmail() {
        this.$emit('input', this.suggestedEmail)
        this.suggestedEmail = null
      },
      verifyEmail(value) {
        this.$emit('input', value)
        this.mailCheckDebounce(value)
      },
      mailCheck(value) {
        this.suggestedEmail = null

        Mailcheck.run({
          email: value,
          suggested: (suggestion) => {
            this.suggestedEmail = suggestion.full
          },
        })
      },
    },
  }
