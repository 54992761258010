export default {
  '01': {
    description: 'BLACK',
    rgbCode: '#020305',
    showBorder: false,
  },
  '02': {
    description: 'WHITE',
    rgbCode: '#EFEFEF',
    showBorder: false,
  },
  '03': {
    description: 'OFFWHITE',
    rgbCode: '#F6F6EE',
    showBorder: false,
  },
  '04': {
    description: 'ECRU',
    rgbCode: '#F2ECDC',
    showBorder: false,
  },
  '05': {
    description: 'BEIGE',
    rgbCode: '#E9E2CF',
    showBorder: false,
  },
  '06': {
    description: 'SAND',
    rgbCode: '#E8CDA7',
    showBorder: false,
  },
  '07': {
    description: 'TAUPE',
    rgbCode: '#C0B8AA',
    showBorder: false,
  },
  '08': {
    description: 'CAMEL',
    rgbCode: '#BA9566',
    showBorder: false,
  },
  '09': {
    description: 'BROWN',
    rgbCode: '#783D04',
    showBorder: false,
  },
  '10': {
    description: 'BROWN LIGHT',
    rgbCode: '#914903',
    showBorder: false,
  },
  '11': {
    description: 'BROWN DARK',
    rgbCode: '#522901',
    showBorder: false,
  },
  '12': {
    description: 'COGNAC',
    rgbCode: '#A96F42',
    showBorder: false,
  },
  '13': {
    description: 'GREY',
    rgbCode: '#969696',
    showBorder: false,
  },
  '14': {
    description: 'GREY LIGHT',
    rgbCode: '#BFBABA',
    showBorder: false,
  },
  '15': {
    description: 'GREY DARK',
    rgbCode: '#636363',
    showBorder: false,
  },
  '16': {
    description: 'RED',
    rgbCode: '#E30707',
    showBorder: false,
  },
  '17': {
    description: 'RED LIGHT',
    rgbCode: '#FC3D3D',
    showBorder: false,
  },
  '18': {
    description: 'RED DARK',
    rgbCode: '#BA0000',
    showBorder: false,
  },
  '19': {
    description: 'BORDEAUX',
    rgbCode: '#790129',
    showBorder: false,
  },
  '20': {
    description: 'ORANGE',
    rgbCode: '#F86300',
    showBorder: false,
  },
  '21': {
    description: 'BRIQUE',
    rgbCode: '#AF4131',
    showBorder: false,
  },
  '22': {
    description: 'CORAL',
    rgbCode: '#F46E65',
    showBorder: false,
  },
  '23': {
    description: 'PINK',
    rgbCode: '#FCAEC8',
    showBorder: false,
  },
  '24': {
    description: 'PINK LIGHT',
    rgbCode: '#FCC5D7',
    showBorder: false,
  },
  '25': {
    description: 'PINK DARK',
    rgbCode: '#E388A6',
    showBorder: false,
  },
  '26': {
    description: 'MAUVE',
    rgbCode: '#B181A2',
    showBorder: false,
  },
  '27': {
    description: 'FUCHSIA',
    rgbCode: '#ED05EC',
    showBorder: false,
  },
  '28': {
    description: 'LILAC',
    rgbCode: '#C29DC2',
    showBorder: false,
  },
  '29': {
    description: 'PURPLE',
    rgbCode: '#BB6EDB',
    showBorder: false,
  },
  '30': {
    description: 'PURPLE LIGHT',
    rgbCode: '#C28AF2',
    showBorder: false,
  },
  '31': {
    description: 'PURPLE DARK',
    rgbCode: '#9F52BF',
    showBorder: false,
  },
  '32': {
    description: 'BLUE',
    rgbCode: '#009EFF',
    showBorder: false,
  },
  '33': {
    description: 'BLUE LIGHT',
    rgbCode: '#00B4FF',
    showBorder: false,
  },
  '34': {
    description: 'BLUE DARK',
    rgbCode: '#0066FF',
    showBorder: false,
  },
  '35': {
    description: 'TURQUOISE',
    rgbCode: '#0BD2C4',
    showBorder: false,
  },
  '36': {
    description: 'MINT',
    rgbCode: '#A6E9CA',
    showBorder: false,
  },
  '37': {
    description: 'GREEN',
    rgbCode: '#20AD0B',
    showBorder: false,
  },
  '38': {
    description: 'GREEN LIGHT',
    rgbCode: '#31C110',
    showBorder: false,
  },
  '39': {
    description: 'GREEN DARK',
    rgbCode: '#2B980A',
    showBorder: false,
  },
  '40': {
    description: 'KHAKI',
    rgbCode: '#85825A',
    showBorder: false,
  },
  '41': {
    description: 'YELLOW',
    rgbCode: '#FAFA00',
    showBorder: false,
  },
  '42': {
    description: 'YELLOW LIGHT',
    rgbCode: '#FFFF6B',
    showBorder: false,
  },
  '43': {
    description: 'YELLOW DARK',
    rgbCode: '#D4D40B',
    showBorder: false,
  },
  '44': {
    description: 'GOLD',
    rgbCode: '#FFD700',
    showBorder: false,
  },
  '45': {
    description: 'SILVER',
    rgbCode: '#C0C0C0',
    showBorder: false,
  },
  '46': {
    description: 'TRANSPARENT',
    rgbCode: '#FFFFFF',
    showBorder: true,
  },
  '47': {
    description: 'BLACK-USED',
    rgbCode: '#3B3B3B',
    showBorder: false,
  },
  '48': {
    description: 'BLEACHED',
    rgbCode: '#CCDEF7',
    showBorder: false,
  },
  '49': {
    description: 'STONEWASHED',
    rgbCode: '#93B5E6',
    showBorder: false,
  },
  '50': {
    description: 'LIME',
    rgbCode: '#C7F207',
    showBorder: false,
  },
}
