import { render, staticRenderFns } from "./product-shout-out.vue?vue&type=template&id=e73e97a2"
import script from "./product-shout-out.vue?vue&type=script&lang=js"
export * from "./product-shout-out.vue?vue&type=script&lang=js"
import style0 from "./product-shout-out.vue?vue&type=style&index=0&id=e73e97a2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnimatedAppLabel: require('/opt/build/repo/src/client/components/animated-app-label/animated-app-label.vue').default,AppLabel: require('/opt/build/repo/src/client/components/app-label/app-label.vue').default})
