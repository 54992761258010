
  export default {
    props: {
      role: {
        type: String,
        default: '',
      },
    },
    computed: {
      modifier() {
        if (!this.role) {
          return ''
        }

        return `app-alert--${this.role}`
      },
    },
  }
