
  import { mapState, mapGetters } from 'vuex'
  import { ValidationObserver } from 'vee-validate'

  import addressContainsMinimalValidData from '~/lib/checkout/address-contains-minimal-valid-data'
  import formatMagentoErrorMessage from '~/lib/format-magento-error-message'

  export default {
    components: {
      ValidationObserver,
    },
    props: {
      textColor: {
        type: String,
        default: '',
      },
      emailInputId: {
        type: String,
        default: 'newsletter-signup-form',
      },
      feedbackColor: {
        type: String,
        default: '',
      },
      background: {
        type: String,
        default: null,
      },
      newsletterFormBorder: {
        type: String,
        default: null,
      },
      newsletterSubscriptionTag: {
        type: String,
        default: null,
      },
      hasFullWidthButton: {
        type: Boolean,
        default: false,
      },
      hasPhoneNumberField: {
        type: Boolean,
        default: false,
      },
      silentMessages: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        email: '',
        telephone: null,
        subscribed: false,
        errorMessage: '',
        isPending: false,
        showPhoneNumberField: true,
        userPhoneNumber: null,
      }
    },
    computed: {
      ...mapState({
        userIsInitialized: state => state.user.isInitialized,
        userEmail: state => state['user'].email,
        userId: state => state.user.id,
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
        defaultBillingAddress: 'user/defaultBillingAddress',
      }),
      userIsLoggedIn() {
        return this.userIsInitialized && this.isAuthenticated
      },
      phoneNumberFieldIsEnabled() {
        return this.hasPhoneNumberField && this.userIsLoggedIn && !this.userPhoneNumber && this.hasValidBillingAddress
      },
      hasValidBillingAddress() {
        return addressContainsMinimalValidData(this.defaultBillingAddress)
      },
    },
    watch: {
      userIsInitialized: {
        immediate: true,
        handler() {
          this.prefillEmail()
        },
      },
      isAuthenticated: {
        immediate: true,
        handler() {
          this.prefillEmail()
        },
      },
    },
    mounted() {
      this.userPhoneNumber = this.defaultBillingAddress?.telephone ?? null
    },
    methods: {
      prefillEmail() {
        if (
          !this.userIsLoggedIn
          || !this.userEmail?.length
        ) {
          return
        }

        this.email = this.userEmail
      },
      async onSubmit() {
        const validFormValues = await this.$refs.newsletterSignupForm?.validate()

        if (!validFormValues) {
          return
        }

        this.isPending = true

        return this.$newsletterService
          .subscribeWithEmail({
            emailAddress: this.email,
            newsletterSubscriptionTag: this.newsletterSubscriptionTag,
            silentErrors: true,
            silentMessages: this.silentMessages,
          })
          .then(this.handleSubscribed)
          .catch(this.handleError)
          .finally(() => {
            this.isPending = false
            this.updatePhoneNumber()
          })
      },
      updatePhoneNumber() {
        if (!this.phoneNumberFieldIsEnabled || !this.showPhoneNumberField || !this.telephone) return

        try {
          this.$userAddressService.updateAddress({
            ...this.defaultBillingAddress,
            telephone: this.telephone,
            customerId: this.userId,
          })
        } catch (error) {
          if (!process.client || process.env.NODE_ENV !== 'production') {
            console.error(`Error updating address:`, error)
          }
        }
      },
      handleSubscribed() {
        if (this.errorMessage) {
          this.errorMessage = ''
        }

        this.subscribed = true

        this.$emit('subscribed')
      },
      handleError(error) {
        this.errorMessage = formatMagentoErrorMessage(error?.response?.data) || this.$t('error_newsletter')

        this.$emit('error')
      },
    },
  }
