import formatMagentoErrorMessage from '~/lib/format-magento-error-message'

/**
 * @typedef {CartDiscountService}
 * @alias this.$cartDiscountService
 */
export class CartDiscountService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
    this.i18n = context.i18n
  }

  init() {
    this.$checkoutApiService = this.context.$checkoutApiService
    this.$cartService = this.context.$cartService
    this.$cartShippingMethodService = this.context.$cartShippingMethodService
  }

  /**
   * @param {string} discountCode
   * @returns {Promise}
   */
  async addDiscountCodeToCart(discountCode) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    try {
      await this.$checkoutApiService.addDiscountCodeToCart({
        discountCode,
        cartId,
      })
    } catch (error) {
      return Promise.reject(formatMagentoErrorMessage(error?.response?.data) || this.i18n.t('something_went_wrong'))
    }

    // Discount code can give free shipping, this is why we update shipping methods
    return this.updateShippingAndCart()
  }

  /**
   * @returns {Promise}
   */
  async removeDiscountCodeFromCart() {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    await this.$checkoutApiService.removeDiscountCodeFromCart(cartId)

    // Discount code can give free shipping, this is why we update shipping methods
    return this.updateShippingAndCart()
  }

  /**
   * @param {string} giftCardCode
   * @returns {Promise}
   */
  async addGiftCardToCart(giftCardCode) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    try {
      await this.$checkoutApiService.addGiftCardToCart({
        giftCardCode,
        cartId,
      })
    } catch (error) {
      return Promise.reject(formatMagentoErrorMessage(error?.response?.data) || this.i18n.t('something_went_wrong'))
    }

    // Gift cards can give free shipping, this is why we update shipping methods
    return this.updateShippingAndCart()
  }

  /**
   * @returns {Promise}
   */
  async removeGiftCardFromCart(giftCardCode) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    await this.$checkoutApiService.removeGiftCardFromCart({
      giftCardCode,
      cartId,
    })

    // Gift cards can give free shipping, this is why we update shipping methods
    return this.updateShippingAndCart()
  }

  async updateShippingAndCart() {
    // Update cart is called in fetchCartShippingMethods -> updateSelectedShippingMethod
    await this.$cartShippingMethodService.fetchCartShippingMethods()
    await this.$cartShippingMethodService.updateCartShippingInformation()
  }
}
