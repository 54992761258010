export default (searchQuery) => ({
  size: 0,
  query: {
    multi_match: {
      query: searchQuery,
      type: 'bool_prefix',
      operator: 'and',
      fields: [
        'suggested_terms',
        'suggested_terms._2gram',
        'suggested_terms._3gram',
      ],
    },
  },
  aggs: {
    suggestions: {
      terms: {
        field: 'suggested_keywords',
      },
    },
  },
})
