
  import AppImage from '@loavies/component-library/dist/app-image'

  export default {
    components: {
      AppImage,
    },
    props: {
      centered: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      socials() {
        return this.$staticDataService.cmsLayoutsDefault?.socials ?? []
      },
    },
  }
