import { LOCATION_RECENTLY_VIEWED_PRODUCTS } from '~/lib/constants'
import { carouselRecordType } from '~/lib/fetch-products-for-carousel-configs'

/**
 * @typedef {ProductCarouselService}
 * @alias this.$productCarouselService
 */
export class ProductCarouselService {
  #carouselTypes = {
    recentlyViewedProducts: 'recently_viewed_products',
  }

  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.store = context.store
  }

  /**
   * @param {Object} datoCarouselRecord
   */
  getProductsByDatoCarouselRecord(datoCarouselRecord) {
    if (datoCarouselRecord.__typename !== carouselRecordType) {
      return Error(`Record is not of type ${carouselRecordType}`)
    }

    if (datoCarouselRecord.productCarousel.userContentCarousel === this.#carouselTypes.recentlyViewedProducts) {
      return this.store.state['recently-viewed-products'].products
    }

    return datoCarouselRecord.productCarousel.products
  }

  /**
   * @param {string} productCarouselType
   * @returns {null|Object[]}
   */
  getProductsByCarouselType(productCarouselType) {
    if (productCarouselType === this.#carouselTypes.recentlyViewedProducts) {
      return this.store.state['recently-viewed-products'].products
    }

    return null
  }

  /**
   * @param {string} productCarouselType
   * @returns {null|string}
   */
  getLocationByCarouselType(productCarouselType) {
    if (productCarouselType === this.#carouselTypes.recentlyViewedProducts) {
      return LOCATION_RECENTLY_VIEWED_PRODUCTS
    }

    return null
  }
}
