
  import { mapState } from 'vuex'
  import { LOCATION_MODULAR_CONTENT } from '~/lib/constants'
  import { carouselRecordType } from '~/lib/fetch-products-for-carousel-configs'
  import { productsViewRecordType } from '~/lib/fetch-products-for-products-view-configs'
  import { isValidProduct } from '~/lib/product-validation'

  export default {
    props: {
      content: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        locationModularContent: LOCATION_MODULAR_CONTENT,
        carouselRecordType,
        productsViewRecordType,
      }
    },
    computed: {
      ...mapState({
        recentlyViewedProducts: state => state['recently-viewed-products'].products,
      }),
    },
    methods: {
      isValidProduct,
      goToCategory(categoryId) {
        const categoryUrl = this.$staticDataService.getCategoryUrlPathById(categoryId, this.$i18n.locale)
        this.$router.push({ path: categoryUrl, query: { categoryId } }).catch(() => {})
      },
      getUserContentCarouselProducts(productCarouselType) {
        return this.$productCarouselService.getProductsByCarouselType(productCarouselType)
      },
      getUserContentCarouselLocation(productCarouselType) {
        return this.$productCarouselService.getLocationByCarouselType(productCarouselType) || LOCATION_MODULAR_CONTENT
      },
    },
  }
