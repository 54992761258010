import { PHONE_NUMBER_ATTRIBUTE_CODE } from '~/lib/constants'

/**
 * @typedef {UserApiService}
 * @alias this.$userApiService
 */
export class UserApiService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.defaultPageSize = 10
  }

  init() {
    this.$magentoApiService = this.context.$magentoApiService
    this.$userApiMapperService = this.context.$userApiMapperService
  }

  /**
   * @param {string} email
   * @param {string} password
   * @param {boolean} [scopeapp]
   * @returns {Promise<string>} returns token
   */
  login({
    email,
    password,
    scopeapp = false,
  }) {
    let url = '/integration/customer/token'

    // Checks if user needs to be put in a specific customer group in Magento
    if (scopeapp) {
      url += '?scopeapp=true'
    }

    return this.$magentoApiService
      .post(url, {
        username: email,
        password,
      })
      .then(response => response.data)
  }

  /**
   * @param {string} email
   * @returns {Promise<boolean>}
   */
  isEmailAvailable(email) {
    return this.$magentoApiService
      .post('/customers/isEmailAvailable', { customerEmail: email })
      .then(response => response.data)
  }

  /**
   * @param {string} email
   * @param {string} password
   * @param {string} firstname
   * @param {string} lastname
   * @param {string} [phonenumber]
   * @param {boolean} [subscribeForNewsletter]
   * @param {boolean} [scopeapp]
   * @returns {Promise}
   */
  register({
    email,
    password,
    firstname,
    lastname,
    telephone,
    subscribeForNewsletter = false,
    scopeapp = false,
  }) {
    let url = '/customers'

    // Checks if user needs to be put in a specific customer group in Magento
    if (scopeapp) {
      url += '?scopeapp=true'
    }

    return this.$magentoApiService.post(url, {
      password,
      customer: {
        email,
        firstname,
        lastname,
        ...(subscribeForNewsletter && {
          extension_attributes: {
            is_subscribed: true,
          },
        }),
        ...(telephone && {
          custom_attributes: [{
            attribute_code: PHONE_NUMBER_ATTRIBUTE_CODE,
            value: telephone,
          }],
        }),
      },
    })
  }

  /**
   * @returns {Promise<Object>}
   */
  getUser() {
    return this.$magentoApiService
      .get('/customers/me')
      .then(response => response.data)
      .then(data => {
        data.addresses = data.addresses.map(address => this.$userApiMapperService.mapAddressFromApi(address))

        return data
      })
  }

  /**
   * @param {string} email
   * @param {string} firstname
   * @param {string} lastname
   * @param {string} [dob]
   * @param {string} [telephone]
   * @returns {Promise<Object>}
   */
  updateUser({
    email,
    firstname,
    lastname,
    dob,
    telephone,
  }) {
    return this.$magentoApiService
      .put('/customers/me', {
        customer: {
          email,
          firstname,
          lastname,
          ...(dob && { dob }),
          ...(telephone && {
            custom_attributes: [
              {
                attribute_code: PHONE_NUMBER_ATTRIBUTE_CODE,
                value: telephone,
              },
            ],
          }),
        },
      })
      .then(response => response.data)
      .then(data => {
        data.addresses = data.addresses.map(address => this.$userApiMapperService.mapAddressFromApi(address))

        return data
      })
  }

  /**
   * @param {string} currentPassword
   * @param {string} newPassword
   * @returns {Promise}
   */
  updatePassword({ currentPassword, newPassword }) {
    return this.$magentoApiService
      .put('customers/me/password', {
        currentPassword,
        newPassword,
      })
      .then(response => response.data)
  }

  /**
   * Returns added address
   * @param {Object} address
   * @returns {Promise<Object>}
   */
  addAddress(address) {
    return this.$magentoApiService
      .post('/customer/addresses', { address })
      .then(response => response.data)
  }

  /**
   * Returns updated address
   * @param {Object} address
   * @returns {Promise<Object>}
   */
  updateAddress(address) {
    return this.$magentoApiService
      .put('/customer/addresses', { address })
      .then(response => response.data)
  }

  /**
   * @param {number} addressId
   * @returns {Promise<boolean>}
   */
  deleteAddress(addressId) {
    return this.$magentoApiService
      .delete(`/customer/addresses/${addressId}`)
      .then(response => response.data)
  }

  /**
   * @param {number} [pageSize]
   * @param {number} [currentPage]
   * @returns {Promise<Object>}
   */
  getOrders({
    pageSize = this.defaultPageSize,
    currentPage = 1,
  } = {}) {
    return this.$magentoApiService
      .get(`/customers/me/order-history?pageSize=${pageSize}&currentPage=${currentPage}`)
      .then(response => response.data)
  }

  /**
   * @param {number|string} id
   * @returns {Promise<Object>}
   */
  getOrder(id) {
    return this.$magentoApiService
      .get(`/customers/me/order/${id}`)
      .then(response => response.data)
  }

  /**
   * @returns {Promise<Object|null>}
   */
  getLatestOrder() {
    return this.getOrders({ pageSize: 1 })
      .then(data => data?.items?.[0] ?? null)
  }

  /**
   * @param {string} email
   * @returns {Promise<boolean>}
   */
  requestPasswordResetLink(email) {
    return this.$magentoApiService
      .put('/customers/password', {
        email,
        template: 'email_reset',
        websiteId: 32,
      })
      .then(response => response.data)
  }

  /**
   * @param {string} email
   * @param {string} resetToken
   * @param {string} newPassword
   * @returns {Promise}
   */
  resetPasswordWithResetToken({
    email,
    resetToken,
    newPassword,
  }) {
    return this.$magentoApiService
      .post('customers/resetPassword', {
        email,
        resetToken,
        newPassword,
      })
      .then(response => response.data)
  }

  /**
   * @param {string} cid
   * @param {string} token
   * @returns {Promise}
   */
  checkResetTokenValidity({
    cid,
    token,
  }) {
    return this.$magentoApiService
      .get(`customers/${cid}/password/resetLinkToken/${token}`)
      .then(response => response.data)
  }
}
