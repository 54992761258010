export function isInStock(product) {
  if (!product) {
    return false
  }

  if (product.configurable_options) {
    return product.configurable_options[0].options.some(option => option.product_qty > 0)
  }

  if (typeof product?.stock?.qty === 'number') {
    return product.stock.qty > 0
  }

  return product.stock && Boolean(product.stock.is_in_stock)
}
