import cloneDeep from 'lodash.clonedeep'

import { readFromLocalStorage, removeFromLocalStorage, writeToLocalStorage } from '~/lib/local-storage'
import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'

/**
 * @typedef {WishlistService}
 * @alias this.$wishlistService
 */
export class WishlistService {
  #localWishlistName = 'local_wishlist'
  #guestItemId = 'guest'

  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$wishlistApiService = this.context.$wishlistApiService
    this.$productApiService = this.context.$productApiService
    this.$trackingService = this.context.$trackingService
  }

  /**
   * Fetches wishlist and sets it in the store
   * @returns {Promise<void>}
   */
  async fetchWishlist() {
    try {
      const isAuthenticated = this.store.getters['user/isAuthenticated']

      await this.store.dispatch('wishlist/setWishlistInitializing', true)

      const wishlist = isAuthenticated
        ? await this.#getWishlistForUser()
        : await this.#getWishlistFromLocalStorage()

      await this.store.dispatch('wishlist/setWishlist', wishlist)
    } catch (error) {
      if (!process.env.isNetlifyProduction) {
        console.error(error)
      }

      await this.store.dispatch('wishlist/setWishlist', {})
    } finally {
      await this.store.dispatch('wishlist/setWishlistInitializing', false)
    }
  }

  /**
   * @returns {Promise<Object>}
   */
  async #getWishlistForUser() {
    const localWishlist = readFromLocalStorage(this.#localWishlistName)

    if (localWishlist) {
      await this.#addLocalWishlistItemsToMagento(localWishlist)
    }

    const magentoWishlist = await this.$wishlistApiService.getItems()

    return this.#mapMagentoWishlist(magentoWishlist)
  }

  /**
   * @param {Object[]} magentoWishlist
   * @returns {Promise<Object>}
   */
  async #mapMagentoWishlist(magentoWishlist) {
    const skus = magentoWishlist.map(wishlistItem => wishlistItem.product.sku)
    const products = skus.length ? await this.$productApiService.getMultipleProductsBySku(skus) : []

    return magentoWishlist.reduce((mappedWishlist, wishlistItem) => {
      const index = products.findIndex(product => getPrimarySkuFromProduct(product) === wishlistItem.product.sku)

      if (index !== -1) {
        mappedWishlist[wishlistItem.product.sku] = cloneDeep(products[index])
        mappedWishlist[wishlistItem.product.sku].itemId = wishlistItem.item_id
        mappedWishlist[wishlistItem.product.sku].pageNumber = 1
      }

      return mappedWishlist
    }, {})
  }

  /**
   * @returns {Promise<Object>}
   */
  async #getWishlistFromLocalStorage() {
    const simpleLocalWishlist = readFromLocalStorage(this.#localWishlistName)
    const localWishlist = simpleLocalWishlist && await this.#mapLocalWishlist(simpleLocalWishlist)

    return localWishlist || {}
  }

  /**
   * @param {Object} [localWishlist]
   * @returns {Promise<Object>}
   */
  async #mapLocalWishlist(localWishlist = {}) {
    const skus = Object.keys(localWishlist)

    if (!skus.length) {
      return {}
    }

    const products = await this.$productApiService.getMultipleProductsBySku(skus)

    return skus.reduce((mappedWishlist, sku) => {
      const index = products.findIndex(product => getPrimarySkuFromProduct(product) === sku)

      if (index !== -1) {
        mappedWishlist[sku] = cloneDeep(products[index])
        mappedWishlist[sku].pageNumber = 1
        mappedWishlist[sku].itemId = this.#guestItemId
      }

      return mappedWishlist
    }, {})
  }

  /**
   * @param localWishlist
   * @returns {Promise<void>}
   */
  async #addLocalWishlistItemsToMagento(localWishlist) {
    const skus = Object.keys(localWishlist)
    const products = await this.$productApiService.getMultipleProductsBySku(skus)
    const addProductPromises = products.map(product => this.addProduct({
      product: {
        ...product,
        pageNumber: 1,
      },
      ignoreTracking: true,
    }))

    await Promise.all(addProductPromises)

    removeFromLocalStorage(this.#localWishlistName)
  }

  /**
   * @param {Object} product
   * @param {boolean} ignoreTracking
   * @returns {Promise}
   */
  async addProduct({ product, ignoreTracking = false }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']

    if (isAuthenticated) {
      await this.#addProductForUser(product)
    } else {
      await this.#addProductForGuest(product)
    }

    if (!ignoreTracking) {
      this.$trackingService.handleWishlistAction({ action: 'add', product })
    }
  }

  /**
   * @param {Object} product
   * @returns {Promise}
   */
  async #addProductForUser(product) {
    const sku = getPrimarySkuFromProduct(product)
    const { wishlist_item_id: itemId } = await this.$wishlistApiService.addItemBySku(sku)
    const mappedProduct = {
      itemId,
      ...cloneDeep(product),

      // We have to reset the pageNumber since products-view creates collections
      // based on this number. If we have different pageNumbers you get one div with
      // say 3 products and then another one with 2 if they come from a different
      // page.
      pageNumber: 1,
    }

    return this.store.dispatch('wishlist/addWishlistItem', { sku, product: mappedProduct })
  }

  /**
   * @param {Object} product
   * @returns {Promise}
   */
  async #addProductForGuest(product) {
    const sku = getPrimarySkuFromProduct(product)
    const mappedProduct = {
      itemId:this.#guestItemId,
      ...cloneDeep(product),

      // We have to reset the pageNumber since products-view creates collections
      // based on this number. If we have different pageNumbers you get one div with
      // say 3 products and then another one with 2 if they come from a different
      // page.
      pageNumber: 1,
    }

    await this.store.dispatch('wishlist/addWishlistItem', { sku, product: mappedProduct })

    const wishlistSkus = this.#convertWishlistToSkuMapping(this.store.state.wishlist.wishlist)

    writeToLocalStorage(this.#localWishlistName, wishlistSkus)
  }

  /**
   * @param {Object} product
   * @returns {Promise}
   */
  async removeProduct({ product }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']

    if (isAuthenticated) {
      await this.#removeProductForUser(product)
    } else {
      await this.#removeProductForGuest(product)
    }

    this.$trackingService.handleWishlistAction({ action: 'remove', product })
  }

  /**
   * @param {Object} product
   * @returns {Promise<void>}
   */
  async #removeProductForUser(product) {
    const sku = getPrimarySkuFromProduct(product)
    const { itemId } = this.store.state.wishlist.wishlist[sku]

    await this.$wishlistApiService.removeItemById(itemId)

    return this.store.dispatch('wishlist/deleteWishlistItemBySku', sku)
  }

  /**
   * @param {Object} product
   * @returns {Promise}
   */
  #removeProductForGuest(product) {
    const sku = getPrimarySkuFromProduct(product)
    const localWishlist = readFromLocalStorage(this.#localWishlistName)

    if (localWishlist) {
      delete localWishlist[sku]

      // Update the local wishlist if there are still products
      // otherwise remove the whole wishlist from localStorage.
      Object.keys(localWishlist).length
        ? writeToLocalStorage(this.#localWishlistName, localWishlist)
        : removeFromLocalStorage(this.#localWishlistName)
    }

    return this.store.dispatch('wishlist/deleteWishlistItemBySku', sku)
  }

  /**
   * @param {Object} wishlist
   * @return {Object}
   */
  #convertWishlistToSkuMapping(wishlist) {
    return Object.keys(wishlist).reduce((skus, sku) => {
      skus[sku] = true
      return skus
    }, {})
  }
}
