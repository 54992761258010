import Vue from 'vue'

export default {
  SET_WISHLIST(state, wishlist) {
    state.wishlist = wishlist
  },

  ADD_WISHLIST_ITEM(state, { product, sku }) {
    // Use an immutable pattern to update the state's wishlist
    Vue.set(state.wishlist, sku, product)
  },

  CLEAR_WISHLIST(state) {
    state.wishlist = {}
  },

  DELETE_WISHLIST_ITEM_BY_SKU(state, sku) {
    Vue.delete(state.wishlist, sku)
  },

  SET_WISHLIST_INITIALIZING(state, isInitializingWishlist) {
    state.isInitializingWishlist = isInitializingWishlist
  },
}
