
  import { mapState } from 'vuex'

  import AppImage from '@loavies/component-library/dist/app-image'

  import generateImageUrl from '~/lib/generate-image-url'

  export default {
    components: {
      AppImage,
    },
    props: {
      image: {
        type: Object,
        required: true,
      },
      widthStep: {
        type: Number,
        default: 100,
      },
      lazyLoadRootMargin: {
        type: String,
        default: '600px 0px 600px 0px',
      },
      ignoreLazyLoad: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        width: null,
        onResizeDebounce: null,
      }
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
      }),
    },
    watch: {
      isMobile() {
        if (this.width) {
          return
        }

        this.setWidth()
      },
    },
    mounted() {
      this.setWidth()
    },
    methods: {
      imageUrl(options) {
        const isGif = this.image.url.match(/\.gif$/) !== null
        const imageOptions = isGif ? null : options

        return generateImageUrl(this.image.url, imageOptions, isGif)
      },
      setWidth() {
        const pixelRatio = window.devicePixelRatio || 1
        const cssWidth = this.$el.getBoundingClientRect().width
        const width = Math.ceil(cssWidth * pixelRatio / this.widthStep) * this.widthStep

        this.width = Math.min(width, this.image.width)
      },
    },
  }
