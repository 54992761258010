/**
 * @param {Function} callback - the function to run after onLoad
 * @param {Number} delay - wait X milliseconds after onLoad
 */
export const onLoad = (callback, delay = 1) => {
  // If load event is already initialized, directly run callback
  if (document.readyState === 'complete') {
    setTimeout(() => callback(), delay)
  } else {
    window.addEventListener('load', function () {
      setTimeout(() => callback(), delay)
    })
  }
}
