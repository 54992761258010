import { CurrencyService } from '~/services/utils/currency-service'

const currencyService = new CurrencyService()

export default {
  /**
   * @param state
   * @returns {number|null}
   */
  spendableWalletAmount(state) {
    if (!state.wallet) {
      return null
    }

    return currencyService.parseCentsToAmount(state.wallet.balance.spendable)
  },

  /**
   * @param state
   * @returns {number|null}
   */
  spendableWalletAmountInCents(state) {
    return state.wallet?.balance.spendable ?? null
  },

  /**
   * @param state
   * @param getters
   * @param rootState
   * @returns {string}
   */
  formattedSpendableWalletAmount(state, getters, rootState) {
    const numberToFormat = typeof getters.spendableWalletAmountInCents === 'number'
      ? getters.spendableWalletAmountInCents
      : 0

    return currencyService.formatCentsToLocalePrice(numberToFormat, state.wallet?.currency_code, rootState.i18n.locale)
  },

  /**
   * @param state
   * @param getters
   * @param rootState
   * @returns {string}
   */
  formattedPayFromWalletAmount(state, getters, rootState) {
    const amountToFormat = typeof state.payFromWalletAmountInCents === 'number'
      ? Math.abs(state.payFromWalletAmountInCents)
      : 0

    return currencyService.formatCentsToLocalePrice(amountToFormat, state.wallet?.currency_code, rootState.i18n.locale)
  },

  /**
   * @param state
   * @returns {number|null}
   */
  payFromWalletAmount(state) {
    if (!state.wallet || typeof state.payFromWalletAmountInCents !== 'number') {
      return null
    }

    return currencyService.parseCentsToAmount(state.payFromWalletAmountInCents)
  },
}
