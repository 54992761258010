import cookie from '~/lib/cookie'
import {
  AUTH_TOKEN_COOKIE_NAME,
  BLOCKED_USER_COOKIE_NAME,
  GUEST_CART_COOKIE_NAME,
  PHONE_NUMBER_ATTRIBUTE_CODE,
} from '~/lib/constants'
import addressContainsMinimalValidData from '~/lib/checkout/address-contains-minimal-valid-data'
import formatMagentoErrorMessage from '~/lib/format-magento-error-message'
import getCountryId from '~/lib/get-country-id'

/**
 * @typedef {UserService}
 * @alias this.$userService
 */
export class UserService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$sentry = this.context.$sentry
    this.$trackingService = this.context.$trackingService
    this.$userApiService = this.context.$userApiService
    this.$cartService = this.context.$cartService
    this.$wishlistService = this.context.$wishlistService
    this.$overlayService = this.context.$overlayService
    this.$socialRetailService = this.context.$socialRetailService
  }

  /**
   * @param {string} token
   * @returns {Promise}
   */
  async fetchUserByToken(token) {
    try {
      cookie(AUTH_TOKEN_COOKIE_NAME, token)

      return this.fetchUser()
    } catch (error) {
      await this.logout()

      return Promise.reject(error)
    }
  }

  /**
   * @returns {Promise}
   */
  async fetchUser() {
    const token = cookie(AUTH_TOKEN_COOKIE_NAME)

    if (!token) {
      return Promise.reject(Error('Missing token'))
    }

    const magentoUser = await this.$userApiService.getUser()
    const mappedUser = this.#getMappedUser(magentoUser)

    this.$sentry.setUser(mappedUser)
    this.$trackingService.identifyUser(mappedUser.email)

    await this.store.dispatch('user/setUserData', mappedUser)

    // Don't overwrite shipping address if user already entered a shipping address
    // Otherwise set the default shipping address after login
    if (!addressContainsMinimalValidData(this.store.state.checkout.shippingAddress) && this.store.getters['user/defaultShippingAddress']) {
      await this.store.dispatch('checkout/setShippingAddress', this.store.getters['user/defaultShippingAddress'])

      // If no current shipping address and no default shipping addresses,
      // set the first address as shipping address if present
    } else if (!addressContainsMinimalValidData(this.store.state.checkout.shippingAddress) && this.store.state.user.addresses[0]) {
      await this.store.dispatch('checkout/setShippingAddress', this.store.state.user.addresses[0])
    }
  }

  /**
   * @param {string} email
   * @param {string} firstname
   * @param {string} lastname
   * @param {string} [telephone]
   * @param {string} [dob]
   */
  async updateUser({
    email,
    firstname,
    lastname,
    telephone,
    dob,
  }) {
    try {
      const updatedMagentoUser = await this.$userApiService.updateUser({
        email,
        firstname,
        lastname,
        telephone,
        dob,
      })

      const mappedUser = this.#getMappedUser(updatedMagentoUser)

      this.$sentry.setUser(mappedUser)
      this.$trackingService.identifyUser(mappedUser.email)

      await this.store.dispatch('user/setUserData', mappedUser)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * @param {string} telephone
   * @returns {Promise}
   */
  updateTelephone(telephone) {
    const { email, firstname, lastname } = this.store.state.user

    return this.updateUser({
      email,
      firstname,
      lastname,
      telephone,
    })
  }

  /**
   * @param {Object} magentoUser
   * @returns {Object}
   */
  #getMappedUser(magentoUser) {
    const token = cookie(AUTH_TOKEN_COOKIE_NAME)

    return {
      email: magentoUser.email,
      firstname: magentoUser.firstname,
      lastname: magentoUser.lastname,
      dateOfBirth: magentoUser.dob,
      customerGroupId: magentoUser.group_id,
      isSubscribedToNewsletter: magentoUser.extension_attributes.is_subscribed,
      telephone: magentoUser.custom_attributes?.find(attribute => attribute.attribute_code === PHONE_NUMBER_ATTRIBUTE_CODE)?.value,
      addresses: magentoUser.addresses,
      websiteId: magentoUser.website_id,
      id: magentoUser.id,
      token,
    }
  }

  /**
   * @param {string} email
   * @param {string} password
   * @param {boolean} [scopeapp]
   * @param {boolean} [closeFlyIn]
   * @param {string} [successMessage]
   * @returns {Promise}
   */
  async login({
    email,
    password,
    scopeapp = false,
    closeFlyIn = false,
    successMessage,
  }) {
    try {
      await this.store.dispatch('user/setIsInitialized', false)

      const token = await this.$userApiService.login({
        email,
        password,
        scopeapp,
      })

      await this.fetchUserByToken(token)

      if (closeFlyIn) {
        await this.$overlayService.closeFlyIn()
      }

      const mappedSuccessMessage = successMessage || 'logged_in'

      await this.store.dispatch('snackbar/addSnackbarMessage', { message: mappedSuccessMessage })

      const guestCartId = cookie(GUEST_CART_COOKIE_NAME)

      // Merge the guest cart if user had a guest cart id
      if (guestCartId) {
        // Don't reject on exception, but do dispatch
        await this.$cartService
          .mergeGuestCartWithUserCart(guestCartId)
          .catch(() => null)
      } else {
        await this.$cartService.updateCart()
      }

      await this.$wishlistService.fetchWishlist()
    } catch (error) {
      return Promise.reject(this.#getUserError(error))
    } finally {
      await this.store.dispatch('user/setIsInitialized', true)
      await this.$socialRetailService.setSocialRetailIsActive(false)
    }
  }

  /**
   * @returns {Promise}
   */
  logout() {
    cookie(AUTH_TOKEN_COOKIE_NAME, false)

    return this.store
      .dispatch('user/resetState')
      .then(() => Promise.all([
        this.store.dispatch('checkout/resetState'),
        this.store.dispatch('wishlist/clearWishlist'),
        this.store.dispatch('wallet/resetState'),
      ]))
      .then(() => this.store.dispatch('checkout/setShippingAddress', { country_id: getCountryId(this.store.state.localization.country) }))
      .then(() => this.store.dispatch('user/setIsInitialized', true))
  }

  /**
   * @param {string} email
   * @param {string} password
   * @param {string} firstname
   * @param {string} lastname
   * @param {string} [phonenumber]
   * @param {boolean} [subscribeForNewsletter]
   * @param {boolean} [scopeapp]
   * @param {boolean} [closeFlyIn]
   * @returns {Promise}
   */
  async register({
    email,
    password,
    firstname,
    lastname,
    telephone,
    subscribeForNewsletter,
    scopeapp,
    closeFlyIn = false,
  }) {
    try {
      await this.$userApiService.register({
        email,
        password,
        firstname,
        lastname,
        telephone,
        subscribeForNewsletter,
        scopeapp,
      })

      this.$trackingService.registerUser()

      return this.login({
        email,
        password,
        closeFlyIn,
        successMessage: 'account_created',
      })
    } catch (error) {
      return Promise.reject(this.#getUserError(error))
    }
  }

  /**
   * @returns {Promise}
   */
  async blockUser() {
    cookie(BLOCKED_USER_COOKIE_NAME, true)

    await this.store.dispatch('user/setIsBlocked', true)

    return this.logout()
  }

  /**
   * @param {Object} error
   * @returns {Error}
   */
  #getUserError(error) {
    const response = error?.response
    const statusCode = response && response.status

    // Only return errors meant for user
    const errorMessage = statusCode && statusCode < 500 && formatMagentoErrorMessage(response.data)

    return new Error(errorMessage)
  }
}
