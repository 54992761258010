
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      newsletterDescriptionText: {
        type: String,
        default: null,
      },
      invertTogglePosition: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['input'],
    computed: {
      ctaText() {
        return this.newsletterDescriptionText ?? this.$t('newsletter_subscribe_cta')
      },
    },
  }
