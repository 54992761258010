
  import 'swiper/swiper-bundle.css'
  import 'swiper/modules/pagination/pagination.min.css'

  export default {
    props: {
      sections: {
        type: Array,
        required: true,
        validator(sections) {
          return sections.every(section => typeof(section.id) === 'string')
        },
      },
      overlaySection: {
        type: Object,
        required: false,
        default: null,
        validator(section) {
          return typeof(section.id) === 'string'
        },
      },
      autoPlay: {
        type: Boolean,
        default: false,
      },
      autoPlaySpeed: {
        type: Number,
        default: 5000,
      },
      hideOnMobile: {
        type: Boolean,
        default: false,
      },
      hideOnDesktop: {
        type: Boolean,
        default: false,
      },
      mobileItemWidth: {
        type: Number,
        default: 100,
      },
      desktopItemWidth: {
        type: Number,
        default: 100,
      },
      centerSections: {
        type: Boolean,
        default: false,
      },
      isFullWidth: {
        type: Boolean,
        default: false,
      },
      backgroundColor: {
        type: String,
        default: null,
      },
      initialShownItem: {
        type: Number,
        default: null,
      },
      sliderId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        swiperReady: false,
        swiperInstance: null,
      }
    },
    computed: {
      // All possible SwiperJS options can be found here https://swiperjs.com/swiper-api#parameters
      swiperOptions() {
        return {
          slidesPerView: 'auto',
          autoHeight: true,
          ...this.autoPlay && {
            autoplay: {
              delay: this.autoPlaySpeed,
              disableOnInteraction: true,
            },
          },
          ...this.centerSections && {
            centeredSlides: this.centerSections,
          },
          ...this.initialShownItem && {
            initialSlide: this.initialShownItem - 1,
          },
        }
      },
      sliderIdentifier() {
        return `slider-${this.sliderId}`
      },
      formattedMobileItemWidth() {
        return `${this.mobileItemWidth}%`
      },
      formattedDesktopItemWidth() {
        return `${this.desktopItemWidth}%`
      },
      shouldIndicateScrollDesktop() {
        return this.swiperReady
          && this.desktopItemWidth === 100
          && !this.autoPlay
      },
      shouldIndicateScrollMobile() {
        return this.swiperReady
          && this.mobileItemWidth === 100
          && !this.autoPlay
      },
    },
    async mounted() {
      // Initialize Swiper
      this.swiperInstance = new this.$swiperInstance(`#${this.sliderIdentifier}`, this.swiperOptions)
      this.swiperReady = true
    },
    beforeDestroy() {
      this.swiperInstance?.destroy()
    },
  }
