
  import AppImage from '@loavies/component-library/dist/app-image'

  export default {
    components: {
      AppImage,
    },
    props: {
      post: {
        type: Object,
        required: true,
      },
      showShopTheLook: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['image-error'],
    data() {
      return {
        fallbackImage: this.post.imageUrl,
      }
    },
    computed: {
      imageSrcSet() {
        return [
          `${this.post.imageUrl} 1024w`,
          `${this.post.imageUrl} 500w`,
        ].join(', ')
      },
      imageSizes() {
        const hasHover = window.matchMedia('(hover: hover)').matches

        return hasHover
          ? [
            '(min-width: 1920px) 384px',
            '(min-width: 1024px) calc(20vw - (4 / 5) * 12px)',
            '(min-width: 540px) 30vw',
            '40vw',
          ].join(', ')
          : [
            '(min-width: 1024px) 30vw',
            '(min-width: 768px) 40vw',
            '(min-width: 540px) 60vw',
            '75vw',
          ].join(', ')
      },
    },
  }
