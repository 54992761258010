/**
 * @typedef {WishlistApiService}
 * @alias this.$wishlistApiService
 */
export class WishlistApiService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
  }

  init() {
    this.$magentoApiService = this.context.$magentoApiService
  }

  /**
   * @returns {Promise<Object[]>}
   */
  getItems() {
    return this.$magentoApiService
      .get('/wishlist/mine')
      .then(response => response.data?.wishlist_items)
  }

  /**
   * @param {string} sku
   * @returns {Promise<{
   *   wishlist_id: number,
   *   wishlist_item_id: number,
   * }>}
   */
  addItemBySku(sku) {
    return this.$magentoApiService
      .post('/wishlist/mine/add', { sku })
      .then(response => response.data)
  }

  /**
   * @param {string} itemId
   * @returns {Promise<Object>}
   */
  removeItemById(itemId) {
    return this.$magentoApiService
      .delete(`/wishlist/mine/remove/${itemId}`)
      .then(response => response.data)
  }
}
