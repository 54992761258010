/**
 * @typedef {CartShippingMethodService}
 * @alias this.$cartShippingMethodService
 */
export class CartShippingMethodService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$userService = this.context.$userService
    this.$checkoutApiService = this.context.$checkoutApiService
    this.$cartService = this.context.$cartService
    this.$cartPaymentService = this.context.$cartPaymentService
  }

  /**
   * @param {Object} address
   * @returns {Promise<Object[]>}
   */
  getCartShippingMethodsByAddress(address) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    if (isAuthenticated && address.id) {
      return this.$checkoutApiService.getUserCartShippingMethodsByAddressId(address.id)
    }

    return this.$checkoutApiService.getCartShippingMethodsByAddress({
      address,
      cartId,
    })
  }

  /**
   * @returns {Promise}
   */
  async fetchCartShippingMethods() {
    const cartId = this.store.state.checkout.cartId
    const shippingAddress = this.store.state.checkout.shippingAddress
    const selectedShippingMethod = this.store.state.checkout.selectedShippingMethod

    if (!cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    const newShippingMethods = await this.getCartShippingMethodsByAddress(shippingAddress)

    await this.store.dispatch('checkout/setShippingMethods', newShippingMethods)

    // Check if a shipping method was previously selected
    const previouslySelectedShippingMethod = newShippingMethods?.find(method => method.method_code === selectedShippingMethod?.method_code)

    // Set previously selected shipping method again or set first shipping method as selected
    await this.updateSelectedShippingMethod(previouslySelectedShippingMethod ?? newShippingMethods[0])
  }

  /**
   * This method always calls updateSelectedDeliveryMoment/updateSelectedServicePoint
   * according to the selected shipping method
   * @param {Object} [selectedShippingMethod]
   * @returns {Promise}
   */
  async updateSelectedShippingMethod(selectedShippingMethod) {
    await this.store.dispatch('checkout/setSelectedShippingMethod', selectedShippingMethod)

    const details = selectedShippingMethod?.details
    const timeframes = details?.timeframes
    const servicePoints = details?.servicePoints

    if (timeframes) {
      await this.#handleUpdateTimeframes(timeframes)
    } else if (servicePoints) {
      await this.#handleUpdateServicePoints(servicePoints)
    } else {
      // Shipping method doesn't contain timeframes or service points
      // This is usually the case for foreign countries.
      await this.store.dispatch('checkout/setSelectedServicePoint', null)
      await this.store.dispatch('checkout/setSelectedDeliveryMoment', null)
    }

    await this.$cartService.updateCart()
  }

  /**
   * @param {Object[]} timeframes
   * @returns {Promise}
   */
  #handleUpdateTimeframes(timeframes) {
    const previouslySelectedDeliveryMoment = this.store.state.checkout.selectedDeliveryMoment
    const timeframesContainsPreviouslySelectedMoment = timeframes.some(item => item.formattedDateTime === previouslySelectedDeliveryMoment?.formattedDateTime)

    if (!previouslySelectedDeliveryMoment || !timeframesContainsPreviouslySelectedMoment) {
      return this.updateSelectedDeliveryMoment(timeframes[0])
    }
  }

  /**
   * @param {Object[]} servicePoints
   * @returns {Promise}
   */
  #handleUpdateServicePoints(servicePoints) {
    const previouslySelectedServicePoint = this.store.state.checkout.selectedServicePoint
    const servicePointsContainsPreviouslySelectedServicePoint = servicePoints.some(item => item.id === previouslySelectedServicePoint?.id)

    if (!previouslySelectedServicePoint || !servicePointsContainsPreviouslySelectedServicePoint) {
      return this.updateSelectedServicePoint(servicePoints[0])
    }
  }

  /**
   * @param {Object} [selectedDeliveryMoment]
   * @returns {Promise}
   */
  async updateSelectedDeliveryMoment(selectedDeliveryMoment) {
    await this.store.dispatch('checkout/setSelectedServicePoint', null)
    await this.store.dispatch('checkout/setSelectedDeliveryMoment', selectedDeliveryMoment)

    return this.updateCartShippingInformation(false)
  }

  /**
   * @param {Object} [selectedServicePoint]
   * @returns {Promise}
   */
  async updateSelectedServicePoint(selectedServicePoint) {
    await this.store.dispatch('checkout/setSelectedDeliveryMoment', null)
    await this.store.dispatch('checkout/setSelectedServicePoint', selectedServicePoint)

    return this.updateCartShippingInformation(false)
  }

  /**
   * This method updates shipping information and fetches payment methods.
   * It requires a shipping method and shipping address.
   * @returns {Promise}
   */
  async updateCartShippingInformation(fetchAdyenPaymentMethods = true) {
    const userIsBlocked = this.store.state.user.isBlocked

    if (userIsBlocked) {
      return Promise.reject(Error('User is blocked'))
    }

    const cartId = this.store.state.checkout.cartId
    const selectedShippingMethod = this.store.state.checkout.selectedShippingMethod
    const shippingAddress = this.store.state.checkout.shippingAddress

    if (!cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    await this.store.dispatch('checkout/setCheckoutIsLoading', true)

    try {
      const response = await this.$checkoutApiService.updateCartShippingInformation({
        ...(!this.store.state.checkout.cartIsVirtual && selectedShippingMethod && { shippingMethod: selectedShippingMethod }),
        shippingAddress,
        cartId,
      })

      if (fetchAdyenPaymentMethods) {
        await this.$cartPaymentService.fetchAllCartPaymentMethods({
          paymentMethodsFromMagento: response?.payment_methods,
          // TODO: Previously selected payment method with Adyen setup
        })
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        return Promise.reject(error)
      }

      return this.$userService.blockUser()
    } finally {
      this.store.dispatch('checkout/setCheckoutIsLoading', false)
    }
  }
}
