
  import debounce from 'lodash.debounce'

  export default {
    props: {
      scrollerElement: {
        type: String,
        default: 'div',
      },
      hasLoadedAsyncContent: {
        type: Boolean,
        default: false,
      },
      noListStyle: {
        type: Boolean,
        default: false,
      },
      initialShownItemIndex: {
        type: Number,
        default: 0,
      },
      showArrowsOnPointerlessDevices: {
        type: Boolean,
        default: false,
      },
      hideArrows: {
        type: Boolean,
        default: false,
      },
      hasScrollSnap: {
        type: Boolean,
        default: false,
      },
      scrollDistancePercentage: {
        type: Number,
        default: 100,
      },
      initialScrollLeft: {
        type: Number,
        default: null,
      },
    },
    emits: [
      'reached-left-end',
      'left-end-out-of-view',
      'reached-right-end',
      'right-end-out-of-view',
    ],
    data() {
      return {
        needsArrowLeft: false,
        needsArrowRight: true,
        isScrolling: false,
        onScrollDebounce: debounce(this.onScroll, 100),
        carouselScroller: null,
      }
    },
    watch: {
      async hasLoadedAsyncContent() {
        if (this.hasLoadedAsyncContent) {
          /*
           * Wait for next DOM update before running onScroll so it's certain that
           * the async content is loaded into the DOM before calculating everything
           * again.
           */
          await this.$nextTick()

          this.onScroll()
        }
      },
      needsArrowLeft(newValue, oldValue) {
        if (!newValue && oldValue) {
          this.$emit('reached-left-end')
        }

        if (newValue && !oldValue) {
          this.$emit('left-end-out-of-view')
        }
      },
      needsArrowRight(newValue, oldValue) {
        if (!newValue && oldValue) {
          this.$emit('reached-right-end')
        }

        if (newValue && !oldValue) {
          this.$emit('right-end-out-of-view')
        }
      },
    },
    async mounted() {
      this.carouselScroller = this.$refs['carousel-scroller']
      this.carouselScroller.addEventListener('scroll', this.onScrollDebounce)
      this.setArrows()
      await this.setInitialScrollPosition()

      if (this.initialScrollLeft) {
        await this.$nextTick()
        this.setScrollLeft(this.initialScrollLeft)
      }
    },
    beforeDestroy() {
      this.carouselScroller.removeEventListener('scroll', this.onScrollDebounce)
      this.carouselScroller = null
    },
    methods: {
      animateScroller(direction) {
        let self = this
        const scrollDistance = this.getScrollDistance()
        const startPosition = this.carouselScroller.scrollLeft
        const endPosition = this.getExpectedEndPosition(startPosition, direction)
        const deltaPosition = -(endPosition - startPosition)
        const spaceRemaining = this.calculateSpaceRemaining(startPosition, endPosition, direction)

        const hasEnoughSpaceRemaining = direction === 'right'
          ? spaceRemaining > -scrollDistance
          : spaceRemaining < scrollDistance

        this.isScrolling = true
        let firstChild = this.carouselScroller.children[0]

        Array.from(this.carouselScroller.children).forEach(child => {
          if (hasEnoughSpaceRemaining) {
            child.style.transform = `translateX(${spaceRemaining}px)`
          } else  {
            child.style.transform = `translateX(${deltaPosition}px)`
          }
        })

        firstChild?.addEventListener('transitionend', onTransitionEnd, false)

        async function onTransitionEnd(event) {
          if (event.target === firstChild) {
            Array.from(self.carouselScroller.children).forEach(child => {
              child.style.transform = ''
              child.style.transition = ''
            })

            firstChild.removeEventListener('transitionend', onTransitionEnd, false)

            self.isScrolling = false

            await self.$nextTick() // make sure transform is reset, so scroll position is available
            self.carouselScroller.scrollLeft = endPosition
          }

          self = null
          firstChild = null
        }
      },
      getExpectedEndPosition(startPosition, direction) {
        const scrollDistance = this.getScrollDistance()

        return direction === 'right'
          ? startPosition + scrollDistance
          : startPosition - scrollDistance
      },
      getScrollDistance() {
        const inViewPortWidth = this.carouselScroller.offsetWidth
        return inViewPortWidth / 100 * this.scrollDistancePercentage
      },
      calculateSpaceRemaining(startPosition, endPosition, direction) {
        const totalWidth = this.carouselScroller.scrollWidth

        return direction === 'right'
          ? endPosition - totalWidth
          : startPosition
      },
      onScroll() {
        if (this.carouselScroller) {
          this.$emit('updateScrollLeftPosition', this.carouselScroller.scrollLeft)
          this.setArrows()
        }
      },
      setArrows() {
        const margin = 2 // using scroll snap resolves in a margin
        this.needsArrowLeft = this.carouselScroller.scrollLeft > margin
        this.needsArrowRight = (
          this.carouselScroller.scrollWidth
          - this.carouselScroller.scrollLeft
          - this.carouselScroller.offsetWidth
          > margin
        )
      },
      async setInitialScrollPosition() {
        let item = this.$slots?.default?.[this.initialShownItemIndex]
        const offsetLeft = item?.elm?.offsetLeft - (this.$el.clientWidth - item?.elm?.clientWidth) / 2

        if (offsetLeft) {
          await this.$nextTick()
          this.setScrollLeft(offsetLeft)
        }

        item = null
      },
      setScrollLeft(scrollLeft) {
        if (this.carouselScroller) {
          this.carouselScroller.scrollLeft = scrollLeft
        }
      },
    },
  }
