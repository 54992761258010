import { onLoad } from '~/lib/on-load'
import checkStatementPeriodically from '~/lib/check-statement-periodically'

const { isNetlifyProduction } = process.env

/*
 * Zendesk Web Widget (Classic)
 * Docs: https://developer.zendesk.com/embeddables/docs/widget/introduction
 */
export default (context, inject) => {
  const showAdaChat = context.$staticDataService.cmsLayoutsDefault.app?.showAdaChat
  const delay = showAdaChat ? 10000 : 5000

  onLoad(() => initializeZendesk(context), delay)

  /**
   * @alias this.$zendesk
   */
  inject('zendesk', {
    async openChat() {
      await zendesk('setLocale', context.app.i18n.locale)
      return zendesk('open')
    },
    async closeChat() {
      return zendesk('close')
    },
  })
}

/*
 * Ada chatbot should be default loaded and used. But Zendesk is the fallback.
 */
function initializeZendesk(context) {
  if (window.adaLoaded) return

  window.zESettings = {
    webWidget: {
      offset: {
        horizontal: '-4px',
        vertical: '-4px',
      },
    },
    chat: {
      departments: {
        enabled: [],
      },
    },
    answerBot: {
      suppress: isNetlifyProduction,
    },
  }

  const script = document.createElement('script')
  script.src = 'https://static.zdassets.com/ekr/snippet.js?key=e29264a8-6f0b-4b94-82b8-88b17cbbdcdd'
  script.id = 'ze-snippet'
  script.async = true
  script.onload = () => checkStatementPeriodically({
    statement: () => !!window.zE,
    callback: () => startZendesk(context),
  })
  document.body.appendChild(script)
}

function startZendesk(context) {
  hideDefaultLauncher()
  initializeListeners(context)
  context.store.dispatch('chat/setLoaded', { loaded: true })
}

/**
 * The default launcher can only be hidden with this command.
 * We call it directly after Zendesk has initialized.
 */
function hideDefaultLauncher() {
  window.zE('webWidget', 'hide')
}

function initializeListeners(context) {
  window.zE('webWidget:on', 'open', () => window.zE('webWidget', 'show'))
  window.zE('webWidget:on', 'close', () => window.zE('webWidget', 'hide'))

  // This listener is always called at least once, for every department.
  // After this, this listener is triggered when departments change online status.
  // Example callback payload:
  // { name: 'en', status: 'online', id: 360006468357 }
  window.zE('webWidget:on', 'chat:departmentStatus', (department) => {

    // Department names are named equally the locales ('nl', 'en', 'fr', etc.),
    // so department names can be compared to the current locale.
    // Departments overview Zendesk:
    // https://loavies-customercare.zendesk.com/chat/agent#departments
    if (department.name === context.app.i18n.locale) {
      context.store.dispatch('chat/setChatIsOnline', { chatIsOnline: department.status === 'online' })

      if (department.status === 'online') {
        window.zE('webWidget', 'updateSettings', {
          chat: {
            suppress: false,
            departments: {
              enabled: [],
              select: context.app.i18n.locale,
            },
          },
        })
      } else {
        // These settings suppresses the chat and shows an custom contact form, which also originates from Zendesk.
        // Contact support desk if this behaviour needs to be changed!
        window.zE('webWidget', 'updateSettings', {
          chat: {
            suppress: true,
          },
        })
      }
    }
  })
}

/**
 * @param {string}  functionName
 * @param {*}       [payload]
 * @returns {Promise}
 */
function zendesk(functionName, payload) {
  if (!window.zE) {
    return Promise.reject('Zendesk not available')
  }

  window.zE('webWidget', functionName, payload)

  return Promise.resolve()
}
