export function readFromLocalStorage(name) {
  return JSON.parse(window.localStorage.getItem(name)) || null
}

export function writeToLocalStorage(name, value) {
  const correctedValue = JSON.stringify(value)

  window.localStorage.setItem(name, correctedValue)
}

export function removeFromLocalStorage(name) {
  window.localStorage.removeItem(name)
}
