/**
 * @typedef {SearchablePagesService}
 * @alias this.$searchablePagesService
 */
export class SearchablePagesService {
  constructor(context) {
    this.context = context
  }

  async init() {
    this.$staticDataService = this.context.$staticDataService
    this.cmsSearchPagesIndex = await this.$staticDataService.getCmsSearchPagesIndex()
  }

  /**
   * @param {string} searchQuery
   * @returns {Object[]}
   */
  getPagesForSearch(searchQuery) {
    return this.cmsSearchPagesIndex.filter(page => page.name?.toLowerCase().includes(searchQuery.toLowerCase()))
  }
}
