
  import { mapState } from 'vuex'

  import { isValidProduct } from '~/lib/product-validation'
  import getPriceGroupFromStorefront from '~/lib/get-price-group-from-storefront'

  export default {
    props: {
      product: {
        type: Object,
        required: true,
        validator: isValidProduct,
      },
    },
    computed: {
      ...mapState({
        currency: state => state.localization.currency,
        customerGroupId: state => state.user.customerGroupId,
      }),
      isGiftCard() {
        return this.$productHelperService.productIsGiftcard(this.product)
      },
      formattedGiftCardPrice() {
        return this.$productHelperService.formatGiftCardPrice(this.product)
      },
      price() {
        if (!this.product.prices_storefront) {
          return
        }

        return getPriceGroupFromStorefront(this.product.prices_storefront, this.customerGroupId, this.currency)
      },
      originalFormattedPrice() {
        return this.$currencyService.formatToLocalePrice(this.price.original_price, this.currency)
      },
      formattedPrice() {
        return this.$currencyService.formatToLocalePrice(this.price.price, this.currency)
      },
    },
  }
