import dataLocales from '~/lib/mollie/data-locales'

/**
 * @typedef {MollieInitializationService}
 * @alias this.$mollieInitializationService
 */
export class MollieInitializationService {
  #mollie

  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.i18n = context.i18n
  }

  /**
   * Docs: https://docs.mollie.com/components/overview#add-mollie-js-to-your-checkout
   */
  initSdk() {
    return new Promise(async (resolve, reject) => {
      if (this.#isInitialized) {
        return resolve(this.#mollie)
      }

      const script = document.createElement('script')
      script.src = 'https://js.mollie.com/v1/mollie.js'
      script.onload = async () => {
        this.#createMollieInstance()
        resolve(this.#mollie)
      }
      script.onerror = (error) => {
        reject(error)
      }

      document.body.appendChild(script)
    })
  }

  #createMollieInstance() {
    if (this.#mollie) {
      return this.#mollie
    }

    this.#mollie = window.Mollie(process.env.mollieToken, {
      locale: dataLocales[this.i18n.locale],
      ...!process.env.isNetlifyProduction && { testmode: true },
    })

    return this.#mollie
  }

  get #isInitialized() {
    return !!this.#mollie
  }
}
