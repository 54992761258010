import axios from 'axios'
import { writeToLocalStorage } from '~/lib/local-storage'

/**
 * @typedef {UserIpService}
 * @alias this.$userIpService
 */
export class UserIpService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
  }

  // Docs: https://www.ipify.org/
  /**
   * ipify is an open source, limitless and free service
   * @see https://www.ipify.org/
   * @return {Promise<string|undefined>}
   */
  getUserIpv6() {
    return axios
      .get('https://api6.ipify.org?format=json')
      .then(response => response.data?.ip)
      .catch(() => undefined)
  }

  /**
   * @return {Promise<void>}
   */
  async storeUserIpv6InLocalStorage() {
    const ipv6 = await this.getUserIpv6()

    writeToLocalStorage('usr_net_addr', ipv6)
  }
}
