import getPriceGroupFromStorefront from '~/lib/get-price-group-from-storefront'
import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'

/**
 * @typedef {BambuserMapperService}
 * @alias this.$bambuserMapperService
 */
export class BambuserMapperService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$productSkuConverterService = this.context.$productSkuConverterService
  }

  /**
   * @param productFactory
   * @param {ProductData} productData
   * @returns {*}
   */
  mapProductForBambuser({
    productFactory,
    productData,
  }) {
    return productFactory
      .product(productDetailFactory =>
        this.handleProductDetailFactory({
          productDetailFactory,
          productData,
        })
      )
  }

  /**
   * @param {Function} productDetailFactory
   * @param {Object} productData
   * @returns {*}
   */
  handleProductDetailFactory({
    productDetailFactory,
    productData,
  }) {
    const product = productData.product

    return productDetailFactory
      .name(this.getMappedProductName(product))
      .brandName('')
      .introduction(product.subtitle[0])
      .description(product.short_description[0])
      .sku(getPrimarySkuFromProduct(product))
      .defaultVariationIndex(this.getDefaultVariationIndex(productData))
      .variations(variationFactory =>
        this.mapVariations({
          variationFactory,
          productData,
        })
      )
  }

  /**
   * This function removes potential colors from the
   * product name keeps parts like 'bottom' / 'tops
   * @example
   * 'WAVE SEEKER - ZEBRA BLACK' -> 'WAVE SEEKER'
   * 'MIDNIGHT SUMMER - TOP' -> 'MIDNIGHT SUMMER - TOP'
   * @param {Object} product
   * @return {string}
   */
  getMappedProductName(product) {
    const splitter = ' - '
    const productGroupName = product.option_text_product_group[0].toLowerCase()
    const productName = product.name[0]
    const productNameParts = productName.split(splitter)

    if (productNameParts.length === 1) {
      return productName
    }

    const filteredProductNameParts = [
      productNameParts[0],
      ...productNameParts
        .slice(1, productNameParts.length)
        .filter(item => productGroupName.includes(item.toLowerCase())),
    ]

    return filteredProductNameParts.join(splitter)
  }

  /**
   * @param {Object} productData
   * @returns {number}
   */
  getDefaultVariationIndex(productData) {
    if (!productData.variations) {
      return 0
    }

    return productData.variations.findIndex(variation => variation.entity_id === productData.product.entity_id)
  }

  /**
   * @param {Function} variationFactory
   * @param {Object} productData
   * @returns {Array<*>}
   */
  mapVariations({
    variationFactory,
    productData,
  }) {
    const variations = []

    // If 1 variation
    if (!productData.variations) {
      variations.push(
        this.handleVariationFactory({
          variationFactory,
          sku: getPrimarySkuFromProduct(productData.product),
          product: productData.product,
        })
      )
      // Variations
    } else {
      productData.variations.forEach(variation => {
        variations.push(
          this.handleVariationFactory({
            variationFactory,
            sku: getPrimarySkuFromProduct(variation),
            product: variation,
          })
        )
      })
    }

    return variations
  }

  /**
   * @param {Function} variationFactory
   * @param {string} sku
   * @param {Object} product
   * @returns {Array<*>}
   */
  handleVariationFactory({
    variationFactory,
    sku,
    product,
  }) {
    return variationFactory()
      .attributes(attributeFactory =>
        this.handleAttributeFactory({
          attributeFactory,
          product,
        })
      )
      .imageUrls(product.media_gallery.map(image => image.formats.large))
      .name(product.name[0])
      .sku(sku)
      .sizes(sizeFactory =>
        this.mapSizes({
          sizeFactory,
          product,
        })
      )
  }

  /**
   * @param {Function} attributeFactory
   * @param {Object} product
   * @returns {*}
   */
  handleAttributeFactory({
    attributeFactory,
    product,
  }) {
    return attributeFactory
      .colorName(product.option_text_color[0])
      .colorHexCode(this.$productSkuConverterService.extractColorData(getPrimarySkuFromProduct(product))?.rgbCode)
  }

  /**
   * @param {Function} sizeFactory
   * @param {Object} product
   * @returns {Array<*>}
   */
  mapSizes({
    sizeFactory,
    product,
  }) {
    const productSizeAttribute = product.configurable_options?.find(option => option.attribute_code === 'size')
    const productSizeOptions = productSizeAttribute.options

    return productSizeOptions.map(sizeOption =>
      this.handleSizeFactory({
        sizeFactory,
        name: sizeOption.store_label,
        sku: sizeOption.product_sku,
        isInStock: sizeOption.product_qty > 0,
        product,
      })
    )
  }

  /**
   * @param {Function} sizeFactory
   * @param {string} name
   * @param {string} sku
   * @param {boolean} isInStock
   * @param {Object} product
   * @returns {*}
   */
  handleSizeFactory({
    sizeFactory,
    name,
    sku,
    isInStock,
    product,
  }) {
    return sizeFactory()
      .name(name)
      .inStock(isInStock)
      .sku(sku)
      .price(priceFactory =>
        this.handlePriceFactory({
          priceFactory,
          product,
        })
      )
  }

  /**
   * @param {Function} priceFactory
   * @param {Object} product
   * @returns {*}
   */
  handlePriceFactory({
    priceFactory,
    product,
  }) {
    const customerGroupId = this.store.state.user.customerGroupId
    const currency = this.store.state.localization.currency
    const customerPrice = getPriceGroupFromStorefront(
      product.prices_storefront,
      customerGroupId,
      currency,
    )

    if (customerPrice.is_discount) {
      return priceFactory
        .currency(currency)
        .current(customerPrice.price)
        .original(customerPrice.original_price)
    } else {
      return priceFactory
        .currency(currency)
        .current(customerPrice.price)
    }
  }
}
