export default function () {
  return {
    cookiesAccepted: false,
    currentRouteIsProductPage: false,
    currentProductPrimaryCategory: null,
    filterInAppHeader: false,
    isDesktop: false,
    isMobile: false,
    isHoverSupported: false,
    mediaQueryInitialized: false,
    isImageLoadingSupported: true,
    routeBeforeScrollLock: null,
    scrollLockLevel: 0,
    scrollPositionBeforeLock: null,
    showChatButton: true,
    loqateLoaded: false,
    userPrefersLargeProductCards: false,
    socialRetailIsActive: false,
    isApp: false,
  }
}
