
  export default {
    props: {
      discountPercentage: {
        type: Number,
        required: true,
      },
      flag: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      formattedText() {
        return `-${this.discountPercentage}%`
      },
    },
  }
