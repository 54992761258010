/**
 * @typedef {PersonalisationService}
 * @alias this.$personalisationService
 */
export class PersonalisationService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.$productApiService = this.context.$productApiService
  }

  /**
   * @param {string} id
   * @param {number} amountOfResults
   * @returns {Promise}
   */
  getProductsByRecommendationId(id, amountOfResults = 10) {
    return new Promise((resolve, reject) => {
      try {
        if (!window.exponea) reject()

        window.exponea.getRecommendation({
          recommendationId: id,
          size: amountOfResults,
          callback: async (recommendations) => {
            if (!recommendations?.length) {
              resolve([])
              return
            }

            const products = await this.handleRecommendations(recommendations)

            resolve(products)
          },
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * @param {Array} recommendations
   * @returns {Promise}
   */
  handleRecommendations(recommendations) {
    const productSkus = recommendations.map(recommendation => recommendation.item_id)

    return this.$productApiService.getMultipleProductsBySku(productSkus)
  }
}
