import axios from 'axios'
import deepFreeze from 'deep-freeze'

import { ApiService } from '~/services/core/api-service'
import { mapContentForProductFromApi } from './pixlee-api-mappers'

/**
 * @typedef {PixleeApiService}
 * @alias this.$pixleeApiService
 */
export class PixleeApiService extends ApiService {
  constructor(context) {
    super()

    /** @type {ServerNuxtContext} */
    this.context = context
    this.locales = context.i18n.locales
    this.products = {}
    this.albums = {}
  }

  /**
   * @param {string} sku
   * @param {number} [maxPosts] Maximum posts
   * @param {string} [locale] If locale is passed, the content is sorted based on this locale
   * @return {Promise<Array>}
   */
  getContentForProduct(sku, { maxPosts, locale }) {
    if (!sku) {
      return this.rejectOnMissingArgument('sku', 'getContentForProduct')
    }

    if (this.products[sku]) {
      return Promise.resolve(this.products[sku])
    }

    return axios.get(`${this.context.env.serverlessPixleeBaseUrl}/get-content-for-product?sku=${sku}`)
      .then(response => mapContentForProductFromApi(this.locales, response.data, locale, maxPosts))
      .then(deepFreeze)
      .then(response => {
        this.products[sku] = response

        return response
      })
  }

  /**
   * @param {string} albumId
   * @return {Promise<Array>}
   */
  getContentForAlbum(albumId) {
    if (!albumId) {
      return this.rejectOnMissingArgument('albumId', 'getContentForAlbum')
    }

    if (this.albums[albumId]) {
      return Promise.resolve(this.albums[albumId])
    }

    return axios.get(`${this.context.env.serverlessPixleeBaseUrl}/get-content-for-album?id=${albumId}`)
      .then(response => mapContentForProductFromApi(this.locales, response.data))
      .then(response => {
        this.albums[albumId] = response

        return response
      })
  }
}
