export default {
  setCategoryProductsLoading(context, payload) {
    context.commit('SET_CATEGORY_PRODUCTS_LOADING', payload)
  },

  setCategoryProducts(context, payload) {
    context.commit('SET_CATEGORY_PRODUCTS', payload)
  },

  resetCategoryProducts(context) {
    context.commit('SET_CATEGORY_PRODUCTS', { categoryProducts: null })
  },

  setCategoryOptions(context, payload) {
    context.commit('SET_CATEGORY_OPTIONS', payload)
  },

  updateCategoryOptions(context, payload) {
    context.commit('UPDATE_CATEGORY_OPTIONS', payload)
  },

  setActiveCategory(context, payload) {
    context.commit('SET_ACTIVE_CATEGORY', payload)
  },

  setAllFilters(context, payload) {
    context.commit('SET_ALL_FILTERS', payload)
  },

  setAvailableFilters(context, payload) {
    context.commit('SET_AVAILABLE_FILTERS', payload)
  },

  setCategoryProductsCount(context, payload) {
    context.commit('SET_CATEGORY_PRODUCTS_COUNT', payload)
  },

  setPreviousCategoryUrl(context, payload) {
    context.commit('SET_PREVIOUS_CATEGORY_URL', payload)
  },

  setAppliedFiltersCount(context, payload) {
    context.commit('SET_APPLIED_FILTERS_COUNT', payload)
  },

  setCategoryPageModularContent(context, payload) {
    context.commit('SET_CATEGORY_PAGE_MODULAR_CONTENT', payload)
  },

  setSingleCategoryPageModularContent(context, payload) {
    context.commit('SET_SINGLE_CATEGORY_PAGE_MODULAR_CONTENT', payload)
  },

  setAnchorIndex(context, payload) {
    context.commit('SET_ANCHOR_INDEX', payload)
  },

  setCheckedFilters(context, payload) {
    context.commit('SET_CHECKED_FILTERS', payload)
  },

  setSortOptions(context, payload) {
    context.commit('SET_SORT_OPTIONS', payload)
  },

  setProductBundles(context, productBundles) {
    context.commit('SET_PRODUCT_BUNDLES', productBundles)
  },

  resetProductBundles(context) {
    context.commit('SET_PRODUCT_BUNDLES', null)
  },

  setCombinedProductBundles(context, payload) {
    context.commit('SET_COMBINED_PRODUCT_BUNDLES', payload)
  },

  setProductsPerRowMobile(context, payload) {
    context.commit('SET_PRODUCTS_PER_ROW_MOBILE', payload)
  },
}
