import getUsersCountry from '~/lib/get-users-country'
import getCountryId from '~/lib/get-country-id'

import { FALLBACK_COUNTRY_ID } from '~/lib/constants'

export default async function (context) {
  /**
   * User country
   */
  try {
    const country = await getUsersCountry()
    return context.store.dispatch('localization/setCountry', getCountryId(country))
  } catch (error) {
    // For development purposes only
    return context.store.dispatch('localization/setCountry', FALLBACK_COUNTRY_ID)
  }
}
