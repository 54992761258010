export default {
  /**
   * @param context
   * @param {Object} [payload]
   */
  addOptimizers(context, payload) {
    context.commit('ADD_OPTIMIZERS', payload ?? {})
  },

  /**
   * @param context
   * @param {string[]} [payload]
   */
  addDefaultBoosters(context, payload) {
    context.commit('ADD_DEFAULT_BOOSTERS', payload ?? [])
  },

  /**
   * @param  context
   * @param {string[]} [payload]
   */
  addSynonymBoosters(context, payload) {
    context.commit('ADD_SYNONYM_BOOSTERS', payload ?? [])
  },

  /**
   * @param context
   * @param {Object} [payload]
   */
  setValue(context, payload) {
    context.commit('SET_VALUE', payload ?? {})
  },

  /**
   * @param context
   * @param {Object} [payload]
   */
  setData(context, payload) {
    context.commit('SET_DATA', payload ?? {})
  },

  /**
   * @param context
   * @param {Object} payload
   */
  setProductsLoading(context, payload) {
    context.commit('SET_PRODUCTS_LOADING', payload)
  },

  /**
   * @param context
   * @param {Object} payload
   */
  setActiveSortOption(context, payload) {
    context.commit('SET_ACTIVE_SORT_OPTION', payload)
  },

  /**
   * @param context
   */
  resetActiveSortOption(context) {
    context.commit('SET_ACTIVE_SORT_OPTION', { activeSortOption: context.state.sortOptions[0] })
  },

  /**
   * @param context
   * @param {Object} payload
   */
  setAllFilters(context, payload) {
    context.commit('SET_ALL_FILTERS', payload)
  },

  /**
   * @param context
   * @param {Object} payload
   */
  setAvailableFilters(context, payload) {
    context.commit('SET_AVAILABLE_FILTERS', payload)
  },

  /**
   * @param context
   * @param {Object} payload
   */
  setCheckedFilters(context, payload) {
    context.commit('SET_CHECKED_FILTERS', payload)
  },
}
