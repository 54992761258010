
  export default {
    props: {
      filterCount: {
        type: Number,
        default: 0,
      },
    },
    emits: ['click'],
  }
