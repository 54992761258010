import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      desktopMatchMedia: process.client && window.matchMedia('(min-width: 1280px)'),
      mobileMatchMedia: process.client && window.matchMedia('(max-width: 1023px)'),
      hoverMatchMedia: process.client && window.matchMedia('(hover: hover)'),
    }
  },
  computed: {
    ...mapState({
      isDesktop: state => state.isDesktop,
      isMobile: state => state.isMobile,
      isHoverSupported: state => state.isHoverSupported,
      isApp: state => state.isApp,
    }),
  },
  mounted() {
    this.desktopMatchMedia.addListener(this.onDesktopMatchMedia)
    this.mobileMatchMedia.addListener(this.onMobileMatchMedia)
    this.hoverMatchMedia.addListener(this.onHoverMatchMedia)
    this.onDesktopMatchMedia(this.desktopMatchMedia)
    this.onMobileMatchMedia(this.mobileMatchMedia)
    this.onHoverMatchMedia(this.hoverMatchMedia)
    this.setMediaQueryInitialized({ mediaQueryInitialized: true })
    this.checkIsApp()
  },
  beforeDestroy() {
    this.desktopMatchMedia.removeListener(this.onDesktopMatchMedia)
    this.mobileMatchMedia.removeListener(this.onMobileMatchMedia)
    this.hoverMatchMedia.removeListener(this.onHoverMatchMedia)
  },
  methods: {
    ...mapActions({
      setIsDesktop: 'setIsDesktop',
      setIsMobile: 'setIsMobile',
      setIsHoverSupported: 'setIsHoverSupported',
      setMediaQueryInitialized: 'setMediaQueryInitialized',
      setIsApp: 'setIsApp',
    }),
    onDesktopMatchMedia(event) {
      if (this.isDesktop !== event.matches) {
        this.setIsDesktop({ isDesktop: event.matches })
      }
    },
    onMobileMatchMedia(event) {
      if (this.isMobile !== event.matches) {
        this.setIsMobile({ isMobile: event.matches })
      }
    },
    onHoverMatchMedia(event) {
      if (this.isHoverSupported !== event.matches) {
        this.setIsHoverSupported({ isHoverSupported: event.matches })
      }
    },
    checkIsApp() {
      const isApp = this.isApp || Boolean(this.$route.query['is-app'])

      return this.setIsApp(isApp)
    },
  },
}
