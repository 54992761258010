import {
  APP_MODAL, APP_TRAY,
  GIFTCARD_DETAILS_MODAL_NAME,
  GIFTCARD_DETAILS_TRAY_NAME,
  GIFTCARD_PRODUCT_TYPE,
  GUEST_CART_COOKIE_NAME,
  INITIAL_CART_ID,
  MAGENTO_OUT_OF_STOCK_ERROR_MESSAGE,
  USER_CART_COOKIE_NAME,
} from '~/lib/constants'
import cookie from '~/lib/cookie'
import formatMagentoErrorMessage from '~/lib/format-magento-error-message'

/**
 * @typedef {CartItemsService}
 * @alias this.$cartItemsService
 */
export class CartItemsService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$trackingService = this.context.$trackingService
    this.$productHelperService = this.context.$productHelperService
    this.$checkoutApiService = this.context.$checkoutApiService
    this.$cartService = this.context.$cartService
    this.$wishlistService = this.context.$wishlistService
    this.$cartShippingMethodService = this.context.$cartShippingMethodService
    this.$overlayService = this.context.$overlayService
  }

  /**
   * @param {Object} product
   * @param {number} quantity
   * @param {Object} [selectedSize]
   * @param {string} [origin]
   * @returns {Promise}
   */
  async addToCart({ product, quantity, selectedSize, origin }) {
    const isBlocked = this.store.state.user.isBlocked

    if (isBlocked) {
      await this.store.dispatch('snackbar/addSnackbarMessage', { message: 'ordering_unavailable' })

      return Promise.reject('User is blocked')
    }

    try {
      const cartId = this.store.state.checkout.cartId ?? await this.$cartService.createCart()

      if (selectedSize.is_virtual && selectedSize.product_type === GIFTCARD_PRODUCT_TYPE) {
        this.$overlayService.setCurrentOverlay({
          settings: {
            options: {
              cartId,
              product,
              quantity,
              selectedSize,
            },
          },
          mobile: {
            type: APP_TRAY,
            component: GIFTCARD_DETAILS_TRAY_NAME,
          },
          desktop: {
            type: APP_MODAL,
            component: GIFTCARD_DETAILS_MODAL_NAME,
          },
        })

        return
      }

      // Before an item is added to the cart, we assume that it is in stock,
      // because the front-end updates stock counts all the time.
      // Yet, while posting the item to the cart endpoint,
      // Magento might throw an error because the item is really _not_ in stock.
      await this.$checkoutApiService.addToCart({
        cartId,
        product,
        quantity,
        selectedSize,
      })

      this.postAddToCart({ product, quantity, selectedSize, origin })
    } catch (error) {
      return this.handleAddToCartError({ error, product, quantity, selectedSize, origin })
    }
  }

  /**
   * @param {Object} product
   * @param {number} quantity
   * @param {Object} [selectedSize]
   * @param {string} [origin]
   * @returns {void}
   */
  postAddToCart({ product, quantity, selectedSize, origin }) {
    if (!this.$productHelperService.productIsGiftcard(product)) {
      this.store.dispatch('checkout/setCartIsVirtual', false)
    }

    // Update cart is called in fetchCartShippingMethods -> updateSelectedShippingMethod
    this.$cartShippingMethodService
        .fetchCartShippingMethods()
        .then(() => this.$trackingService.addToCart({ product, quantity, selectedSize, origin }))
        .catch(() => {})

    this.store.dispatch('snackbar/addSnackbarMessage', { message: 'added_to_cart', icon: 'cart-check' })
  }

  /**
   * @param {*} error
   * @param {Object} product
   * @param {number} quantity
   * @param {Object} [selectedSize]
   * @param {string} [origin]
   * @returns {Promise}
   */
  async handleAddToCartError({ error, product, quantity, selectedSize, origin }) {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const responseData = error?.response?.data
    const isOutOfStockError = responseData?.message?.includes(MAGENTO_OUT_OF_STOCK_ERROR_MESSAGE)

    // Temp fix: this is to fix the inf loop that is describes here: https://trello.com/c/itIacVXJ/648-infinite-guest-cart-creation-bug
    if (isOutOfStockError) {
      await this.store.dispatch('snackbar/addSnackbarMessage', { message: 'out_of_stock_error_message' })

      return Promise.reject(error)
    }

    // This will create a new guest cart and tries to add the product again
    if (!isAuthenticated && ['quoteId', 'cartId'].includes(responseData?.parameters?.fieldName)) {
      await this.store.dispatch('checkout/setCartId', INITIAL_CART_ID)

      cookie(GUEST_CART_COOKIE_NAME, false)
      cookie(USER_CART_COOKIE_NAME, false)

      return this.addToCart({ product, quantity, selectedSize, origin })
    }

    await this.handleAddToCartErrorMessage(responseData)

    return Promise.reject(error)
  }

  /**
   * @param {Object} cartItem
   * @param {number} quantity
   * @param {string} [origin]
   * @returns {Promise}
   */
  async updateCartItemQuantity({ cartItem, quantity, origin }) {
    if (quantity < 1) {
      return this.removeCartItemStackFromCart({ cartItem })
    }

    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId
    const quantityDifference = quantity - cartItem.qty
    const selectedSize = this.$productHelperService.getSelectedSizeByCartItem(cartItem)

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    try {
      await this.$checkoutApiService.updateCartItem({
        cartId,
        quantity,
        cartItem,
      })

      this.#handleUpdateCartItemQuantityTracking({
        cartItem,
        quantityDifference,
        selectedSize,
        origin,
      })

      // Update cart is called in fetchCartShippingMethods -> updateSelectedShippingMethod
      await this.$cartShippingMethodService.fetchCartShippingMethods()
    } catch (error) {
      return this.#handleUpdateCartItemQuantityError({ error, quantityDifference })
    }
  }

  /**
   * @param {Object} cartItem
   * @param {number} quantityDifference
   * @param {Object} [selectedSize]
   * @param {string} [origin]
   */
  #handleUpdateCartItemQuantityTracking({ cartItem, quantityDifference, selectedSize, origin }) {
    const product = cartItem.product

    if (quantityDifference < 0) {
      return this.$trackingService.removeFromCart({ cartItem, quantity: Math.abs(quantityDifference) })
    }

    this.$trackingService.addToCart({
      product,
      quantity: quantityDifference,
      selectedSize,
      origin,
    })
  }

  /**
   * @param {*} error
   * @param {number} quantityDifference
   * @returns {Promise}
   */
  async #handleUpdateCartItemQuantityError({ error, quantityDifference }) {
    const responseData = error.response?.data

    if (quantityDifference < 0) {
      await this.#handleRemoveFromCartErrorMessage(responseData)
    } else {
      await this.handleAddToCartErrorMessage(responseData)
    }

    return Promise.reject(error)
  }

  /**
   * @param {Object} cartItem
   * @param {number} [quantity]
   * @returns {Promise}
   */
  async removeFromCart({ cartItem, quantity }) {
    if (cartItem.qty === 1 || quantity === 0) {
      return this.removeCartItemStackFromCart({ cartItem })
    }

    return this.updateCartItemQuantity({
      cartItem,
      quantity: quantity ? quantity : cartItem.qty - 1,
    })
  }

  /**
   * @param {Object} cartItem
   * @param {boolean} [itemsMovedToWishlist]
   * @returns Promise
   */
  async removeCartItemStackFromCart({ cartItem, itemsMovedToWishlist = false }) {
    const cartId = this.store.state.checkout.cartId

    if (!cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    await this.$checkoutApiService.removeCartItem({
      cartId,
      cartItem,
    })

    // Update cart is called in fetchCartShippingMethods -> updateSelectedShippingMethod
    await this.$cartShippingMethodService.fetchCartShippingMethods()

    this.$trackingService.removeFromCart({ cartItem, quantity: cartItem.qty, itemsMovedToWishlist })
  }

  /**
   * @param {Object} cartItem
   * @returns {Promise}
   */
  async moveCartItemToWishlist({ cartItem }) {
    try {
      await Promise.all([
        this.removeCartItemStackFromCart({ cartItem, itemsMovedToWishlist: true }),
        this.$wishlistService.addProduct({ product: cartItem.product }),
      ])
    } catch {
      this.store.dispatch('snackbar/addSnackbarMessage', { message: 'failed_to_remove_from_cart' })
    }
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  handleAddToCartErrorMessage(data) {
    const message = formatMagentoErrorMessage(data) || 'failed_to_add_to_cart'

    return this.store.dispatch('snackbar/addSnackbarMessage', { message })
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  #handleRemoveFromCartErrorMessage(data) {
    const message = formatMagentoErrorMessage(data) || 'failed_to_remove_from_cart'

    return this.store.dispatch('snackbar/addSnackbarMessage', { message })
  }
}
