/**
 * @typedef {ProductImageHelperService}
 * @alias this.$productImageHelperService
 */
export class ProductImageHelperService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
  }

  init() {
    this.$responsiveImageService = this.context.$responsiveImageService
  }

  /**
   * @param {string} imageUrl
   * @returns {string}
   */
  getSrcForProductPage(imageUrl) {
    return this.$responsiveImageService.getResizeUrl({
      url: imageUrl,
      width: 1000,
    })
  }

  /**
   * @param {string} imageUrl
   * @returns {string}
   */
  getImageSrcsetForProductPage(imageUrl) {
    return this.$responsiveImageService.getSrcsetByWidths({
      url: imageUrl,
      widths: [400, 600, 1000],
    })
  }

  /**
   * @returns {string}
   */
  getImageSizesForProductPage() {
    return [
      '(max-width: 400px) 400px',
      '(max-width: 600px) 600px',
      '(max-width: 768px) 1000px',
      // Above 768, 2 images on single row
      '(max-width: 800px) 400px',
      '600px',
    ].join(', ')
  }
}
