import { FUNCTIONAL_COOKIE_NAME } from '~/lib/constants'
import cookie from '~/lib/cookie'

export default function(context) {
  const cookiesAccepted = cookie(FUNCTIONAL_COOKIE_NAME)

  if (cookiesAccepted) {
    context.store.dispatch('setCookiesAccepted', { cookiesAccepted: true })
  }
}
