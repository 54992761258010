import { isValidProduct } from './product-validation'

export const carouselRecordType = 'CarouselRecord'

export function fetchProductsForCarouselConfigs(productApiService, carouselConfigs) {
  return Promise.all(carouselConfigs.map(carouselData => {
    return fetchProductsForCarousel(productApiService, carouselData)
      .then(products => carouselData.productCarousel.products = products)
  }))
}

export function fetchProductsForCarousel(productApiService, carousel) {
  const categoryId = carousel.productCarousel.categoryId
  const products = carousel.productCarousel.products
  const skus = products.map(product => product.sku)

  if (categoryId) {
    return productApiService.getProductsAndAvailableFiltersByCategoryId(categoryId)
      .then(response => response.products)
      .then(filterValidProducts)
      .catch(handleProductFetchError)
  }

  return productApiService.getMultipleProductsBySku(skus)
    .catch(handleProductFetchError)
}

function filterValidProducts(products) {
  return products.filter(isValidProduct)
}

function handleProductFetchError(error) {
  if (!process.client) {
    console.error('Error while fetching products: ', error)
  }
  return []
}
