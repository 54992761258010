export default function () {
  return {
    currentRoutePath: null,
    currentRouteName: null,
    previousRouteName: null,
    previousFullPath: null,
    previousLayout: null,
    currentLayout: null,
  }
}
