import { render, staticRenderFns } from "./newsletter-signup-form.vue?vue&type=template&id=8d741508"
import script from "./newsletter-signup-form.vue?vue&type=script&lang=js"
export * from "./newsletter-signup-form.vue?vue&type=script&lang=js"
import style0 from "./newsletter-signup-form.vue?vue&type=style&index=0&id=8d741508&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmailInput: require('/opt/build/repo/src/client/components/email-input/email-input.vue').default,TelephoneInput: require('/opt/build/repo/src/client/components/telephone-input/telephone-input.vue').default,AppToggle: require('/opt/build/repo/src/client/components/app-toggle/app-toggle.vue').default,AppAlert: require('/opt/build/repo/src/client/components/app-alert/app-alert.vue').default,AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default})
