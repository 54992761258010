
  import { mapState, mapActions } from 'vuex'

  export default {
    props: {
      needToFocus: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState({
        searchValue: state => state.search.value,
      }),
      showClearButton() {
        return this.searchValue?.length > 0
      },
      searchConfiguration() {
        return this.$staticDataService.cmsLayoutsDefault?.searchConfiguration
      },
      searchFieldPlaceholder() {
        return this.searchConfiguration.searchFieldPlaceholder ?? ''
      },
    },
    methods: {
      ...mapActions({
        setSearchValue: 'search/setValue',
      }),
      onSubmit() {
        const cleanInput = this.searchValue.trim()

        if (!cleanInput.length) {
          this.input = ''
        }

        this.$overlayService.closeFlyIn()

        if (this.$route.name.includes('search__')) {
          return
        }

        this.$router.push(this.localePath({ name: 'search', query: { q: cleanInput } })).catch(() => {})
      },
      clearInput() {
        this.setSearchValue({ value: '' })
      },
      handleInput(value) {
        this.setSearchValue({ value })
      },
    },
  }
