import {
  MAGENTO_PAYMENT_METHOD_CODES,
  MOLLIE_PAYMENT_METHODS_PREFIX,
} from '~/lib/constants'

/**
 * @typedef {OrderService}
 * @alias this.$orderService
 */
export class OrderService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
    this.i18n = context.i18n
    this.localePath = context.localePath
    this.router = context.app.router
  }

  init() {
    this.$userAddressService = this.context.$userAddressService
    this.$checkoutApiService = this.context.$checkoutApiService
    this.$cartService = this.context.$cartService
    this.$overlayService = this.context.$overlayService
  }

  /**
   * @returns {Promise}
   */
  async placeCartOrder() {
    const isAuthenticated = this.store.getters['user/isAuthenticated']
    const cartId = this.store.state.checkout.cartId

    if (!isAuthenticated && !cartId) {
      return Promise.reject(Error('Missing cartId'))
    }

    await this.store.dispatch('checkout/setProcessingOrder', true)

    const paymentMethod = this.store.state.checkout.selectedPaymentMethod
    const paymentMethodDetails = this.store.state.checkout.selectedPaymentMethodDetails
    const isMolliePaymentMethod = paymentMethod.id.startsWith(MOLLIE_PAYMENT_METHODS_PREFIX)

    // Check if Mollie payment, then create payment token
    const molliePaymentToken = isMolliePaymentMethod
      ? await this.$checkoutApiService.createMolliePaymentToken(cartId)
      : null

    // Create Magento order
    return this.$checkoutApiService
      .placeCartOrder({
        paymentMethod,
        paymentMethodDetails,
        cartId,
      })
      .then(async response => {
        if (!isAuthenticated) {
          return response
        }

        // If a new address was added in the checkout,
        // the user addresses will be updated in Magento
        await this.$userAddressService.fetchAddresses()

        // Set user address from user account if user used a custom address
        // Because this address is added to the account after a successful order.
        if (!this.store.state.checkout.shippingAddress?.id) {
          const defaultShippingAddress = this.store.getters['user/defaultShippingAddress']
          const defaultBillingAddress = this.store.getters['user/defaultBillingAddress']
          const addresses = this.store.state.user.addresses
          const shippingAddress = defaultShippingAddress ?? defaultBillingAddress ?? addresses[0]

          await this.store.dispatch('checkout/setShippingAddress', shippingAddress)
        }

        return response
      })
      .then(async response => {
        if (isMolliePaymentMethod) {
          return this.#handlePaymentIsMollie(molliePaymentToken)
        }

        const paymentInformation = {
          orderId: response.bold.order_id,
          key: response.bold.key,
        }

        // Handle Magento payment method
        if (MAGENTO_PAYMENT_METHOD_CODES.includes(paymentMethod.id)) {
          return this.#handlePaymentIsMagento(paymentInformation)
        }

        throw Error('Unsupported payment method')
      })
      .catch(error => {
        const applePaySession = this.store.state.checkout.applePaySession

        if (applePaySession) {
          applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
        }

        return Promise.reject(error)
      })
  }

  /**
   * @param {string} molliePaymentToken
   * @return {Promise<string>} Redirect URL
   */
  async #handlePaymentIsMollie(molliePaymentToken) {
    window.location = await this.$checkoutApiService
      .startMollieTransaction(molliePaymentToken)
  }

  /**
   * @param {Object} paymentInformation
   * @returns {Promise}
   */
  async #handlePaymentIsMagento(paymentInformation) {
    await this.store.dispatch('checkout/resetState', ['shippingAddress'])

    // Redirect to order confirmation page
    return this.router.replace(this.localePath(
      {
        name: 'order-confirmation',
        query: {
          order_id: paymentInformation.orderId,
          key: paymentInformation.key,
        },
      },
      this.i18n.locale,
    ))
  }

  /**
   * @param {string} cartId
   * @returns {Promise<boolean>}
   */
  getOrderIsPaid(cartId) {
    return this.$checkoutApiService
      .getOrderIsPaid(cartId)
      .catch(error => {
        if (error?.response?.status === 404) {
          // No order yet, so not paid
          return Promise.resolve(false)
        }

        return Promise.reject(error)
      })
  }
}
