
  import {
    APP_TRAY,
    SHOP_THE_LOOK_APP_TRAY_NAME,
  } from '~/lib/constants'

  export default {
    props: {
      products: {
        type: Array,
        required: true,
        validator(value) {
          if (!value.length) {
            return false
          }

          return value.every(item => item.sku)
        },
      },
      shopTheLookButtonIsLeft: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      skus() {
        return this.products.map(product => product.sku)
      },
    },
    methods: {
      onClick() {
        this.$overlayService.setCurrentOverlay({
          settings: {
            type: APP_TRAY,
            component: SHOP_THE_LOOK_APP_TRAY_NAME,
            options: { skus: this.skus },
          },
        })
      },
    },
  }
