/*
 * Simple products return a string for the sku,
 * while configurables return an array.
 * The first sku of a configurable is the primary one.
 */

module.exports = function (product) {
  return Array.isArray(product.sku)
    ? product.sku[0]
    : product.sku
}
