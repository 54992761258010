
  import { mapState, mapActions } from 'vuex'
  import debounce from 'lodash.debounce'
  import throttle from 'lodash.throttle'

  import {
    APP_TRAY,
    FLY_IN,
    PRODUCTS_FILTER_APP_TRAY_NAME,
    PRODUCTS_FILTER_FLY_IN_NAME,
  } from '~/lib/constants'

  import { APP_HEADER_HEIGHT_DESKTOP_BAR } from '~/lib/constants'

  const scrollThrottleDuration = 50

  export default {
    props: {
      hideProductCardSizeButtons: {
        type: Boolean,
        default: false,
      },
      hasMobileStickyHeader: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        onScrollStartDebounced: debounce(this.onScrollStart, scrollThrottleDuration, { trailing: false, leading: true }),
        onScrollEndDebounced: debounce(this.onScrollEnd, scrollThrottleDuration),
        onScrollThrottled: throttle(this.onScroll, scrollThrottleDuration),
        headerIsSticky: false,
        stickyHeaderIsHidden: false,
        stickyHeaderIsInitialized: false,
        scrollStartPosition: 0,
        previousScrollPosition: 0,
        scrollDirectionOnPreviousScrollEvent: null,
        filterObserver: null,
      }
    },
    computed: {
      ...mapState({
        activeCategory: state => state.category.activeCategory,
        appliedFiltersCount: state => state.category.appliedFiltersCount,
        categoryProductsLoading: state => state.category.categoryProductsLoading,
      }),
      categoryTitle() {
        return this.activeCategory ? this.activeCategory.name[0] : ''
      },
    },
    async mounted() {
      if (this.hasMobileStickyHeader) {
        window.addEventListener('scroll', this.onScrollStartDebounced)
        window.addEventListener('scroll', this.onScrollEndDebounced)
        window.addEventListener('scroll', this.onScrollThrottled)

        // Await until nuxt has restored scroll position, as this trigger scroll events too.
        // Otherwise the header will flicker on browser back
        setTimeout(() => {
          this.stickyHeaderIsInitialized = true
        }, 1000)
      }

      await this.$nextTick()

      this.addFilterObserver()
    },
    beforeDestroy() {
      if (this.hasMobileStickyHeader) {
        window.removeEventListener('scroll', this.onScrollStartDebounced)
        window.removeEventListener('scroll', this.onScrollEndDebounced)
        window.removeEventListener('scroll', this.onScrollThrottled)
      }

      if (this.filterObserver) {
        this.removeFilterObserver()
      }
    },
    methods: {
      ...mapActions({
        addFilterToAppHeader: 'addFilterToAppHeader',
        removeFilterFromAppHeader: 'removeFilterFromAppHeader',
      }),
      openFilter() {
        this.$overlayService.setCurrentOverlay({
          desktop: {
            type: FLY_IN,
            component: PRODUCTS_FILTER_FLY_IN_NAME,
            options: {
              transitionOrigin: 'right',
            },
          },
          mobile: {
            type: APP_TRAY,
            component: PRODUCTS_FILTER_APP_TRAY_NAME,
          },
        })
      },
      onScrollStart() {
        this.scrollStartPosition = window.scrollY
      },
      onScrollEnd() {
        // Set scroll position for when starting next scroll cycle
        this.scrollStartPosition = window.scrollY
      },
      onScroll() {
        const currentScrollPosition = window.scrollY
        const productsViewTopOffset = currentScrollPosition - this.$el.getBoundingClientRect().top
        const threshold = this.$refs?.header?.clientHeight || 0
        const scrollDirection = this.getScrollDirection()
        const userScrolledFarEnough = Math.abs(currentScrollPosition - this.scrollStartPosition) > threshold
        const shouldAlwaysShow = productsViewTopOffset < window.innerHeight

        if (shouldAlwaysShow || scrollDirection === 'up' && userScrolledFarEnough) {
          this.headerIsSticky = true
          this.stickyHeaderIsHidden = false
        } else if (scrollDirection === 'down' && userScrolledFarEnough) {
          this.stickyHeaderIsHidden = true
        }

        this.previousScrollPosition = currentScrollPosition

        if (scrollDirection !== this.previousScrollDirection) {
          this.scrollStartPosition = currentScrollPosition
          this.previousScrollDirection = scrollDirection
        }
      },
      getScrollDirection() {
        const scrollPosition = window.scrollY

        if (scrollPosition > this.previousScrollPosition) {
          return 'down'
        }

        if (scrollPosition < this.previousScrollPosition) {
          return 'up'
        }
      },
      addFilterObserver() {
        if (this.filterObserver || !this.$refs['filter-button']?.$el) {
          return
        }

        const options = {
          rootMargin: `-${ APP_HEADER_HEIGHT_DESKTOP_BAR }px`,
        }

        this.filterObserver = new IntersectionObserver(entities => {
          const isIntersecting = entities[0].isIntersecting

          if (isIntersecting) {
            this.removeFilterFromAppHeader()
          } else {
            this.addFilterToAppHeader()
          }
        }, options)

        this.filterObserver.observe(this.$refs['filter-button'].$el)
      },
      removeFilterObserver() {
        this.filterObserver?.disconnect()
        this.filterObserver = null

        this.removeFilterFromAppHeader()
      },
    },
  }
